import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { BsModalService } from 'ngx-bootstrap/modal';
import {Location} from '@angular/common';

@Component({
  selector: 'app-spotify-play',
  templateUrl: './spotify-play.component.html',
  styleUrls: ['./spotify-play.component.scss']
})
export class SpotifyPlayComponent implements OnInit {

  spotifySongId;
  constructor(
    // private route: ActivatedRoute,
    private sharedService: SharedService,
    private toastrService: ToastrService,
    private spinner: SpinnerVisibilityService,
    @Inject(DOCUMENT) private document: Document,
    private modalService: BsModalService,
    private route: Router,
    private _location: Location
  ) { 
    // this.route.queryParams.subscribe(params => {
    //   this.spotifySongId = params['id'];
    // });
  }

  spotifySongDetails;
  spotifySongType;

  ngOnInit(): void {
    this.spotifySongDetails = JSON.parse(localStorage.getItem("spotifySongDetails"));
    // this.spotifySongType = localStorage.getItem("spotifySongType");
    console.log(this.spotifySongDetails);
    // console.log(this.spotifySongType);
    this.checkAuthorization();
  }

  getSpotifySongDetails(id){
    return localStorage.getItem("spotifySongDetails");
  }

  songPlaying:boolean = false;
  songPaused:boolean = false;
  playOrResume(){
    if(this.spotifyAuthorized){
      this.playTheSong(this.spotifySongDetails.uri);
    }
    else{
      this.checkAuthorization();
    }
    
  }

  pause(){
    this.spotifyPlayerNew.pause();
    this.songPaused = true;
    this.songPlaying = false;
  }

  playTheSong(url){
    if(this.songPaused){
      this.spotifyPlayerNew.resume();
      this.songPaused = false;
      this.songPlaying = true;
      return;
    }
    $.ajax({
      url: "https://api.spotify.com/v1/me/player/play?device_id=" + this.device_id,
      type: "PUT",
      data: `{"uris": ["${url}"]}`,
      // data: `{"uris": ["}"]}`,
      beforeSend: (xhr)=>{xhr.setRequestHeader('Authorization', 'Bearer ' + this.spotifyToken );},
      success: (data) => { 
        console.log(data);
        this.songPlaying = true;
        this.songPaused = false;
        this.startProgress();

      },
      error:(arg1,arg2,arg3) => {
        console.log("Error occured while playing the song");
        console.log(arg1);
        console.log(arg2);
        console.log(arg3);
      }
    });
  }

  spotifyProgressBarInterval;
  startProgress(){
    this.spotifyProgressBarInterval = setInterval(()=>{
      this.spotifyPlayerNew.getCurrentState().then(state => {
        if (!state) {
          console.error('User is not playing music through the Web Playback SDK');
          return;
        }
       
        console.log(state.position);
        console.log(state.duration);
        if(state.position == 0){
          this.songPlaying = false;
          clearInterval(this.spotifyProgressBarInterval);
        }
        // this.spotifySongProgressBarValue = (state.position/state.duration)*100;
        // this.spotifySongPlayedTill = this.getTimeInMinuteAndSeconds(state.position);
        // console.log(this.spotifySongProgressBarValue);

        // if(this.already){
        // if(state.paused && !this.spotifySongProgressBarValue){
        // clearInterval(this.spotifyProgressBarInterval);
        // console.log("end");
        // // if(this.already){
        //   this.checkNextAndPlay();
        //   this.already = false;
        // }
        // if(!this.songEnded){
          // let timer = 0;
          // if(this.spotifySongSelectedIndex == this.audioList.length-1){
          //   timer = 4000;
          // }
          // else{
          //   timer = 1000;
          // }
          // setTimeout(()=>{
          //   this.checkNextAndPlay();
          // },timer);
          
          // this.songEnded = true;
        // }
    //     });
    // });
// }
        

      });
    },1000);
  }

  spotifyAuthorized:boolean;
  async checkAuthorization(){
    let spotifyTokenInfoParsed:any = await this.getSpotifyToken();
    // debugger;
    // spotifyTokenInfoParsed
    console.log(spotifyTokenInfoParsed);
    if(spotifyTokenInfoParsed){
      console.log(spotifyTokenInfoParsed);
      // let spotifyTokenInfoParsed = JSON.parse(spotifyTokenInfo);
      console.log(spotifyTokenInfoParsed);

      // Check token expired
      // new Date().getTime() - spotifyTokenInfoParsed.expiresAtDate) >= (30*60*1000
      if(new Date().getTime() >= (spotifyTokenInfoParsed.expiresAtDate - (30*60*1000))){
        let refreshTokenResponse =  await this.refreshSpotifyToken(spotifyTokenInfoParsed.refresh_token);
        refreshTokenResponse['expiresAtDate'] = (refreshTokenResponse.expires_in*1000) + new Date().getTime();
        this.updateSpotifyToken(refreshTokenResponse);
        this.spotifyTokenInfo = refreshTokenResponse;
        this.spotifyInitialization();
      }
      else{
        this.spotifyTokenInfo = spotifyTokenInfoParsed;
        this.spotifyInitialization();
        // this.spotifyAuthorized = false;
      }
      
    }
    else{
      // this.spotifyAuth();
      this.spotifyAuthorized = false;
    }
  }

  spotifyTokenInfo:any;

  updateSpotifyToken(data){
    this.sharedService.saveOrUpdateSpotifyToken(data).subscribe((res:any)=>{
      console.log(data);
    })
  }

  async refreshSpotifyToken(refresh_token){

    // b9568077e42a48ba89e30e3c7aa48b33
    let body = new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: refresh_token,
      client_id: '9dd84c590fe34a9f849c5b45df2046c0',
    });
    const response = await fetch('https://accounts.spotify.com/api/token', {
      method: 'POST',
      body:body,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    });

    const data = await response.json();

    console.log(data);

    if(data.error){
      this.spotifyAuth();
    }
    else{
      return data;
    }
  }

  async getSpotifyToken(){
    return new Promise((resolve,reject)=>{
      this.sharedService.getSpotifyAuthToken().subscribe((res:any)=>{
        console.log(res);
        if(res.data && res.data.access_token){
          resolve(res.data);
        }
        else{
          this.spotifyAuth();
        }
      },
      err => {
        console.log(err);
        this.toastrService.info("There is some issue with spotify.Please try after sometime");
        resolve('');
      })
    })
  }



  spotifyAuth(){
    console.log("from spotify-auth");
    // const clientId = 'b9568077e42a48ba89e30e3c7aa48b33';
    const clientId = '9dd84c590fe34a9f849c5b45df2046c0';

    // const redirectUri = 'http://localhost:4200/spotify-auth-redirect';

    const redirectUri = 'https://stg-indietown-web.approd.ca/spotify-auth-redirect';

    // const redirectUri = 'https://indietown-web.approd.ca/spotify-auth-redirect';

    let codeVerifier = this.generateRandomString(128);

    console.log("codeVerifier",codeVerifier);

    this.generateCodeChallenge(codeVerifier).then(codeChallenge => {
      let state = this.generateRandomString(16);
      console.log("state",state);

      let scope = 'streaming user-read-email user-modify-playback-state user-read-private';

      localStorage.setItem('code_verifier', codeVerifier);

      let args = new URLSearchParams({
        response_type: 'code',
        client_id: clientId,
        scope: scope,
        redirect_uri: redirectUri,
        state: state,
        code_challenge_method: 'S256',
        code_challenge: codeChallenge
      });

      this.document.location.href = 'https://accounts.spotify.com/authorize?' + args;
    });
  }

  generateRandomString(length) {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  async generateCodeChallenge(codeVerifier) {
    function base64encode(string) {
      return btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
    }
  
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
  
    return base64encode(digest);
  }

  spotifyToken;
  spotifyPlayerNew;
  device_id;
  async spotifyInitialization() {
    
    const script = document.createElement('script')
    script.src = 'https://sdk.scdn.co/spotify-player.js'
    script.type = 'text/javascript'
    script.addEventListener('load', e => {
      console.log(e)
    });
    document.head.appendChild(script);

    (<any>window).onSpotifyWebPlaybackSDKReady = () => {

      this.spotifyToken = this.spotifyTokenInfo.access_token;
      let token = this.spotifyToken;
      this.spotifyPlayerNew = new (<any>window).Spotify.Player({
        name: 'Web Playback SDK Quick Start Player',
        getOAuthToken: cb => { cb(token); },
        volume: 0.5
      }, (res:any)=> {
        // this.spinner.hide();
      });

      console.log(this.spotifyPlayerNew);

      this.spotifyPlayerNew.addListener('ready', ({ device_id }) => {
        console.log('Ready with Device ID', device_id);
        this.device_id = device_id;
      });
    
      // Not Ready
      this.spotifyPlayerNew.addListener('not_ready', ({ device_id }) => {
        console.log('Device ID has gone offline', device_id);
      });

      this.spotifyPlayerNew.addListener('player_state_changed', state => {
        // May Required
        // this.currState["paused"] = state?.paused;
        // this.currState["position"] = state?.position;
        // this.currState['duration'] = state?.duration;
        // this.currState['updateTime'] = performance.now();
      });

      this.spotifyPlayerNew.connect();
      this.spotifyAuthorized = true;
    }

  }

  ngOnDestroy(){
    console.log("from destroy");
    console.log("paused");
    if(this.spotifyPlayerNew){
      this.spotifyPlayerNew?.disconnect();
      clearInterval(this.spotifyProgressBarInterval);
    }
  }

  goBack(){
    this._location.back();
  }

}
