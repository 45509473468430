<app-layout></app-layout>

<div style="margin-left: 14%; padding-top: 100px;padding-bottom: 2px;background-color: #F6F6F6;padding-left: 4%;">
    <div class="about-container spotify-container" style="background-color: #F6F6F6;">
        <div class="d-flex mb-4">
            <span><img src="assets/Indietown_icons/Spotify_Logo_RGB_Green.png" /></span>
        </div>
    </div>
    <div class="d-flex" style="background-color: #F6F6F6;">
        <!-- <div *ngFor="let track of spotifyList; let spotifyIndex = index" class="d-inine-block mr-4"> -->
            <div class="cursorpointer">
                <img [src]=" spotifyPlaylistDetails?.imageUrl" alt="" height="265" width="265" style="object-fit: cover;"/>
            </div>
            <div class="mt-2 cursorpointer d-flex ml-4" style="flex-direction: column;">
                <div class="track-name d-inline-block">{{ spotifyPlaylistDetails?.name }}</div>
                <div class="track-artist-name d-inline-block mt-3">{{ spotifyPlaylistDetails?.owner?.display_name }}</div>
                <!-- <div class="mt-5">
                    <img src="assets/Indietown_icons/Heart-Shape.png">
                </div> -->
            </div>
            
        <!-- </div> -->
    </div>
    <!-- width: 75px;height: 75px;border-radius: 50%; -->
    <div class="mt-4 mb-4" style="cursor: pointer;" (click)="playSpotifyPlaylistSongs()" *ngIf="!songPlaying || songPaused">
        <img src="assets/Indietown_icons/Group_117127507_2.png" alt="" style="">
    </div>
    <div (click)="pause()" class="mt-4 mb-4" *ngIf="songPlaying" style="cursor: pointer;">
        <img src="assets/Indietown_icons/Group 1171275074.png" alt="">
    </div>
    
</div>

<div class="mt-4" style="margin-left: 18%;cursor: pointer;">
    <div style="padding: 20px;
    padding-left: 66px;
    margin-left: -66px;" *ngFor="let track of spotifySongsList; let spotifyIndex = index" class="d-flex" [ngClass]="{highlight : (spotifyIndex == currentIndex) && (songPlaying || songPaused)}" (click)="navigateToSong(spotifyIndex)">
        <div>
            <img [src]="track?.imageUrl" height="80" width="80" />
        </div>
        <div class="ml-2" style="display: flex;
        flex-direction: column;
        justify-content: center;">
            <div style="font-family: 'Poppins';
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;">{{ track?.name }}</div>
            <div style="font-weight: 400;
            font-size: 18px;
            line-height: 27px;">{{ track?.artists[0]?.name }}</div>
        </div>
        <!-- <div class="col-1 col-md-1 mob-md-1 cursorpointer" (click)="navigateToSelectedSong('singleTrack',spotifyIndex,'')">
            <img [src]="track?.imageUrl" alt="" height="60" width="60" style="border-radius: 15px" />
        </div>
        <div class="col-lg-7 col-md-7 mob-md-7 cursorpointer" style="" (click)="navigateToSelectedSong('singleTrack',spotifyIndex,'')">
            <p class="spot-title">{{ track?.name }}</p>
        </div>
        <div  style="margin-top: 0px;
                            margin-left: 43px;
justify-content: flex-end;" class="spotifybtn d-flex col-lg-3 col-md-3">
            <button *ngIf="!userId" (click)="openRemoveSpotifyModal('single',spotifyIndex,'')" type="button" style="border:1px solid #E9E9EB;
            margin-top: 7px;
        " class="btn btn-light">Remove</button>
            <p class="spot-time" style="    margin-left: 12px;
            margin-top: 10px;">
                {{ getTimeInMinuteAndSeconds(track?.items[0].duration_ms) }}
            </p>
        </div> -->
    </div>
</div>
