
<app-layout *ngIf="showSiidbar"></app-layout>

<div class="row mt-3 dynamicprofile" style="margin-left: 18%">
  <div class="col-lg-12">
    <div class="about-profile" style="margin-top: 5%; position: relative">
      <span *ngIf="postId" class="position-absolute starimage" style="right: 119px;top: 20px;"> 
        <span *ngIf="(profileDetails?.friendStatus == 'FRIEND') && (!profileDetails?.isTopFriend)" (click)="makeFavouriteFriend()"><img style="cursor: pointer;
          background: #000000;
          border-radius: 50px;" src="../../../assets/Indietown_icons/starimg.svg" alt=""></span>
        <span *ngIf="(profileDetails?.friendStatus == 'FRIEND') && (profileDetails?.isTopFriend)" (click)="makeUnFavouriteFriend()"><img style="cursor: pointer;
          background: #000000;
          border-radius: 50px;" src="../../../assets/Indietown_icons/starimgfill.svg" alt=""></span>

<!-- (click)="reportNonFriend()" -->
        <div class="btn-group" dropdown placement="bottom right" *ngIf="(profileDetails?.friendStatus != 'FRIEND')">
          <div id="button-alignment" class="menubuttonn" dropdownToggle type="button"
          aria-controls="dropdown-alignment"><img style="cursor: pointer;" src="../../../assets/Indietown_icons/Report-Icon-Latest.svg" alt=""></div>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right headerdropdown" role="menu"
              aria-labelledby="button-alignment" style="background: #f8f9f8;
              box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
              border-radius: 15px;
              ">
              <li role="menuitem" style="cursor: pointer;" (click)="reportNonFriend()"><span class="dropdown-item admin-dropdown">Report User</span></li>
          </ul>
        </div>
        <!-- <img
          src="../../../assets/Indietown_icons/top-four.svg"
          alt=""
          class="top-4"
          *ngIf="profileDetails?.isMyFavrouite"
          (click)="makeUnFavouriteFriend()"
        /> -->
      </span>

      <!-- height="290px" -->
      <img
        class="aboutprofimg"
        (click)="navigateToPhotoAlbum(profileDetails?.coverImage)"
        [src]="
          profileDetails?.coverImage?.fileUrl
            ? profileDetails?.coverImage?.fileUrl
            : coverPicUrl
        "
        alt=""
        width="93%"
        style="border-radius: 15px 15px 0px 0px; cursor: pointer;height: 100% !important;"
        [ngStyle]="{'object-fit': profileDetails?.coverImage?.fileUrl ? 'cover' : 'fill' }"
      />
      
      <span class="camera-image pe-auto" *ngIf="!postId">
        <!-- routerLink="/profile/update/cover" -->
        <!-- (click)="openEditModal()" -->
        <img *ngIf="!profileDetails?.coverImage?.fileUrl"
          src="../../../assets/Indietown_icons/camera.png"
          
          routerLink="/profile/update/cover"
          alt=""
          height="45"
          width="45"
          style="cursor: pointer;"
        />

        <!-- routerLink="/profile/update/cover" -->
        <!-- (click)="openEditModal()" -->
        <img *ngIf="profileDetails?.coverImage?.fileUrl"
          src="../../../assets/Indietown_icons/edit-icon2.svg"
          
          routerLink="/profile/update/cover"
          alt=""
          height="45"
          width="45"
          style="cursor: pointer;"
        />
      
      </span>
      <span class="profile-image" [ngClass]="{'othersprofileimage':postId}">
        <img
          class="profileImageTop"
          [ngClass]="{'profileuderpicture' : profileDetails?.friendStatus != 'FRIEND'}"
          [src]="
            profileDetails?.profileImage?.fileUrl
              ? profileDetails?.profileImage?.fileUrl
              : profilePicUrl
          "
          alt=""
          style="border-radius: 100%;cursor: pointer;"
          height="160"
          width="160"
          (click)="navigateToPhotoAlbum(profileDetails?.profileImage)"
        />

        <span  *ngIf="!postId">
          <!-- routerLink="/profile/update" -->
          <!-- (click)="openEditModal()" -->
          <img
            class="profileImageCameraTop pe-auto"
            *ngIf="!profileDetails?.profileImage?.fileUrl"
            src="../../../assets/Indietown_icons/camera.png"
            alt=""
            routerLink="/profile/update"
            style="border-radius: 100%; cursor: pointer"
            height="45"
            width="45"
          />
          <!-- routerLink="/profile/update" -->
          <!-- (click)="openEditModal()" -->
          <img
            class="profileImageCameraTop pe-auto"
            *ngIf="profileDetails?.profileImage?.fileUrl"
            src="../../../assets/Indietown_icons/edit-icon2.svg"
            alt=""
            routerLink="/profile/update"
            style="border-radius: 100%; cursor: pointer"
            height="45"
            width="45"
          />
        </span>
        <!-- top: 297px -->
        <div
          style="position: absolute; left: 17%; top:512px ; width: 50%"
          class="profilenamebox"
        >
          <h2 class="profileband">
            {{ profileDetails?.bandName | titlecase }}
          </h2>
          <p class="profilegenre" *ngIf="profileDetails?.role == 'Community'">
            <span>{{ profileDetails?.communityIds[0]?.communityName }}</span>
            <span *ngIf="profileDetails?.communityIds[1]">
              | {{ profileDetails?.communityIds[1]?.communityName }}</span
            >
          </p>
          <p class="profilegenre" *ngIf="profileDetails?.role == 'Artist'" style="line-height: 20px;">
            <span>{{ profileDetails?.genereIds[0]?.genereName }}</span
            ><span *ngIf="profileDetails?.genereIds[1]">
              | {{ profileDetails?.genereIds[1]?.genereName }}</span
            ><span *ngIf="profileDetails?.genereIds[2]">
              | {{ profileDetails?.genereIds[2]?.genereName }}</span
            >
          </p>
          <p class="profilegenre" style="line-height: 20px;">
            <span>{{ profileDetails?.address?.city ? profileDetails?.address?.city + ', ':'' }}{{ profileDetails?.address?.state }} {{ profileDetails?.address?.country }}</span>
          </p>
        </div>
      </span>

      
      <div class="edit" *ngIf="!postId">
        <div class="btn-group d-inline-block mobile-logout" dropdown placement="bottom right" style="position: absolute;right: 9%;
        top: 33px;">
          <div id="button-alignment" class="menubuttonn" dropdownToggle type="button"
              aria-controls="dropdown-alignment" style="height: 19.95px; z-index: 9999;cursor: pointer;">
              <span class="first"></span>
              <span class="second"></span>
              <span class="last"></span>
          </div>                    
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right headerdropdown" role="menu"
              aria-labelledby="button-alignment" style="background: #f8f9f8;
              box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
              border-radius: 15px;
              ">
              <li role="menuitem" routerLink="/settings"><span class="dropdown-item admin-dropdown" id="active-remove"><span><img class="icons" style="margin-right: 10px;width: 20px; height: 20px;" src="assets/Indietown_icons/seetings.png" alt=""> </span> Settings</span></li>
              <div class="dropdown-divider"></div>
              <li style="cursor: pointer;" role="menuitem" (click)="openLogoutModal(template)" ><span class="dropdown-item admin-dropdown" id="active-remove"><span><img style="margin-right: 10px;width: 20px; height: 20px" class="icons" src="assets/Indietown_icons/logout.png" alt=""> </span> Log Out</span></li>
          </ul>
        </div>
        <div class="edit-profile" (click)="openEditModal()" id="editProfileBtn">
          <div>
            <span class="pe-auto">
              <img src="../../../assets/Indietown_icons/Edit.svg" alt="" />
            </span>
            <span
              style="
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 14px;
                color: #0f0d0f;
              "
            >
              Edit Profile
            </span>
          </div>
        </div>
        <div class="add-playlist" (click)="openAddPlaylistModal()">
          <button class="btn" title="Add Music + Videos (Spotify + YouTube + Bandcamp + SoundCloud)">Add Playlist</button>
        </div>
        
        <div class="view-spotify-playlist-btn" style="position: absolute;
        display: flex;
        right: 37%;
        top: 15px;
        width: 214px;
        height: 50px;
        background: rgba(0, 0, 0, 1);
        border-radius: 15px;
        text-align: center;
        padding-left: 10px;
        align-items: center;" (click)="navigateToSpotify()">
          <img style="width: 28px;height: 28px;" src="assets/Indietown_icons/Spotify_Icon_RGB_Green_only_logo.png" alt="">
          <button style="font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 14px;
          color: white;
          border: none;
          cursor: pointer;
          background: none;
          vertical-align: initial !important;
          padding: 0;" class="ml-2 view-spotify-playlist-btn-btn">View Spotify Playlist</button>
        </div>
      </div>
      <!-- <div class="add-playlist" (click)="openAddPlaylistModal()">
        <button>Add Playlist</button>
      </div> -->

      <div *ngIf="postId" class="mt-2">
        <!-- *ngIf="profileDetails?.friendStatus == 'FRIEND'" -->
        <div class="view-spotify-playlist-btn" style="position: absolute;
        display: flex;
        right: 31%;
        /* top: 15px; */
        width: 214px;
        height: 50px;
        background: rgba(0, 0, 0, 1);
        border-radius: 15px;
        text-align: center;
        padding-left: 10px;
        align-items: center;" (click)="navigateToSpotify()">
          <img style="width: 28px;height: 28px;" src="assets/Indietown_icons/Spotify_Icon_RGB_Green_only_logo.png" alt="">
          <button style="font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 14px;
          color: white;
          border: none;
          cursor: pointer;
          background: none;
          vertical-align: initial !important;
          padding: 0;" class="ml-2 view-spotify-playlist-btn-btn">View Spotify Playlist</button>
        </div>
        <button
          class="btn btn-primary addfriendtxt"
          style="
            height: 50px;
            width: 120px;
            border-radius: 8px;
            background-color: black;
            border: solid 1px black;
            position: absolute;
            right: 20%;
          "
          id="addFriendButton{{ postId }}"
          (click)="navigateToMessages()"
        >
        Message
        </button>

        <div dropdown class="mt-3" *ngIf="profileDetails?.friendStatus == 'FRIEND'">
          <div dropdownToggle class="unfriend-toggle">
            <div class="btn-group dropup unfriend" placement="bottom right">
              <div
                id="button-alignment"
                type="button"
                aria-controls="dropdown-alignment"
                style="padding: 15px 15px 15px 21px;
                border-radius: 8px;"
                
              >
                <span class="frienddtxt">Friend</span>
                <span
                  ><img
                  style="    margin-left: 9px;"
                    src="../../../assets/Indietown_icons/dropdown.png"
                    height="20"
                    width="20"
                    alt=""
                /></span>
              </div>
              <ul
                id="dropdown-alignment"
                *dropdownMenu
                class="dropdown-menu dropdown-menu-right"
                role="menu"
                aria-labelledby="button-alignment"
                style="background: rgb(248, 249, 248);
                box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 60px;
                border-radius: 15px;
                z-index: 9;
                inset: 100% 0px auto auto;
                transform: translateY(0px);"
              >
                <li class="menuitem" role="menuitem">
                  <a
                    class="dropdown-item admin-dropdown"
                    (click)="onClickUnfriendUser()"
                  >
                    Unfriend
                  </a>
                </li>
                <div class="dropdown-divider"></div>
                <li class="menuitem" style="cursor: pointer" role="menuitem">
                  <a
                    class="dropdown-item admin-dropdown"
                    (click)="onClickreportProfile()"
                    >Report User</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <button
          *ngIf="profileDetails?.friendStatus == 'NOT_FRIEND'"
          class="btn btn-primary addfriendtxt"
          [ngClass]="{'buttonuserpicture' : profileDetails?.friendStatus != 'FRIEND'}"
          style="
            height: 50px;
            width: 150px;
            border-radius: 8px;
            background-color: black;
            border: solid 1px black;
            position: absolute;
            right: 7%;
          "
          (click)="addFriendImpl()"
          id="addFriendButton{{ postId }}"
          >
          Add Friend
          </button>
        </div>
        <div>
          <!-- width: 120px; -->
          <button
          *ngIf="profileDetails?.friendStatus == 'REQUEST_SENT'"
          class="btn btn-primary addfriendtxt"
          style="
            height: 50px;
            width: 150px;
            border-radius: 8px;
            background-color: white;
            border: solid 1px black;
            position: absolute;
            right: 7%;
            color: black;
          "
          (click)="AddFriend()"
                id="addFriendText{{ postId }}"
          >
          <!-- Cancel Request -->
          Pending
          </button>
          <button
          *ngIf="profileDetails?.friendStatus == 'ACCEPT_REQUEST'"
          class="btn btn-primary addfriendtxt"
          style="
            height: 50px;
            width: 150px;
            border-radius: 8px;
            background-color: white;
            border: solid 1px black;
            position: absolute;
            right: 7%;
            color: black;
          "
          (click)="AddFriend()"
                id="addFriendText{{ postId }}"
          >
          Accept
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12 dynamictabs" [ngClass]="{'dynamictabothers': postId}" style="margin-top: 7.8%">
    <mat-tab-group
      [(selectedIndex)]="selectedIndex"
      (selectedTabChange)="tabChanged($event)"
      #tabGroup
    >
      <!-- All Posts Start -->
      <mat-tab label="Posts">
        <!-- <ng-template matTabContent> -->
          <!-- routerLink="/home" -->
          <!-- *ngIf="!timelineVisible" -->
          <!-- [queryParams]="{ post: 1, timeline: true }" -->
          <div
            class="d-flex"
            
            style="margin-bottom: 21px"
            
            *ngIf="(!postId) || (postId && profileDetails?.friendStatus == 'FRIEND')"
          >
            <span *ngIf="!postId">
              <img
                [src]="
                  profileDetails?.profileImage?.fileUrl
                    ? profileDetails?.profileImage?.fileUrl
                    : '../../../../assets/Indietown_icons/upload-profile-pic.png'
                "
                height="50"
                width="50"
                alt=""
                style="
                  border-radius: 100%;
                  left: 0 !important;
                  top: 0px !important;
                "
              />
            </span>
            <span *ngIf="postId && profileDetails?.friendStatus == 'FRIEND'">
              <img
                [src]="
                myProfileDetails?.profileImage?.fileUrl
                    ? myProfileDetails?.profileImage?.fileUrl
                    : '../../../../assets/Indietown_icons/upload-profile-pic.png'
                "
                height="50"
                width="50"
                alt=""
                style="
                  border-radius: 100%;
                  left: 0 !important;
                  top: 0px !important;
                "
              />
            </span>
            <!-- [placeholder]="
            showSiidbar == false
              ? 'What’s on your mind?'
              : 'Comment on your friends timeline'
            " -->
            <input
              class="form-control showbox"
              id="search"
              type="text"
              *ngIf="(postId && profileDetails?.friendStatus == 'FRIEND') || (!postId)"
              [placeholder]="
                showSiidbar == false
                  ? 'What\'s new with you?'
                  : 'Comment on ' + unFriendUserName + '\'s timeline'
              "
              aria-label="Search"
              ngModel
              name="search"
              readonly
              (click)="openCreatePost()"
              style="margin-left: 10px; width: 55%; height: 50px; font-size: 16px;background: rgba(233, 233, 235, 0.3);
              border: 1px solid #E9E9EB;
              border-radius: 15px; cursor: pointer;"
            />
            <!-- *ngIf="showprogressBar" -->
            
          </div>
          <div class="form-group ml-2"  style="
            height: 8px;
            transform: translate(0px, 19px);max-width: 58%;" *ngIf="showprogressBar">
              <div>
                <mat-progress-bar mode="determinate" [value]="progress" class="top-progressbar"></mat-progress-bar>
              </div>
        
            </div>
          <!-- <mat-progress-bar mode="determinate" [value]="progress" class="top-progressbar"></mat-progress-bar> -->
          
          <div class="row" style="margin: 0px">
            
            <div class="col-lg-7 mtb-70 photoalbum7 postssec h-700" style="padding-left: 0px;" infinite-scroll [scrollWindow]="false"
            (scrolled)="onScrollDownPosts()" [infiniteScrollDistance]="8">
              <!-- infinite-scroll
              [scrollWindow]="true"
              infinite-scroll-disabled="stopScrollingPost"
              (scrolled)="onScrollDown($event)"
              [infiniteScrollDistance]="0.07" -->
            <!-- <div class="col-lg-7 "> -->
              <mat-card
              class="post-card mt-2"
              *ngFor="let post of allPosts; let i = index" 
              
            >
              <mat-card-header class="d-flex align-items-center" style="margin-bottom: 12px;">
                <img
                  mat-card-avatar
                  [src]="post?.userId?.profileImage?.fileUrl ? post?.userId?.profileImage?.fileUrl : '../../../../assets/Indietown_icons/upload-profile-pic.png'"
                  alt=""
                  width="50" height="50" 
                />
                <mat-card-title class="mt-2 profilebandname">{{
                  post?.userId?.bandName |titlecase
                }}</mat-card-title>
                <span class="reportIcon d-inline-block ml-auto">
                  <div class="btn-group" dropdown placement="bottom right">
                    <div
                      id="button-alignment"
                      dropdownToggle
                      type="button"
                      aria-controls="dropdown-alignment"
                    >
                      <img
                        src="../../../assets/Indietown_icons/report-icon.png"
                        alt=""
                        style="cursor: pointer"
                      />
                    </div>
                    <ul
                      id="dropdown-alignment"
                      *dropdownMenu
                      class="dropdown-menu dropdown-menu-right"
                      role="menu"
                      aria-labelledby="button-alignment"
                      style="background: #f8f9f8;
                      box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
                      border-radius: 15px; z-index: 9;"
                    >
                  <!-- USER PROFILE -->
                    <div >
                      <li *ngIf="(postId) && (post?.userId?._id != myProfileDetails?._id)" class="menuitem" role="menuitem">
                        <a
                          class="dropdown-item admin-dropdown"
                          (click)="openReportDialog(post?._id,post.type=='POST'?'Post':'Show')"
                          >Report {{post.type=='POST'?'Post':'Show'}}</a
                        >
                      </li>
                      <div *ngIf="(postId) && (post?.userId?._id != myProfileDetails?._id)"  class="dropdown-divider"></div>
                      <li *ngIf="(postId) && (post?.userId?._id != myProfileDetails?._id)"  class="menuitem" style="cursor: pointer" role="menuitem"
                      (click)="onClickSilentPost(post?.userId?._id,i)">
                      <a class="dropdown-item admin-dropdown">Silence User</a>
                    </li>
                    <!-- our post -->
                    <li *ngIf="(post?.userId?._id == myProfileDetails?._id)" class="menuitem" style="cursor: pointer" role="menuitem"
                    (click)="onClickEditPost(post)">
                    <a class="dropdown-item admin-dropdown">Edit {{post.type=='POST'?'Post':'Show'}}</a>
                  </li>
                  <div *ngIf="(post?.userId?._id == myProfileDetails?._id)" class="dropdown-divider"></div>
                  <li *ngIf="(post?.userId?._id == myProfileDetails?._id)" class="menuitem" style="cursor: pointer" role="menuitem"
                    (click)="onClickDeletePost(post?._id,post)">
                    <a class="dropdown-item admin-dropdown">Delete {{post?.type=='POST'? 'Post':'Show'}}</a>
                  </li>
                    </div>
                    <!-- PROFILE -->
                    <div *ngIf="!postId">
                      <div *ngIf="post?.createdFor">
                        <li class="menuitem" role="menuitem">
                          <a
                            class="dropdown-item admin-dropdown"
                            (click)="openReportDialog(post?._id,post.type=='POST'?'Post':'Show')"
                            >Report {{post.type=='POST'?'Post':'Show'}}</a
                          >
                        </li>
                        <div class="dropdown-divider"></div>
                        <!-- <li class="menuitem" style="cursor: pointer" role="menuitem"
                          (click)="onClickDeletePost(post?._id,post)">
                          <a class="dropdown-item admin-dropdown">Delete {{post?.type=='POST'? 'Post':'Show'}}</a>
                        </li> -->
                        <li class="menuitem" style="cursor: pointer" role="menuitem"
                          (click)="onClickSilentPost(post?.userId?._id,i)">
                          <a class="dropdown-item admin-dropdown">Silence User</a>
                        </li>
                      </div>
                      <div *ngIf="!post?.createdFor">
                        <li class="menuitem" style="cursor: pointer" role="menuitem"
                          (click)="onClickEditPost(post)">
                          <a class="dropdown-item admin-dropdown">Edit {{post.type=='POST'?'Post':'Show'}}</a>
                        </li>
                        <div class="dropdown-divider"></div>
                        <li class="menuitem" style="cursor: pointer" role="menuitem"
                          (click)="onClickDeletePost(post?._id,post)">
                          <a class="dropdown-item admin-dropdown">Delete {{post?.type=='POST'? 'Post':'Show'}}</a>
                        </li>
                      </div>
                    </div>
                      <!-- <li class="menuitem" role="menuitem"  *ngIf="post?.userId?._id != profileDetails?._id">
                        <a
                          class="dropdown-item admin-dropdown"
                          (click)="openReportDialog(post)"
                          >Report {{post.type=='POST'?'Post':'Show'}}</a
                        >
                      </li>
                      <div class="dropdown-divider" *ngIf="post?.userId?._id != profileDetails?._id"></div>
                      <li
                        class="menuitem"
                        style="cursor: pointer"
                        role="menuitem"
                        (click)="onClickSilentPost(post?.userId?._id,i)" *ngIf="post?.userId?._id != profileDetails?._id"
                      >
                        <a class="dropdown-item admin-dropdown">Silence User</a>
                      </li>
        
                      <li class="menuitem" style="cursor: pointer" role="menuitem"
                        (click)="onClickDeletePost(post?._id,post)" *ngIf="post?.userId?._id == profileDetails?._id">
                        <a class="dropdown-item admin-dropdown">Delete {{post?.type=='POST'? 'Post':'Show'}}</a>
                      </li>
        
                      <div class="dropdown-divider" *ngIf="post?.userId?._id ==profileDetails?._id"></div>
        
                      <li class="menuitem" style="cursor: pointer" role="menuitem"  *ngIf="post?.userId?._id == profileDetails?._id"
                        (click)="onClickEditPost(post)">
                      <a class="dropdown-item admin-dropdown">Edit {{post.type=='POST'?'Post':'Show'}}</a>
                    </li> -->
                    </ul>
                  </div>
                </span>
              </mat-card-header>

              <carousel [noWrap]="noWrapSlides" [showIndicators]="showIndicator" [interval]="22000000000" [isAnimated]="true" loop="false">
                <slide *ngFor="let media of post?.media; let index=index">
                  <!-- <img *ngIf="media.type=='image'" class="d-block w-100 postimgs" style="border-radius:20px; object-fit: contain; height: 582px;" [src]="media.fileUrl" alt="First slide"> -->
                  <!-- height: 582px; -->
                  <img *ngIf="media.type=='image'" class="d-block w-100 postimgs" style="border-radius:20px; object-fit: cover; height: auto;" [src]="media.fileUrl" alt="First slide">
                  <!-- height: 582px; -->
                    <video id="homePostVideo" *ngIf="media.type=='video'" (playing)="onPlayingVideo($event)" class="d-block w-100 postvideos" [src]="media.fileUrl" style="border-radius:20px; object-fit: cover;" [height]="getDisplayVideoHeight(media)" controls [poster]="media.thumbnail"></video>
                    <!-- post?.media -->
                </slide>
            </carousel>


                <!--Post Like Comments Shares Buttons Start-->
                <mat-card-actions class="d-flex mt-1 mb-0 ml-0 mr-0 p-0" *ngIf="post?.media.length>0" style="flex-direction: column">
                    <div class="d-flex justify-content-between" style="
                    padding-top: 10px; margin-left: 0">
                      <div class="d-flex w-70" style="width:80%; margin-left: 0">
                      <div class="d-flex align-items-center">
                        <span (click)="likePost(post?._id)" *ngIf="!post?.isLikedByMe" id="likeIcons{{post?._id}}"><img src="../../../assets/Indietown_icons/Heart.svg" alt="" id="likeImage{{post?._id}}"></span>
                        <span (click)="dislikePost(post?._id)" *ngIf="post?.isLikedByMe" id="dislikeIcons{{post?._id}}"><img src="../../../assets/Indietown_icons/like-dark.svg" alt="" id="dislikeImage{{post?._id}}"></span>
        
        
                        <span style="font-size: 12px; margin-left: 11px; width: 53px;" (click)="openLikesModal(post?._id)" class="countText" id="totalLikeCount{{post?._id}}">{{ post?.likesCount }} {{post?.likesCount<=1 && post?.likesCount!=0?'Like':'Likes'}}</span>
                    </div>
                    <div class="d-flex align-items-center" style="margin-left: 15px;">
                        <span><img src="../../../assets/Indietown_icons/Chat-square.svg"  (click)="commentAreaEnable(i)" alt=""></span>
                        <span style="font-size: 12px; margin-left: 11px" class="countText" (click)="loadMoreComments(post?._id)" id="totalcommentCount{{post?._id}}">{{ post?.commentCount }}</span>
                    </div>
                    <!-- <div class="" style="margin-left: 26px;">
                        <img src="../../../assets/Indietown_icons/share.svg" alt=""/>
                    </div> -->
                    </div>
                    <div>
                      <!-- <span>{{ post?.createdOn | date : "HH:mm" }}{{changeTime(post?.createdOn)}}</span> -->
                      <span style="color: #0F0D0F;

                      opacity: 0.5;" class="changetimee">{{timeSince(post?.createdOn)}}</span>
                    </div>
                    </div>
                    <div style="margin-top: 1%;">
                      <!-- <p class="postdesc" [ngClass]="{'fw-600':post.type=='SHOW'}">
                        {{ post.text }}
                      </p> -->
                      <!-- <h3 [innerHtml]="post.text ? (post.text | safeHtml) : ''" 
                      [ngClass]="{'fw-600':post.type=='SHOW'}"
                      class="text-justify postdesc"></h3> -->

                    <div *ngIf="post?.type=='POST'">
                      <span *ngIf="post?.text?.length>200" [innerHtml]="post.text ? (post.text.slice(0,200) | linky:{stripPrefix : false}) : ''"
                        [ngClass]="{'fw-600':post.type=='SHOW'}"
                        class="text-justify postdesc postdescspan formatted" id="lessDesc{{post?._id}}" style="font-size: 16px;font-weight: 500;"></span>

                        <span *ngIf="post?.text?.length<200" [innerHtml]="post.text ? (post.text | linky:{stripPrefix : false}) : ''"
                        [ngClass]="{'fw-600':post.type=='SHOW'}"
                        class="text-justify postdesc postdescspan formatted" id="lessDesc{{post?._id}}" style="font-size: 16px;font-weight: 500;"></span>
    
                      <span (click)="showMoreDesc(post?._id)" id="moreBtn{{post?._id}}" *ngIf="post?.text?.length>200" style="cursor: pointer;font-weight: 600;    text-transform: lowercase;color: black !important;">... more</span>
                    
                        <span [innerHtml]="post.text ? (post.text | linky:{stripPrefix : false}) : ''"
                        [ngClass]="{'fw-600':post.type=='SHOW'}"
                        class="text-justify postdesc postdescspan formatted" style="display: none;font-size: 16px;font-weight: 500;" id="moreDataDesc{{post?._id}}"></span>
    
                        <span (click)="showLessDesc(post?._id)" id="lessBtn{{post?._id}}" style="display: none;cursor: pointer;font-weight: 600;    text-transform: lowercase;color: black !important;"> less</span>
                      </div>
      
                    </div>

                    <div *ngIf="post?.type=='SHOW'">
                      <span [innerHtml]="post.text ? (post.text | linky:{stripPrefix : false}) : ''"
                      [ngClass]="{'fw-600':post.type=='SHOW'}"
                      class="text-justify postdesc" style="font-size: 16px;"></span>
                    </div>

                </mat-card-actions>
          <!--Post Like Comments Shares Buttons End-->
              <mat-card-content>

                  <!--Post Like Comments Shares Buttons Start-->
                  <mat-card-actions class="d-flex ml-0 mr-0 p-0" *ngIf="post?.media.length==0" style="flex-direction: column;">
                    <!-- <p class="postdesc" [ngClass]="{'fw-600':post.type=='SHOW'}">
                      {{ post.text }}
                    </p> -->

                  <div>
                    <span *ngIf="post?.text?.length>400" [innerHtml]="post.text ? (post.text.slice(0,400) | linky:{stripPrefix : false}) : ''"
                      [ngClass]="{'fw-600':post.type=='SHOW'}"
                      class="text-justify postdesc postdescspan formatted" id="lessDesc{{post?._id}}" style="font-size: 16px;font-weight: 500;"></span>
                  
                      <!-- ? (post.text | linky:{stripPrefix : false}) : '' -->
                      <span *ngIf="post?.text?.length<400" [innerHtml]="post.text ? (post.text | linky:{stripPrefix : false}) : ''"
                      [ngClass]="{'fw-600':post.type=='SHOW'}"
                      class="text-justify postdesc postdescspan formatted" id="lessDesc{{post?._id}}" style="font-size: 16px;font-weight: 500;"></span>

                    <span (click)="showMoreDesc(post?._id)" id="moreBtn{{post?._id}}" *ngIf="post?.text?.length>400" style="cursor: pointer;font-weight: 600; text-transform: lowercase;color: black !important;">... more</span>
                    
                      <span [innerHtml]="post.text ? (post.text | linky:{stripPrefix : false}) : ''"
                      [ngClass]="{'fw-600':post.type=='SHOW'}"
                      class="text-justify postdesc postdescspan formatted" style="display: none;font-size: 16px;font-weight: 500;" id="moreDataDesc{{post?._id}}"></span>

                      <span (click)="showLessDesc(post?._id)" id="lessBtn{{post?._id}}" style="display: none;cursor: pointer;font-weight: 600;     text-transform: lowercase;color: black !important;"> Less</span>
                    </div>
    
                    <!-- <h3 [innerHtml]="post.text ? (post.text | safeHtml) : ''"
                    [ngClass]="{'fw-600':post.type=='SHOW'}"
                    class="text-justify postdesc"></h3> -->
                    <div class="d-flex  justify-content-between" style="
                    padding-top: 10px; margin-left: 0">
                      <div class="d-flex w-70" style="width: 80%; margin-left: 0;">
                      <div class="d-flex align-items-center">
                        <span (click)="likePost(post?._id)" *ngIf="!post?.isLikedByMe" id="likeIcons{{post?._id}}"><img src="../../../assets/Indietown_icons/Heart.svg" alt="" id="likeImage{{post?._id}}"></span>
                        <span (click)="dislikePost(post?._id)" *ngIf="post?.isLikedByMe" id="dislikeIcons{{post?._id}}"><img src="../../../assets/Indietown_icons/like-dark.svg" alt="" id="dislikeImage{{post?._id}}"></span>
        
        
                        <span style="font-size: 12px; margin-left: 11px; width: 53px;" (click)="openLikesModal(post?._id)" class="countText" id="totalLikeCount{{post?._id}}">{{ post?.likesCount }} {{post?.likesCount<=1 && post?.likesCount!=0?'Like':'Likes'}}</span>
                    </div>
                    <div class="d-flex align-items-center" style="margin-left: 15px;">
                        <span><img src="../../../assets/Indietown_icons/Chat-square.svg"  (click)="commentAreaEnable(i)" alt=""></span>
                        <span style="font-size: 12px; margin-left: 11px" class="countText" (click)="loadMoreComments(post?._id)" id="totalcommentCount{{post?._id}}">{{ post?.commentCount }}</span>
                    </div>
                    <!-- <div class="" style="margin-left: 26px;">
                        <img src="../../../assets/Indietown_icons/share.svg" alt=""/>
                    </div> -->
                    </div>
                    <div>
                      <!-- <span>{{ post?.createdOn | date : "HH:mm" }}{{changeTime(post?.createdOn)}}</span> -->
                      <span style="color: #0F0D0F;

                      opacity: 0.5;" class="changetimee">{{timeSince(post?.createdOn)}}</span>
                    </div>
                    </div>
                </mat-card-actions>
        
                <p *ngIf="post?.type=='SHOW' && post?.from && post?.to" id="clockspace" style="margin-top: 18px;">
                  <span><img style="margin-right: 9px;
                    margin-left: -1px;
                    width: 19px;" src="../../../assets/Indietown_icons/clock.svg" alt=""></span>
                  <span>{{post?.from?.length > 10 ? (post?.from | date:'MMM d, y h:mm a') : post?.from }} - </span>
                  <span>{{post?.to?.length > 10 ? (post?.to | date:'MMM d, y h:mm a') : post?.to}}</span>
                </p>
        
                <p *ngIf="post?.type=='SHOW' && post?.address?.venue">
                  <span><img style="margin-right: 10px;" src="../../../assets/Indietown_icons/location-icon.png" alt=""></span>
                  <span>{{post?.address?.venue}} {{post?.address?.city}}, {{post?.address?.state}}, {{post?.address?.country}}</span>
                </p>
        
                <p *ngIf="post?.type=='SHOW' && post?.money?.from && post?.money?.to">
                  <span><img style="margin-right: 10px;  margin-left: -4px;" src="../../../assets/Indietown_icons/currency.png" alt=""></span>
                  <span>US {{(post?.money?.from)?((post?.money?.from) | currency) : 0 | currency}} - </span>
                  <span>US {{(post?.money?.to)?((post?.money?.to)| currency) : 0 | currency}}</span>
                </p>
              </mat-card-content>
        
              <div class="d-flex justify-content-between">
                <div class="likes-coments">
                  <!-- <span (click)="openLikesModal()"
                    >{{ post?.likesCount }} Likes</span
                  > -->
                  <span style="font-size: 12px;
                  line-height: 18px;" (click)="commentAreaEnable(i)" *ngIf="post?.commentCount">View {{post?.commentCount>1 ? 'all '+post?.commentCount+' Comments':post?.commentCount+' Comment'}}</span>
                  <!-- <span style="margin-left: 20px;">{{getCommentsList(post?._id)}}</span> -->
                  <!-- View all {{ post?.commentsCount }} {{post?.commentsCount>1 ? 'Comments':'Comment'}} -->
                </div>
                <!-- <div>
                  <span>{{ post?.createdOn | date : "HH:mm" }}{{changeTime(post?.createdOn)}}</span>
                  <span>{{changeTime(post?.createdOn)}}</span>
                </div> -->
              </div>
        
        
        
              <div id="postCommentList{{ post?._id }}" class="postcommentlistt" style="display: none; padding-top: 25px;
              margin-top: 25px;
              border-top: 1px solid #e7e6e7;">
                <div style="display:none; background: #eee;
                border-radius: 8px;
                padding: 11px;
                width: 35%;    margin-left: 9%;" class="replyingto" id="replyCrossMark{{post?._id}}">
                  Replying to <span style="color: #000;">{{commentbandname}}</span>
                  <button style="border-radius: 50%;
                  background: #fff;
                  border: none;
                  float: right;
                  color: #000;
                  cursor: pointer;
                  font-weight: bold; padding-bottom: 3px;" (click)="removeReplyOption(i)" class="reply-cross-mark">x</button>
                </div>
                  <div class="d-flex mt-1 commentboxxprofile">
                      <div>
                          <img *ngIf="postId" [src]="myProfileDetails?.profileImage?.fileUrl ? myProfileDetails?.profileImage?.fileUrl : '../../../assets/Indietown_icons/upload-profile-pic.png'" height="50" width="50" alt="" style="border-radius: 100%;margin-top: 22%;    object-fit: cover;">
                          <img *ngIf="!postId" [src]="profileDetails?.profileImage?.fileUrl ? profileDetails?.profileImage?.fileUrl : '../../../assets/Indietown_icons/upload-profile-pic.png'" height="50" width="50" alt="" style="border-radius: 100%;margin-top: 22%;    object-fit: cover;">
                      </div>
                      <form action="" [formGroup]="createCommentForm" class="commentForm">
                      
                          <span class="commentSpan" (click)="createComment(i)" style="z-index: 3;"><img src="../../../assets/Indietown_icons/message-icon.svg" alt=""></span>
                          
                          
                          <textarea (keydown.enter)="stopGoToNextLine($event)" (keyup.enter)="createComment(i)" class="form-control commentboxx" id="addcommnt{{ post?._id }}" formControlName="comment" placeholder="Add a comment" style="border: 1px solid #E9E9EB;
                          width: 100%;
                          background: none;
                          height: 60px;
                          box-shadow: none;
                          padding-top: 15px;
                          transform: translate(5px, 7px);
                          border-radius: 46px;
                          padding-left: 20px !important;
                          padding-right: 50px;
                          font-size: 16px !important;
                          position: relative;
                          resize: none;
                          z-index: 2;" #textareaRef></textarea>
                          <!-- <angular-editor class="form-control commentboxx" id="addcommnt{{ post?._id }}" formControlName="comment" placeholder="Add a comment" style="border: 1px solid rgba(0, 0, 0, 0.48);
                          width: 100%;
                          background: none;
                          height: 60px;
                          padding-top: 15px;
                          transform: translate(5px, 7px);
                          border-radius: 46px;
                          padding-left: 20px !important;
                          font-size: 16px !important;
                          position: relative;
                          resize: none;
                          z-index: 2;"
                          keyup=""
                            #textareaRef
                          [config]="editorConfigs"
                          ></angular-editor> -->
                          <flx-mentions [textInputElement]="textareaRef"
                          [menuTemplate]="menuTemplate"
                          [triggerCharacter]="'@'"
                          [getChoiceLabel]="getChoiceLabel"
                          [selectedChoices]="mentionReplyingTo"
                          (search)="loadChoices($event)"
                          (selectedChoicesChange)="onSelectedChoicesChange($event)"
                          (menuShow)="onMenuShow()"
                          (menuHide)="onMenuHide()"
                          (choiceRemoved)="choiceRemoved($event,i)"
                          ></flx-mentions>
                          <!-- [getChoiceLabel]="getChoiceLabel" -->
                          <ng-template #menuTemplate
                                      let-selectChoice="selectChoice">
                            <ul *ngIf="usersForMention?.length" class="flx-selectable-list" style="background: #fff;
                            border-radius: 8px;
                            border: 1px solid #eee;
                            height: 285px;
                            padding-left: 0px;
                            overflow: auto;
                            list-style-type: none;
                            z-index: 99999;
                            position: relative;
                            width: 100%;
                            box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.05);">
                              <li *ngFor="let user of usersForMention"
                                  class="flx-selectable-list-item" (click)="selectChoice(user)" style="cursor: pointer;
                                  padding: 10px;
                                  height: 60px;margin-bottom: 5px;
                                  border-radius: 8px;
                                  background: #fff;
                                  display: flex;
                                  align-items: center;
                                  list-style-type: none;">
                              <img style="width:40px; height: 40px; border-radius: 50%; object-fit: cover; margin-right: 15px;" [src]="user.profileImage.fileUrl ? user.profileImage.fileUrl : '../../../assets/Indietown_icons/upload-profile-pic.png'" alt="">
                                <span class="" title="{{user.bandName |titlecase}}">{{user.bandName |titlecase}}</span>
                              </li>
                            </ul>
                          </ng-template>
                      </form>
                  </div>
        
                <div *ngFor="let comment of post?.comments;let commentIndex=index">
                    <div class="d-flex " style="flex-direction: column;">
                      <div class="d-flex">
                        <img *ngIf="comment?.userId?.profileImage?.fileUrl
                        " [src]="comment?.userId?.profileImage?.fileUrl
                        " class="comment-image" style="cursor: pointer;" (click)="navigateToUserProfile(comment?.userId?._id,comment?.userId?.bandName)"/>
                        <img *ngIf="!comment?.userId?.profileImage?.fileUrl
                        " src="../../../assets/Indietown_icons/upload-profile-pic.png" class="comment-image" style="cursor: pointer;" (click)="navigateToUserProfile(comment?.userId?._id,comment?.userId?.bandName)"/>
                        <span style="margin-top: 3%;padding-left: 20px;     width: 100%;" ><b style="    margin-right: 7px;cursor: pointer;" (click)="navigateToUserProfile(comment?.userId?._id,comment?.userId?.bandName)">{{comment?.userId?.bandName}}</b> <span style="" [innerHTML]="getModifiedComment(comment,i) | linky"></span></span><!--{{ getModifiedComment(comment) }}-->
                      </div>
                        <div class="" style="padding-left: 52px; padding-bottom: 7px; font-size: 11px;     padding-top: 10px;" *ngIf="!postId">
                          <span style="color: #0F0D0F; opacity: 0.5;">{{getTimeWeekWise(comment?.createdOn)}}</span>
                          <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" (click)="replyPostCommentEnable(i,comment,'')">Reply</span>
                          <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" (click)="openReportCommentModel(comment._id, post?._id,'')" *ngIf="comment?.userId?._id!=profileDetails?._id">Report</span>
                          <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" *ngIf="comment?.userId?._id==profileDetails?._id" (click)="openDeleteCommentModal(i,commentIndex)">Delete</span>
                        </div>
                        <div class="" style="padding-left: 52px; padding-bottom: 7px; font-size: 11px;     padding-top: 10px;" *ngIf="postId">
                          <span style="color: #0F0D0F; opacity: 0.5;">{{getTimeWeekWise(comment?.createdOn)}}</span>
                          <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" (click)="replyPostCommentEnable(i,comment,'')">Reply</span>
                          <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" (click)="openReportCommentModel(comment._id, post?._id,'')" *ngIf="comment?.userId?._id!=myProfileDetails?._id">Report</span>
                          <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" *ngIf="comment?.userId?._id==myProfileDetails?._id" (click)="openDeleteCommentModal(i,commentIndex)">Delete</span>
                        </div>
                      </div>
                    
                    <div style="display: none; position: relative;" class="replyinputbox reply-box" id="postCommentReply{{comment?._id}}">
                      <span class="replyCommentPost" style="z-index: 3;"><img src="../../../assets/Indietown_icons/message-icon.svg" alt="" (click)="replyPostCommentClick(i,comment?._id,post?._id)"></span>
                      <textarea class="form-control" id="replyPostText{{comment?._id}}" placeholder="Reply" style="border: 1px solid #0000007a;
                      padding-left: 20px !important;
                      font-size: 16px !important;
                      width: 100%;
                      padding-top: 15px;
                      resize: none;
                      background: transparent;
                      height: 60px;
                      margin-top: 10px;
                      margin-left: 8px;
                      border-radius: 46px;" #postCommentReply></textarea>
                      <flx-mentions [textInputElement]="postCommentReply"
                      [menuTemplate]="menuTemplateForReply"
                      [triggerCharacter]="'@'"
                      [getChoiceLabel]="getChoiceLabel"
                      [selectedChoices]="'[]'"
                      (search)="loadChoices($event)"
                      (selectedChoicesChange)="onSelectedChoicesChange($event)"
                      (menuShow)="onMenuShow()"
                      (menuHide)="onMenuHide()"
                      ></flx-mentions>
                      <!-- [getChoiceLabel]="getChoiceLabel" -->
                      <ng-template #menuTemplateForReply
                                  let-selectChoice="selectChoice">
                        <ul class="flx-selectable-list" style="background: #fff;
                        border-radius: 8px;
                        border: 1px solid #eee;
                        height: 285px;
                        padding-left: 0px;
                        overflow: auto;
                        list-style-type: none;
                        z-index: 99999;
                        position: relative;
                        width: 100%;
                        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.05);">
                          <li *ngFor="let user of usersForMention"
                              class="flx-selectable-list-item" (click)="selectChoice(user)" style="cursor: pointer;
                              padding: 10px;
                              margin-bottom: 5px;
      border-radius: 8px;
                              height: 60px;
                              background: #fff;
                              display: flex;
                              align-items: center;
                              list-style-type: none;">
                              <img style="width:40px; height: 40px; border-radius: 50%; object-fit: cover; margin-right: 15px;" [src]="user.profileImage.fileUrl ? user.profileImage.fileUrl : '../../../assets/Indietown_icons/upload-profile-pic.png'" alt="">
                            <span title="{{user.bandName |titlecase}}">{{user.bandName |titlecase}}</span>
                          </li>
                        </ul>
                      </ng-template>
                    </div>
                    <div style="margin-left: 55px;">
                      <!-- && comment?.replies && comment?.replies?.length == 0 -->
                      <div class="d-flex">
                      <span *ngIf="comment?.totalRepliesCount && comment?.totalRepliesCount>0" id="hideunderline{{comment?._id}}" class="hide-underline">&nbsp;</span>
                      <div *ngIf="comment?.totalRepliesCount && comment?.totalRepliesCount>0" style="cursor: pointer;" (click)="showReplies(comment)" class="hiderepl" id="showRepliesBtn{{comment?._id}}">Show {{comment?.totalRepliesCount == 1 ? comment?.totalRepliesCount + ' Reply' : comment?.totalRepliesCount + ' Replies'}}</div>
                    </div>
                      <div *ngIf="comment?.replies && comment?.replies?.length>0" style="display: none; position: relative;" id="repliesBox{{comment?._id}}">
                        <!-- Show Replies Here -->
                        <div *ngFor="let reply of comment?.replies;let replyIndex=index">
                          <div class="d-flex " style="flex-direction: column;">
                          <div class="d-flex">
                            <img *ngIf="reply?.userId?.profileImage?.fileUrl" [src]="reply?.userId?.profileImage?.fileUrl" class="comment-image" style="cursor: pointer;" (click)="navigateToUserProfile(reply?.userId?._id,reply?.userId?.bandName)"/>
                            <img *ngIf="!reply?.userId?.profileImage?.fileUrl" src="../../../assets/Indietown_icons/upload-profile-pic.png" class="comment-image" style="cursor: pointer;" (click)="navigateToUserProfile(reply?.userId?._id,reply?.userId?.bandName)"/>
                            <span style="margin-top: 4%;padding-left: 20px; "><b style="    margin-right: 7px;" style="cursor: pointer;" (click)="navigateToUserProfile(reply?.userId?._id,reply?.userId?.bandName)">{{reply?.userId?.bandName |titlecase}}</b> <span [innerHTML]="getModifiedComment(reply,i) | linky"></span></span>
                          </div>
                            <div class="" style="padding-left: 52px; padding-bottom: 7px; font-size: 11px;     padding-top: 10px;" *ngIf="!postId">
                              <span style="color: #0F0D0F; opacity: 0.5;">{{getTimeWeekWise(reply?.createdOn)}}</span>
                              <span class="commentsListOptions" (click)="replyPostCommentEnable(i,comment,reply)" style="color: #0F0D0F; opacity: 0.5;">Reply</span>
                              <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" (click)="openReportCommentModel(comment._id, post?._id,reply?._id)" *ngIf="reply?.userId?._id!=profileDetails?._id">Report</span>
                              <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" *ngIf="reply?.userId?._id==profileDetails?._id" (click)="openDeleteCommentReplyModal(i,commentIndex,replyIndex)">Delete</span>
                            </div>
                            <div class="" style="padding-left: 52px; padding-bottom: 7px; font-size: 11px;     padding-top: 10px;" *ngIf="postId">
                              <span style="color: #0F0D0F; opacity: 0.5;">{{getTimeWeekWise(reply?.createdOn)}}</span>
                              <span class="commentsListOptions" (click)="replyPostCommentEnable(i,comment,reply)" style="color: #0F0D0F; opacity: 0.5;">Reply</span>
                              <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" (click)="openReportCommentModel(comment._id, post?._id,reply?._id)" *ngIf="reply?.userId?._id!=myProfileDetails?._id">Report</span>
                              <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" *ngIf="reply?.userId?._id==myProfileDetails?._id" (click)="openDeleteCommentReplyModal(i,commentIndex,replyIndex)">Delete</span>
                            </div>
                          </div>
                          
                          <!-- <div style="display: none; position: relative;" class="replyinputbox" id="postCommentReplyReply{{reply?._id}}">
                            <span class="replyCommentPost" style="z-index: 3;"><img src="../../../assets/Indietown_icons/message-icon.svg" alt="" (click)="replyPostCommentClick(comment?._id,post?._id)"></span>
                            <textarea class="form-control" id="replyPostText{{comment?._id}}" placeholder="Reply" style="border: 1px solid #0000007a;
                            padding-left: 20px !important;
                            font-size: 16px !important;
                            width: 100%;
                            background: transparent;
                            height: 60px;
                            margin-top: 10px;
                            margin-left: 8px;
                            border-radius: 46px;"></textarea>
                          </div> -->
                        </div>
                        <!-- <span *ngIf="comment?.totalRepliesCount>comment?.replies?.length" class="hide-underline">&nbsp;</span> -->
                        <div *ngIf="comment?.totalRepliesCount>comment?.replies?.length" style="padding-top: 5px;" class="d-flex"><span  *ngIf="comment?.totalRepliesCount>comment?.replies?.length" class="hide-underline">&nbsp;</span><span *ngIf="comment?.totalRepliesCount>comment?.replies?.length" (click)="loadMoreReplies(i,commentIndex)" id="repliesLoadMore{{ comment?._id }}" style="cursor: pointer; color: #0F0D0F; opacity: 0.5; padding-left: 0px; font-size: 14px;">Show More Replies</span></div>
                        <div *ngIf="comment?.replies?.length" style="padding-top: 5px;" class="d-flex"><span  *ngIf="comment?.replies?.length" class="hide-underline">&nbsp;</span><span *ngIf="comment?.replies?.length" class="hiderepl" (click)="hideReplies(i,commentIndex)" style="cursor: pointer;color: #0F0D0F; opacity: 0.5;">Hide all replies</span></div>
                        <!-- *ngIf="comment?.replies?.length>3" (click)="loadMoreReplies(i,commentIndex)" id="repliesLoadMore{{ comment?._id }}" -->
                        <!-- <span *ngIf="comment.repliyCount>comment?.replies?.length" (click)="loadMoreReplies(post.?_id,comment?._id)" id="repliesLoadMore{{ comment?._id }}" style="cursor: pointer; font-weight: bold;">Show More</span> -->
                      </div>
                    </div>
                    
                </div>
        
                <span *ngIf="post?.commentsCount>post?.comments?.length" (click)="loadMoreComments(post?._id)" id="commentLoadMore{{ post?._id }}" style="cursor: pointer; font-weight: bold;">Load more comments</span>      
                <span *ngIf="post?.comments?.length>3" (click)="showLessComments(i)" id="showLessComments{{ post?._id }}" style="cursor: pointer; font-weight: bold;" class="ml-3">Show Less</span>      
              </div>
            </mat-card>
            <div *ngIf="!allPosts.length && !postId" style="text-align: center;
            margin-top: 25px; font-weight: bold; font-size: 24px;">
              Add Posts!
            </div>
            <div *ngIf="!allPosts.length && postId" style="text-align: center;
            margin-top: 25px; font-weight: bold; font-size: 24px;">
              No Posts Yet !
            </div>
            </div>

            <div class="col-lg-3 max-width-100 spotifyfortab" [ngClass]="{'positionfix': sidebarFixed==true}" style="max-width: 38%; margin-top: -6%;">
              <!-- Spotify card start -->
              <div>
                <!-- <app-spotify-card [playList]="audioList"  *ngIf="profileDetails?.playList?.spotify?.url"></app-spotify-card> -->

                <!-- Hide Spotify
                <app-spotify-card (gotoSpotify)="gotoSpotify()" [userId]="postId"></app-spotify-card>
                -->
              </div>
              <!-- Spotify card end -->

              <!-- Youtube card start -->
              <!-- *ngIf="profileDetails?.playList?.youtube?.url" -->
              <div style="margin-top: 30px">
                <!-- <app-youtube-card
                  [goYoutube]="selectedIndex"
                  [playList]="playList"
                ></app-youtube-card> -->
                <app-youtube-card (gotoYoutube)="gotoYoutube()" [userId]="postId"></app-youtube-card>
              </div>
              <!-- Youtube card end -->
            </div>
          </div>
        <!-- </ng-template> -->
      </mat-tab>

      <!-- All Posts End -->

      <!-- All Shows Start -->
      <mat-tab label="Shows">
        <!-- routerLink="/home" 
         [queryParams]="{ post: 1, timeline: true }"
        -->
        <!-- <ng-template matTabContent> -->
          <!-- routerLink="/home" -->
          <!-- *ngIf="!timelineVisible" -->
          <!-- [queryParams]="{ post: 1, timeline: true }" -->
          <!-- *ngIf="!postId" -->
          <div
            class="d-flex"
            
            style="margin-bottom: 21px"
            
            *ngIf="(!postId) || (postId && profileDetails?.friendStatus == 'FRIEND')"
          >
          <!-- *ngIf="!postId" -->
            <span *ngIf="!postId">
              <img
                [src]="
                  profileDetails?.profileImage?.fileUrl
                    ? profileDetails?.profileImage?.fileUrl
                    : '../../../../assets/Indietown_icons/upload-profile-pic.png'
                "
                height="50"
                width="50"
                alt=""
                style="
                  border-radius: 100%;
                  left: 0 !important;
                  top: 0px !important;
                "
              />
            </span>
            <!-- -->
             <span *ngIf="postId && profileDetails?.friendStatus == 'FRIEND'">
              <img
                [src]="
                myProfileDetails?.profileImage?.fileUrl
                    ? myProfileDetails?.profileImage?.fileUrl
                    : '../../../../assets/Indietown_icons/upload-profile-pic.png'
                "
                height="50"
                width="50"
                alt=""
                style="
                  border-radius: 100%;
                  left: 0 !important;
                  top: 0px !important;
                "
              />
            </span> 
            <!-- opneCreateShowModal -->
            <!-- *ngIf="postId" -->
            <input
              class="form-control showbox"
              id="search"
              type="text"
              *ngIf="(postId && profileDetails?.friendStatus == 'FRIEND') || (!postId)"
              [placeholder]="
                showSiidbar == false
                  ? 'What’s new with you?'
                  : 'Comment on ' + unFriendUserName + '\'s timeline'
              "
              aria-label="Search"
              ngModel
              name="search"
              readonly
              (click)="openCreatePost()"
              style="margin-left: 10px; width: 55%; height: 50px; font-size: 16px;background: rgba(233, 233, 235, 0.3);
              border: 1px solid #E9E9EB;
              border-radius: 15px; cursor: pointer;"
            />
          </div>
          <!-- <div class="form-group" *ngIf="showprogressBarForShow" style="height: 8px;transform: translate(0px, 19px);">
            <div>
              <mat-progress-bar mode="determinate" [value]="showProgress" class="top-progressbar"></mat-progress-bar>
            </div>
          </div> -->
          <div class="row" style="margin: 0px">
            <div class="col-lg-7 mtb-70 photoalbum7 h-700" style="padding-left: 0;" infinite-scroll [scrollWindow]="true"
            (scrolled)="onScrollDownShows($event)" [infiniteScrollDistance]="6">
              <!-- infinite-scroll
              [scrollWindow]="true"
              infinite-scroll-disabled="stopScrollingPost"
              (scrolled)="onScrollDown($event)"
              [infiniteScrollDistance]="0.07" -->
            <!-- <div class="col-lg-7 "> -->
              <mat-card
              class="post-card mt-2"
              *ngFor="let post of allShows; let i = index" 
            >
              <mat-card-header class="d-flex align-items-center" style="margin-bottom: 12px;">
                <img
                  mat-card-avatar
                  [src]="post?.userId?.profileImage?.fileUrl ? post?.userId?.profileImage?.fileUrl : '../../../../assets/Indietown_icons/upload-profile-pic.png'"
                  alt=""
                  width="50" height="50"
                  routerLink="/user/profile"
                  [queryParams]="{ id: post?.userId?._id,user:post?.userId?.bandName }"
                  style="cursor: pointer;"
                />
                <mat-card-title class="mt-2 profilebandname">{{
                  post?.userId?.bandName |titlecase
                }}</mat-card-title>
                <span class="reportIcon d-inline-block ml-auto">
                  <div class="btn-group" dropdown placement="bottom right">
                    <div
                      id="button-alignment"
                      dropdownToggle
                      type="button"
                      aria-controls="dropdown-alignment"
                    >
                      <img
                        src="../../../assets/Indietown_icons/report-icon.png"
                        alt=""
                        style="cursor: pointer"
                      />
                    </div>
                    <ul
                      id="dropdown-alignment"
                      *dropdownMenu
                      class="dropdown-menu dropdown-menu-right"
                      role="menu"
                      aria-labelledby="button-alignment"
                      style="background: #f8f9f8;
                      box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
                      border-radius: 15px; z-index: 9;"
                    >

                      <li class="menuitem" role="menuitem"  *ngIf="(!postId) && post?.userId?._id != profileDetails?._id">
                        <a
                          class="dropdown-item admin-dropdown"
                          (click)="openReportDialog(post?._id,post.type=='POST'?'Post':'Show')"
                          >Report {{post.type=='POST'?'Post':'Show'}}</a
                        >
                      </li>
                      <div class="dropdown-divider" *ngIf="(!postId) && post?.userId?._id != profileDetails?._id"></div>

                      <li
                      class="menuitem"
                      style="cursor: pointer"
                      role="menuitem"
                      (click)="onClickSilentPost(post?.userId?._id,i)" *ngIf="(!postId) && post?.userId?._id != profileDetails?._id"
                    >
                      <a class="dropdown-item admin-dropdown">Silence User</a>
                    </li>
                      <!-- <li class="menuitem" *ngIf="(!postId) && post?.userId?._id != profileDetails?._id" style="cursor: pointer" role="menuitem"
                      (click)="onClickSilentPost(post?.userId?._id,i)">
                      <a class="dropdown-item admin-dropdown">Silence User</a>
                    </li> -->
                      <li class="menuitem" role="menuitem"  *ngIf="(postId) && post?.userId?._id != myProfileDetails?._id">
                        <a
                          class="dropdown-item admin-dropdown"
                          (click)="openReportDialog(post?._id,post.type=='POST'?'Post':'Show')"
                          >Report {{post.type=='POST'?'Post':'Show'}}</a
                        >
                      </li>

                      <div class="dropdown-divider" *ngIf="(!postId) && post?.userId?._id != profileDetails?._id"></div>
                      <div class="dropdown-divider" *ngIf="(postId) && post?.userId?._id != myProfileDetails?._id"></div>

                      <li
                        class="menuitem"
                        style="cursor: pointer"
                        role="menuitem"
                        (click)="onClickSilentPost(post?.userId?._id,i)" *ngIf="(postId) && post?.userId?._id != myProfileDetails?._id"
                      >
                        <a class="dropdown-item admin-dropdown">Silence User</a>
                      </li>
        
                      <li class="menuitem" style="cursor: pointer" role="menuitem"
                        (click)="onClickDeletePost(post?._id,post)" *ngIf="!postId && (post?.userId?._id == profileDetails?._id)">
                        <a class="dropdown-item admin-dropdown">Delete {{post?.type=='POST'? 'Post':'Show'}}</a>
                      </li>
        
                      <div class="dropdown-divider" *ngIf="postId && (post?.userId?._id == myProfileDetails?._id)"></div>
                      <div class="dropdown-divider" *ngIf="!postId && (post?.userId?._id == profileDetails?._id)"></div>

        
                      <li class="menuitem" style="cursor: pointer" role="menuitem"  *ngIf="!postId && (post?.userId?._id == profileDetails?._id)"
                        (click)="onClickEditPost(post)">
                      <a class="dropdown-item admin-dropdown">Edit {{post.type=='POST'?'Post':'Show'}}</a>
                    </li>
                    </ul>
                  </div>
                </span>
              </mat-card-header>

              <carousel [noWrap]="noWrapSlides" [showIndicators]="showIndicator" [interval]="22000000000" [isAnimated]="true" loop="false">
                <slide *ngFor="let media of post?.media; let index=index">
                  <!-- <img *ngIf="media.type=='image'" class="d-block w-100 postimgs" style="border-radius:20px; object-fit: contain; height: 582px;" [src]="media.fileUrl" alt="First slide"> -->

                  <!-- height: 582px; -->
                  <!-- height: 582px;  -->
                  <img *ngIf="media.type=='image'" class="d-block w-100 postimgs" style="border-radius:20px; object-fit: cover; height: auto;" [src]="media.fileUrl" alt="First slide">
                    <video *ngIf="media.type=='video'" (playing)="onPlayingVideo($event)" class="d-block w-100 postvideos" [src]="media.fileUrl" style="border-radius:20px; object-fit: cover;height: auto;" controls [poster]="media.thumbnail"></video>
                </slide>
            </carousel>


                <!--Post Like Comments Shares Buttons Start-->
                <mat-card-actions class="d-flex mt-1 mb-0 ml-0 mr-0 p-0" *ngIf="post?.media.length>0" style="flex-direction: column">
                    <div class="d-flex justify-content-between" style="
                    padding-top: 10px;">
                      <div class="d-flex w-70">
                      <div class="d-flex align-items-center">
                        <span (click)="likePost(post?._id)" *ngIf="!post?.isLikedByMe" id="likeIcons{{post?._id}}"><img src="../../../assets/Indietown_icons/Heart.svg" alt="" id="likeImage{{post?._id}}"></span>
                        <span (click)="dislikePost(post?._id)" *ngIf="post?.isLikedByMe" id="dislikeIcons{{post?._id}}"><img src="../../../assets/Indietown_icons/like-dark.svg" alt="" id="dislikeImage{{post?._id}}"></span>
        
        
                        <span style="font-size: 12px; margin-left: 11px;  width: 53px;" (click)="openLikesModal(post?._id)" class="countText" id="totalLikeCount{{post?._id}}">{{ post?.likesCount }} {{post?.likesCount<=1 && post?.likesCount!=0?'Like':'Likes'}}</span>
                    </div>
                    <div class="d-flex align-items-center" style="margin-left: 26px;">
                        <span><img src="../../../assets/Indietown_icons/Chat-square.svg"  (click)="commentAreaEnable(i)" alt=""></span>
                        <span style="font-size: 12px; margin-left: 11px" class="countText" (click)="loadMoreComments(post?._id)" id="totalcommentCount{{post?._id}}">{{ post?.commentCount }}</span>
                    </div>
                    <!-- <div class="" style="margin-left: 26px;">
                        <img src="../../../assets/Indietown_icons/share.svg" alt=""/>
                    </div> -->
                    </div>
                    <div>
                      <!-- <span>{{ post?.createdOn | date : "HH:mm" }}{{changeTime(post?.createdOn)}}</span> -->
                      <span class="changetimee" style="color: #0F0D0F;

                      opacity: 0.5;">{{timeSince(post?.createdOn)}}</span>
                    </div>
                    </div>
                    <div style="margin-top: 1%;">
                      <!-- <p class="postdesc" [ngClass]="{'fw-600':post.type=='SHOW'}">
                        {{ post.text }}
                      </p> -->
                      <!-- <h3 [innerHtml]="post.text ? (post.text | safeHtml) : ''" 
                      [ngClass]="{'fw-600':post.type=='SHOW'}"
                      class="text-justify postdesc"></h3> -->

                    <div *ngIf="post?.type=='POST'">
                      <span *ngIf="post?.text?.length>200" [innerHtml]="post.text ? (post.text.slice(0,200) | linky) : ''"
                        [ngClass]="{'fw-600':post.type=='SHOW'}"
                        class="text-justify postdesc postdescspan formatted" id="lessDesc{{post?._id}}" style="font-size: 16px;font-weight: 500;"></span>
                        <span *ngIf="post?.text?.length<200" [innerHtml]="post.text ? (post.text | linky) : ''"
                        [ngClass]="{'fw-600':post.type=='SHOW'}"
                        class="text-justify postdesc postdescspan formatted" id="lessDesc{{post?._id}}" style="font-size: 16px;font-weight: 500;"></span>
    
                      <span (click)="showMoreDesc(post?._id)" id="moreBtn{{post?._id}}" *ngIf="post?.text?.length>200" style="cursor: pointer;font-weight: 600;    text-transform: lowercase;">... more</span>
                    
                        <span [innerHtml]="post.text ? (post.text | linky:{stripPrefix : false}) : ''"
                        [ngClass]="{'fw-600':post.type=='SHOW'}"
                        class="text-justify postdesc postdescspan formatted" style="display: none;font-size: 16px;font-weight: 500;" id="moreDataDesc{{post?._id}}"></span>
    
                        <span (click)="showLessDesc(post?._id)" id="lessBtn{{post?._id}}" style="display: none;cursor: pointer;font-weight: 600;    text-transform: lowercase;"> less</span>
                      </div>
      
                    </div>

                    <div *ngIf="post?.type=='SHOW'">
                      <span [innerHtml]="post.text ? (post.text | linky:{stripPrefix : false}) : ''"
                      [ngClass]="{'fw-600':post.type=='SHOW'}"
                      class="text-justify postdesc" style="font-size: 16px;"></span>
                    </div>

                </mat-card-actions>
          <!--Post Like Comments Shares Buttons End-->
              <mat-card-content>

                  <!--Post Like Comments Shares Buttons Start-->
                  <mat-card-actions class="d-flex ml-0 mr-0 p-0" *ngIf="post?.media.length==0" style="flex-direction: column;">
                    <!-- <p class="postdesc" [ngClass]="{'fw-600':post.type=='SHOW'}">
                      {{ post.text }}
                    </p> -->

                  <div>
                    <span *ngIf="post?.text?.length>200" [innerHtml]="post.text ? (post.text.slice(0,200) | linky:{stripPrefix : false}) : ''"
                      [ngClass]="{'fw-600':post.type=='SHOW'}"
                      class="text-justify postdesc postdescspan formatted" id="lessDesc{{post?._id}}" style="font-size: 16px;font-weight: 500;"></span>
                  
                      <span *ngIf="post?.text?.length<200" [innerHtml]="post.text ? (post.text | linky:{stripPrefix : false}) : ''"
                      [ngClass]="{'fw-600':post.type=='SHOW'}"
                      class="text-justify postdesc postdescspan formatted" id="lessDesc{{post?._id}}" style="font-size: 16px;font-weight: 500;"></span>

                    <span (click)="showMoreDesc(post?._id)" id="moreBtn{{post?._id}}" *ngIf="post?.text?.length>200" style="cursor: pointer;font-weight: 600; text-transform: lowercase;">... more</span>
                    
                      <span [innerHtml]="post.text ? (post.text | linky:{stripPrefix : false}) : ''"
                      [ngClass]="{'fw-600':post.type=='SHOW'}"
                      class="text-justify postdesc postdescspan formatted" style="display: none;font-size: 16px;font-weight: 500;" id="moreDataDesc{{post?._id}}"></span>

                      <span (click)="showLessDesc(post?._id)" id="lessBtn{{post?._id}}" style="display: none;cursor: pointer;font-weight: 600;     text-transform: lowercase;"> Less</span>
                    </div>
    
                    <!-- <h3 [innerHtml]="post.text ? (post.text | safeHtml) : ''"
                    [ngClass]="{'fw-600':post.type=='SHOW'}"
                    class="text-justify postdesc"></h3> -->
                    <div class="d-flex  justify-content-between" style="
                    padding-top: 10px;">
                      <div class="d-flex w-70">
                      <div class="d-flex align-items-center">
                        <span (click)="likePost(post?._id)" *ngIf="!post?.isLikedByMe" id="likeIcons{{post?._id}}"><img src="../../../assets/Indietown_icons/Heart.svg" alt="" id="likeImage{{post?._id}}"></span>
                        <span (click)="dislikePost(post?._id)" *ngIf="post?.isLikedByMe" id="dislikeIcons{{post?._id}}"><img src="../../../assets/Indietown_icons/like-dark.svg" alt="" id="dislikeImage{{post?._id}}"></span>
        
        
                        <span style="font-size: 12px; margin-left: 11px; width: 53px;" (click)="openLikesModal(post?._id)" class="countText" id="totalLikeCount{{post?._id}}">{{ post?.likesCount }} {{post?.likesCount<=1 && post?.likesCount!=0?'Like':'Likes'}}</span>
                    </div>
                    <div class="d-flex align-items-center" style="margin-left: 26px;">
                        <span><img src="../../../assets/Indietown_icons/Chat-square.svg"  (click)="commentAreaEnable(i)" alt=""></span>
                        <span style="font-size: 12px; margin-left: 11px" class="countText" (click)="loadMoreComments(post?._id)" id="totalcommentCount{{post?._id}}">{{ post?.commentCount }}</span>
                    </div>
                    <!-- <div class="" style="margin-left: 26px;">
                        <img src="../../../assets/Indietown_icons/share.svg" alt=""/>
                    </div> -->
                    </div>
                    <div>
                      <!-- <span>{{ post?.createdOn | date : "HH:mm" }}{{changeTime(post?.createdOn)}}</span> -->
                      <span style="color: #0F0D0F;

                      opacity: 0.5;" class="changetimee">{{timeSince(post?.createdOn)}}</span>
                    </div>
                    </div>
                </mat-card-actions>
        
                <p *ngIf="post?.type=='SHOW' && post?.from && post?.to" id="clockspace" style="margin-top: 18px;">
                  <span><img style="margin-right: 9px;
                    margin-left: -1px;
                    width: 19px;" src="../../../assets/Indietown_icons/clock.svg" alt=""></span>
                  <span>{{post?.from?.length > 10 ? (post?.from | date:'MMM d, y h:mm a') : post?.from }} - </span>
                  <span>{{post?.to?.length > 10 ? (post?.to | date:'MMM d, y h:mm a') : post?.to}}</span>
                </p>
        
                <!-- <p *ngIf="post?.type=='SHOW' && post?.address?.venue">
                  <span><img style="margin-right: 10px;" src="../../../assets/Indietown_icons/location-icon.png" alt=""></span>
                  <span>{{post?.address?.venue}} {{post?.address?.city}}, {{post?.address?.state}}, {{post?.address?.country}}</span>
                </p> -->
                <p *ngIf="post?.type=='SHOW' && (post?.address?.venue || post?.venueDetails)">
                  <span><img style="margin-right: 10px;" src="../../../assets/Indietown_icons/location-icon.png" alt=""></span>
                  <span>{{post?.venueDetails}}</span><span *ngIf="post?.venueDetails && post?.address?.venue">,</span><span *ngIf="post?.address?.venue"> {{post?.address?.venue}} {{post?.address?.city}}, {{post?.address?.state}},
                    {{post?.address?.country}}</span>
                </p>
        
                <p *ngIf="post?.type=='SHOW' && post?.money?.from && post?.money?.to">
                  <span><img style="margin-right: 10px;  margin-left: -4px;" src="../../../assets/Indietown_icons/currency.png" alt=""></span>
                  <span>US {{(post?.money?.from)?((post?.money?.from) | currency) : 0 | currency}} - </span>
                  <span>US {{(post?.money?.to)?((post?.money?.to)| currency) : 0 | currency}}</span>
                </p>
              </mat-card-content>
        
              <div class="d-flex justify-content-between">
                <div class="likes-coments">
                  <!-- <span (click)="openLikesModal()"
                    >{{ post?.likesCount }} Likes</span
                  > -->
                  <span style="font-size: 12px;
                  line-height: 18px;" (click)="commentAreaEnable(i)" *ngIf="post?.commentCount">View {{post?.commentCount>1 ? 'all '+post?.commentCount+' Comments':post?.commentCount+' Comment'}}</span>
                  <!-- <span style="margin-left: 20px;">{{getCommentsList(post?._id)}}</span> -->
                  <!-- View all {{ post?.commentsCount }} {{post?.commentsCount>1 ? 'Comments':'Comment'}} -->
                </div>
                <!-- <div>
                  <span>{{ post?.createdOn | date : "HH:mm" }}{{changeTime(post?.createdOn)}}</span>
                  <span>{{changeTime(post?.createdOn)}}</span>
                </div> -->
              </div>
        
        
        
              <div id="postCommentList{{ post?._id }}" class="postcommentlistt" style="display: none; padding-top: 25px;
              margin-top: 25px;
              border-top: 1px solid #e7e6e7;">
                <div style="display:none; background: #eee;
                border-radius: 8px;
                padding: 11px;
                width: 35%;    margin-left: 9%;" class="replyingto" id="replyCrossMark{{post?._id}}">
                  Replying to <span style="color: #000;">{{commentbandname}}</span>
                  <button style="border-radius: 50%;
                  background: #fff;
                  border: none;
                  float: right;
                  color: #000;
                  cursor: pointer;
                  font-weight: bold; padding-bottom: 3px;" (click)="removeReplyOption(i)" class="reply-cross-mark">x</button>
                </div>
                  <div class="d-flex mt-1 commentboxxprofile">
                      <div>
                          <img *ngIf="postId" [src]="myProfileDetails?.profileImage?.fileUrl ? myProfileDetails?.profileImage?.fileUrl : '../../../assets/Indietown_icons/upload-profile-pic.png'" height="50" width="50" alt="" style="border-radius: 100%;margin-top: 22%;    object-fit: cover;">
                          <img *ngIf="!postId" [src]="profileDetails?.profileImage?.fileUrl ? profileDetails?.profileImage?.fileUrl : '../../../assets/Indietown_icons/upload-profile-pic.png'" height="50" width="50" alt="" style="border-radius: 100%;margin-top: 22%;    object-fit: cover;">
                      </div>
                      <form action="" [formGroup]="createCommentForm" class="commentForm">
                      
                          <span class="commentSpan" (click)="createComment(i)" style="z-index: 3;"><img src="../../../assets/Indietown_icons/message-icon.svg" alt=""></span>
                          
                          
                          <textarea (keydown.enter)="stopGoToNextLine($event)" (keyup.enter)="createComment(i)" class="form-control commentboxx" id="addcommnt{{ post?._id }}" formControlName="comment" placeholder="Add a comment" style="border: 1px solid #E9E9EB;
                          width: 100%;
                          background: none;
                          height: 60px;
                          box-shadow: none;
                          padding-top: 15px;
                          transform: translate(5px, 7px);
                          border-radius: 46px;
                          padding-left: 20px !important;
                          padding-right: 50px;
                          font-size: 16px !important;
                          position: relative;
                          resize: none;
                          z-index: 2;" #textareaRef></textarea>
                          <!-- <angular-editor class="form-control commentboxx" id="addcommnt{{ post?._id }}" formControlName="comment" placeholder="Add a comment" style="border: 1px solid rgba(0, 0, 0, 0.48);
                          width: 100%;
                          background: none;
                          height: 60px;
                          padding-top: 15px;
                          transform: translate(5px, 7px);
                          border-radius: 46px;
                          padding-left: 20px !important;
                          font-size: 16px !important;
                          position: relative;
                          resize: none;
                          z-index: 2;"
                          keyup=""
                            #textareaRef
                          [config]="editorConfigs"
                          ></angular-editor> -->
                          <flx-mentions [textInputElement]="textareaRef"
                          [menuTemplate]="menuTemplate"
                          [triggerCharacter]="'@'"
                          [getChoiceLabel]="getChoiceLabel"
                          [selectedChoices]="mentionReplyingTo"
                          (search)="loadChoices($event)"
                          (selectedChoicesChange)="onSelectedChoicesChange($event)"
                          (menuShow)="onMenuShow()"
                          (menuHide)="onMenuHide()"
                          (choiceRemoved)="choiceRemoved($event,i)"
                          ></flx-mentions>
                          <!-- [getChoiceLabel]="getChoiceLabel" -->
                          <ng-template #menuTemplate
                                      let-selectChoice="selectChoice">
                            <ul *ngIf="usersForMention?.length" class="flx-selectable-list" style="background: #fff;
                            border-radius: 8px;
                            border: 1px solid #eee;
                            height: 285px;
                            padding-left: 0px;
                            overflow: auto;
                            list-style-type: none;
                            z-index: 99999;
                            position: relative;
                            width: 100%;
                            box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.05);">
                              <li *ngFor="let user of usersForMention"
                                  class="flx-selectable-list-item" (click)="selectChoice(user)" style="cursor: pointer;
                                  padding: 10px;
                                  height: 60px;margin-bottom: 5px;
      border-radius: 8px;
                                  background: #fff;
                                  display: flex;
                                  align-items: center;
                                  list-style-type: none;">
                              <img style="width:40px; height: 40px; border-radius: 50%; object-fit: cover; margin-right: 15px;" [src]="user.profileImage.fileUrl ? user.profileImage.fileUrl : '../../../assets/Indietown_icons/upload-profile-pic.png'" alt="">
                                <span class="" title="{{user.bandName |titlecase}}">{{user.bandName |titlecase}}</span>
                              </li>
                            </ul>
                          </ng-template>
                      </form>
                  </div>
        
                <div *ngFor="let comment of post?.comments;let commentIndex=index">
                    <div class="d-flex " style="flex-direction: column;">
                      <div class="d-flex">
                        <img *ngIf="comment?.userId?.profileImage?.fileUrl
                        " [src]="comment?.userId?.profileImage?.fileUrl
                        " class="comment-image" style="cursor: pointer;" (click)="navigateToUserProfile(comment?.userId?._id,comment?.userId?.bandName)"/>
                        <img *ngIf="!comment?.userId?.profileImage?.fileUrl
                        " src="../../../assets/Indietown_icons/upload-profile-pic.png" class="comment-image" style="cursor: pointer;" (click)="navigateToUserProfile(comment?.userId?._id,comment?.userId?.bandName)"/>
                        <span style="margin-top: 3%;padding-left: 20px;     width: 100%;" ><b style="    margin-right: 7px;cursor: pointer;" (click)="navigateToUserProfile(comment?.userId?._id,comment?.userId?.bandName)">{{comment?.userId?.bandName}}</b> <span style="" [innerHTML]="getModifiedComment(comment,i) | linky"></span></span><!--{{ getModifiedComment(comment) }}-->
                      </div>
                        <div class="" style="padding-left: 52px; padding-bottom: 7px; font-size: 11px;     padding-top: 10px;" *ngIf="!postId">
                          <span style="color: #0F0D0F; opacity: 0.5;">{{getTimeWeekWise(comment?.createdOn)}}</span>
                          <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" (click)="replyPostCommentEnable(i,comment,'')">Reply</span>
                          <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" (click)="openReportCommentModel(comment._id, post?._id,'')" *ngIf="comment?.userId?._id!=profileDetails?._id">Report</span>
                          <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" *ngIf="comment?.userId?._id==profileDetails?._id" (click)="openDeleteCommentModal(i,commentIndex)">Delete</span>
                        </div>
                        <div class="" style="padding-left: 52px; padding-bottom: 7px; font-size: 11px;     padding-top: 10px;" *ngIf="postId">
                          <span style="color: #0F0D0F; opacity: 0.5;">{{getTimeWeekWise(comment?.createdOn)}}</span>
                          <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" (click)="replyPostCommentEnable(i,comment,'')">Reply</span>
                          <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" (click)="openReportCommentModel(comment._id, post?._id,'')" *ngIf="comment?.userId?._id!=myProfileDetails?._id">Report</span>
                          <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" *ngIf="comment?.userId?._id==myProfileDetails?._id" (click)="openDeleteCommentModal(i,commentIndex)">Delete</span>
                        </div>
                      </div>
                    
                    <div style="display: none; position: relative;" class="replyinputbox reply-box" id="postCommentReply{{comment?._id}}">
                      <span class="replyCommentPost" style="z-index: 3;"><img src="../../../assets/Indietown_icons/message-icon.svg" alt="" (click)="replyPostCommentClick(i,comment?._id,post?._id)"></span>
                      <textarea class="form-control" id="replyPostText{{comment?._id}}" placeholder="Reply" style="border: 1px solid #0000007a;
                      padding-left: 20px !important;
                      font-size: 16px !important;
                      width: 100%;
                      padding-top: 15px;
                      resize: none;
                      background: transparent;
                      height: 60px;
                      margin-top: 10px;
                      margin-left: 8px;
                      border-radius: 46px;" #postCommentReply></textarea>
                      <flx-mentions [textInputElement]="postCommentReply"
                      [menuTemplate]="menuTemplateForReply"
                      [triggerCharacter]="'@'"
                      [getChoiceLabel]="getChoiceLabel"
                      [selectedChoices]="'[]'"
                      (search)="loadChoices($event)"
                      (selectedChoicesChange)="onSelectedChoicesChange($event)"
                      (menuShow)="onMenuShow()"
                      (menuHide)="onMenuHide()"
                      ></flx-mentions>
                      <!-- [getChoiceLabel]="getChoiceLabel" -->
                      <ng-template #menuTemplateForReply
                                  let-selectChoice="selectChoice">
                        <ul class="flx-selectable-list" style="background: #fff;
                        border-radius: 8px;
                        border: 1px solid #eee;
                        height: 285px;
                        padding-left: 0px;
                        overflow: auto;
                        list-style-type: none;
                        z-index: 99999;
                        position: relative;
                        width: 100%;
                        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.05);">
                          <li *ngFor="let user of usersForMention"
                              class="flx-selectable-list-item" (click)="selectChoice(user)" style="cursor: pointer;
                              padding: 10px;
                              margin-bottom: 5px;
      border-radius: 8px;
                              height: 60px;
                              background: #fff;
                              display: flex;
                              align-items: center;
                              list-style-type: none;">
                              <img style="width:40px; height: 40px; border-radius: 50%; object-fit: cover; margin-right: 15px;" [src]="user.profileImage.fileUrl ? user.profileImage.fileUrl : '../../../assets/Indietown_icons/upload-profile-pic.png'" alt="">
                            <span title="{{user.bandName |titlecase}}">{{user.bandName |titlecase}}</span>
                          </li>
                        </ul>
                      </ng-template>
                    </div>
                    <div style="margin-left: 55px;">
                      <!-- && comment?.replies && comment?.replies?.length == 0 -->
                      <div class="d-flex">
                      <span *ngIf="comment?.totalRepliesCount && comment?.totalRepliesCount>0" id="hideunderline{{comment?._id}}" class="hide-underline">&nbsp;</span>
                      <div *ngIf="comment?.totalRepliesCount && comment?.totalRepliesCount>0" style="cursor: pointer;" (click)="showReplies(comment)" class="hiderepl" id="showRepliesBtn{{comment?._id}}">Show {{comment?.totalRepliesCount == 1 ? comment?.totalRepliesCount + ' Reply' : comment?.totalRepliesCount + ' Replies'}}</div>
                    </div>
                      <div *ngIf="comment?.replies && comment?.replies?.length>0" style="display: none; position: relative;" id="repliesBox{{comment?._id}}">
                        <!-- Show Replies Here -->
                        <div *ngFor="let reply of comment?.replies;let replyIndex=index">
                          <div class="d-flex " style="flex-direction: column;">
                          <div class="d-flex">
                            <img *ngIf="reply?.userId?.profileImage?.fileUrl" [src]="reply?.userId?.profileImage?.fileUrl" class="comment-image" style="cursor: pointer;" (click)="navigateToUserProfile(reply?.userId?._id,reply?.userId?.bandName)"/>
                            <img *ngIf="!reply?.userId?.profileImage?.fileUrl" src="../../../assets/Indietown_icons/upload-profile-pic.png" class="comment-image" style="cursor: pointer;" (click)="navigateToUserProfile(reply?.userId?._id,reply?.userId?.bandName)"/>
                            <span style="margin-top: 4%;padding-left: 20px; "><b style="    margin-right: 7px;cursor: pointer;" (click)="navigateToUserProfile(reply?.userId?._id,reply?.userId?.bandName)">{{reply?.userId?.bandName |titlecase}}</b> <span [innerHTML]="getModifiedComment(reply,i) | linky"></span></span>
                          </div>
                            <div class="" style="padding-left: 52px; padding-bottom: 7px; font-size: 11px;     padding-top: 10px;" *ngIf="!postId">
                              <span style="color: #0F0D0F; opacity: 0.5;">{{getTimeWeekWise(reply?.createdOn)}}</span>
                              <span class="commentsListOptions" (click)="replyPostCommentEnable(i,comment,reply)" style="color: #0F0D0F; opacity: 0.5;">Reply</span>
                              <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" (click)="openReportCommentModel(comment._id, post?._id,reply?._id)" *ngIf="reply?.userId?._id!=profileDetails?._id">Report</span>
                              <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" *ngIf="reply?.userId?._id==profileDetails?._id" (click)="openDeleteCommentReplyModal(i,commentIndex,replyIndex)">Delete</span>
                            </div>
                            <div class="" style="padding-left: 52px; padding-bottom: 7px; font-size: 11px;     padding-top: 10px;" *ngIf="postId">
                              <span style="color: #0F0D0F; opacity: 0.5;">{{getTimeWeekWise(reply?.createdOn)}}</span>
                              <span class="commentsListOptions" (click)="replyPostCommentEnable(i,comment,reply)" style="color: #0F0D0F; opacity: 0.5;">Reply</span>
                              <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" (click)="openReportCommentModel(comment._id, post?._id,reply?._id)" *ngIf="reply?.userId?._id!=myProfileDetails?._id">Report</span>
                              <span style="color: #0F0D0F; opacity: 0.5;" class="commentsListOptions" *ngIf="reply?.userId?._id==myProfileDetails?._id" (click)="openDeleteCommentReplyModal(i,commentIndex,replyIndex)">Delete</span>
                            </div>
                          </div>
                          
                          <!-- <div style="display: none; position: relative;" class="replyinputbox" id="postCommentReplyReply{{reply?._id}}">
                            <span class="replyCommentPost" style="z-index: 3;"><img src="../../../assets/Indietown_icons/message-icon.svg" alt="" (click)="replyPostCommentClick(comment?._id,post?._id)"></span>
                            <textarea class="form-control" id="replyPostText{{comment?._id}}" placeholder="Reply" style="border: 1px solid #0000007a;
                            padding-left: 20px !important;
                            font-size: 16px !important;
                            width: 100%;
                            background: transparent;
                            height: 60px;
                            margin-top: 10px;
                            margin-left: 8px;
                            border-radius: 46px;"></textarea>
                          </div> -->
                        </div>
                        <!-- <span *ngIf="comment?.totalRepliesCount>comment?.replies?.length" class="hide-underline">&nbsp;</span> -->
                        <div *ngIf="comment?.totalRepliesCount>comment?.replies?.length" style="padding-top: 5px;" class="d-flex"><span  *ngIf="comment?.totalRepliesCount>comment?.replies?.length" class="hide-underline">&nbsp;</span><span *ngIf="comment?.totalRepliesCount>comment?.replies?.length" (click)="loadMoreReplies(i,commentIndex)" id="repliesLoadMore{{ comment?._id }}" style="cursor: pointer; color: #0F0D0F; opacity: 0.5; padding-left: 0px; font-size: 14px;">Show More Replies</span></div>
                        <div *ngIf="comment?.replies?.length" style="padding-top: 5px;" class="d-flex"><span  *ngIf="comment?.replies?.length" class="hide-underline">&nbsp;</span><span *ngIf="comment?.replies?.length" class="hiderepl" (click)="hideReplies(i,commentIndex)" style="cursor: pointer;color: #0F0D0F; opacity: 0.5;">Hide all replies</span></div>
                        <!-- *ngIf="comment?.replies?.length>3" (click)="loadMoreReplies(i,commentIndex)" id="repliesLoadMore{{ comment?._id }}" -->
                        <!-- <span *ngIf="comment.repliyCount>comment?.replies?.length" (click)="loadMoreReplies(post.?_id,comment?._id)" id="repliesLoadMore{{ comment?._id }}" style="cursor: pointer; font-weight: bold;">Show More</span> -->
                      </div>
                    </div>
                    
                </div>
        
                <span *ngIf="post?.commentsCount>post?.comments?.length" (click)="loadMoreComments(post?._id)" id="commentLoadMore{{ post?._id }}" style="cursor: pointer; font-weight: bold;">Load more comments</span>      
                <span *ngIf="post?.comments?.length>3" (click)="showLessComments(i)" id="showLessComments{{ post?._id }}" style="cursor: pointer; font-weight: bold;" class="ml-3">Show Less</span>      
              </div>
            </mat-card>
            <div *ngIf="!allPosts.length && postId" style="text-align: center;
            margin-top: 25px; font-weight: bold; font-size: 24px;">
              No Shows Yet !
            </div>
            <div *ngIf="!allPosts.length && !postId" style="text-align: center;
            margin-top: 25px; font-weight: bold; font-size: 24px;">
              Add Shows!
            </div>
            </div>

            <div class="col-lg-3 max-width-100 spotifyfortab" [ngClass]="{'positionfix': sidebarFixed==true}" style="max-width: 38%; margin-top: -6%;">
              <!-- Spotify card start -->
              <div>
                <!-- <app-spotify-card [playList]="audioList"  *ngIf="profileDetails?.playList?.spotify?.url"></app-spotify-card> -->
                
                <!-- Hide Spotify
                <app-spotify-card (gotoSpotify)="gotoSpotify()"  [userId]="postId"></app-spotify-card>
                -->
              </div>
              <!-- Spotify card end -->

              <!-- Youtube card start -->
              <!-- *ngIf="profileDetails?.playList?.youtube?.url" -->
              <div style="margin-top: 30px">
                <!-- <app-youtube-card
                  [goYoutube]="selectedIndex"
                  [playList]="playList"
                ></app-youtube-card> -->
                <app-youtube-card (gotoYoutube)="gotoYoutube()" [userId]="postId"></app-youtube-card>
              </div>
              <!-- Youtube card end -->
            </div>
          </div>
        <!-- </ng-template> -->
      </mat-tab>

      <!-- All Shows End -->

      <!-- Friends Section Start -->
      <mat-tab label="Friends">
        <ng-template matTabContent>
          <div class=" row m-0">
            <div class="col-lg-7 photoalbum7 extrah h-700 p-0">
              <app-profile-friends-list [userId]="postId" [myProfileId]="myProfileDetails?._id"></app-profile-friends-list>
            </div>
            <div class="col-lg-3 spotifyfortab max-width-100" style="max-width: 38% ;
               margin-top: -10px;">
                <!-- *ngIf="profileDetails?.playList?.spotify?.url" -->
              <div>
                <!-- <app-spotify-card [playList]="audioList"></app-spotify-card> -->

                <!-- Hide Spotify
                <app-spotify-card (gotoSpotify)="gotoSpotify()" [userId]="postId"></app-spotify-card>
                -->
              </div>
              <!-- *ngIf="profileDetails?.playList?.youtube?.url" -->
              <div style="margin-top: 30px" [ngClass]="{'positionfix': sidebarFixed==true}" >
                <!-- <app-youtube-card [goYoutube]="selectedIndex" [playList]="playList"></app-youtube-card> -->
                <app-youtube-card (gotoYoutube)="gotoYoutube()" [userId]="postId"></app-youtube-card>
              </div>
            </div>
          </div>
        </ng-template>

        <!-- <div class="row" style="margin: 0">
          <div class="col-lg-7">
            <div class="search-friends">
              <div class="form-group">
                <span>
                  <img
                    src="../../../../../assets/icons/search-icon.svg"
                    alt=""
                  />
                </span>
                <input
                  class="form-control"
                  id="search"
                  type="text"
                  (keyup)="searchFriend($event.target.value)"
                  placeholder="Search"
                  aria-label="Search"
                />
              </div>
            </div>

            <div>
              <h1 *ngIf="!postId && !friendsListStatus">
                <span style="font-weight: 600">Top Friends</span>
                <span
                  >({{ friendsList.length > 4 ? 4 : friendsList.length }})</span
                >
              </h1>
              <div class="mt-5 d-flex flex-wrap">
                <div
                  class="friends-cards m-2"
                  style="position: relative"
                  *ngFor="
                    let friend of topFourFriends | filter : searchFriendKey
                  "
                >
                  <img
                    [src]="
                      friend?.profileImage?.fileUrl
                        ? friend?.profileImage?.fileUrl
                        : '../../../../assets/Indietown_icons/upload-profile-pic.png'
                    "
                    alt=""
                    (click)="onClickFriend(friend)"
                    height="77"
                    width="77"
                    class="card-img-top"
                  />

                  <img
                    src="../../../assets/Indietown_icons/top-four.svg"
                    alt=""
                    class="top-4"
                  />

                  <div>
                    <p style="margin-bottom: 5px" class="mt-1 frindsbandname">
                      {{ friend?.bandName | titlecase }}
                    </p>
                    
                  </div>

                  <div>
                    <button
                      *ngIf="showSiidbar == false"
                      class="btn"
                      (click)="sendMessage(friend?._id)"
                    >
                      Message
                    </button>
                    <button
                      *ngIf="showSiidbar == true"
                      class="btn"
                      id="userFriendsList{{ friend?._id }}"
                      (click)="addFriendById(friend?._id)"
                    >
                      Add Friend
                    </button>
                  </div>
                </div>
              </div>

              <div style="margin-top: 5%" *ngIf="!friendsListStatus">
                <h1 *ngIf="!postId">
                  <span style="font-weight: 600">Others</span>
                  <span> ({{ otherFriends.length }})</span>
                </h1>
                <div class="d-flex flex-wrap" style="margin-top: 5%">
                  <div
                    class="friends-cards m-2"
                    style="position: relative"
                    *ngFor="let friend of otherFriends"
                  >
                    <img
                      [src]="
                        friend?.profileImage?.fileUrl
                          ? friend?.profileImage?.fileUrl
                          : '../../../../assets/Indietown_icons/upload-profile-pic.png'
                      "
                      *ngIf="!postId"
                      (click)="onClickFriend(friend)"
                      alt=""
                      height="77"
                      width="77"
                      class="card-img-top"
                    />
                    <img
                      [src]="
                        friend?.profileImage?.fileUrl
                          ? friend?.profileImage?.fileUrl
                          : '../../../../assets/Indietown_icons/upload-profile-pic.png'
                      "
                      *ngIf="postId"
                      routerLink="/user/friends-list"
                      [queryParams]="{ id: friend?._id, timeline: true }"
                      alt=""
                      height="77"
                      width="77"
                      class="card-img-top"
                    />

                    <div>
                      <p style="margin-bottom: 5px" class="mt-1 frindsbandname">
                        {{ friend?.bandName | titlecase }}
                      </p>
                    </div>

                    <div>
                      <button
                        *ngIf="showSiidbar == false"
                        class="btn"
                        (click)="sendMessage(friend?._id)"
                      >
                        Message
                      </button>
                      <button
                        *ngIf="showSiidbar == true"
                        class="btn"
                        id="userFriendsList{{ friend?._id }}"
                        (click)="addFriendById(friend?._id)"
                      >
                        Add Friend
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h1 *ngIf="friendsListStatus">No Friends</h1>
          </div>

          <div class="col-lg-3 max-width-100" style="max-width: 38%">
            
            <div>
              <app-spotify-card [playList]="audioList"></app-spotify-card>
            </div>

           
            <div style="margin-top: 30px">
              <app-youtube-card
                [goYoutube]="selectedIndex"
                [playList]="playList"
              ></app-youtube-card>
            </div>
          </div>
        </div> -->
      </mat-tab>
      <!-- Friends Section End -->

      <!-- <mat-tab label="Spotify-Auth">
        Here comes the spotify
      </mat-tab> -->
      <!-- Spotify Functionality Start -->
      <!-- *ngIf="profileDetails?.playList?.spotify?.url" -->

          <!-- <app-spotify-view></app-spotify-view> -->

       <!-- <span><img src="../../../assets/Indietown_icons/spotify-green-black.svg" height="41" width="41" alt="" /></span>
                        <h1 class="spotyfy-text">Spotify</h1> -->
                         <!-- Hide Spotify
                  <app-spotify [userId]='postId' [addSpotify]="newSpotifyAddedData"></app-spotify>
                  -->
                  <!-- <div class="row" style="margin: 0px"> -->
                        
                        <!-- <div class="col-lg-5 suggestionscardd" (scroll)="onScroll($event)"
                            [ngClass]="{'positionfix': sidebarFixed==true}" style="margin-top: -9%;">
                            <app-suggestions-card></app-suggestions-card>
                        </div> -->
                    <!-- </div> -->

                    <!-- <ang-music-player nextButtonColor="#0F0D0F" previousButtonColor="#0F0D0F"
                    style="position: fixed;bottom: 0px;box-shadow: 0px -10px 60px rgba(0, 0, 0, 0.1); width: 100%; z-index: 9; margin-left: -4.8%;"
                    volumeButtonColor="#0F0D0F" audioTimeColor="#0F0D0F" repeatButtonColor="#0F0D0F"
                    activeRepeatButtonColor="#0F0D0F" backgroundColor="#FFFFFF" playButtonColor="#0F0D0F" 
                    pauseButtonColor="#0F0D0F" volumeSliderColor="#0F0D0F" audioTitleColor="#0F0D0F" 
                    audioTimeColor="#0F0D0F"[audioList]="audioList" loop="true
                   "></ang-music-player> -->
          <!-- <img class="shuffleicn" src="../../../assets/Indietown_icons/shuffle.svg" alt=""> -->
      
          <mat-tab label="Spotify" id="" *ngIf="showSpotifyTab">
        <ng-template matTabContent>
          <div class="about-container spotify-container">
                    <div class="d-flex justify-content-center align-items-center" style="width: 97%;">
                      <span><img src="../../../assets/Indietown_icons/Spotify_Logo_RGB_Green.png" alt="" /></span>

                       
                    </div>
                </div>

                <div class="mt-3" style="">

                 
                  <app-spotify [userId]='postId' [addSpotify]="newSpotifyAddedData"></app-spotify>



                    
                </div>

          
        </ng-template>
      </mat-tab>
      <!-- Spotify Functionality End -->
      <!-- Bandcamp Functionality Start -->
      <mat-tab label="Bandcamp" id="" *ngIf="showBandcampTab">
        <div class="about-container" style="    background-color: black;" [ngClass]="{'userprofileeplaylist': postId}">
          <div class="d-flex justify-content-center align-items-center">
            <span
              ><img
                src="../../../assets/Indietown_icons/bandcamp-input-icon.svg"
                height="41"
                width="41"
                alt=""
            /></span>
            <h1 class="spotyfy-text" style="color: #fff;
            margin-left: 14px;">Bandcamp</h1>
          </div>
        </div>

        <div class="mt-3">
          <div class="row" style="margin: 0px">
            <div class="col-lg-7 h-500" style="padding-left: 0 !important;">
              <app-bandcamp [userId]='postId' [addBandcamp]="newBandcampAddedData"></app-bandcamp>
            </div>

            <!-- <div class="col-lg-1"></div> -->
            <div class="col-lg-5 suggestionscardd" [ngClass]="{'positionfix': sidebarFixed==true}" style="margin-top: -9%">
              <app-suggestions-card></app-suggestions-card>
            </div>
          </div>
        </div>
      </mat-tab>
      <!-- Bandcamp Functionality End -->

      <!-- Soundcloud Functionality Start -->
      <mat-tab label="SoundCloud" id="" *ngIf="showSoundcloudTab">
        <div class="about-container" style="box-shadow: none;    background-color: black;" [ngClass]="{'userprofileeplaylist': postId}">
          <div class="d-flex justify-content-center align-items-center">
            <span
              ><img
                src="../../../assets/Indietown_icons/soundcloud-input-icon.svg"
                height="41"
                width="41"
                alt=""
            /></span>
            <h1 class="spotyfy-text">SoundCloud</h1>
          </div>
        </div>

        <div class="mt-3">
          <div class="row" style="margin: 0px">
             <div class="col-lg-7 h-500" style="padding-left: 0 !important;">
                <div class="">
                   <div>
                      
                    <app-soundcloud [userId]='postId' [addSoundcloud]="newSoundcloudAddedData"></app-soundcloud>
                      
                      <!-- <div class="spotify-music-card m-2" (click)="openSoundCloudModal('')">
                         <div class="row" style="align-items: center">
                            <div class="col-1 col-md-1 mob-md-1">
                               <img
                                  src="../../../assets/images/spirit_house_sound_cloud.png"
                                  alt=""
                                  height="60"
                                  width="60"
                                  style="border-radius: 15px"
                                  />
                            </div>
                            <div class="col-lg-9 col-md-9 mob-md-9" style="">
                               <p class="spot-title">Spirit House by Future Lives</p>
                            </div>
                            
                         </div>
                      </div>
        -->
                      
                      
                      
                   </div>
                </div>
             </div>
             <!-- <div class="col-lg-1"></div> -->
             <div class="col-lg-5 suggestionscardd" style="margin-top: -9%">
                <app-suggestions-card></app-suggestions-card>
             </div>
          </div>
       </div>
      </mat-tab>
      <!-- Soundcloud Functionality End -->

      <!-- Youtube Functionality Start -->
      <!-- *ngIf="profileDetails?.playList?.youtube?.url" -->
      <mat-tab label="YouTube" *ngIf="showYoutubeTab">
        <ng-template matTabContent>
          <div
            class="about-container youtube-container"
            style="background-color: black; color: white"
          >
            <div class="d-flex justify-content-center">
              <span
                ><img
                  src="../../../assets/Indietown_icons/youtube-input-icon.svg"
                 style="    width: 40px;
                 height: 40px;
                 margin-top: -3px;"
                  alt=""
              /></span>
              <h1 class="spotyfy-text" style="padding-top: 0; padding-left: 10px">
                YouTube
              </h1>
            </div>
          </div>

          <div class="mt-3" style="">
            <div class="row" style="margin: 0">
              <!-- infinite-scroll
              (scrolled)="onYoutubeScrollDown($event)"
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="10" -->
              <div
            
                class="col-lg-7 h-500 youtubeplayerbloc d-flex"
                style="padding-left: 0; margin-bottom: 0;"
               
              >
              
              <app-youtube [userId]='postId' [addYoutube]="newYoutubeAddedData" class="w-100 scrollbarhidden" style="height: 600px;
              overflow: auto;overflow-x: hidden;"></app-youtube>

              <!-- [scrollWindow]="true"
                  infinite-scroll-disabled="stopScrollingPost" -->
                <!-- <div
                  class="youtube-music-card ma-20"
                  *ngFor="let video of playList; let videoIndex = index"
                  (click)="openYoutubeModalP(videoIndex, video)"
                              >
                  <img
                    [src]="video?.thumbnails?.high?.url"
                    class="card-img-top"
                    height="179px"
                    alt=""
                    style="border-radius: 15px; width: 100%; height: 170px"
                  />
                  <div class="play-container">
                    <img
                      src="../../../assets/Indietown_icons/videoplayicon.svg"
                      height="45"
                      width="45"
                      alt=""
                    /> -->
                    <!-- class="play-img" -->
                  <!-- </div>
                  <div style="position: relative">
                    <span class="youtubeduration">
                      {{
                        ISO8601toDuration(video?.contentDetails?.duration)
                      }}</span
                    >
                  </div>
                  <div class="card-body" style="padding: 5px 5px 10px 5px">
                    <p class="youtubetitle">{{ video?.title }}</p> -->
                    <!-- <p> <b>time {{getTime(i.id)}}</b></p> -->
                  <!-- </div>
                </div> -->
                <!-- <div *ngIf="moreVideosComing" class="ytloaderr">{{showspinner()}}</div>
                <div *ngIf="!moreVideosComing" class="ytloaderr">{{hidespinner()}}</div> -->
                <!-- <span>{{playList?.length}}</span> -->
                <!-- <h1 *ngIf="playList?.length <= 0" style="margin-top: 20px">
                  No Data Found
                </h1> -->
              </div>

              <div class="col-lg-5 suggestionscardd" [ngClass]="{'positionfix': sidebarFixed==true}" style="margin-top: -9%">
                <app-suggestions-card></app-suggestions-card>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <!-- Youtube Functionality End -->

      <!-- album section start -->
      <mat-tab label="Photo Album">
        <ng-template matTabContent>
          <div class="row" style="margin: 0px">
            <div class="col-lg-7 photoalbum7 extrah h-700 p-0">
              <app-photo-album
                [userId]="postId ? postId : profileDetails?._id"
                [userAvailable]="showSiidbar"
                [deleteButton]="postId == undefined ? true : false"
              ></app-photo-album>
            </div>
            <div class="col-lg-3 max-width-100 spotifyfortab" [ngClass]="{'positionfix': sidebarFixed==true}" style="max-width: 38%">
              <!-- <app-spotify-card [playList]="audioList"  *ngIf="profileDetails?.playList?.spotify?.url"></app-spotify-card> -->

              <!-- Hide Spotify
              <app-spotify-card (gotoSpotify)="gotoSpotify()" [userId]="postId"></app-spotify-card>
              -->
              <app-youtube-card (gotoYoutube)="gotoYoutube()" [userId]="postId"></app-youtube-card>
              <!-- <app-youtube-card
                [goYoutube]="selectedIndex"
                [playList]="playList"
                *ngIf="profileDetails?.playList?.youtube?.url"
              ></app-youtube-card> -->
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <!-- album section end -->

      <!-- about section -->
      <mat-tab label="About">
        <div
          class="row common-styles"
          style="margin-left: 0.5%; margin-right: 1%; margin-bottom: 0;"
        >
          <div class="col-lg-7 photoalbum7">
            <div class="about-container" style="width: 91%; padding: 30px">
              <div class="d-flex justify-content-center">
                <span
                  ><img
                    src="../../../assets/Indietown_icons/about-icon.svg"
                    alt=""
                /></span>
                <h1 class="about-text">About</h1>
              </div>
            </div>

            <div style="margin-top: 4%; margin-left: -25px">
              <div>
                <div class="row abouttabs" id="abottabss">
                  <div class="col-lg-1 mob-md-1 mob-tab-1">
                    <span
                      ><img
                        class=""
                        src="../../../assets/Indietown_icons/aboutlocation.svg"
                        alt=""
                    /></span>
                  </div>
                  <div
                    class="col-lg-9 mob-md-9 mob-tab-9"
                    style="padding: 0; margin-top: 28px; padding-left: 48px"
                  >
                    <span class="about-subtext"
                      >{{ profileDetails?.address?.city }},
                      {{ profileDetails?.address?.state }},
                      {{ profileDetails?.address?.country }}</span
                    >
                  </div>
                </div>
              </div>

              <div style="margin-top: -34px" *ngIf="profileDetails?.bio">
                <div class="row abouttabs" id="abottabss">
                  <div class="col-lg-1 mob-md-1 mob-tab-1">
                    <span
                      ><img
                        class=""
                        src="../../../assets/Indietown_icons/aboutinfo.svg"
                        alt=""
                    /></span>
                  </div>
                  <div
                    class="col-lg-9 mob-md-9 mob-tab-9"
                    style="padding: 0; padding-left: 48px; margin-top: 24px"
                  >
                    <div class="about-subtext"
                      ><pre style="white-space: pre-wrap;word-break: break-word;">{{profileDetails?.bio}}</pre></div
                    >
                    <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Suscipit aut consectetur perferendis eligendi quod eum
                      ipsam, commodi, unde a officiis est? Fugiat sunt adipisci
                      repudiandae similique a voluptatibus temporibus
                      animi! -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 pt-40 spotifyfortab maxwidth100" style="max-width:38%">
            <!-- Spotify card start -->
            <div>
              <!-- <app-spotify-card [playList]="audioList"  *ngIf="profileDetails?.playList?.spotify?.url"></app-spotify-card> -->

              <!-- Hide Spotify
              <app-spotify-card (gotoSpotify)="gotoSpotify()" [userId]="postId"></app-spotify-card>
              -->
            </div>
            <!-- Spotify card end -->

            <!-- Youtube card start -->
            <!-- *ngIf="profileDetails?.playList?.youtube?.url"  -->
            <div [ngClass]="{'positionfix': sidebarFixed==true}">
              <!-- <app-youtube-card
                [goYoutube]="selectedIndex"
                [playList]="playList"
              ></app-youtube-card> -->
              <app-youtube-card (gotoYoutube)="gotoYoutube()" [userId]="postId"></app-youtube-card>
            </div>
            <!-- Youtube card end -->
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<!-- Report Post -->
<ng-template #reportPostModal>
  <div class="modal-header">
    <div class="text-center" style="margin-left: 11%; margin-top: 2%">
      <h1 class="reporttitle">Report Inappropriate Content</h1>
    </div>
    <div style="position: absolute">
      <!-- <button
        type="button"
        class="close pull-left"
        aria-label="Close"
        style="right: 0px"
        (click)="cancel()"
      >
        <span aria-hidden="true">&times;</span>
        <img src="../../../assets/Indietown_icons/back-icon.png" alt="" />
      </button> -->
      <button type="button" (click)="cancel()" aria-label="Close" class="close pull-left" style="right: 0px; margin-top: 10px;border: 0;">
        <img  src="../../../assets/Indietown_icons/leftarrowicon.svg" alt=""></button>
    </div>
  </div>
  <div class="modal-body" style="margin-top: -5%">
    <!-- reportPostModel || postId -->
    <form
      action=""
      [formGroup]="reportPostForm"
      *ngIf="reportPostModel || reportProfileUser"
    >
      <div class="form-group reportText w-100">
        <textarea
          name=""
          formControlName="comment"
          class="form-control"
          placeholder="Report Inappropriate Content"
          id=""
          cols="30"
          rows="10"
          style="height: 235px;
          background: transparent;
          border-radius: 15px;
          margin-bottom: 14px;
          border: 1px solid #E9E9EB;
          margin-top: 25px;
          resize: none;
          padding: 24px 10px 10px 24px;
          box-shadow: none;"
        ></textarea>
      </div>
      <button
        class="btn btn-primary w-100 postShareButton"
        *ngIf="reportPostModel"
        [disabled]="reportPostForm.invalid"
        (click)="reportPostById()"
      >
        Send Report
      </button>
      <button
        class="btn btn-primary w-100 postShareButton"
        *ngIf="reportProfileUser"
        [disabled]="reportPostForm.invalid"
        (click)="reportUserById()"
      >
        Send Report
      </button>
    </form>

    <form action="" [formGroup]="reportCommentForm" *ngIf="reportCommentModel">
      <div class="form-group reportText w-100">
        <textarea
          name=""
          formControlName="comment"
          class="form-control"
          placeholder="Report Inappropriate Content"
          id=""
          cols="30"
          rows="10"
          style="height: 200px; border: 1px solid #E9E9EB;
          border-radius: 15px; background: transparent; padding: 24px 10px 10px 17px;"
        ></textarea>
      </div>
      <button
        class="btn btn-primary w-100 postShareButton"
        [disabled]="reportCommentForm.invalid"
        (click)="reportCommentById()"
      >
        Send Report
      </button>
    </form>
  </div>
</ng-template>

<!-- Edit Profile -->
<ng-template #editProfileModal>
  <div class="modal-header editprofileheader" style="padding: 30px; padding-bottom: 0px;">
    <div class="d-flex justify-content-between w-100" style="margin-right: 0">
      <button
      style="margin-left: 0px;"
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="cancelEditProfileModal()"
        style="background: #FFFFFF;
        border: 1px solid rgba(15, 13, 15, 0.2);
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.05);
        opacity: 1;
        margin-top: -8px;
    margin-left: 0px;
    "
      >
        <span aria-hidden="true"><img style="margin-top: -3px;
          width: 18.5px;
          height: 19.5px;" src="../../../assets/Indietown_icons/cross.svg" alt=""></span>
      </button>
      <div
        class="text-center"
        style="margin-top: 2%; margin-left: 0; width: auto"
      >
        <h1 class="editprofilee">Edit Profile</h1>
      </div>
      <!-- [disabled]="(updateProfileForm.pristine && !profileUpdated) || (updateProfileForm.dirty && updateProfileForm.invalid)" -->
      <button
        type="button"
        aria-label="Close"
        style=" margin-left: 0; background: transparent; border: none !important; cursor: pointer; outline: 0;color: #000;
        font-weight: normal;
        font-size: 26px;"
        (click)="updateProfileData()"
      >
        Save
      </button>
      <!-- <div
      class="close"
        style="font-size: 16px; font-weight: bold; margin-left: 0;pointer-events: none;"
        (click)="updateProfileData()"
      >
        Save
      </div> -->
    </div>
  </div>
  <div class="modal-body text-center delete-modal align-items-center" id="editprofilepopup">
    <div class="createShowFields" style="height: 610px; overflow: auto">

      <form action="" [formGroup]="updateProfileForm">
        <div class="my-3" style="position: relative;width: 200px; margin: auto;">
          <img
            [src]="
            editProfilePictureURL
                ? editProfilePictureURL
                : '../../../../assets/Indietown_icons/upload-profile-pic.png'
            "
            height="120"
            width="120"
            alt=""
            style="border-radius: 100%"
          />
          <div style=" position: absolute;
          right: 29px;
          bottom: 7px;">
          <!-- routerLink="/profile/update"
          (click)="cancel()" -->
            <img
              [src]="editProfilePictureURL?'../../../assets/Indietown_icons/edit-icon2.svg':'../../../../assets/Indietown_icons/camera.png'"
              alt=""
              height="40"
              width="40"
              (click)="showUpdateModal('updateProfilePicture')"
              style="
                cursor: pointer;
              "
            />
          <!-- <img src="../../../../assets/Indietown_icons/camera.png" height="40" width="40" style="cursor: pointer !important; display: none;" id="imageView"/> -->

          </div>
        </div>

     
        <div class="creatShowBanner">
          <div class="my-2" style="display: block; position: relative; width: 100%;">
            <!-- float: left -->
            <div class="editprofiletitles" style="font-weight: bold;text-align: left;">Cover Image</div>
            <div style="    display: flex;
            justify-content: center;
            align-items: center;
            width: 370px;
            height: 193px;
            background-color: black;">
              <img
                [src]="
                editProfileCoverPictureURL
                    ? editProfileCoverPictureURL
                    : '../../../../assets/Indietown_icons/upload-cover-pic.png'
                "
                alt=""
                id="updatecoverpict"
                style="border-radius: 0;width: 100%;"
              />
              <!-- height="193"
              
              width="370px" -->
              <!-- width: 100% -->
            </div>

            <div style="position: relative">
              <!-- routerLink="/profile/update/cover"
                (click)="cancel()" -->
              <img
                [src]="editProfileCoverPictureURL?'../../../assets/Indietown_icons/edit-icon2.svg':'../../../../assets/Indietown_icons/camera.png'"
                alt=""
                height="40"
                width="40"
                (click)="showUpdateModal('updateCoverPicture')"
                style="
                  position: absolute;
                  bottom: 10px;
                  right: 10px;
                  cursor: pointer;
                "
              />
          <!-- <img src="../../../../assets/Indietown_icons/camera.png" height="40" width="40" style="position: absolute;
          bottom: 10px;
          right: 10px;
          cursor: pointer;"  id="imageView"/> -->

            </div>
          </div>
        </div>

        <div class="form-group input-fieldIcons bandnamee" style="margin-top: 9%">
          <label for="" class="editprofiletitles" *ngIf="profileDetails?.role == 'Artist'">Artist Name</label>
          <label for="" class="editprofiletitles" *ngIf="profileDetails?.role == 'Community'">Name</label>
          <!-- *ngif="updateProfileForm.controls.bandName.disabled" -->

          <span class="input-icon" id="bandNameUpdate"
            ><img
              src="../../../assets/Indietown_icons/Edit.svg"
              alt=""
              height="24"
              width="24"
              style="
              cursor: pointer;
            "
              (click)="enableBandNameUpdate()"
          />
        
        </span>
          
          <input
            class="form-control"
            formControlName="bandName"
            placeholder="Band Name"
            id="bandNameUpdateText"
            disabled
          />
        </div>

        <div class="form-group input-fieldIcons">
          <label for="" class="editprofiletitles">Email</label>
          <!-- <span class="input-icon"><img src="../../../assets/Indietown_icons/Edit.svg" alt="" height="24" width="24"></span> -->
          <input
            class="form-control"
            disabled
            formControlName="email"
            placeholder="Email"
            id="venue"
          />
        </div>

        <div class="form-group input-fieldIcons">
          <label for="" class="editprofiletitles">Mobile Number</label>
          <span class="input-icon"><img src="../../../assets/Indietown_icons/Edit.svg" alt="" height="24" width="24" (click)="updateMobNum()"></span>
          <input
            class="form-control"
            disabled
            formControlName="phone"
            placeholder="Mobile Number"
            id="venue"
          />
        </div>

        <div class="form-group input-fieldIcons">
          <label for="" class="editprofiletitles">Location</label>
          <span class="input-icon"
            ><img
              src="../../../assets/Indietown_icons/Edit.svg"
              alt=""
              height="24"
              width="24"
              style="
              cursor: pointer;
            "
              (click)="updateCity()"
          /></span>
          <input
            class="form-control"
            formControlName="location"
            placeholder="Location"
            id="venue"
            style="padding-right: 40px;"
            readonly
          />
        </div>

        <div class="form-group input-fieldIcons">
          <label for="" class="editprofiletitles" *ngIf="profileDetails?.role == 'Artist'">Genre</label>
          <label for="" class="editprofiletitles" *ngIf="profileDetails?.role == 'Community'">Title</label>
          <!-- routerLink="/profile/update/gener" -->
          <span class="input-icon"
            ><img
              src="../../../assets/Indietown_icons/Edit.svg"
              alt=""
              height="24"
              style="
              cursor: pointer;
            "
              width="24"
              (click)="showUpdateModal('updateGener')"
              
          /></span>
          <input
            class="form-control"
            formControlName="genre"
            placeholder="Genre"
            id="GenreList"
            disabled
            style="padding-right: 61px !important;"
          />
        </div>

        <div class="form-group input-fieldIcons">
          <label for="" class="editprofiletitles">Bio</label>
          <span class="input-icon" id="bioUpdate"
            ><img
              src="../../../assets/Indietown_icons/Edit.svg"
              alt=""
              height="24"
              width="24"
              style="
              cursor: pointer;
            "
              (click)="enableBioUpdate()"
          /></span>
          <textarea
            name=""
            formControlName="bio"
            class="form-control"
            placeholder="Bio"
            id="bioUpdateText"
            cols="30"
            rows="10"
            style="resize: none; padding-right: 40px; box-shadow: none;"
            disabled
          ></textarea>
        </div>

        <!-- <div class="form-group input-fieldIcons">
          <label for="" class="editprofiletitles">Edit Spotify URL</label>
          (click)="onClickSpotifyEditUrl()"
          <span
            class="input-icon"
            id = 'spotifyUpdate'
            ><img
              src="../../../assets/Indietown_icons/Edit.svg"
              alt=""
              height="24"
              width="24"
              style="
              cursor: pointer;
            "
              (click)="enableSpotifyUpdate()"
          /></span>
          <span class="editiconns">
            <img src="../../../assets/Indietown_icons/spotify-green-black.svg" alt="">
          </span>
          <input
            class="form-control"
            disabled
            formControlName="spotifyURL"
            placeholder="Edit Spotify URL"
            id="spotifyUpdateText"
            pattern="^(https:\/\/)((?:www|m)\.)?((?:open.))?((?:spotify\.com))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$"
            style="padding-right: 67px;     padding-left: 61px !important;"
          />
          <span *ngIf="updateProfileForm.controls.spotifyURL.invalid">Enter valid spotify URL</span>
        </div> -->

        <!-- <div class="form-group input-fieldIcons">
          <label for="" class="editprofiletitles">Edit YouTube URL</label>
          (click)="onClickYoutubeEditUrl()"
          <span
            class="input-icon"
            id="youtubeUpdate"
            ><img
              src="../../../assets/Indietown_icons/Edit.svg"
              alt=""
              height="24"
              width="24"
              style="
              cursor: pointer;
            "
              (click)="enableYoutubeUpdate()"
          /></span>
          <span class="editiconns">
            <img src="../../../assets/Indietown_icons/youtube-input-icon.svg" alt="">
          </span>
          <input
            class="form-control"
            disabled
            formControlName="youtubeURL"
            placeholder="Edit YouTube URL"
            id="youtubeUpdateText"
            pattern="^(https:\/\/)((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$"
            style="padding-right: 67px;     padding-left: 61px !important;"
          />
          <span *ngIf="updateProfileForm.controls.youtubeURL.invalid">Enter valid youtube URL</span>
        </div> -->
      </form>
    </div>
  </div>
</ng-template>

<!-- Edit Profile -->
<ng-template #showupdateGenerModal>
  <!-- <div class="modal-body text-center delete-modal align-items-center"> -->
    
  <shared-update-gener *ngIf="updateScreen == 'updateGener'" (ids)="collectUpdatedIds($event)"></shared-update-gener>
  <shared-update-city  *ngIf="updateScreen == 'updateCity'" (addressDetails)="collectUpdatedAddress($event)"></shared-update-city>
  <shared-update-cover *ngIf="updateScreen == 'updateCoverPicture'" (coverPicDetails)="collectUpdatedCoverPicture($event)" [profileURL] = 'editProfilePictureURL'></shared-update-cover>
  <shared-update-profile *ngIf="updateScreen == 'updateProfilePicture'" (profilePicDetails)="collectUpdatedProfileDetails($event)"></shared-update-profile>
<shared-updated-mob-num *ngIf="updateScreen == 'updateMobNum'" (MobNumDetails)="collectUpdatedupdateMobNum($event)"></shared-updated-mob-num>
  <!-- </div> -->
</ng-template>



<!-- Youtube Play Modal -->
<ng-template #youtubePlay class="youtubeplaypopup">
  <div class="modal-header">
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true" style="text-shadow: none"
        ><img src="../../../assets/Indietown_icons/close.svg" alt=""
      /></span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center ytmodal">
    <!-- <youtube-player 
      [videoId]="getYoutubeId(youtubePlayData?.url)"
      (ready)="savePlayer($event)"
      (change)="onStateChange($event)">
      </youtube-player> -->
    <!-- <video width="320" height="240" controls>
        <source [src]="youtubePlayData?.url" type="video/mp4">
        <source [src]="youtubePlayData?.url" type="video/ogg">
        Your browser does not support the video tag.
      </video> -->
    <!-- <div #youtubePlayer></div> -->
    <yt-player [videoId]="currentlyPlayingVideoId"></yt-player>
  </div>
</ng-template>

<!-- Friend Modal -->
<!-- <ng-template #friendClickModal>
  <div class="modal-header">
    <div>
      <img
        [src]="singleFriendData?.profileImage?.fileUrl"
        height="77"
        width="77"
        alt=""
        style="border-radius: 100%"
      />
      <div style="position: absolute">
        <div class="friendClickModal-text">
          <p style="font-weight: 600">
            {{ singleFriendData?.bandName | titlecase }}
          </p>
          <p>{{ singleFriendData?.bandName | titlecase }}</p>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body delete-modal"
    style="border-top: solid 3px #f5f5f5; border-radius: unset"
  >
    <ul style="list-style: none; margin-left: -35px">
      <li class="friendClickModal-group">
        <span
          ><img src="../../../assets/Indietown_icons/Chat-white.svg" alt=""
        /></span>
        <span>Message The {{ singleFriendData?.bandName | titlecase }}</span>
      </li>

      <li
        class="friendClickModal-group"
        (click)="onClickUnfriend(singleFriendData?._id)"
      >
        <span
          ><img src="../../../assets/Indietown_icons/unfriend.svg" alt=""
        /></span>
        <span>Unfriend The {{ singleFriendData?.bandName | titlecase }}</span>
      </li>

      <li
        class="friendClickModal-group"
        (click)="onClickSilencePost(singleFriendData?._id)"
      >
        <span
          ><img src="../../../assets/Indietown_icons/Volume Off.svg" alt=""
        /></span>
        <span
          >Silence The {{ singleFriendData?.bandName | titlecase }} Posts</span
        >
      </li>
    </ul>
  </div>
</ng-template> -->

<!-- Spotify Play Modal -->
<ng-template #spotifyPlay>
  <div class="modal-header">
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body text-center delete-modal align-items-center">
    <img [src]="spotifySingleData?.cover" alt="" height="60" width="60" />
    <div class="col-lg-8" style="padding-top: 3.5%">
      <p>{{ spotifySingleData?.title }}</p>
    </div>

    <div class="col-lg-2" style="padding-top: 3.5%">
      <p>{{ getTimeRemaining(spotifySingleData?.time) }}</p>
    </div>

    <audio controls autoplay>
      <source [src]="spotifySingleData?.url" type="audio/ogg" />
      <source [src]="spotifySingleData?.url" type="audio/mpeg" />
      Your browser does not support the audio tag.
    </audio>
  </div>
</ng-template>

<!-- photoAlbumSlider -->
<ng-template #photoAlbumSlider>
  <div class="modal-header">
    <div class="d-flex justify-content-between" style="margin-right: 5%">
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div
        class="text-center"
        style="margin-top: 2%; margin-left: 30%; width: 234px"
      >
        <h1>Photo Album</h1>
      </div>
      <button
        type="button"
        class="close pull-right photoAlbumDelete"
        aria-label="Close"
        (click)="onClickDeletePhotoAlbum(1)"
      >
        <span aria-hidden="true" style="margin-left: 259%">
          <img src="../../../assets/Indietown_icons/delete.svg" alt="" />
        </span>
      </button>
    </div>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <!-- <div style="width: 100%;" id="carouselExampleIndicators">
            <carousel [noWrap]="noWrapSlides" [showIndicators]="showIndicator">
               <slide *ngFor="let slide of slides; let index=index" class="carousel-item">
                  <img [src]="slide.image" alt="image slide" style="display: block; width: 100%;">
                  <div class="carousel-caption">
                  </div>
               </slide>
            </carousel>
          </div> -->

    <owl-carousel-o
      [options]="customOptions"
      (translated)="getPassedData($event)"
    >
      <ng-container *ngFor="let slide of slidesStore; let slideIndex = index">
        <ng-template
          carouselSlide
          [id]="slideIndex"
          [dataMerge]="3"
          id="yourItem"
        >
          <img style="width: 610px; height: 610px" [src]="slide.image" />
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</ng-template>

<!-- Logout fonfirm -->
<ng-template #deleteAlbumImage>
  <div class="modal-header">
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <!-- <img src="../../../../../assets/images/create-successfull-img.svg" alt=""> -->
    <input type="hidden" id="deletePhotoAlbumId" />
    <p
      style="
        margin-top: 0px;
        font-size: 22px;
        font-weight: 500;
        line-height: 40px;
      "
    >
      Are you sure want to delete <br />
      this photo?
    </p>
    <div
      class="modal-btn d-flex justify-content-around"
      style="margin-top: 39px"
    >
      <button class="btn btn-red notDeleteAlbum" (click)="modalRef.hide()">
        No
      </button>
      <button class="btn btn-blue okDeleteAlbum" (click)="photoDelete()">
        Yes
      </button>
    </div>
  </div>
</ng-template>

<!-- Likes Modal -->
<ng-template #likesModel>
  <div class="modal-header">
    <div class="text-center" style="">
      <h1>Likes</h1>
    </div>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="cancel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body createPostBody"
    style="
      min-height: 130px !important;
      max-height: 616px !important;
      overflow: auto;
    "
  >
    <mat-card
      class="d-flex justify-content-between align-items-center"
      *ngFor="let like of likesList"
      style="margin-bottom: 2%"
    >
      <mat-card-header style="display: flex; align-items: center">
        <img
          (click)="navigateToProfile(like?._id,like?.bandName)"
          mat-card-avatar
          width="60"
          height="60"
          [src]="
            like?.profileImage?.fileUrl
              ? like?.profileImage?.fileUrl
              : '../../../../assets/Indietown_icons/upload-profile-pic.png'
          "
        />
        <mat-card-title style="overflow-wrap: anywhere;" (click)="navigateToProfile(like?._id,like?.bandName)">{{ like?.bandName }}</mat-card-title>
        <mat-card-subtitle style="overflow-wrap: anywhere;">{{like?.communityIds.length>0 ? (like?.communityIds[0]?.communityName + " " + (like?.communityIds[1]?.communityName!=null ?  " | " +like?.communityIds[1]?.communityName : '')): ((like?.genereIds[0]?.genereName!=null ? like?.genereIds[0]?.genereName : '') +  (like?.genereIds[1]?.genereName!=null ? " | " +like?.genereIds[1]?.genereName : '') + (like?.genereIds[2]?.genereName ? " | " +like?.genereIds[2]?.genereName :
          ''))}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-actions class="like-friendButtons">
        <button class="btn " id="addFriendTextLikes{{like?._id}}" *ngIf="like?._id != profileDetails?._id" style="    border-radius: 8px;
        font-size: 12px;" (click)="AddFriendLikes(like?._id)"  [ngClass]="{'requestSent' : (like?.friendStatus =='REQUEST_SENT') || (like?.friendStatus =='ACCEPT_REQUEST'), 'requestNotSent' : like?.friendStatus =='NOT_FRIEND','friend':like?.friendStatus=='FRIEND'}">
          <!-- Add Friend -->
          {{like?.friendStatus =='REQUEST_SENT' ?  'Pending' : like?.friendStatus == 'NOT_FRIEND'?'Add Friend' : like?.friendStatus == 'ACCEPT_REQUEST' ? 'Accept' :'Friend'}}
        </button>
        <span style="font-size: 18px; font-weight: 600;margin-right: 20px;" class="" *ngIf="like?._id == profileDetails?._id">You</span>
        
      </mat-card-actions>
    </mat-card>

    <h1 class="text-center" *ngIf="likesList.length == 0" style="font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 40px;
    text-align: center;
    color: #0F0D0F;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
    height: 80vh;">No one has liked yet!</h1>

    <!-- <button class="btn btn-primary w-100 mt-3 postShareButton">Share</button> -->
  </div>
</ng-template>

<!-- Delete Post -->
<ng-template #deletePost>
  <div class="modal-header">
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true"><img style="    width: 19.5px;
        height: 19.5px;
        margin-top: -3px;" src="../../../assets/Indietown_icons/cross.svg" alt=""></span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <p
      style="
        margin-top: 0px;
        font-size: 22px;
        font-weight: 500;
        line-height: 40px;
      "
    >
      Are you sure want to delete <br />
      this {{deletePostShowMsg}}?
    </p>
    <div
      class="modal-btn d-flex justify-content-around"
      style="margin-top: 39px"
    >
      <button
        class="btn btn-red"
        (click)="modalRef.hide()"
        style="color: black !important"
      >
        No
      </button>
      <button
        class="btn btn-blue"
        (click)="clickDeletePost()"
        style="background-color: black !important; margin-left: 20px;"
      >
        Yes
      </button>
    </div>
  </div>
</ng-template>

<!-- Likes Modal -->
<ng-template #commentsListModel>
  <div class="modal-header">
    <div class="text-center" style="margin-left: 34%">
      <h1>Comments</h1>
    </div>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="cancel()"
      style="
        border: 1px solid rgba(15, 13, 15, 0.2) !important;
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.05);
      "
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body createPostBody"
    style="max-height: 400px !important; overflow: auto"
  >
    <mat-card *ngFor="let comment of commentsListData">
      <mat-card-header>
        <img
          mat-card-avatar
          [src]="comment?.commentedBy?.profileImage?.fileUrl"
        />
        <mat-card-title>
          <div>
            <b>{{ comment?.commentedBy?.bandName | titlecase }}</b>
            {{ comment.text }}
          </div>
        </mat-card-title>
        <mat-card-subtitle class="commentsReply d-flex justify-content-between">
          <span (click)="replyCommentEnable(comment?._id)">Reply</span>
          <span
            (click)="cancel()"
            (click)="openReportCommentModel(comment._id, comment?.postId,'')"
            style="cursor: pointer"
            >Report</span
          >
          <span (click)="deleteComment(comment?._id)">Delete</span>
        </mat-card-subtitle>
      </mat-card-header>

      <mat-card-actions
        style="display: none"
        id="commentReply{{ comment?._id }}"
      >
        <span class="replyCommentModal"
          ><img
            src="../../../assets/Indietown_icons/share-icon.png"
            alt=""
            (click)="replyCommentClick(comment?._id, comment?.postId)"
        /></span>
        <input
          class="form-control"
          id="replyText{{ comment?._id }}"
          placeholder="Reply"
          style="
            padding-left: 20px !important;
            font-size: 14px !important;
            width: 100%;
          "
        />
      </mat-card-actions>
    </mat-card>

    <h1
      class="text-center"
      style="font-size: 16px"
      *ngIf="commentsListData.length == 0"
    >
      No Comments
    </h1>

    <form action="" [formGroup]="createCommentForm" class="commentForm">
      <!-- <span class="replyCommentModal" (click)="createComment(openModalPostId)"><img src="../../../assets/Indietown_icons/share-icon.png" alt="" ></span> -->
      <span class="commentSpan" (click)="createComment(openModalPostId)"
        ><img src="../../../assets/Indietown_icons/message-icon.svg" alt=""
      /></span>
      <input
        class="form-control"
        formControlName="comment"
        placeholder="Add a comment"
        style="
          border: 1px solid #0000007a;
          padding-left: 20px !important;
          font-size: 16px !important;
          width: 100%;
          background: transparent;
          height: 60px;
          margin-top: 10px;
          margin-left: 8px;
          border-radius: 46px;
        "
      />
    </form>
  </div>
</ng-template>

<!-- unFriend Popup -->
<ng-template #userUnfriend>
  <div class="modal-header">
    <div>
      <span
        >Sure you want to unfriend?</span
      >
    </div>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <!-- <img src="../../../../../assets/images/create-successfull-img.svg" alt=""> -->
    <p style="margin-top: 25px" class="unfriendText">
      Sure you want to unfriend with <br><b> {{ unFriendUserName }} ?</b>
    </p>
    <div class="modal-btn d-flex" style="margin-top: 39px">
      <button
        class="btn btn-blue logoutbtn"
        (click)="cancel()"
        style="background-color: #e9e9eb !important; color: black !important"
      >
        No
      </button>
      <button
        class="btn btn-red yesbtn"
        style="margin-left: 15px"
        (click)="unFriendConfirm()"
      >
        Yes
      </button>
    </div>
  </div>
</ng-template>

<div class="modal" id="createPostModalBootstrap" aria-hidden="true" aria-labelledby="">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header" style="padding-top: 30px;">
          <div class="text-center createposttitle"  style="margin-left: 32%">
            <h1 style="" class="createposttxt"><span *ngIf="disabledCreateShow">Edit</span> <span *ngIf="!disabledCreateShow">Create</span> Post</h1>
          </div>
          <button
          style="border: 1px solid rgba(15, 13, 15, 0.2) !important;
          box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.05); opacity: 1;     padding: 2px;"
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="cancelPostModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        <!-- <h5 class="modal-title" id="exampleModalToggleLabel">Modal 1</h5>
        <button type="button" class="btn-close" id="myModalTest" aria-label="Close"></button> -->
      </div>
      <div class="modal-body" style="overflow: auto;" [ngClass]="{'editpostheight':disabledCreateShow}">
        <div class="d-flex align-items-center createPost">
          <img
            [src]="profileDetails?.profileImage?.fileUrl ? profileDetails?.profileImage?.fileUrl : '../../../../assets/Indietown_icons/upload-profile-pic.png'"
            style="border-radius: 100%; width: 50px; height: 50px;"
          />
          <div class="profilename">{{ profileDetails?.bandName }}</div>
        </div>
        <form action="" [formGroup]="createPostForm">
          <div class="mt-3">
            <!-- <textarea
              name=""
              formControlName="text"
              class="form-control"
              placeholder="What's on your mind?"
              id=""
              (keypress)="removespacess($event)"
              [ngClass]="{'dynamic':createPostForm.value.text!=''}"
              cols="30"
              rows="10"
              style="height: 130px;font-weight: unset;font-size: 16px;border-radius: 15px;
              resize: none;padding: 20px 10px 10px 20px; background: transparent;border: 1px solid #E9E9EB;box-shadow: none;" ></textarea> -->
              <!-- <angular-editor [placeholder]="'Whats new with you?'"
              name=""
              formControlName="text"
              class="form-control"
              (keyup)="removespacessPost($event)"
              [config]="editorConfig"
              style="height: 130px;font-weight: unset;font-size: 16px;border-radius: 15px;
              resize: none;padding: 0; background: transparent;border: none;box-shadow: none;" ></angular-editor> -->
              <textarea (keyup)="removespacessPost($event)" class="create-post-editor" height="130px" [placeholder]="'Whats new with you?'" name="" formControlName="text"></textarea>
             </div>
    
          <div class="mt-2 d-flex uploadedimagesblock" style="overflow: auto;    margin-bottom: 15px;">
            <div *ngFor="let i of countImagesUploaded; let index = index" class="imgblockdiv">
              <div style="position: unset; cursor: pointer;">
                <!-- <img *ngIf="disabledCreateShow==false"
                  src="../../../assets/Indietown_icons/edit-icon2.svg"
                  class="remove-img"
                  (click)="file.click()"
                  alt=""
                  style="background: #f8f9f8; padding: 5px"
                /> -->
                <input type="file" accept="image/*" class="d-none" *ngIf="!disabledCreateShow" #file (change)="onChangeimg1($event)" (keypress)="checkEnterPress($event)">
                
                <div dropdown class="" *ngIf="disabledCreateShow==false" style="display: inline-block;position: relative;top: 40px;left: 136px;">
                  <div dropdownToggle>
                    <div class="btn-group dropup" placement="top right">
                      <div id="button-alignment" type="button" aria-controls="dropdown-alignment" style="background: #efefef;
                      border-radius: 50%;width: 24px;height: 24px;">
                        <span>
                            <img src="assets/new-icons/vector-bottom.png" alt="" style="width: 7px;
                            height: 7px;
                            margin-left: 5px;
                            margin-bottom: -4px;">
                            <img src="assets/new-icons/vector-top.png" alt="" style="width: 7px;
                            height: 7px;
                            margin-bottom: 8px;">
                        </span>
                      </div>
                      <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                        aria-labelledby="button-alignment" >
                        <li class="menuitem" role="menuitem">
                          <a class="dropdown-item admin-dropdown" (click)="changeImageAspectRatioAll(0)">1:1</a>
                        </li>
                        <div class="dropdown-divider"></div>
                        <li class="menuitem" role="menuitem">
                          <a class="dropdown-item admin-dropdown" (click)="changeImageAspectRatioAll(1)">4:5</a>
                        </li>
                        <div class="dropdown-divider"></div>
                        <li class="menuitem" role="menuitem">
                          <a class="dropdown-item admin-dropdown" (click)="changeImageAspectRatioAll(2)">16:9</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                
                <img *ngIf="disabledCreateShow==false" src="../../../assets/Indietown_icons/edit-icon.svg"
                class="" (click)="cropCreatePostImage(i,index)" alt=""
                style="background: #f8f9f8;
                padding: 5px;
                position: relative;
                top: 40px;
                left: 140px;
                width: 20px;
                height: 20px;
                border-radius: 32%;" />
                <img *ngIf="disabledCreateShow==false"
                  src="../../../assets/Indietown_icons/cross.svg"
                  class="remove-img"
                  (click)="removeImage(index,i.key)"
                  alt=""
                  style="background: #f8f9f8;
                  padding: 5px;left: 170px;
                  position: relative;
                  /* top: 43px; */
                  width: 20px;
                  height: 20px;"
                />
              </div>
              <!-- height="150" -->
              <img
                [src]="i.fileUrl"
                alt=""
                id="uploadedimg"
                
                width="200"
                style="margin: 12px;object-fit: cover;border-radius: 8px;height: auto;"
              />
            </div>
            <div *ngFor="let i of countVideoUploaded; let index = index" class="imgblockdiv">
              <div style="position: relative; z-index: 1; cursor: pointer;" >
                <!-- <img *ngIf="disabledCreateShow==false"
                  src="../../../assets/Indietown_icons/edit-icon2.svg"
                  class="remove-img"
                  (click)="file.click()"
                  alt=""
                  style="background: #f8f9f8; padding: 5px"
                /> -->
                <input type="file" class="d-none" accept="video/*" #file *ngIf="!disabledCreateShow" (change)="onChangeimg1($event)" (keypress)="checkEnterPress($event)">
                <img *ngIf="disabledCreateShow==false"
                  src="../../../assets/Indietown_icons/cross.svg"
                  class="remove-video remove-img"
                  (click)="removeVideo(index,i.key)"
                  alt=""
                  style="background: #f8f9f8;
                  padding: 5px;
                  position: relative;
                  top: 40px;
                  /* left: 159px; */
                  left: 210px !important;
                  width: 20px;
                  height: 20px;
                  border-radius: 32%;"
                />
              </div>
              <!-- width="150" height="150" -->
              <video width="200" [height]="getInputVideoHeight()" controls style="margin:12px;object-fit: cover;" (playing)="onPlayingVideo($event)">
                <source [src]="i.fileNew" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
    
          <!-- <div class="mt-3 d-flex flex-wrap">
            
          </div> -->
        </form>
    
        <input type="hidden" class="form-control" id="videoDurationTime">
        <!-- <div class="ml-1">Hint : Try to upload quality images.</div> -->
        <div class="text-center" *ngIf="!disabledCreateShow">
          <div class="createPostImages">
            <div class="row">
              <!-- (click)="showCreatePostImage()" -->
              <div _ngcontent-hbx-c228="" class="col-6" style="    border-right: 1px solid #c7c7c7">
                <div
                  _ngcontent-hbx-c228=""
                  class="file-input-wrapper-createPost d-flex"
                  style="
                    height: auto;
                    background: transparent;
                    width: 100%;
                    margin-top: 0px;
                  "
                  [ngClass]="{'notAllow':disabledCreateShow}"
                >
                  <input
                  (change)="onChangeimg1($event)" *ngIf="!disabledCreateShow" (keypress)="checkEnterPress($event)"
                    _ngcontent-hbx-c228=""
                    type="file"
                    name="myFile2"
                    id="myFile2"
                    #myfileMac
                    value=""
                    multiple=""
                    accept="image/*,.webm,.mp4,.mov,.mkv,.3gp,.flv,video/*,video/quicktime,video/3g2,video/3gp,video/3gp2,video/asf,video/asx,video/avi,video/m4v,video/mpe,video/mpg,video/wmv"
                    style="
                      height: auto !important;
                      margin-top: 0px;
                      opacity: 0;
                      cursor: pointer;
                      font-size: 0;
                      position: absolute;
                      width: 142px !important;
                    "
                  /><input _ngcontent-hbx-c228="" type="hidden" name="" id="" />
                  <img
                  *ngIf="!disabledCreateShow"
                    _ngcontent-hbx-c228=""
                    src="../../../assets/Indietown_icons/select-video-image.svg"
                    height="29.1"
                    width="29.1"
                    alt=""
                    style="margin-top: 0; z-index: 99999"
                  />
                  <div 
                    _ngcontent-hbx-c228=""
                    class="createPost-Image-Text"
                    style=" padding-right: 35px"
                    [ngClass]="{'padd':!disabledCreateShow}"
                    (click)="myfileMac.click()"
                  >
                   Photo/Video
                  </div>
                </div>
              </div>
    
              <div class="col-6 d-flex align-items-center justify-content-center">
                <span (click)="opneCreateShowModal()" style="cursor: pointer"
                  ><img *ngIf="!disabledCreateShow"
                    src="../../../assets/Indietown_icons/show-group.svg"
                    height="29.1"
                    width="43"
                    alt=""
                    style="margin-top: -15%;"
                />
                <span class="createPost-Image-Text" id="myshowId" [ngClass]="{'notAllow':disabledCreateShow}">Show</span>
              </span>
                
              </div>
            </div>
          </div>
        </div>
    
        <button
          class="btn btn-primary w-100 mt-3 postShareButton"
          [disabled]="!createPostForm.value.text && imagesVideosArray.length ==0 || btndisablespace"
          (click)="userCreatePost()" *ngIf="updatePostButton==false">
          Post
        </button>

        <button
        class="btn btn-primary w-100 mt-3 postShareButton"
        [disabled]="!createPostForm.value.text && imagesVideosArray.length ==0 || btndisablespace"
        (click)="updatePost()" *ngIf="updatePostButton==true">
        Update
      </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="createCommentOnFriendsTimeline" aria-hidden="true" aria-labelledby="">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header" style="padding-top: 30px;">
          <div class="text-center createposttitle"  style="margin-left: 32%">
            <h1 style="" class="createposttxt">Create Post</h1>
          </div>
          <button
          style="border: 1px solid rgba(15, 13, 15, 0.2) !important;
          box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.05); opacity: 1;     padding: 2px;"
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="hideTimelineComment()"
          >
            <span aria-hidden="true"><img style="width: 19px; height: 19px; margin-top: -5px;" src="../../../assets/Indietown_icons/cross.svg" alt=""></span>
          </button>
        <!-- <h5 class="modal-title" id="exampleModalToggleLabel">Modal 1</h5>
        <button type="button" class="btn-close" id="myModalTest" aria-label="Close"></button> -->
      </div>
      <div class="modal-body" style="overflow: auto;" [ngClass]="{'editpostheight':disabledCreateShow}">
        <div class="d-flex align-items-center createPost">
          <img
            [src]="myProfileDetails?.profileImage?.fileUrl ? myProfileDetails?.profileImage?.fileUrl : '../../../../assets/Indietown_icons/upload-profile-pic.png'"
            style="border-radius: 100%; width: 50px; height: 50px;"
          />
          <div class="profilename">{{ myProfileDetails?.bandName }}</div>
        </div>
        <form action="" [formGroup]="commentOnFriendsTimelineForm">
          <div class="form-group reportText w-100" style="margin-bottom: 0;">
            <textarea
            style="    height: 130px;
            background: transparent;
            border-radius: 15px;
            margin-bottom: 20px;
            border: 1px solid #E9E9EB;
            margin-top: 25px;
            margin-bottom: 59px;
            resize: none;
            padding: 20px 10px 10px 20px; box-shadow: none;"
              name=""
              formControlName="comment"
              class="form-control"
              placeholder="What's new with you?"
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <button
            class="btn btn-primary w-100 postShareButton"
            [disabled]="commentOnFriendsTimelineForm.invalid"
            (click)="createCommentOnFriendTimeline()"
          >
            POST
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal createshowpopup" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" style="z-index: 999;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content craeteshowmodal">
      <div class="modal-header">
          <div class="text-center" style="margin-left: 32%">
              <h1 style="font-weight: 500;
              font-size: 26px;
              line-height: 40px;"><span *ngIf="!editshoww">Create</span><span *ngIf="editshoww">Edit</span> Show</h1>
            </div>
            <div *ngIf="!editshoww" style="position: absolute;">
              <button
              
                type="button"
                class="close pull-left"
                aria-label="Close"
                (click)="backButtonClick()"
                style="right: 0px;border: 0;"
                id="myModalTest"
              >
                <!-- <span aria-hidden="true">&times;</span> -->
                <img src="../../../assets/Indietown_icons/leftarrowicon.svg" alt="" />
              </button>
              </div>
              <div *ngIf="editshoww" style="position: absolute; right: 16px;">
              <button
              
          style="border: 1px solid rgba(15, 13, 15, 0.2) !important;
          box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.05);
          width: 35px;
  height: 35px;"
            type="button"
            (click) ="hideshowpopup()"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
        <!-- <h5 class="modal-title" id="exampleModalToggleLabel">Modal 1</h5>
        <button type="button" class="btn-close" id="myModalTest" aria-label="Close"></button> -->
      </div>
      <div class="modal-body" style="max-height: 670px !important;overflow: auto; overflow-x: hidden;">

        <div class="d-flex">
            <img *ngIf="profileDetails?.profileImage?.fileUrl" [src]="profileDetails?.profileImage?.fileUrl" alt="" height="50" width="50" style="border-radius: 100%;">
            <img *ngIf="!profileDetails?.profileImage?.fileUrl" src="../../../assets/Indietown_icons/upload-profile-pic.png" alt="" height="50" width="50" style="border-radius: 100%;">
            <p style="font-size: 16px;font-weight: 600;padding-left: 15px;margin-top: 14px;">{{profileDetails?.bandName}}</p>
        </div>
          <form action="" [formGroup]="createShowForm">
              <div class="createShowFields">
                <div class="creatShowBanner" style="height: auto;"><!-- height: 333px; -->
                  <img [src]="createShowImageUrl" id="craeteshowplaceholder" alt="" width="350" height="225px"  style="width: 100%; border-radius: 15px;height: 100%;"/>
        
                  <!-- <div class="file-input-wrapper">
                    <input
                      #myFile2
                      type="file"
                      name="myFile2"
                      id="myFile2"
                      value=""
                      multiple=""
                      (change)="uploadShowImage($event)"
                      accept="image/*"
                      style="margin-top: 106px;"
                    />
                    <input type="hidden" name="" id="" formControlName="imageUrl" />
                  </div> -->
                  <div class="cameraCover" style="position: relative;">
                    <!-- multiple="" -->
                    <!-- (change)="uploadShowImage($event)" -->
                    <input
                    #myFile2
                    type="file"
                    name="myFile2"
                    id="uploadshowImg"
                    class="cameracoverimg"
                    value=""
                    (change)="uploadShowImage1($event)"
                    accept="image/*"
                  />
                  <input type="hidden" name="" id="" formControlName="imageUrl" />
                    <img
                      src="../../../../assets/Indietown_icons/camera.png"
                      alt=""
                      height="75"
                      width="75"
                      id="imageView"
                    />
                  </div>
                </div>

                <!-- image cropper functionality -->
                <!-- <div class="mt-3">
                  <image-cropper
                  [imageChangedEvent]="imageChangedEvent"
                  [maintainAspectRatio]="true"
                  [aspectRatio]="4 / 3"
                  format="png"
                  (imageCropped)="imageCropped($event)"
                  ></image-cropper>
                </div>
                <button *ngIf="imageChangedEvent!=''" (click)="uploadNewShowImage()" class="btn btn-dark">upload this image</button> -->



                <div class="form-group" style="margin-top: 30px;margin-bottom: 3rem;">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="title"
                    placeholder="Enter Show Title"
                    (keypress)="removespacess($event,2)"
                    name=""
                    style="background: transparent;  border: 1px solid #E9E9EB;"
                    id=""
                   
                  />
                </div>
        
                <div class="row" style="margin-top: -40px">
                  <div class="col-lg-5 startdate" >
                    <div class="form-group">
                      <mat-form-field appearance="fill w-100">
                        <!-- <mat-label>Verbose datepicker</mat-label> -->
                        <!-- <input matInput [matDatepicker]="dp"> -->
                        <input
                        id="startdate"
                        readonly
                          matSuffix
                          matInput
                          [matDatepicker]="startDate"
                          formControlName="startDate"
                          placeholder="Start Date"
                          style="padding-left: 44px !important;"
                        />
                        <!-- [max]="maxStartDate" -->
                        <!-- <mat-hint>MMMM DD, YYYY</mat-hint> -->
                        <mat-datepicker-toggle
                          matIconSuffix
                          [for]="startDate"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
        
                  <div class="col-lg-7 starttimer" style="margin-bottom: 0px;">
                    <div class="form-group">
                      <timepicker formControlName="startTime"></timepicker>
                    </div>
                  </div>
        
                  <div class="col-lg-5 enddate" style="margin-top: -9%">
                    <div class="form-group">
                      <mat-form-field appearance="fill w-100">
                        <!-- <mat-label>Verbose datepicker</mat-label> -->
                        <!-- <input matInput [matDatepicker]="dp"> -->
                        <input
                        readonly
                          matSuffix
                          matInput
                          id="enddate"
                          [matDatepicker]="endDate"
                          formControlName="endDate"
                          placeholder="End Date"
                          [min]="minEndDate"
                          style="padding-left: 44px !important;"
                        />
                        <!-- <mat-hint>MMMM DD, YYYY</mat-hint> -->
                        <mat-datepicker-toggle
                          matIconSuffix
                          [for]="endDate"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
        
                  <div class="col-lg-7 endtimer" style="margin-top: -9%">
                    <div class="form-group">
                      <!-- <ng-template *ngIf="createShowForm.controls.startDate.value == createShowForm.controls.endDate.value"> -->
                        <timepicker formControlName="endTime"></timepicker>
                        <!-- [min]="createShowForm.controls.startTime.value" -->
                      <!-- </ng-template> -->

                      <!-- <ng-template *ngIf="createShowForm.controls.startDate.value<createShowForm.controls.endDate.value">
                        <timepicker formControlName="endTime"></timepicker>
                      </ng-template>
                      -->
                    </div>
                  </div>
                </div>

                <div class="form-group input-fieldIcons">
                  <span class="input-icon"><img src="../../../assets/Indietown_icons/location-icon.png" alt="" /></span>
                  <input class="form-control" placeholder="Address" id="venueDetails" formControlName="venueDetails" style="box-shadow: none !important;
                            border: 1px solid #E9E9EB !important;
                            background: transparent !important;" />
                </div>
        
                <div class="form-group input-fieldIcons">
                      <span class="input-icon"
                      ><img
                        src="../../../assets/Indietown_icons/location-icon.png"
                        alt=""
                    /></span>
                    <input placeholder="Venue" style=" border: 1px solid #E9E9EB !important;
                    background: transparent !important;" (keypress)="CheckEnterPress($event)" formControlName="venue" 
                   
                    class="controls form-control w-100" id="pac-input" type="text"/>
                    <div id="mapmk1234" class="mt-3" style="height: 50px;" hidden></div>
                </div>
        
                <div class="form-group input-fieldIcons">
                      <span class="input-icon"><img
                        src="../../../assets/Indietown_icons/location-icon.png"alt=""/></span>
                    <input
                      class="form-control"
                      placeholder="City"
                      id="cityNameFetch"
                      formControlName="city"
                      style="box-shadow: none !important;
                      border: 1px solid #E9E9EB !important;
                      background: transparent !important;"
                      readonly
                    />
                </div>
        
                <div class="form-group input-fieldIcons">
                  <span class="input-icon"
                    ><img
                      src="../../../assets/Indietown_icons/location-icon.png"
                      alt=""
                  /></span>
                  <input
                    class="form-control"
                    placeholder="State"
                    id="stateModal"
                    style="box-shadow: none !important;
                     border: 1px solid #E9E9EB !important;
                      background: transparent !important;"
                    formControlName="state"
                    readonly
                  />
                  <input type="hidden" id="abcd">
                  <!-- <input placeholder="City Name Enter" class="controls form-control pac-input" type="text"/>

                    
                    <div class="mt-3 mapmk1234" style="height: 100px; width: 200px;border: solid 1px black;"></div> -->
                </div>
        
                <div class="form-group input-fieldIcons">
                  <span class="input-icon"
                    ><img
                      src="../../../assets/Indietown_icons/location-icon.png"
                      alt=""
                  /></span>
                  <input
                  readonly
                  style="box-shadow: none !important;
                   border: 1px solid #E9E9EB !important;
                      background: transparent !important;"
                    class="form-control"
                    placeholder="Country"
                    id="countryModal"
                    formControlName="country"
                  />
                  <label for="" style="color: black;margin-top: 10px;margin-bottom: 10px;">Optional</label>
                </div>
        
                <div class="row" style="margin-top: 70px;">
                  <div class="col-lg-6">
                    <div class="form-group input-fieldIcons">
                      <span class="input-icon"
                        ><img src="../../../assets/Indietown_icons/currency.png" alt=""
                      /></span>
                      <input
                      autocomplete="off"
                        class="form-control"
                        placeholder="Price Range"
                        id="venue"
                        style=" border: 1px solid #E9E9EB !important;
                        background: transparent !important;"
                        formControlName="ticketFrom"
                        type="number"
                        (keyup) = "validatePriceRange1($event)"
                    (change)="validatePriceRange1($event)"
                        [ngClass]="{'redbordercolor':createShowForm.value.ticketFrom<0 || changeToNumber(createShowForm.value.ticketFrom) > changeToNumber(createShowForm.value.ticketTo) &&  createShowForm.value.ticketTo!='' && createShowForm.value.ticketTo!=undefined}"

                      />
                    </div>
                  </div>
        
                  <div class="col-lg-6">
                    <div class="form-group input-fieldIcons">
                      <span class="input-icon"
                        ><img src="../../../assets/Indietown_icons/currency.png" alt=""
                      /></span>
                      <input
                      autocomplete="off"
                      type="number"
                        class="form-control"
                        placeholder="Price Range"
                        id="venue"
                        style=" border: 1px solid #E9E9EB !important;
                        background: transparent !important;"
                        formControlName="ticketTo"
                        (keyup)="validatePriceRange($event)"
                        (change)="validatePriceRange($event)"
                        [ngClass]="{'redbordercolor':createShowForm.value.ticketTo<0 }"
                      />
                      <!-- [min]="createShowForm.controls.ticketFrom.value" -->
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div style="margin-top: -30px">
              <button [disabled]="createShowForm.invalid || btndisablespace || !croppedShowPicture || !croppedShowPictureName" *ngIf="!updateShowButton"
                class="btn btn-primary w-100 mt-3 postShareButton"(click)="userCreateShow()">
                Post
              </button>

              <button [disabled]="createShowForm.invalid || btndisablespace" *ngIf="updateShowButton"
              class="btn btn-primary w-100 mt-3 postShareButton"(click)="updateShow()">
                Update
            </button>

              <!-- <button *ngIf="updateShowButton"
                class="btn btn-primary w-100 mt-3 postShareButton"(click)="updateShow()">
                Update
              </button> -->
            </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addPlaylistModal>
  <app-register-playlist (closePopup)="closeRegisterPlaylist()" (urlAdded)="newPlaylistUrlAdded($event)"></app-register-playlist>
  <!-- <div class="modal-header">
    <div>
      <span
        >Add Playlist</span
      >
    </div>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="addPlaylistModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
   Add Playlist info Here
  </div> -->
</ng-template>

<ng-template #ProfileOrCoverPictureview>
  <div class="modal-header">
    <!-- <div>
      <span
        >Sure you want to unfriend?</span
      >
    </div> -->
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="enlargeProfileOrCoverPictureModalRef?.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <img [src]="enlargeProfileOrCoverPictureURL" style="max-width: 600px;
    max-height: 600px;
    min-width: 300px;
    min-height: 300px;">    
  </div>
</ng-template>
<ng-template #cancelRequests>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center align-items-center" style="height: 220px !important;">
    <p style="line-height: 26px;font-size: 16px;font-weight: bold;color: black;">Are you sure you want to cancel <br>
      this request?</p>
    <div class="modal-btn d-flex justify-content-around" style="margin-top: 39px;">
      <button class="btn btn-red nobtn" (click)="cancelFriendRequest()" style="color: black;margin-right: 10px;font-weight:bold;background-color: #f0f0f0 !important;font-size: 1rem;">Yes</button>
      <button class="btn btn-blue logoutbtn" (click)="modalRef?.hide()" style="background-color: black;font-weight:bold;color: white;font-size: 1rem;">No</button>
    </div>
    <div *ngIf="showCancelRequestLoader" style="text-align: center;">
      <img width="50"  src="../../../assets/Indietown_icons/Loading_icon.gif">
    </div>
  </div>
</ng-template>

<ng-template #CreateShowCropModal>
  <div class="" >
  <div  class="modal-header " style="padding: 20px;    padding-bottom: 10px;">
      <h4 class="modal-title pull-left" style="font-weight: 600;
margin-bottom: 0px;">EDIT</h4>
      <button type="button" class="btn-close close pull-right"
          style="width:30px; height: 30px; font-size: 30px; opacity: 1;"
          aria-label="Close" (click)="cancelUploadShowImage()">
          <span aria-hidden="true" style="opacity: 1; text-shadow: none;"
              class="visually-hidden">&times;</span>
      </button>
  </div>
  <div class="modal-body "  style="padding: 20px;">
      <div class="profile-crop">
        <!-- [backgroundColor]="green" -->
          <image-cropper [imageChangedEvent]="showImageChangedEvent"
              [maintainAspectRatio]="true"
              [aspectRatio]="aspectRatio" format="png" [allowMoveImage]="true" [(transform)]="imageTransform"
              (imageCropped)="croppedProfilePicture($event)"></image-cropper>

      </div>
      <div style="padding-top: 10px;">
        <div class="" style="    text-align: center;">
            
          <div dropdown class="mt-3 mr-2" style="display: inline-block;" [dropup]="true">
            <div dropdownToggle>
              <div class="btn-group dropup" placement="top right">
                <div id="button-alignment" type="button" aria-controls="dropdown-alignment" style="background: #efefef;
                padding-top: 10px;
                padding-inline: 10px;
                border-radius: 50%;">
                  <span>
                    <!-- <img style="    margin-left: 9px;" src="assets/Indietown_icons/Group 1171276050.png" height="20" width="20"
                      alt="" /> -->
                      <img src="assets/new-icons/vector-bottom.png" alt="">
                      <img src="assets/new-icons/vector-top.png" alt="" style="padding-bottom: 20px;">
                  </span>
                </div>
                <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                  aria-labelledby="button-alignment" >
                  <li class="menuitem" role="menuitem">
                    <a class="dropdown-item admin-dropdown" (click)="changeImageAspectRatio(0)">1:1</a>
                  </li>
                  <div class="dropdown-divider"></div>
                  <li class="menuitem" role="menuitem">
                    <a class="dropdown-item admin-dropdown" (click)="changeImageAspectRatio(1)">4:5</a>
                  </li>
                  <div class="dropdown-divider"></div>
                  <li class="menuitem" role="menuitem">
                    <a class="dropdown-item admin-dropdown" (click)="changeImageAspectRatio(2)">16:9</a>
                  </li>
                  <!-- <div class="dropdown-divider"></div>
                  <li class="menuitem" role="menuitem">
                    <a class="dropdown-item admin-dropdown">1:1</a>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
          <button type="button" class="btn croppic" style="background: #efefef;" (click)="cancelUploadShowImage()">     
            <span style="background: #efefef; padding:4px 10px; border-radius: 4px; color: #000; font-size: 16px;">Cancel</span>
          </button>
          <button type="button" class="ml-2 btn croppic" style="background: #000;" (click)="uploadShowImage2()">
            <span style="background: #000; padding:4px 10px; border-radius: 4px; color: #fff; font-size: 16px;">Save</span>
          </button>

          <button class="ml-2 text-white croppic"
          style="background: transparent !important;transform: translate(0px, 5px); outline: 0 !important; color: #555 !important;outline:0 !important;  border:none !important; padding: 0px !important; margin-right: 5px !important;  font-size: 28px !important;margin-top:20px !important; outline:0 !important"
          (click)="zoomOut()">-</button>
          <input type="range" min="1" max="10" step=".1" value="{{scale}}"
          (mouseup)="ZoomImage($event)" class="slider" id="myRange"
          style="background: #ccc;margin-top:-10px !important;">

          <button class="text-white croppic"
          style="background: transparent !important;transform: translate(5px, 5px); outline: 0 !important; color: #555 !important;  border:none !important; font-size: 28px !important;margin-top:20px !important; padding: 0px !important; margin-left: 5px !important; outline:0 !important"
          (click)="zoomIn()">+</button>

          

          <!-- <button class="text-white croppic"
          style="background: transparent !important; color: #555 !important;  border:none !important; font-size: 28px !important;margin-top:20px !important; padding: 0px !important; margin-left: 5px !important;"
          (click)="moveLeft()">left-arrow</button>

          <button class="text-white croppic"
          style="background: transparent !important; color: #555 !important;  border:none !important; font-size: 28px !important;margin-top:20px !important; padding: 0px !important; margin-left: 5px !important;"
          (click)="moveRight()">right-arrow</button> -->
          <!-- (click)="profilePictureCancelled()" -->

        </div>
      </div>
  </div>
  </div>
</ng-template>

<ng-template #CreatePostImageCropModal>
  <div class="" >
  <div  class="modal-header " style="padding: 20px;    padding-bottom: 10px;">
      <h4 class="modal-title pull-left" style="font-weight: 600;
margin-bottom: 0px;">Edit Your Image</h4>
      <button type="button" class="btn-close close pull-right"
          style="width:30px; height: 30px; font-size: 30px; opacity: 1;"
          aria-label="Close" (click)="cancelCreatePostEditImage()">
          <span aria-hidden="true" style="opacity: 1; text-shadow: none;"
              class="visually-hidden">&times;</span>
      </button>
  </div>
  <div class="modal-body "  style="padding: 20px;">
      <div class="profile-crop">

        <!-- [imageURL]="createPostEditImageUrl" -->
          <image-cropper 
          [imageFile]="createPostEditImageFile"
              [maintainAspectRatio]="true"
              [aspectRatio]="aspectRatioAll" format="png" [allowMoveImage]="true" [(transform)]="createPostEditImageTransform"
              (imageCropped)="croppedCreatePostImage($event)"></image-cropper>

      </div>
      <div style="padding-top: 10px;">
        <div class="" style="    text-align: center;">
            
          <button type="button" class="btn croppic" style="background: #efefef;" (click)="cancelCreatePostEditImage()">     
            <span style="background: #efefef; padding:4px 10px; border-radius: 4px; color: #000;font-size: 16px;">Cancel</span>
          </button>
          <button type="button" class="ml-2 btn croppic" style="background: #000;" (click)="updateCreatePostEditImage()">
            <span style="background: #000; padding:4px 10px; border-radius: 4px; color: #fff;font-size: 16px;">Save</span>
          </button>

          <button class="ml-2 text-white croppic"
          style="background: transparent !important; transform: translate(0px, 5px); outline: 0 !important; color: #555 !important;  border:none !important; padding: 0px !important; margin-right: 5px !important;  font-size: 28px !important;margin-top:20px !important"
          (click)="createPostImageEditZoomOut()">-</button>
          <input type="range" min="1" max="10" step=".1" value="{{createPostImageEditScale}}"
          (mouseup)="createPostImageEditZoomImage($event)" class="slider" id="myRange2"
          style="background: #ccc;margin-top:-10px !important;">

          <button class="text-white croppic"
          style="background: transparent !important; transform: translate(5px, 5px); outline: 0 !important; color: #555 !important;  border:none !important; font-size: 28px !important;margin-top:20px !important; padding: 0px !important; margin-left: 5px !important;"
          (click)="createPostImageEditZoomIn()">+</button>

          

          <!-- <button class="text-white croppic"
          style="background: transparent !important; color: #555 !important;  border:none !important; font-size: 28px !important;margin-top:20px !important; padding: 0px !important; margin-left: 5px !important;"
          (click)="moveLeft()">left-arrow</button>

          <button class="text-white croppic"
          style="background: transparent !important; color: #555 !important;  border:none !important; font-size: 28px !important;margin-top:20px !important; padding: 0px !important; margin-left: 5px !important;"
          (click)="moveRight()">right-arrow</button> -->
          <!-- (click)="profilePictureCancelled()" -->

        </div>
      </div>
  </div>
  </div>
</ng-template>

<ng-template #deleteCommentPopup>
  <div class="modal-header">
    <button type="button" style="border: 1px solid rgba(15, 13, 15, 0.2) !important;
      box-shadow: 0px 14px 34px rgb(0 0 0 / 5%);" class="close pull-right" aria-label="Close"
      (click)="deleteCommentModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <p style="margin-top: 0px;font-size: 22px;font-weight: 500;line-height: 40px;">Are you sure want to delete <br> this
      Comment?</p>
    <div class="modal-btn d-flex justify-content-around" style="margin-top: 39px;">
      <button class="btn btn-red mr-2" (click)="deleteCommentModalRef.hide()" style="color: black !important;">No</button>
      <button class="btn btn-blue" (click)="deletePostComment()" style="background-color: black !important;">Yes</button>
    </div>
  </div>
</ng-template>

<ng-template #deleteCommentReplyPopup>
  <div class="modal-header">
    <button type="button" style="border: 1px solid rgba(15, 13, 15, 0.2) !important;
      box-shadow: 0px 14px 34px rgb(0 0 0 / 5%);" class="close pull-right" aria-label="Close"
      (click)="deleteCommentReplyModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <p style="margin-top: 0px;font-size: 22px;font-weight: 500;line-height: 40px;">Are you sure want to delete <br> this
      Reply?</p>
    <div class="modal-btn d-flex justify-content-around" style="margin-top: 39px;">
      <button class="btn btn-red mr-2" (click)="deleteCommentReplyModalRef.hide()" style="color: black !important;">No</button>
      <button class="btn btn-blue" (click)="deletePostCommentReply()" style="background-color: black !important;">Yes</button>
    </div>
  </div>
</ng-template>

<!-- Invalid Video -->
<ng-template #invalidVideoCodecPopup>
  <div class="modal-header">
   
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <button type="button" style="border: 1px solid rgba(15, 13, 15, 0.2) !important;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    position: absolute;
    right: 30px;opacity: 1;" class="close pull-right" aria-label="Close"
      (click)="closeUnsupportedVideoPopup()">
      <span aria-hidden="true" style="font-size: 36px;line-height: 1;color: black;">&times;</span>
    </button>
    <div >
      <img src="assets/Indietown_icons/exclamatory-mark.png" width="" height="" alt="">
    </div>
    <div style="font-size: 26px;line-height: 39px;font-weight: 600;color:#000000;" class="mt-3 mb-3">The following <span *ngIf="unSupportedVideoDetails.length == 1" class="flag">video is not</span><span class="flag" *ngIf="unSupportedVideoDetails.length > 1">videos are not</span>  <br> supported by your browser</div>
    <div style="font-size: 16px;line-height: 26px;font-weight: 400;">
      <ul style="list-style-type: decimal;">
        <li *ngFor="let vid of unSupportedVideoDetails" style="text-align: start;">
          {{vid?.name}}
        </li>
      </ul>
      
    </div>
    <div class="modal-btn d-flex justify-content-around" style="margin-top: 39px;">
      <button class="btn col-lg-12" (click)="closeUnsupportedVideoPopup()" style="background-color: black !important;color:white;height: 70px;
      font-size: 20px;">Okay</button>
      <!-- <button class="btn btn-blue" (click)="clickDeletePost()" style="margin-left: 20px; background-color: black !important;">Yes</button> -->
    </div>
  </div>
</ng-template>
<ng-template #template>
  
  <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="logoutmodalRef.hide()">
          <span aria-hidden="true"><img style="filter: brightness(0);
           width: 39px;
  height: 29px;
            margin-top: -2px;" src="assets/Indietown_icons/close.svg" alt=""></span>
      </button>
  </div>
  <div class="modal-body text-center delete-modal logoutpopupp align-items-center">
      <!-- <img src="../../../../../assets/images/create-successfull-img.svg" alt=""> -->
      <p style="margin-top: 10px;" class="logouttxt">Are you sure want to logout?</p>
      <div class="modal-btn d-flex" style="margin-top: 39px;">
          <button class="btn btn-blue logoutbtn" (click)="confirm()" style="background-color: #E9E9EB !important;color: black !important;">Yes</button>
          <button class="btn btn-red nobtn" style="margin-left: 15px;" (click)="logoutmodalRef.hide()">No</button>
      </div>
  </div>
</ng-template>