<!-- <p>spotify-view works!</p> -->
<app-layout></app-layout>

<div style="margin-left: 18%; margin-top: 100px;margin-right: 54px;">
    
    <div class="about-container spotify-container">
        <div class="go-back mb-2" (click)="goBack()">
            <img width="20" height="20" src="assets/Indietown_icons/back-arrow-icon.svg" alt="">
        </div>
        <div class="d-flex mb-4" style="justify-content: space-between;align-items: center;">
            <span><img src="assets/Indietown_icons/Spotify_Logo_RGB_Green.png" /></span>
            <!-- <h1 class="spotyfy-text">Spotify</h1> -->
            <div (click)="openAddMusicPopup()" style="cursor: pointer;margin-right: 30px;" *ngIf="!showDeleteIcon && !this.userId">
                <span><img src="assets/Indietown_icons/Group_1171275041.png" alt=""></span>
            </div>
            <div (click)="cancelRemove()" style="cursor: pointer;
            margin-right: 30px;
            border: 1px solid rgba(15, 13, 15, 0.2);
            border-radius: 15px;
            padding: 3px;" *ngIf="showDeleteIcon">
                <span><img src="assets/Indietown_icons/close_spotify_new.png" alt="" style="width: 22px;height: 22px;"></span>
            </div>
        </div>
    </div>
    <!-- (click)="navigateToSelectedSong('singleTrack',spotifyIndex,'')" -->
    <div class="d-flex" style="flex-wrap: wrap;">
        <div *ngFor="let track of spotifyList; let spotifyIndex = index" class="mr-4 mb-4" (contextmenu)="onRightClick()" style="cursor: pointer;position: relative;">
            <div class="cross-icon" (click)="remove(track, spotifyIndex)" *ngIf="showDeleteIcon">
                <img width="16" height="16" src="assets/Indietown_icons/close_spotify.png" alt="">
            </div>
            <div class="cursorpointer" (click)="navigateToSelectedSong(track.contentType,spotifyIndex)">
                <img [src]=" track?.imageUrl" alt="" height="243" width="243" style="object-fit: cover;"/>
            </div>
            <div class="track-name mt-2 cursorpointer">
                <div class="d-inline-block">{{ track?.name | slice:0:16 }}</div>
                <div *ngIf="track?.name?.length > 16" class="d-inline">...</div>
            </div>
            <div class="track-artist-name">
                <div class="d-inline-block" *ngIf="track.contentType == 'track'">{{ track?.items[0]?.artists[0]?.name | slice:0:16 }}</div>
                <div class="d-inline-block" *ngIf="track.contentType == 'playlist'">{{ track?.owner?.display_name | slice:0:16 }}</div>
                <div class="d-inline-block" *ngIf="track.contentType == 'album'">Album</div>
                <div *ngIf="track.contentType == 'track' && track?.items[0]?.artists[0]?.name?.length > 16" class="d-inline">...</div>
                <div *ngIf="track.contentType == 'playlist' && track?.owner?.display_name?.length > 16" class="d-inline">...</div>
            </div>
        </div>
        <div *ngIf="!spotifyList.length" style="display: flex;
        justify-content: center;
        width: 100%;" class="mb-5 mt-4">
            <div style="font-size: 24px;font-weight: bold;">Get started by Adding Songs/Albums/Playlists</div>
        </div>
    </div>
    <div class="spotify-logo-open d-block d-flex align-items-center mb-4" style="cursor: pointer;" (click)="navigateToSpotifyApp()">
        <img src="assets/Indietown_icons/Spotify_Logo_RGB_Green_2_New.png" width="35px" height="34px"> <div style="font-weight: 500;font-size: 20px;line-height: 30px;display: inline-block;">OPEN SPOTIFY</div> 
     </div>
</div>

<ng-template #addMusicURL>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="addMusicURLModalref.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center align-items-center" style="margin-left: 20px;
    margin-right: 20px;">
        <h2 style="font-size: 26px;
        font-weight: 600;
        line-height: 39px;
        font-family: 'Poppins';">Add music URL</h2>
        <div style="line-height: 26px;font-size: 16px;font-weight: 400;color: #000000A6;">To remove a song/playlsit, simply right click <br>on it. This will activate the delete action and <br> remove the select music.</div>
        <div class="form-group col-lg-12 pl-0 mt-4" style="margin-top: 39px;">
            <!-- [(ngModel)]="myInputValuedyn" -->
            <input type="text" [(ngModel)]="myInputValuedyn" class="form-control" id="spotifyUrlNew"
                aria-describedby="emailHelp" placeholder="Spotify URL: Album, Single or Playlist"
                style="height: 50px;border: 1px solid #E9E9EB;border-radius: 15px;" autocomplete="off">
        </div>
        <div class="modal-btn d-flex justify-content-around" style="margin-top: 39px;">
            <button class="btn btn-red nobtn col-lg-12 dynamic-btn dynamic-btn-add" (click)="addSpotify()"
                style="margin-right: 10px;" [disabled]="!myInputValuedyn.length">Add</button>
            <!-- <button class="btn btn-blue logoutbtn" (click)="removeModalref.hide()"
                style="background-color: black;font-weight:bold;">No</button> -->
        </div>
        <div *ngIf="showAddOrRemoveUrlLoader" style="text-align: center;">
            <img width="50" src="../../../assets/Indietown_icons/Loading_icon.gif">
        </div>
    </div>
</ng-template>

<ng-template #addNewMusicURL>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center align-items-center">
        <div style="line-height: 26px;font-size: 16px;font-weight: bold;color: black;" *ngIf="spotifyAddedSuccess">Spotify URL added successfully!</div>
        <div style="line-height: 26px;font-size: 16px;font-weight: bold;color: black;" *ngIf="!spotifyAddedSuccess && !spotifyAddedInvalid">Spotify URL already added!</div>
        <div style="line-height: 26px;font-size: 16px;font-weight: bold;color: black;" *ngIf="spotifyAddedInvalid">Spotify Album, Single or Playlist URL only</div>
        <div class="modal-btn d-flex justify-content-around" style="margin-top: 39px;">
            <button class="btn btn-red nobtn" (click)="addNewSpotify()"
                style="color: black;margin-right: 10px;font-weight:bold;">Add New</button>
            <button class="btn btn-blue logoutbtn" (click)="close()"
                style="background-color: black;font-weight:bold;">Continue</button>
        </div>
        <div *ngIf="showAddOrRemoveUrlLoader" style="text-align: center;">
            <img width="50" src="../../../assets/Indietown_icons/Loading_icon.gif">
        </div>
    </div>
</ng-template>

<ng-template #removeMusicPopup>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="removeMusicModalref.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center align-items-center" style="margin-left: 20px;
    margin-right: 20px;">
        <h2 style="font-size: 26px;
        font-weight: 600;
        line-height: 39px;
        font-family: 'Poppins';">Are you sure you want to <br> remove this music?</h2>
        <!-- <div style="line-height: 26px;font-size: 16px;font-weight: 400;color: #000000A6;">To remove a song/playlsit, simply right click on it. This will activate...</div> -->
        <!-- [(ngModel)]="myInputValuedyn" -->
        <!-- <div class="form-group col-lg-12 pl-0 mt-4" style="margin-top: 39px;">
            <input type="text" [(ngModel)]="myInputValuedyn" class="form-control" id="spotifyUrlNew"
                aria-describedby="emailHelp" placeholder="Enter Spotify URL"
                style="height: 50px;border: 1px solid #E9E9EB;border-radius: 15px;">
        </div> -->
        <div class="modal-btn d-flex justify-content-around" style="margin-top: 39px;">
            <button class="btn btn-red nobtn dynamic-btn"
                style="margin-right: 10px;background-color: white;
                font-weight: bold;
                color: black;
                border: 1px solid black;" (click)="removeMusic()">yes</button>
            <button class="btn btn-blue logoutbtn" (click)="hideRemoveMusic()"
                style="background-color: black;
                font-weight: bold;
                color: white;">No</button>
        </div>
        <div *ngIf="showAddOrRemoveUrlLoader" style="text-align: center;">
            <img width="50" src="../../../assets/Indietown_icons/Loading_icon.gif">
        </div>
    </div>
</ng-template>











































































































<!--SPOTIFY OLD CODE-->
<!-- <div class="mt-3" style="margin-bottom: 50px">
    <div class="row" style="margin: 0px">
        <div class="col-lg-7 h-500">
            <div class="d-flex align-items-center">
                <div class="form-group col-lg-8">
                    <input type="text" class="form-control" id="spotifyUrlNew" aria-describedby="emailHelp"
                        placeholder="Enter Url">
                </div>
                <div>
                    <button type="button" class="btn btn-dark btn-lg" (click)="addSpotifyUrl()">Submit</button>
                </div>
            </div>
            <div class="spotify-music-card m-2" *ngFor="let track of spotifyList; let spotifyIndex = index">
                <div class="row" style="align-items: center">
                    <ng-template [ngIf]="track.contentType == 'track'">

                        <div class="col-1 col-md-1 mob-md-1">
                            <img [src]="track?.imageUrl" alt="" height="60" width="60" style="border-radius: 15px" />
                        </div>
                        <div class="col-lg-9 col-md-9 mob-md-9" style=""
                            (click)="playSelectedSong('singleTrack',spotifyIndex,'')">
                            <p class="spot-title">{{ track?.name }}</p>
                        </div>

                        <div class="col-lg-2 col-md-2 mob-md-2">
                            <p class="spot-time">
                                {{ getTimeInMinuteAndSeconds(track?.items[0].duration_ms) }}
                            </p>
                        </div>



                    </ng-template>
                    <ng-template [ngIf]="track.contentType == 'playlist'">
                        <mat-accordion>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div class="col-1 col-md-1 mob-md-1">
                                            <img [src]="track?.imageUrl" alt="" height="60" width="60"
                                                style="border-radius: 15px" />
                                        </div>

                                        <div class="col-lg-9 col-md-9 mob-md-9" style="">
                                            <p class="spot-title">{{ track?.name }}</p>
                                        </div>
                                    </mat-panel-title>
                                    
                                </mat-expansion-panel-header>
                                <ul>
                                    <li *ngFor="let subtrack of track.items;let subTrackIndex = index" class="d-flex"
                                        (click)="playSelectedSong('playlist',spotifyIndex,subTrackIndex)">
                                        <div class="col-1 col-md-1 mob-md-1">
                                            <img [src]="subtrack?.imageUrl" alt="" height="60" width="60"
                                                style="border-radius: 15px" />
                                        </div>
                                        <div class="col-lg-9 col-md-9 mob-md-9" style="">
                                            <p class="spot-title">{{ subtrack?.name }}</p>
                                        </div>

                                        <div class="col-lg-2 col-md-2 mob-md-2">
                                            <p class="spot-time">
                                                {{ getTimeInMinuteAndSeconds(subtrack?.duration_ms) }}
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </mat-expansion-panel>

                        </mat-accordion>
                        


                        
                    </ng-template>


                </div>
            </div>
            
            
            <div class="spotifyplayer">
                <div class="d-flex w-80">
                    <div class="saudioplayermob">
                        <div class="d-flex spotplayermab">
                            <img class="spotifyplayerimg" [src]="currentlyPlayingSpotifySong?.cover" alt="">
                            <div class="spotifytxtbox">
                                <span class="spotifysongname">{{currentlyPlayingSpotifySong?.name}}</span>
                                <span class="spotifyalbum">{{currentlyPlayingSpotifySong?.albumName}}</span>
                            </div>
                        </div>
                        <div class="saudioplayer visible-xs justify-content-center align-items-center">
                            <img class="volumee" src="../../../assets/Indietown_icons/volume-up.svg" alt="">
                            <img class="volumee" style="display: none;"
                                src="../../../assets/Indietown_icons/Volume Off.svg" alt="">
                            <input class="sprogressbar" id="volumeprogress" type="range" min="1" max="100" value="50">
                        </div>
                    </div>

                    <div class="saudioplayer btnsbox">
                        <div class="playerbtns d-flex">
                            <img class="shuffle" *ngIf="!spotifyShuffleModeOn" (click)="spotifySongShuffleToggle()"
                                src="../../../assets/Indietown_icons/shuffle.svg" alt="">
                            <img class="shuffle" *ngIf="spotifyShuffleModeOn" (click)="spotifySongShuffleToggle()"
                                src="../../../assets/Indietown_icons/shuffle-dark.svg" alt="">
                            <img class="backward" id="spotifyPrevious" (click)="spotifyPlayPrevious()"
                                src="../../../assets/Indietown_icons/skip-forward.svg" alt="">
                            <img class="backward" style="display:none;"
                                src="../../../assets/Indietown_icons/skip-forward-disabled.svg" alt="">
                            <img class="playbtn" style="display: none;"
                                src="../../../assets/Indietown_icons/spotifyplayerd.svg" alt="">
                            <img class="playbtn" id="spotifySongPlay" (click)="spotifySongPlay()"
                                src="../../../assets/Indietown_icons/spotifyplayer.svg" alt="">
                            <img class="playbtn" id="spotifySongPause" (click)="spotifySongPause()"
                                style="display: none;" src="../../../assets/Indietown_icons/pauseicon.svg" alt="">
                            <img class="forward" id="spotifyNext" (click)="spotifyPlayNext()"
                                src="../../../assets/Indietown_icons/skip-forward.svg" alt="">
                            <img class="forward" style="display:none;"
                                src="../../../assets/Indietown_icons/skip-forward-disabled.svg" alt="">
                            <img class="repeat" *ngIf="!spotifySongRepeatModeOn" (click)="spotifySongRepeatToggle()"
                                src="../../../assets/Indietown_icons/repeat.svg" alt="">
                            <img class="repeat" *ngIf="spotifySongRepeatModeOn" (click)="spotifySongRepeatToggle()"
                                src="../../../assets/Indietown_icons/repeat-dark.svg" alt="">
                        </div>
                        <div class="splayer d-flex justify-content-center">
                            <p class="currenttime commontimer">{{spotifySongPlayedTill}}</p>
                            <input class="sprogressbar" id="audioprogress" type="range" min="1" max="100"
                                [value]="spotifySongProgressBarValue" (change)="changeSongPosition($event)">
                            <p class="remainingtime commontimer">
                                {{getTimeInMinuteAndSeconds(currentlyPlayingSpotifySong?.durationInMilli)}}</p>

                        </div>
                    </div>
                    <div class="saudioplayer hidden-xs d-flex justify-content-center align-items-center">
                        <img class="volumee" *ngIf="spotifyVolumeOn" (click)="spotifyVolumeToggle()"
                            src="../../../assets/Indietown_icons/volume-up.svg" alt="">
                        <img class="volumee" *ngIf="!spotifyVolumeOn" (click)="spotifyVolumeToggle()"
                            src="../../../assets/Indietown_icons/Volume Off.svg" alt="">
                        <input class="sprogressbar" id="volumeprogress" type="range" min="1" max="100"
                            [value]="spotifyVolume" (change)="volumeChange($event)">
                    </div>
                </div>

            </div>

        </div>
        <div class="col-lg-5 suggestionscardd" (scroll)="onScroll($event)"
            [ngClass]="{'positionfix': sidebarFixed==true}" style="margin-top: -9%;">
            <app-suggestions-card></app-suggestions-card>
        </div>
    </div>
</div> -->