<app-layout></app-layout>

<div style="padding-left: 18%; padding-top: 100px;background-color: #F6F6F6;padding-bottom: 20px;">
    <div class="about-container spotify-container">
        <div class="d-flex mb-4">
            <span><img src="assets/Indietown_icons/Spotify_Logo_RGB_Green.png" /></span>
            <!-- <h1 class="spotyfy-text">Spotify</h1> -->
        </div>
    </div>
    <!-- (click)="navigateToSelectedSong('singleTrack',spotifyIndex,'')" -->
    <div class="d-flex">
        <!-- <div *ngFor="let track of spotifyList; let spotifyIndex = index" class="d-inine-block mr-4"> -->
            <div class="cursorpointer">
                <img [src]=" spotifySongDetails?.imageUrl" alt="" height="265" width="265" style="object-fit: cover;"/>
            </div>
            <div class="mt-2 cursorpointer d-flex ml-4" style="flex-direction: column;">
                <div class="track-name d-inline-block">{{ spotifySongDetails?.name }}</div>
                <!-- <div *ngIf="spotifySongDetails?.name?.length > 16" class="d-inline">...</div> -->
                <div class="track-artist-name d-inline-block mt-3">{{ spotifySongDetails?.artists[0]?.name }}</div>
                <!-- <div *ngIf="spotifySongDetails?.name?.length > 16" class="d-inline">...</div> -->
                <div class="mt-5">
                    <img src="assets/Indietown_icons/Heart-Shape.png">
                </div>
            </div>
            
        <!-- </div> -->
    </div>
    <div (click)="playOrResume()" *ngIf="!songPlaying || songPaused" class="mt-4" style="cursor: pointer;">
        <img src="assets/Indietown_icons/Group_117127507_2.png" alt="">
    </div>
    <div (click)="pause()" class="mt-4" *ngIf="songPlaying" style="cursor: pointer;">
        <img src="assets/Indietown_icons/Group 1171275074.png" alt="">
    </div>
</div>
