import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef
} from "@angular/core";
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/auth/services/auth.service";
import { HeaderService } from "src/app/shared/services/header.service";
import { SharedService } from "src/app/shared/services/shared.service";
import { error } from "src/app/_helpers/error";
import { FormBuilder, NgForm } from "@angular/forms";
import * as _moment from 'moment';
import { MatTabChangeEvent } from "@angular/material/tabs";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SpinnerVisibilityService } from "ng-http-loader";
import * as $ from 'jquery';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { HttpEventType } from "@angular/common/http";
import { ThrowStmt } from "@angular/compiler";
import { filter } from "rxjs/operators";
import { UrlService } from "src/app/views/registration-part/services/url.service";
import SpotifyPlayer from "spotify-web-playback";
// const YTPlayer = require('yt-player');
// import YTPlayer from "yt-player";
import { YtPlayerService, PlayerOptions } from 'yt-player-angular';
import { DomSanitizer } from "@angular/platform-browser";
import { formatDate, Location } from "@angular/common";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { DOCUMENT } from '@angular/common';
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { AnalyticsEvents, SVConstants } from "src/app/app.constants";
// import { resolve } from "dns";
// import { rejects } from "assert";

declare global {
  interface Window {
      FB:any;
  }
}

@Component({
  selector: "dynamic-profile",
  templateUrl: "./dynamic-profile.component.html",
  styleUrls: ["./dynamic-profile.component.scss",'../../views/home/home.component.scss'],
})
export class ProfileDynamicComponent implements OnInit, OnDestroy {

  @ViewChild("cancelRequests") cancelRequests: TemplateRef<any>;

  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  reportPostModel:boolean;
  reportCommentModel:boolean;
  reportPost:any;
  reportPostId:any;
  likes:any;
  reportCommentPostId:any;
  reportCommentId:any;
  allPosts:any = [];
  allShows:any = [];
  friendsList:any=[];
  topFourFriends:any=[];
  searchFriendKey:any;
  myInputValuedyn:any = '';
  error = error.err;
  date:any;

  update: boolean = false;
  view: boolean = false;
  submit: boolean = true;
  del: boolean = false;
  modalRefOpen:any;
  imagessArray = [];
  commentArea:boolean=false;

  moment = _moment;

  imgURL: any;
  fileName: any;
  uploaded: boolean = false;
  imagePath: any;
  message: any;
  cId: String;

  imgChangeEvt: any = "";
  cropImgPreview: any = "";
  singalurl: any;
  selectedFile: any;

  viewData: any;
  type: any;
  name: String;

  submitted: boolean = false;
  servicesList:any = [];
  serviceId:any;
  roleForm: FormGroup;
  dropdownChange:any = 1;
  modalImageUrl:any;
  profilePicUrl:any;
  coverPicUrl:any;
  profileDetails:any;
  createShowImageUrl:any;
  singleFriendData:any;
  playList:any=[];
  postId:any;
  profileId:any;
  otherFriends:any=[];
  genreList:any=[];
  photoAlbumCheckbox:boolean=true;
  uploadPhotoAlbumUrl:any;
  page:number=1;
  itemperPage:number=10;
  stopScrollingPost:boolean=true;
  stopScrollingShow:boolean=true;
  commentPostId:any;
  youtubeEdit:boolean = true;
  spotifyEdit:boolean = true;
  youtubePlayListData:any=[];

  @ViewChild("template") template: TemplateRef<any>;
  @ViewChild("myFile2") myFile2: ElementRef;
  @ViewChild("reportPostModal") reportPostModal: TemplateRef<any>;
  @ViewChild("userUnfriend") userUnfriend: TemplateRef<any>;
  @ViewChild("editProfileModal") editProfileModal: TemplateRef<any>;
  @ViewChild("showupdateGenerModal") showupdateGenerModal: TemplateRef<any>;
  @ViewChild("youtubePlay") youtubePlay: TemplateRef<any>;
  @ViewChild("friendClickModal") friendClickModal: TemplateRef<any>;
  @ViewChild("spotifyPlay") spotifyPlay: TemplateRef<any>;  
  @ViewChild("photoAlbumSlider") photoAlbumSlider: TemplateRef<any>;  
  @ViewChild("deleteAlbumImage") deleteAlbumImage: TemplateRef<any>;  
  @ViewChild("deletePost") deletePost : TemplateRef<any>;
  @ViewChild("commentsListModel") commentsListModel: TemplateRef<any>;
  @ViewChild("likesModel") likesModel: TemplateRef<any>;
  @ViewChild("addPlaylistModal") addPlaylistModal: TemplateRef<any>;
  @ViewChild('tabGroup') tabGroup;
  @ViewChild('ProfileOrCoverPictureview') ProfileOrCoverPictureview;
  @ViewChild("CreateShowCropModal") CreateShowCropModal: TemplateRef<any>;
  @ViewChild("CreatePostImageCropModal") CreatePostImageCropModal: TemplateRef<any>;
  @ViewChild("deleteCommentPopup") deleteCommentPopup: TemplateRef<any>;
  @ViewChild("deleteCommentReplyPopup") deleteCommentReplyPopup: TemplateRef<any>;
  @ViewChild("invalidVideoCodecPopup") invalidVideoCodecPopup: TemplateRef<any>;
  @ViewChild("myfileMac") myfileMac;
  // @HostListener("click")
  // clicked() {
  //   this.myFile2.nativeElement.value = "";
  // }
  previousUrl:any;
  currentUrl:any;
  showSiidbar:boolean;
  spotify:any;
  constructor(
    private _header: HeaderService,
    private modalService: BsModalService,
    private service: SharedService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private fb: FormBuilder,
    private router: ActivatedRoute,
    private spinner: SpinnerVisibilityService,
    private route: Router,
    private urlService: UrlService,
    private ytPlayerService: YtPlayerService,
    protected _sanitizer: DomSanitizer,
    private spinners: SpinnerVisibilityService,
    @Inject(DOCUMENT) private document: Document,
    private analytics: AngularFireAnalytics,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    
    this.route.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      console.log('previous url',this.previousUrl);
      console.log('currentUrl url',this.currentUrl);
      this.previousUrl = this.currentUrl;
      // if(this.previousUrl =='/dashboard/profile?id=639326892ab261ca24429811'){
      //   (<HTMLInputElement>document.querySelector(".slidepage")).style.marginLeft = "-78.3%";
      // }
      
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);
      this.currentUser = this.authService.currentUserValue;
      this.analytics.logEvent(AnalyticsEvents.SCREEN_VISITED,{name:SVConstants.profile});
    });
  }

  currentUser;

  interests: any;
  postList:any = [];
  goYoutubeField:any;
  unFriendUserName:string;
  timelineVisible:boolean;
  youtubePlayer;

  // heading
  ngOnInit(): void {
    // var tabs= document.querySelector('.mat-tab-label');
    // console.log(tabs);
    // document.querySelector('audio').removeAttribute('controls')
    // this.bandcampp();
    document.getElementById("text").style.overflow="";
    document.getElementById('text').classList.remove('removebodyscroll');
    var owl = $('.owl-carousel');
    // get owl instance from element
    var owlInstance = owl.data('owlCarousel');
  
    // if instance is existing
    if(owlInstance != null)
      owlInstance.reinit();


      this.service.data$.subscribe(
        (data) => {
          console.log(data);
          this.selectedIndex = data;
        }
      );

      this.service.play$.subscribe((data:any)=> {
        this.youtubePlayData = data;
        this.openModal(this.youtubePlay);
        // this.youtubePlay.classList.add('hello');
        // this.modalService.openModal( youtubePlay, {  windowClass: 'my-own-styles' });
        this.modalService.show(this.youtubePlay, {class: 'customClass'});
        document.querySelector('.modal').classList.add('youtubepopup');
      })

      this.service.playSpotify$.subscribe((data:any)=> {
        this.spotifySingleData = data;
        this.openModal(this.spotifyPlay);
        console.log(data);
      })


    this._header.manageServicesHeading.next(true);
    this.coverPicUrl = '../../../../assets/Indietown_icons/upload-cover-pic.png';
    this.profilePicUrl = "../../../../assets/Indietown_icons/upload-profile-pic.png";
    this.createShowImageUrl = '../../../../assets/Indietown_icons/coverpic.png';
    this.uploadPhotoAlbumUrl='../../../assets/Indietown_icons/upload-photo-album.svg';
    this.router.queryParams.subscribe(params => {
      this.postId = params['id'];
      this.profileId = params['id'];
      this.unFriendUserName = params['user'];
      this.timelineVisible = params['timeline'];
      if(this.unFriendUserName) {
        this.showSiidbar = true;
      }else{
        this.showSiidbar = false;
      }

      

    })

    this.photoAlbumCheckbox= false;

    if(this.postId){
      this.getProfileById(this.postId);
      this.getMyProfile();
      this.getPostsId(this.postId,this.postsPageNumber,this.postsPageSize);
      this.getShows(this.postId,this.showsPageNumber,this.showsPageSize);
      // this.getFriendsListById(this.postId);
      this.getPlayListById(this.postId);
      this.getYoutubePlaylist(this.postId);
    }else{
      
      this.getProfile();
      // this.getPlayList();
      // this.getSpotifyList();
      console.log(this.currentUser);
      this.getPostsId(this.currentUser.userId,this.postsPageNumber,this.postsPageSize);
      this.getShows(this.currentUser.userId,this.showsPageNumber,this.showsPageSize);
      // this.getPosts(this.page, this.itemperPage);
      // this.getFriendsList();
      this.getYoutubePlaylist("");
    }
    // this.spotifyInitialization();
    // this.getPostsId(this.profileDetails._id);
    // this.youtubePlayer = new YTPlayer('#youtubePlayer');

    /** Hide Spotify 
    this.checkSpotifyExist(this.postId);
    */
    /** Unhide and opens link in new tab or Iframe */
    // this.checkSpotifyExist(this.postId);
   
    this.checkYoutubeExist(this.postId);
    this.checkSoundcloudExist(this.postId);
    this.checkBandcampExist(this.postId);
    // if(localStorage.getItem("SpotifyAutorizedAndRedirected")){
    //   localStorage.removeItem("SpotifyAutorizedAndRedirected");
    //   this.selectedIndex = 3;
    // }
    // if(localStorage.getItem("profile-incomplete")){
    //   console.log("1111111",this.document.getElementById("editProfileBtn"));
    //   localStorage.removeItem("profile-incomplete");
    //   // this.document.getElementById("editProfileBtn")?.click();
    //   this.openEditModal();
    // }

    // console.log("from after view init");
    if(localStorage.getItem("profile-incomplete")){
      console.log("1111111",this.document.getElementById("editProfileBtn"));
      localStorage.removeItem("profile-incomplete");
      // this.document.getElementById("editProfileBtn")?.click();
      // this.spinner.show();
      this.openEditModal();
    }
    
  }

  ngAfterViewInit(){
    // console.log("from after view init");
    // if(localStorage.getItem("profile-incomplete")){
    //   console.log("1111111",this.document.getElementById("editProfileBtn"));
    //   localStorage.removeItem("profile-incomplete");
    //   // this.document.getElementById("editProfileBtn")?.click();
    //   // this.spinner.show();
    //   this.openEditModal();
    // }
  }

  spotifyPlayerNew;
  spotifyToken;
  device_id;
  postsPageNumber = 1;
  postsPageSize = 20;
  showsPageSize = 20;
  showsPageNumber = 1;


  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      300: {
        items: 3
      },
      400: {
        items: 4
      }
    },
    nav: true
  }

  updateProfileForm =  new FormGroup({
    bandName: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    location: new FormControl(''),
    genre: new FormControl(''),
    bio: new FormControl(''),
    spotifyURL: new FormControl(''),
    youtubeURL: new FormControl(''),
    profilePicture: new FormControl(''),
    profilePictureKey: new FormControl(''),
    coverPicture: new FormControl(''),
    coverPictureKey: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    country: new FormControl(''),
    lat: new FormControl(''),
    long: new FormControl(''),
    MobNum: new FormControl ('')
  })


  reportPostForm = new FormGroup({
    comment: new FormControl('', [Validators.required])
  })

  reportCommentForm = new FormGroup({
    comment: new FormControl('', [Validators.required])
  })

  createCommentForm = new FormGroup({
    comment: new FormControl('', [Validators.required])
  })


  geners = '';
  genersId=[];
  role:any;
  myProfileDetails;
 getProfile(){

  this.service.getProfile().subscribe((res:any) => {
    this.geners = '';
    this.genersId=[];
    if(res.type == 'success'){
      this.profileDetails = res.data;
      this.role = res.data.role;
        if(this.role=='Artist'){
          this.service.getArtistGenerList().subscribe((res:any) => {
            if(res.type == 'success'){
              this.genreList = res.data;
              this.service.getProfile().subscribe((res:any) => {
                if(res.type == 'success'){
                  this.profileDetails = res.data;
                  console.log(this.profileDetails, "this.profileDetails")
                  for(var i of this.genreList){
                    for(var j of res.data.genereIds)
                      if(i._id == j?._id){
                        this.genersId.push(i._id);
                          this.geners = this.geners + ',' + ' ' + i.genereName;
                          console.log(this.geners);
                      }
                  }
                }
              })
            }
          })
        }else{
          this.service.getGenres().subscribe((res:any) => {
            if(res.type == 'success'){
              let list = res.data;
              this.service.getProfile().subscribe((res:any) => {
                if(res.type == 'success'){
                  this.profileDetails = res.data;
                  for(var i of list){
                    for(var j of res.data.communityIds)
                      if(i._id == j?._id){
                        this.genersId.push(i._id);
                          this.geners = this.geners + ',' + ' ' + i.communityName;
                      }
                  }
                }
              })
            }
          })
        }
    }
  })

  }

  getMyProfile(){
    this.service.getProfile().subscribe((res:any) => {
      if(res.type == 'success'){
        this.myProfileDetails = res.data;  
      }
    })
  }

  getGeners(){
    this.service.getGenres().subscribe((res:any) => {
      if(res.type == 'success'){
        this.genreList = res.data;
      }
    })
  }

  getProfileById(id:any){
    this.service.getProfileById(id).subscribe((res:any) => {
      if(res.type == 'success'){
        this.profileDetails = res.data;
      }
    })
  }

  allPostsCopy = [];
  getPostsId(id:any,pageSize,pageNumber){
    this.service.getPostByIdImpl(id,pageSize,pageNumber).subscribe((res:any) => {
      if(res.type == 'success'){
        if(res?.data?.length){
          // this.allPosts.push(...res.data);
          this.allPostsCopy.push(...res.data);
          this.allPosts = this.allPostsCopy;
        }
        else{
          this.stopScrollingPost= false;
          this.allPosts = this.allPostsCopy;
        }
          // this.postList = res.data;
          // if(res.data == 'res.data'){
          //   this.postList = [];
          // }
          // if(res.data.length==0){
          //   this.stopScrollingPost= false;
          // }else{
          //   this.stopScrollingPost= true;
          // }

        //   for(var i of this.postList){
        //     if(i.type=='SHOW'){
        //       this.allShows.push(i);
        //     }else{
        //       this.allPosts.push(i);
        //       this.allPostsCopy.push(i);
        //     }
        // }

        // this.allPostsCopy = this.allPosts;
      }else{
        this.toastrService.error(res.message);
      }
    })
  }

  getShows(id:any,pageSize,pageNumber){
    this.service.getShowsByIdImpl(id,pageSize,pageNumber).subscribe((res:any) => {
      if(res.type == 'success'){
        // this.allShows = res.data;
        if(res?.data?.length){
          this.allShows.push(...res.data)
        }
        else{
          this.stopScrollingShow= false;
        }
        //   this.postList = res.data;
        //   if(res.data == 'res.data'){
        //     this.postList = [];
        //   }
        //   if(res.data.length==0){
        //     this.stopScrollingPost= false;
        //   }else{
        //     this.stopScrollingPost= true;
        //   }

        //   for(var i of this.postList){
        //     if(i.type=='SHOW'){
        //       this.allShows.push(i);
        //     }else{
        //       this.allPosts.push(i)
        //     }
        // }
      }else{
        this.toastrService.error(res.message);
      }
    })
  }

  spotifyplayList:any=[];
  // audioList:any = [];


  myPlayList:any=[];
  // audioList = [
  //   {
  //     url: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //     title: "Smaple 1",
  //     cover: "https://i1.sndcdn.com/artworks-000249294066-uow7s0-t500x500.jpg",
  //     time:6100
  //   }
  // ];
  audioList:any = [];
  NextPageTokenForYoutube;
  getYoutubePlaylist(userId){
    console.log(1);
    this.service.getYoutubePlayList({id:userId}).subscribe((res:any)=>{
      // this.playList = res?.data?.youtubeResult;
      console.log(res.data.videos);
      this.playList = res.data.videos;
      if(res.data?.pageInfo?.nextPageToken){
        this.moreVideosComing = true;
      }
      this.NextPageTokenForYoutube = res.data.pageInfo.nextPageToken;
    })
    // this.playList = res.data.youtubeResult;
  }

  getPlayList(){
    this.service.getSpotifyPlayList("").subscribe(
      (res:any)=> {
      console.log(res);  
      for(var i of res?.data){
        let data = { 
          url: ''+i?.track?.uri,
          title: ''+i?.track?.name,
          cover: ''+i?.track?.album?.images[2]?.url,
          time: i?.track?.duration_ms,
          albumName: ''+i?.track?.album?.name,
          durationInMilli: i?.track?.duration_ms
        }
        this.audioList.push(data);
        if(this.audioList.length>3){
          this.audioList = this.audioList.splice(0, 3);
        }
        this.currentlyPlayingSpotifySong = this.audioList[0];
        this.spotifySongSelectedIndex = 0;
      }
      // this.audioList.splice(0,1);
    }, (res:any)=> {
      this.spinner.hide();
    });
    // let data = [
    //   {
    //     "name": "a",
    //     "uri": "spotify:track:6XYQvYJzHjK5150Vl7NKfJ",
    //     url:"https://i.scdn.co/image/ab67616d00001e02ff9ca10b55ce82ae553c8228",
    //     duration_ms:3500
    //   }  ,
    //   {
    //     "name": "b",
    //     "uri": "spotify:track:3LP4QpKptfe2yEC7oG5iNa",
    //     url:"https://i.scdn.co/image/ab67616d00001e02ff9ca10b55ce82ae553c8228",
    //     duration_ms:3500
    //   },
    //   {
    //     "name": "c",
    //     "uri": "spotify:track:1AzNN19lPYC68iacrbWL8X",
    //     url:"https://i.scdn.co/image/ab67616d00001e02ff9ca10b55ce82ae553c8228",
    //     duration_ms:3500
    //   } 
    // ]
    //   for(var i of data){
    //     let data = { 
    //       url: ''+i?.uri,
    //       title: ''+i?.name,
    //       cover: ''+i?.url,
    //       time: i?.duration_ms
    //     }
    //     this.audioList.push(data);
    //   }
      // this.audioList.splice(0,1);
    // this.service.getPlayList().subscribe((res:any)=> {
    //     if(res.type == 'success'){
    //       let youtb;
    //       let spot;
    //       if(res?.data?.youtubeResult?.length<=0 || res?.data?.youtubeResult?.length==undefined){
    //         youtb = true;
    //       }
    //       if(res.data?.spotifyResult?.body?.tracks?.items?.length<=0 || res.data?.spotifyResult?.body?.tracks?.items?.length==undefined){
    //         spot = true;
    //       }

    //       if(spot==true && youtb==true){
    //         return
    //       }
    //       if(youtb!=true){
    //         this.playList = res.data.youtubeResult;
    //       }
    //       if(spot!=true){
    //         this.spotifyplayList = res.data.spotifyResult;

    //         for(var i of res?.data?.spotifyResult?.body?.tracks?.items){
    //           let data =   
    //            { 
    //             url: ''+i?.track?.preview_url,
    //             title: ''+i?.track?.name,
    //             cover: ''+i?.track?.album?.images[2]?.url,
    //             time: i?.track?.duration_ms
    //             }
    //             if(i?.track?.preview_url!=null){
    //               this.audioList.push(data);
    //             }
    //         }
    //         this.audioList.splice(0,1);
    //       }
    //     }else{
    //       this.toastrService.error(res.message);
    //     }
    // },(error)=>{
    //   this.toastrService.error(error?.error?.message?.body?.error?.message);
    // })
  }

  getPlayListById(id:any){
    this.service.getSpotifyPlayList(id).subscribe((res:any)=> {
      console.log(res);  
      for(var i of res?.data){
        let data = { 
          url: ''+i?.track?.uri,
          title: ''+i?.track?.name,
          cover: ''+i?.track?.album?.images[2]?.url,
          time: i?.track?.duration_ms,
          albumName: ''+i?.track?.album?.name,
          durationInMilli: i?.track?.duration_ms
        }
        this.audioList.push(data);
        //  if(this.audioList.length>3){
        //   this.audioList = this.audioList.splice(0, 3);
        // }
        this.currentlyPlayingSpotifySong = this.audioList[0];
        this.spotifySongSelectedIndex = 0;
      }
      // this.audioList.splice(0,1);
    }, (res:any)=> {
      this.spinner.hide();
    });
  }

  spotifySongSelectedIndex:any;
  spotifySongSelected:boolean = false;
  currentlyPlayingSpotifySong;

  


  async spotifyInitialization() {
    
    // const token = "BQDa4OM2ETnfXrs_te2UPsh4FG-gE0wE_4OUSpPKO0JAWkWA3kDB_i5GcRXD_N1zXFlxu_kP64kD_WzStIrjETUTDsmRtR6Oh6SnM8G4kLbyr-t5KDbMIBC_mWb3KUqLiBnUbLSZF7ljNRwZeivyIAlqde6V9CsDy3Z5ClJ5uKObaNPlScemEElXJE0G9bQMCcgm2mTXDhJhyYVXfL2S_A54NUz8ECKc4m7gZDZmG7gLAbpFlui_Ixqeiw";
    // const token = "BQCavXJA-UdLHHtPDlSbZRJ45TIcp59Hir7wa86AeuyAGgArNGs-cQSxMZeOAR5fDjnH9n24vjnT0pC_jRvIvP0dJpEjlXMfHRHSmpAtmDC2HLBAnnakgEWrZZTC4qkLzcjw10aLvtT81vzdopYm4WbgpH06NYhksTeq6bj2E82JP0IBVrWC5kfSqQ3Cq-VHqTXPw7mghpV4j63RO_MHp7RPT-7xEuXVf_NBlJerdmkWf0eM6BbiyNwjww";
    // const token = "BQBssH7wcAx7c5diUlPcTjA6RiJNV103jaiaWgS1fSBh_UYu2Pg8rAlI_-1snXdLSj7DUSBFBdPXR8BLOxQRcH3E-aQx3W2AdX4m1AtCu8pAeo0OpzE2s_rNcgjDGhN4oSbScDHgBMS0fUbR_0P61RLVHIMhQZOMjSpeT3IdITK2WHNjZw6HkptDSZKqGSsX6kHSJbH_JzEoTm4ODNdIAI0tRT3n_jKrP_95o4UmR-GiEd6Pv_7ooUHIlQ";
    // const token = "BQAG-hn1hI8C6iN1Rz0YOGaff33zVyV5gDKeURTTqck9gysOKvjrDBH0UdSXDTlUyATm0Dq4vkqLEIqiHvS0OX17QQRG9m-Z8NGlP6MMqqPZHeh-WA4kAB28WmhoAd6diYyk9Hqu2LmaF-Y5Wa6Cf0f6vvTwb5gwXqxlR5g3RZHjaBDNfzsPxZpkD-naLNmDMGuoD27h8cKtTduNyEUwTnvS0Bmeo6ZuYy_RGenfnEyWcwq2REe3oO5ODA";
    //const token = "BQC2TWaAmcQZRKXXraIC2XwKVXwF_9LY44ITTgeKocRTvupOjZmhRDl4gzVVvatUS0bYbJ5Ni_mWkPqcFFjq1PeA9sFTPsEoYn8DgDgLE6rG9_DfSQAl14_dbZmfptYqMvv6IO84YA8sWrtvqXujURDhGpl28RSkxnP-kDAwWviuymOzQN9wCqENQg-jVmNDVPBm3q70WiL1S_Ibd3vaByeR3o0t2DHSM-5YxzxNjXMV8UA52UKNDU88MQ";
    // const token = "BQDQbKgEpL7LfpvPfnUAIiLWlKAQxC1RA5m-98RVQmlgGc6wdpA-JhYBEwgTnA4F4LNDFJwUblCdfMfgTIII6XyMYuURxVdKSD-9_kMSzkslTV5-yhg9bQpqRpF6YrG3_aDqF_j13-yMrgSaJAbbrFJiM5ij8NaTLcS97n8BQ4NgPYuEx1iGA_QyQ15ZDkabps2gTP0J1lxYIcUhTZVrCZMSd_m6xZ-QlwLuQ9jCJDhfUWmB3QZJwsd9Yg";
    // const token = "BQAwAt3z_WeJELJMoqPb8b5roH0DGLSViY1Ki_nv0IfGWI9WUE0lwIfNcVt79j7FCw8BaN0H_6zdoWlqmuDi_rI6Q8-YchKNtxuAqIH26aXlTz6owSGTAzmpMDJoWDJiqFHE5Sls9RHwFOPYUxeI_SIXXmHwBk1IfAn9c6NzxQuR0J0wOhEOUObYInGJ22uipMS5EEyaQgnWog19hnvL3E0lTMiFfiwgHzl4xdBJA39mR6U69KI6ZYJTpA";
    // const token = "BQDQbKgEpL7LfpvPfnUAIiLWlKAQxC1RA5m-98RVQmlgGc6wdpA-JhYBEwgTnA4F4LNDFJwUblCdfMfgTIII6XyMYuURxVdKSD-9_kMSzkslTV5-yhg9bQpqRpF6YrG3_aDqF_j13-yMrgSaJAbbrFJiM5ij8NaTLcS97n8BQ4NgPYuEx1iGA_QyQ15ZDkabps2gTP0J1lxYIcUhTZVrCZMSd_m6xZ-QlwLuQ9jCJDhfUWmB3QZJwsd9Yg";
    // const token = "BQBla4Zxrkw6Htc5yg7f6EcgEY988E3iKrenxxykF7pY02NQWlRr3B_xk1-5sBIPNf8ZSsPWC4SmeAto8Pc4QufYDF0LezoLygBsUGPPqiz5qSnn5I3wRRDyR5Z8D6YWRHyqMaJZVCip0T00EPT6qjO5Dbs3N9myNHwTf6VVnKLk9ACl_latNRFrwBj4U_cdEF10y1WoCibSZpa04VB3MgRouXOxbZ3c3Y3gpFat97axEeTW8-i2oLASXg";
    // this.service.getSpotifyToken().subscribe(async(res:any)=>{
    //   console.log(res);
    //   this.spotify = new SpotifyPlayer("test12");
    //   await this.spotify.connect(res?.data?.access_token);
    // })
    // const uri = "spotify:track:3GCwsB2loRGeUL8oq2Y4TW";
    
    // this.spotify = new SpotifyPlayer("test12");
    // await this.spotify.connect(token);

    const script = document.createElement('script')
    script.src = 'https://sdk.scdn.co/spotify-player.js'
    script.type = 'text/javascript'
    script.addEventListener('load', e => {
      console.log(e)
    });
    document.head.appendChild(script);

    (<any>window).onSpotifyWebPlaybackSDKReady = () => {
      // this.spotifyToken = 'BQAT3ywNQLFFwApAcZIwBdX3KOpi2THCpgcbQktLHa_zcmbYT_k_kckdxw-SjlA22fwrOygbALYZQNZHcI3wzE4PcOdy3tA4KmzKi22jiSxgNpXcefemHH-JYZsgjNacBCO4nYK6EXDvbg5iaJnQYoKc6Sfo7chjBCKBwgasmsKyd5s0UoeAuWKwqhmzOB9_UllWwoLfY58PCQVmHNa2mJkI9mee2U22xbm7wiJKXzutMSbngls0T3zwqQ';
      // this.spotifyToken = "BQAc4b9Lp4nD-D-WljcC8XoSJjEhteGPmT_V7vQxKGpO5Aqi2V5_5KeVEPkDpA2lcwC69gLET0WwqESlchV6crOcdO_N-kgQyx1pdL6eLryV5VTtvewM1gWa6fSetmgJOdFHjTJcATxdXg8YO6KrqJFYeP_cXn6uQGxEJV4nGSG4Ivd6IShEK3NvUwT73wLZm63DaDB_bgbWxoTLqTvOcJ5mNTW-p_O1hoQFnBgZzGyLlclmeG-cv3fMVg";
      // this.spotifyToken = "BQC9-Cnx8EmSGYlI-mb4wf5vEfKFFvY7nnoeu9xGDYJ76HVH_qzwp4qeEU2o-8egQXIF0eBfmXyfN_UAkAlomX_FUWnO1hfxu3KV6nAfjulBGyq4G0wVEVo5KQjSwC5Hv2Vud8zXS9KbHkw7RUkHBAsMkeZJhHF6veIRi16Wrx8jQWhLfng-FMdiMW1ROJ0Q09DB6JHF1BwtKh5J8Snk9aC4w6DU1vKorecgviPyDszjDG0CTX40W18aRg";
      // this.spotifyToken = this.service.getSpotifyToken().subscribe(
        // (res:any)=>{
        // let token = this.spotifyToken;


        // this.spotifyToken = res.data["access_token"];
        // this.spotifyToken = JSON.parse(localStorage.getItem('spotifyTokenInfo')).accessToken;
           this.spotifyToken = this.getSpotifyToken();
        // Get the token and assign here

        this.spotifyToken = this.spotifyTokenInfo.access_token;
        let token = this.spotifyToken;
        this.spotifyPlayerNew = new (<any>window).Spotify.Player({
          name: 'Web Playback SDK Quick Start Player',
          getOAuthToken: cb => { cb(token); },
          volume: 0.5
        }, (res:any)=> {
          this.spinner.hide();
        });
  
        console.log(this.spotifyPlayerNew);
  
        this.spotifyPlayerNew.addListener('ready', ({ device_id }) => {
          console.log('Ready with Device ID', device_id);
          this.device_id = device_id;
        });
      
        // Not Ready
        this.spotifyPlayerNew.addListener('not_ready', ({ device_id }) => {
          console.log('Device ID has gone offline', device_id);
        });

        this.spotifyPlayerNew.addListener('player_state_changed', state => {
          // this.paused1 = state.paused;
          // this.state1 = state;
          // // this.spotifySongProgressBarValue = Math.trunc((state.position/state.duration)*100);
          // this.position1 = state.position;
          // this.duration1 = state.duration;
          this.currState["paused"] = state?.paused;
          this.currState["position"] = state?.position;
          this.currState['duration'] = state?.duration;
          this.currState['updateTime'] = performance.now();
        });
  
        this.spotifyPlayerNew.connect();
  
      // })
      
      // this.spotifyPlayerNew.addListener('player_state_changed', ({
      //   position,
      //   duration,
      //   track_window: { current_track }
      // }) => {
      //   this.spotifySongProgressBarValue = Math.trunc((position/duration)*100);
      //   this.position1 = position;
      //   this.duration1 = duration;
      // });

      
      // setInterval(()=>{
      //   // console.log(this.spotifySongProgressBarValue);
      //   // console.log(this.position1);
      //   // console.log(this.duration1);
      //   // console.log(this.paused1);
      //   // console.log(this.state1);
      //   // let aa = this.getStatePosition();
      //   // console.log(aa);
      //   this.spotifyPlayerNew.getCurrentState().then(state => {
      //     if (!state) {
      //       console.error('User is not playing music through the Web Playback SDK');
      //       return;
      //     }
      //     console.log(state);
        
      //     console.log('Currently Playing');
      //     console.log('Playing Next');
      //   });
      // });

      // setTimeout(()=>{
      //   this.spotifyPlayerNew.getCurrentState().then(state => {
      //     if (!state) {
      //       console.error('User is not playing music through the Web Playback SDK');
      //       return;
      //     }
        
      //     var current_track = state.track_window.current_track;
      //     var next_track = state.track_window.next_tracks[0];
        
      //     console.log('Currently Playing', current_track);
      //     console.log('Playing Next', next_track);
      //   });
      // },10000)
    }

  }

  getStatePosition() {
    if (this.currState?.paused) {
       return this.currState?.position;
    }
    console.log("position ",this.currState?.position);
    console.log("pnow ",performance.now());
    console.log("upddatetime ",this.currState?.updateTime);
    let position = this.currState?.position + (performance.now() - this.currState?.updateTime) / 1000;

    console.log("final pos",position);
    console.log("final duration",this.currState?.duration);
    return position > this.currState?.duration ? this.currState?.duration : position;
  }
  // currState:any;
  currState = {
    paused:false,
    position:0,
    duration:0,
    updateTime:0
  }
  position1;
  duration1;
  paused1;
  state1;
  abc(position,duration){
    console.log("123");
    this.spotifySongProgressBarValue = Math.trunc((position/duration)*100);
  }
  // playSelectedSong(songDetails,index:any){
  //   // var names = document.querySelectorAll('ang-music-player audio');
  //   // var image = document.querySelectorAll('ang-music-player img');
  //   // var title = document.querySelectorAll('ang-music-player p');
  
  //   console.log(songDetails);
  //   console.log(index);
    
  //   this.spotifySongSelected = true;
  //   this.spotifySongSelectedIndex = index;
  //   this.currentlyPlayingSpotifySong = this.audioList[index];
    
  //   this.play(this.currentlyPlayingSpotifySong.url);
  //   document.getElementById("spotifySongPlay").style.display = 'none';
  //   document.getElementById("spotifySongPause").style.display = 'block';
  //   // console.log(this.audioList[index].url);
  //   // this.spotify.play(this.audioList[index].url);
  //   console.log("playing spotify song");
  //   // console.log(this.playList);
  //   // for(var i=0; i<title.length; i++){
  //   //   (<HTMLInputElement>title[i]).innerHTML =  data.title;
  //   // }
  
  //   // for(var i=0; i<names.length; i++){
  //   //   (<HTMLInputElement>names[i]).src =  data.url;
  //   // }
  
  //   // for(var i=0; i<image.length; i++){
  //   //   (<HTMLInputElement>image[i]).src =  data.cover;
  //   // }
  //   // document.getElementsByTagName('audio')[0].autoplay=true;
   
  //     // this.spotifySingleData = data;
  //     // this.openModal(this.spotifyPlay)
  // }

  spotifySongTrackType;
  spotifySongPlaylistIndex;
  playSelectedSong(type,index,subTrackIndex:any){
    // var names = document.querySelectorAll('ang-music-player audio');
    // var image = document.querySelectorAll('ang-music-player img');
    // var title = document.querySelectorAll('ang-music-player p');
  
    console.log(type);
    console.log(index);
    console.log(subTrackIndex);
    
    this.spotifySongSelected = true;
    if(type == 'playlist'){
      this.spotifySongTrackType = 'playlist';
      this.spotifySongPlaylistIndex = index;
      this.spotifySongSelectedIndex = subTrackIndex;
      this.currentlyPlayingSpotifySong = this.spotifyList[index].items[subTrackIndex];
    }
    else{
      this.spotifySongTrackType = 'singleTrack';
      this.spotifySongSelectedIndex = index;
      this.currentlyPlayingSpotifySong = this.spotifyList[index].items[0];
    }
    
    console.log(this.currentlyPlayingSpotifySong);
    this.play(this.currentlyPlayingSpotifySong.uri);
    document.getElementById("spotifySongPlay").style.display = 'none';
    document.getElementById("spotifySongPause").style.display = 'block';
    console.log("playing spotify song");
    
  }

  spotifyVolumeOn = true;
  spotifyVolumeToggle(){
    if(this.spotifyVolumeOn){
      this.spotifyPlayerNew.setVolume(0).then(() => {
        console.log('Muted');
        this.spotifyVolumeOn = false;
        this.spotifyVolume = 0;
      });
    }
    else{
      this.spotifyPlayerNew.setVolume(0.3).then(() => {
        console.log('UnMuted');
        this.spotifyVolumeOn = true;
        this.spotifyVolume = 30;
      });
    }
    

  }
  
  spotifyPlayPrevious(){
    // if(this.spotifyShuffleModeOn){
    //   this.spotifySongSelectedIndex = Math.floor(Math.random()*this.audioList.length)
    // }
    // else if(this.spotifySongSelectedIndex == 0){
    //   // if(this.spotifySongRepeatModeOn){
    //     this.spotifySongSelectedIndex = this.audioList.length;
    //   // }
    // }
    // this.spotifySongSelectedIndex-=1;



    if(this.spotifySongTrackType == 'playlsit'){
      console.log(this.spotifySongSelectedIndex)
      if(this.spotifySongSelectedIndex == 0){
        this.spotifySongSelectedIndex = this.spotifyList[this.spotifySongPlaylistIndex].items.length-1;
      }
      else{
        this.spotifySongSelectedIndex--;
      }
      this.playSelectedSong('playlist',this.spotifySongPlaylistIndex,this.spotifySongSelectedIndex);
    }
    else{
      this.getPreviousSingleAndPlay();
    }


    /*********************************  Latest Code ************************************* */
    /** 
    if((this.spotifySongTrackType == 'playlsit' && this.spotifySongPlaylistIndex == 0 && this.spotifySongSelectedIndex == 0) || (this.spotifySongTrackType == 'singleTrack' && this.spotifySongSelectedIndex == 0)){
      // this.spotifySongSelectedIndex = -1;
      let subTrackIndex = this.spotifyList[this.spotifyList.length-1].trackType == 'playlist' ? this.spotifyList[this.spotifyList.length-1].items[this.spotifyList[this.spotifyList.length-1].items.length-1] : null;
      this.playSelectedSong(this.spotifyList[this.spotifyList.length-1].trackType,this.spotifyList.length-1,subTrackIndex);
      return;
    } 
    else if(this.spotifySongTrackType == 'playlist'){
      if(this.spotifySongSelectedIndex == 0){
        this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex-1].items[this.spotifySongSelectedIndex];
      }
      else{
        this.spotifySongSelectedIndex = this.spotifySongSelectedIndex - 1;
        this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex].items[this.spotifySongSelectedIndex];
      }
    }
    else{
      this.spotifySongSelectedIndex = this.spotifySongSelectedIndex - 1;
      this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongSelectedIndex].items[0];
    }
    // this.currentlyPlayingSpotifySong = this.audioList[this.spotifySongSelectedIndex];
    this.play(this.currentlyPlayingSpotifySong.uri);
    document.getElementById("spotifySongPlay").style.display = 'none';
    document.getElementById("spotifySongPause").style.display = 'block';
    this.spotifySongPaused = false;
    */
  }

  spotifySongPlay(){
    // this.spotify
    // debugger;
    // if(this.spotifySongSelected)
    //   this.spotify.play(this.audioList[this.spotifySongSelectedIndex].url);
    // else{
    //   this.spotifySongSelected = true;
    //   this.spotifySongSelectedIndex = 0;
    //   this.spotify.play(this.audioList[this.spotifySongSelectedIndex].url);
    // }
    // this.spotifyPlayerNew.play();
   
    // this.spotifyPlayerNew.getCurrentState().then(state => {
    //   console.log(state);
    //   if (!state) {
    //     console.log("playing new")
    //     this.play(this.currentlyPlayingSpotifySong.url);
    //     document.getElementById("spotifySongPlay").style.display = 'none';
    //     document.getElementById("spotifySongPause").style.display = 'block';
    //   }
    //   else{
    //     this.spotifyPlayerNew.resume().then(() => {
    //       console.log('Resumed!');
    //       document.getElementById("spotifySongPlay").style.display = 'none';
    //       document.getElementById("spotifySongPause").style.display = 'block';
    //     });
    //   }
    // });
    // if(document.getElementById("spotifySongPlay").style.display == 'block'){
    //   document.getElementById("spotifySongPlay").style.display = 'none';
    //   document.getElementById("spotifySongPause").style.display = 'block';
    //   this.play(this.currentlyPlayingSpotifySong.url);
    // }
    // else{
    //   document.getElementById("spotifySongPlay").style.display = 'block';
    //   document.getElementById("spotifySongPause").style.display = 'none';
    //   this.spotifyPlayerNew.resume();
    // }

    if(this.spotifySongPaused){
      this.spotifyPlayerNew.resume();
      this.spotifySongPaused = false;
      document.getElementById("spotifySongPlay").style.display = 'none';
      document.getElementById("spotifySongPause").style.display = 'block';
    }
    else{
      this.play(this.currentlyPlayingSpotifySong.uri);
      document.getElementById("spotifySongPlay").style.display = 'none';
      document.getElementById("spotifySongPause").style.display = 'block';
    }
    
    
  }

  spotifySongProgressBarValue = 0;
  spotifySongPaused = false;
  spotifySongRepeatModeOn = false;
  spotifyVolume = 50;
  songEnded = false;
  already = true;
  spotifySongPause(){
    this.spotifyPlayerNew.pause().then(() => {
      console.log('Paused!');
      this.spotifySongPaused = true;
      document.getElementById("spotifySongPlay").style.display = 'block';
      document.getElementById("spotifySongPause").style.display = 'none';
    }); 
  }
  volumeChange(e){
    console.log(e.target.value);
    this.spotifyPlayerNew.setVolume(e.target.value*0.01).then(() => {
      console.log('Volume updated!');
      if(e.target.value == 0){
        this.spotifyVolumeOn = false;
      }
    });
  }
  spotifyPlayNext(){
    // if(this.spotifyShuffleModeOn){
    //   this.spotifySongSelectedIndex = Math.floor(Math.random()*this.audioList.length)
    // }
    // else if(this.spotifySongSelectedIndex == this.spotifyList.length-1){
    //   // if(this.spotifySongRepeatModeOn){
    //     this.spotifySongSelectedIndex = -1;
    //   // }
    // } 

    
    
    // this.currentlyPlayingSpotifySong = this.audioList[this.spotifySongSelectedIndex];
    // this.play(this.audioList[this.spotifySongSelectedIndex].url);
    
    /****  ***************************** Latest Commented ************************************ */


    // Check if this is last song
    // if((this.spotifySongTrackType == 'playlsit' && this.spotifySongPlaylistIndex == this.spotifyList.length-1 && this.spotifySongSelectedIndex == this.spotifyList[this.spotifySongPlaylistIndex].length-1) || (this.spotifySongTrackType == 'singleTrack' && this.spotifySongSelectedIndex == this.spotifyList.length-1)){
    //   // this.spotifySongSelectedIndex = -1;
    //   let subTrackIndex = this.spotifyList[0].trackType == 'playlist' ? 0 : null;
    //   this.playSelectedSong(this.spotifyList[0].trackType,0,subTrackIndex);
    //   return;
    // } 
    // else if(this.spotifySongTrackType == 'playlist'){
    //   if(this.spotifyList[this.spotifySongPlaylistIndex].items.length-1 == this.spotifySongSelectedIndex){
    //     this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex+1].items[this.spotifySongSelectedIndex];
    //   }
    //   else{
    //     this.spotifySongSelectedIndex = this.spotifySongSelectedIndex + 1;
    //     this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex].items[this.spotifySongSelectedIndex];
    //   }
    // }
    // else{
    //   this.spotifySongSelectedIndex = this.spotifySongSelectedIndex + 1;
    //   this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongSelectedIndex].items[0];
    // }
    // this.play(this.currentlyPlayingSpotifySong.uri);
    // document.getElementById("spotifySongPlay").style.display = 'none';
    // document.getElementById("spotifySongPause").style.display = 'block';
    // this.spotifySongPaused = false;
    
    //Check if it the last song
    // this.audioList.length

    /******************************************************************************************** */
    // if(this.spotifyShuffleModeOn){
    //   this.spotifySongSelectedIndex = Math.floor(Math.random()*this.spotifyList.length);

    //   if(this.spotifyList)

    // }
    // else
    // debugger;
     if(this.spotifySongTrackType == 'playlist'){
      console.log(this.spotifySongSelectedIndex)
      if(this.spotifySongSelectedIndex == this.spotifyList[this.spotifySongPlaylistIndex].items.length-1){
        this.spotifySongSelectedIndex = 0;
      }
      else{
        this.spotifySongSelectedIndex++;
      }
      this.playSelectedSong('playlist',this.spotifySongPlaylistIndex,this.spotifySongSelectedIndex);
    }
    else{
      this.getNextSingleAndPlay();
    }
  }
  spotifyProgressBarInterval;
  // play(url){
  //   // this.songEnded = false;
  //   setTimeout(()=>{
  //     this.already = true;
  //   },5000)
    
  //   $.ajax({
  //     url: "https://api.spotify.com/v1/me/player/play?device_id=" + this.device_id,
  //     type: "PUT",
  //     data: `{"uris": ["${url}"]}`,
  //     // data: `{"uris": ["}"]}`,
  //     beforeSend: (xhr)=>{xhr.setRequestHeader('Authorization', 'Bearer ' + this.spotifyToken );},
  //     success: (data) => { 
  //       console.log(data);
  //       this.startProgress();
  //       // this.spotifyProgressBarInterval = setInterval(()=>{
  //       //   // console.log(this.spotifySongProgressBarValue);
  //       //   // console.log(this.position1);
  //       //   // console.log(this.duration1);
  //       //   // console.log(this.paused1);
  //       //   // console.log(this.state1);
  //       //   // let aa = this.getStatePosition();
  //       //   // console.log(aa);
  //       //   this.spotifyPlayerNew.getCurrentState().then(state => {
  //       //     if (!state) {
  //       //       console.error('User is not playing music through the Web Playback SDK');
  //       //       return;
  //       //     }
  //       //     console.log(state);
  //       //     this.spotifySongProgressBarValue = (state.position/state.duration)*100;
  //       //     console.log(this.spotifySongProgressBarValue);

  //       //     if(state.paused && !Math.trunc(this.spotifySongProgressBarValue)){
  //       //       clearInterval(this.spotifyProgressBarInterval);
  //       //     }

  //       //   });
  //       // });
  //     }
  //   });
  // }
  play(url){
    // this.songEnded = false;
    setTimeout(()=>{
      this.already = true;
    },5000)
    
    $.ajax({
      url: "https://api.spotify.com/v1/me/player/play?device_id=" + this.device_id,
      type: "PUT",
      data: `{"uris": ["${url}"]}`,
      // data: `{"uris": ["}"]}`,
      beforeSend: (xhr)=>{xhr.setRequestHeader('Authorization', 'Bearer ' + this.spotifyToken );},
      success: (data) => { 
        console.log(data);
        this.startProgress();
      }
    });
  }
  spotifySongPlayedTill = '0:00';
  spotifyShuffleModeOn = false;
  originalSpotifyList = [];
  spotifySongShuffleToggle(){
    this.spotifyShuffleModeOn = !this.spotifyShuffleModeOn;
    // if(this.spotifyShuffleModeOn){
    //   this.spotifyList = this.shuffle(this.spotifyList);
    // }
    // else{
    //   this.spotifyList = this.originalSpotifyList;
    // }
  }

  shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }



  startProgress(){
    console.log("started");
    this.spotifySongProgressBarValue = 0;
    this.spotifyProgressBarInterval = setInterval(()=>{
          // console.log(this.spotifySongProgressBarValue);
          // console.log(this.position1);
          // console.log(this.duration1);
          // console.log(this.paused1);
          // console.log(this.state1);
          // let aa = this.getStatePosition();
          // console.log(aa);
          this.spotifyPlayerNew.getCurrentState().then(state => {
            if (!state) {
              console.error('User is not playing music through the Web Playback SDK');
              return;
            }
            // console.log(state);
            this.spotifySongProgressBarValue = (state.position/state.duration)*100;
            this.spotifySongPlayedTill = this.getTimeInMinuteAndSeconds(state.position);
            // console.log(this.spotifySongProgressBarValue);

if(this.already){
  if(state.paused && !this.spotifySongProgressBarValue){
    clearInterval(this.spotifyProgressBarInterval);
    console.log("end");
    // if(this.already){
      this.checkNextAndPlay();
      this.already = false;
    // }
    // if(!this.songEnded){
      // let timer = 0;
      // if(this.spotifySongSelectedIndex == this.audioList.length-1){
      //   timer = 4000;
      // }
      // else{
      //   timer = 1000;
      // }
      // setTimeout(()=>{
      //   this.checkNextAndPlay();
      // },timer);
      
      // this.songEnded = true;
    // }
    
  }
}
            

          });
        },1000);

  }
 
  checkNextAndPlay(){
    if(this.spotifySongRepeatModeOn){
      this.spotifySongPlay();
    }
    else{
      console.log("1");
      console.log("2");
      console.log("3");
      console.log("4");
      if(this.spotifyShuffleModeOn){
        this.playShuffledSong();
        // this.spotifySongSelectedIndex = Math.floor(Math.random()*this.audioList.length)
      }
      else if(this.spotifySongTrackType == 'playlsit'){
        console.log(this.spotifySongSelectedIndex)
        if(this.spotifySongSelectedIndex == this.spotifyList[this.spotifySongPlaylistIndex].items.length-1){
          this.spotifySongSelectedIndex = 0;
        }
        else{
          this.spotifySongSelectedIndex++;
        }
        this.playSelectedSong('playlist',this.spotifySongPlaylistIndex,this.spotifySongSelectedIndex);
      }
      else{
        this.getNextSingleAndPlay();
      }

      // else if((this.spotifySongTrackType == 'playlsit' && this.spotifySongPlaylistIndex == this.spotifyList.length-1 && this.spotifySongSelectedIndex == this.spotifyList[this.spotifySongPlaylistIndex].length-1) || (this.spotifySongTrackType == 'singleTrack' && this.spotifySongSelectedIndex == this.spotifyList.length-1)){
      //   // this.spotifySongSelectedIndex = -1;
      //   let subTrackIndex = this.spotifyList[0].trackType == 'playlist' ? 0 : null;
      //   this.playSelectedSong(this.spotifyList[0].trackType,0,subTrackIndex);
      // } 
      // else{
      //   this.spotifyPlayNext();
      // }
      
    }
    
  }
  getNextSingleAndPlay(){
    for(let i=this.spotifySongSelectedIndex;i<this.spotifyList.length;){
      if(i == this.spotifyList.length-1)
        i = 0;
      else
        i++;
      if(this.spotifyList[i].contentType == 'track'){
        this.spotifySongSelectedIndex = i;
        break;
      }
    }
    this.playSelectedSong('singleTrack',this.spotifySongSelectedIndex,'');
  }
  getPreviousSingleAndPlay(){
    for(let i=this.spotifySongSelectedIndex;i<this.spotifyList.length;){
      if(i == 0)
        i = this.spotifyList.length-1;
      else
        i--;
      if(this.spotifyList[i].contentType == 'track'){
        this.spotifySongSelectedIndex = i;
        break;
      }
    }
    this.playSelectedSong('singleTrack',this.spotifySongSelectedIndex,'');
  }
  playShuffledSong(){
    let index = Math.floor(Math.random()*this.spotifyList.length);

    if(this.spotifySongTrackType == 'playlist'){
      if(this.spotifyList[this.spotifySongPlaylistIndex].items.length-1 == this.spotifySongSelectedIndex){
        //If this is the last song, then get random and play
        // this.spotifySongSelectedIndex = Math.floor(Math.random()*this.spotifyList.length)
        if(this.spotifyList[index].trackType == 'playlist'){
          this.spotifySongTrackType = 'playlist';
          this.spotifySongPlaylistIndex = index;
          this.spotifySongSelectedIndex = 0;
          this.currentlyPlayingSpotifySong = this.spotifyList[index].items[0];
        // }
        // else{
          
        }
        else{
          this.spotifySongTrackType = 'singleTrack';
          this.spotifySongSelectedIndex = index;
          this.currentlyPlayingSpotifySong = this.spotifyList[index].items[0];
        }
        // this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex+1].items[this.spotifySongSelectedIndex];
      }
      else{
        // this.spotifySongSelectedIndex = this.spotifySongSelectedIndex + 1;
        // this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex].items[this.spotifySongSelectedIndex];
        this.spotifyPlayNext();
      }
    }
    else{
      this.spotifySongTrackType = 'singleTrack';
      this.spotifySongSelectedIndex = index;
      this.currentlyPlayingSpotifySong = this.spotifyList[index].items[0];
    }
    this.play(this.currentlyPlayingSpotifySong.uri);
    document.getElementById("spotifySongPlay").style.display = 'none';
    document.getElementById("spotifySongPause").style.display = 'block';
    this.spotifySongPaused = false;


    // if(this.spotifySongTrackType == 'playlist'){
    //   if(this.spotifyList[this.spotifySongPlaylistIndex].items.length-1 == this.spotifySongSelectedIndex){
    //     this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex+1].items[this.spotifySongSelectedIndex];
    //   }
    //   else{
    //     this.spotifySongSelectedIndex = this.spotifySongSelectedIndex + 1;
    //     this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex].items[this.spotifySongSelectedIndex];
    //   }
    // }
    // else{
    //   this.spotifySongSelectedIndex = this.spotifySongSelectedIndex + 1;
    //   this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongSelectedIndex].items[0];
    // }
    // this.play(this.currentlyPlayingSpotifySong.uri);
    // document.getElementById("spotifySongPlay").style.display = 'none';
    // document.getElementById("spotifySongPause").style.display = 'block';
    // this.spotifySongPaused = false;
    
    
  }
  spotifySongRepeatToggle(){
    this.spotifySongRepeatModeOn = !this.spotifySongRepeatModeOn;
  }
  changeSongPosition(e){
    console.log(e.target.value);
    console.log(this.spotifyPlayerNew);
    this.spotifyPlayerNew.getCurrentState().then(state => {
      if (!state) {
        console.error('User is not playing music through the Web Playback SDK');
        return;
      }
        
      this.spotifyPlayerNew.seek((e.target.value/100)*state.duration).then(() => {
        console.log('Changed position!');
      });
    });
  }

  getTimeInMinuteAndSeconds = function(timeInMilli){
    let seconds = timeInMilli/1000;
    return Math.trunc(seconds/60) + ":" + Math.trunc(seconds%60);
  }

  getPosts(page,pageLimit){
    this.spinner.show();
    this.service.getPosts(page,pageLimit).subscribe((res:any) => {
      if(res.type == 'success'){
        this.postList = res.data;
        if(res.data.length==0){
          this.stopScrollingPost= false;
        }else{
          this.stopScrollingPost= true;
        }
        for(var i of res.data){
          if(i.type=='SHOW'){
            this.allShows.push(i);
          }else{
            this.allPosts.push(i)
          }
        }
      }else{
        this.toastrService.error(res.message);
      }
    })
    this.spinner.hide();
  }

  
  ngOnDestroy() {
    this._header.manageServicesHeading.next(false);
  }

  onSubmit(search:any){

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,{backdrop: 'static'});
  }

  reportPostType;
  openReportDialog(id:any,type){
    this.reportPostModel = true;
    this.reportCommentModel = false;
    // this.openModal(this.reportPostModal);
    this.modalRef = this.modalService.show(this.reportPostModal, {class:"reportpostdynamic",backdrop: 'static'});
    this.reportPostType = type;
    this.reportPostId = id;
  }

  onClickSilentPost(id,index){
    // this.service.silentPost(id,{"isSilent":true}).subscribe((res:any)=> {
    //   if(res.type == "success"){
  
    //   }else{
    //     this.toastrService.error(res.message);
    //   }
    // })
    this.service.silentUserPosts({"userId":id}).subscribe((res:any)=> {
      if(res.type == "success"){
        this.toastrService.success("User Silenced");
        // this.allPostData.splice(index,1);
        if(!this.postId){
          this.allPosts = this.allPosts.filter((data)=>{
            return data.userId._id !=id
          })
          this.allShows = this.allShows.filter((data)=>{
            return data.userId._id !=id
          })
        }
      }else{
        this.toastrService.error(res.message);
      }
    })
  }

  commentAreaEnable(postIndex:any){
    // document.getElementById('postCommentList'+postId).style.display = 'block';
    // document.getElementById('postCommentList'+postId).style.display = 'block';
    
    this.service.getCommentsList(this.allPosts[postIndex]._id,1,3).subscribe((res:any)=>{
      this.allPosts[postIndex].comments = res.data;
      this.allPosts[postIndex]['pageNumber'] = 0;
      document.getElementById('postCommentList'+this.allPosts[postIndex]._id).style.display = 'block';
    })
  }


  likesList:any=[];
  LikeListModalPostId;
  openLikesModal(id:any){
    this.getLikesList(id);
    this.LikeListModalPostId = id;
    // this.openModal(this.likesModel);
  this.modalRef = this.modalService.show(this.likesModel, {class:"likesmodaldynamic"});

  }

  mentionReplyingTo = [];
  removeReplyOption(postIndex){
    (<HTMLInputElement>document.getElementById('replyCrossMark'+this.allPosts[postIndex]._id)).style.display = "none";
    this.allPosts[postIndex].commentFieldStatus = 'comment';
    delete this.allPosts[postIndex].replyCommentId;
    (<HTMLInputElement>document.getElementById('replyCrossMark'+this.allPosts[postIndex]._id)).style.display = "none";
    (<HTMLInputElement>document.getElementById('addcommnt'+this.allPosts[postIndex]._id)).value = '';
    this.mentionReplyingTo = [];
  }


  getLikesList(id:any){
    this.service.getLikesList(id).subscribe((res:any)=> {
      if(res.type == "success"){
          this.likesList = res.data;
          console.log(this.likesList);

      }else{
        this.toastrService.error(res.message);
      }
    })
  }

  reportPostById(){
    this.service.reportPost(this.reportPostId, {"comment":this.reportPostForm.value.comment}).subscribe((res:any) => {
      if(res.type == 'success'){
        this.modalRef.hide();
        this.reportPostForm.reset();
        // this.toastrService.success(res.message);
        this.toastrService.success(this.reportPostType + " Reported");
      }else{
        this.toastrService.error(res.message);
      }
      this.reportPostModel = false;
    })
  }

  reportCommentById(){
    this.service.reportComment(this.reportCommentPostId,this.reportCommentId,this.reportCommentForm.value.comment,{}).subscribe((res:any)=>{
      if(res.type == 'success'){
        this.modalRef.hide();
        this.reportCommentForm.reset();
        // this.toastrService.success(res.message)
        this.toastrService.success("Comment Reported")
      }else{
        this.toastrService.error(res.message)
      }
      this.reportCommentModel = false;
    },(err)=>{
      this.toastrService.error(err.error.message)
      this.reportCommentModel = false;
    })
  }

  cancel(): void {
    this.youtubeEdit = true;
    this.spotifyEdit = true;
    this.reportPostForm.reset();
    this.modalRef.hide();
    this.imagessArray = [];
    this.updateProfileForm.reset();
    this.reportPostModel = false;
    this.reportProfileUser = false;
    this.reportCommentModel = false;
  }

  cancelEditProfileModal(){
    this.youtubeEdit = true;
    this.spotifyEdit = true;
    this.reportPostForm.reset();
    this.modalRef.hide();
    this.imagessArray = [];
    this.updateProfileForm.reset();
    this.service.getProfile().subscribe((res:any) => {
      if(res.type == 'success'){
        this.profileDetails = res.data;
      }
    });
  }

  mentionsAdded;
  createComment(postIndex:any){
    if(this.allPosts[postIndex].commentFieldStatus && this.allPosts[postIndex].commentFieldStatus == 'reply'){
      this.replyPostCommentClick(postIndex,this.allPosts[postIndex]["replyCommentId"],this.allPosts[postIndex]._id);
    }
    else{
      let data = {
        "text": this.createCommentForm.value.comment,
        "postId": this.allPosts[postIndex]._id,
      }
      
      if((this.createCommentForm.invalid) ||(this.createCommentForm.value.comment.trim()=="")){
        this.toastrService.error("Add a comment please");
        return;
      }
      console.log(this.allPosts[postIndex]._id);
  
      if(this.mentionsAdded){
        data['mentions'] = [];
        this.mentionsAdded.forEach(mention => {
          data['mentions'].push({
            "userId" : mention.choice._id,
            "name" : mention.choice.bandName,
            "key" : "@"+mention.choice.bandName,
            "startIndex" : mention.indices?.start,
            "endIndex" : mention.indices?.end
          })
        });
        
      }
      this.service.createComment(data).subscribe((res:any) => {
        // this.onSelectedChoicesChange([]);
        if(res.type == 'success'){
         
          this.toastrService.success("Comment created");
          // let commentcount = (<HTMLInputElement>document.getElementById('totalcommentCount'+this.allPosts[postIndex]._id));
          // // commentcount= commentcount.split(' ')[0];
          // console.log(commentcount.innerText);
          // (<HTMLInputElement>document.getElementById('totalcommentCount'+this.allPosts[postIndex]._id)).innerHTML=`${parseInt(commentcount.innerHTML)+1}`;
          
          // append the comment here
          // this.allPostData[postIndex].comments.push(
          //   {
          //     "createdOn":"2023-03-23T14:34:57.591Z",
          //     "isDeleted":false,
          //     "mentions":[],
          //     "postId":this.allPostData[postIndex]._id,
          //     "replies":[],
          //     "reports":[],
          //     "text":this.createCommentForm.value.comment,
          //     "_id":"2212156654"
          //   }
          // )
          this.allPosts[postIndex].comments.unshift(res.data);
          this.createCommentForm.reset();
          this.allPosts[postIndex].commentCount++;

          // {
          //   "text":"a",
          //   "mentions":[],
          //   "_id":"641d70cbd49fa109d8e72449",
          //   "createdOn":"2023-03-24T09:43:39.876Z",
          //   "reports":[]
          // }

        }else{
          this.toastrService.error(res.message);
        }
      },   (err) => {
        this.toastrService.error(err.error.message);
      })
    }
    // this.spinner.show();
    // let data = {
    //   "text": this.createCommentForm.value.comment,
    //   "postId": postId,
    // }

    // if(this.createCommentForm.invalid){
    //   this.toastrService.error("Add a comment please");
    //   return;
    // }
    // this.service.createComment(data).subscribe((res:any) => {
    //   if(res.type == 'success'){
    //       this.createCommentForm.reset();
    //       if(this.openModalPostId){
    //         this.getCommentsList(this.openModalPostId);
    //       }else{
    //         this.createCommentForm.reset();
    //         this.toastrService.success(res.message);
    //         this.postList=[];
    //         this.allPosts=[];
    //         this.allShows=[];
    //         this.page=1;
    //         this.getPosts(this.page,this.itemperPage);
    //       }
    //   }else{
    //     this.toastrService.error(res.message);
    //   }
    // },   (err) => {
    //   this.toastrService.error(err.error.message);
    // })
    // this.spinner.hide();
  }

  usersForMention = [];

  loadChoices(e){
    console.log("loading choices", e);
    if(e.length >= 2 && !this.choiceRemovedRecently){
      this.service.getUsersForMention({"keyword":e}).subscribe((res:any)=>{
        this.usersForMention = res.data;
      });
    }
    else
      this.usersForMention = [];
  }
  choiceRemovedRecently:Boolean;
  choiceRemoved(e,postIndex){
    console.log("choice removed",e);
    console.log("choice removed",postIndex);
    let str = (<HTMLInputElement>document.getElementById('addcommnt'+this.allPosts[postIndex]._id)).value;
    console.log(str.replace(str.substring(e.indices.start,e.indices.end),""));
    (<HTMLInputElement>document.getElementById('addcommnt'+this.allPosts[postIndex]._id)).value = str.replace(str.substring(e.indices.start+1,e.indices.end),"");
    this.choiceRemovedRecently = true;
    this.usersForMention = [];
    setTimeout(()=>{
      this.choiceRemovedRecently = false;
    },1000);
    
  }
  onSelectedChoicesChange(e){
    console.log('Selected Choices Changed',e);
    this.mentionsAdded = e;
  }
  getChoiceLabel(choice){
    console.log("creating choice label",choice);
    return `@${choice.bandName}`;
  }
  onMenuShow(){
    console.log("menu show");
    // this.service.getUsersForMention({}).subscribe((res:any)=>{
    //   this.usersForMention = res.data;
    // });
  }
  onMenuHide(){
    console.log("mennu hide");
    this.usersForMention = [];
  }
  selectChoice(user){
    console.log("select choice",user);
  }
  selectChoice2(user){
    console.log("select choice",user);
  }
  getModifiedComment(comment,i){
    if(comment?.mentions?.length){
      return comment?.mentions?.reduce((outPut, mention) =>{

        return outPut.replace(mention?.key, `<a href='${document.location.origin}/user/profile?id=${mention?.userId}&user=${mention?.name}'><b>` + mention?.key + "</b></a>");
        
        // return outPut.replace(mention?.key,"<a href='https://www.google.com'><b>"+mention?.key+"</b></a>");
        
        // if(mention.endIndex){
        //   return outPut.substring(0,mention.startIndex) + "<a href='https://www.google.com'><b>" + mention.key + "</b></a>" + outPut.substring(mention.endIndex+1);
        // }
      },comment?.text);
    }
    else{
      return comment?.text;
    }
  }

  editProfilePictureURL;
  editProfileCoverPictureURL;
  editProfilePicUpated:Boolean = false;
  editCoverPicUpdated:Boolean = false;
  profileUpdated:Boolean = false;


  openEditModal(){
   
    // this.spinner?.hide();
    this.spinner.show();
    this.service.getProfile().subscribe((res:any) => {
      if(res.type == 'success'){

        this.profileDetails = res.data;
        this.spinner.hide();
        console.log(this.profileDetails, "this.geners")
        this.modalRef = this.modalService.show(this.editProfileModal, {class: 'editprofilepopup'});
        
        this.updateProfileForm.get('bandName').patchValue(this.profileDetails.bandName);
        this.updateProfileForm.get('email').patchValue(this.profileDetails.email);
        let phone = this.profileDetails.countryCode + ' ' + this.profileDetails.mobileNumber.toString().slice(0,3) + ' ' + this.profileDetails.mobileNumber.toString().slice(3,6) + ' ' + this.profileDetails.mobileNumber.toString().slice(6);
        this.updateProfileForm.get('phone').patchValue(phone);
        this.updateProfileForm.get('location').patchValue(this.profileDetails?.address?.city+', '+this.profileDetails?.address?.state+', '+this.profileDetails?.address?.country);
        // this.updateProfileForm.get('genre').patchValue(this.profileDetails?.genereIds[0]?.genereName +", " + this.profileDetails?.genereIds[1]?.genereName +", " +  this.profileDetails?.genereIds[2]?.genereName);
        // this.updateProfileForm.get('genre').patchValue(
        //   this.profileDetails?.genereIds[0]?.genereName ? (this.profileDetails?.genereIds[1]?.genereName ? 
        //     (this.profileDetails?.genereIds[2]?.genereName ? this.profileDetails?.genereIds[0]?.genereName +","+this.profileDetails?.genereIds[1]?.genereName +","+this.profileDetails?.genereIds[2]?.genereName: this.profileDetails?.genereIds[0]?.genereName +","+this.profileDetails?.genereIds[1]?.genereName) : this.profileDetails?.genereIds[0]?.genereName) : '');

        this.updateProfileForm.get('genre').patchValue(this.profileDetails?.communityIds.length>0 ? ((this.profileDetails?.communityIds[0]?.communityName != null ? this.profileDetails?.communityIds[0]?.communityName : '') + (this.profileDetails?.communityIds[1]?.communityName!=null ?  "," +this.profileDetails?.communityIds[1]?.communityName : '')): ((this.profileDetails?.genereIds[0]?.genereName!=null ? this.profileDetails?.genereIds[0]?.genereName : '') +  (this.profileDetails?.genereIds[1]?.genereName!=null ? "," +this.profileDetails?.genereIds[1]?.genereName : '') + (this.profileDetails?.genereIds[2]?.genereName ? "," +this.profileDetails?.genereIds[2]?.genereName :
        '')))    
        // if(this.geners.length>3){
        //   this.updateProfileForm.get('genre').patchValue(this.geners.slice(2,40)+'...');
        // }
        // this.updateProfileForm.get('genre').patchValue(this.profileDetails);
        // this.updateProfileForm.get('genre').patchValue(this.geners.substring(1));
        this.updateProfileForm.get('bio').patchValue(this.profileDetails.bio);
        this.updateProfileForm.get('spotifyURL').patchValue(this.profileDetails?.playList?.spotify?.url);
        this.updateProfileForm.get('youtubeURL').patchValue(this.profileDetails?.playList?.youtube?.url);
        this.updateProfileForm.get('profilePicture').patchValue(this.profileDetails?.profileImage?.fileUrl);
        this.updateProfileForm.get('coverPicture').patchValue(this.profileDetails?.coverImage?.fileUrl);
        this.updateProfileForm.get('city').patchValue(this.profileDetails?.address?.city);
        this.updateProfileForm.get('state').patchValue(this.profileDetails?.address?.state);
        this.updateProfileForm.get('country').patchValue(this.profileDetails?.address?.country);
        this.updateProfileForm.get('city').patchValue(this.profileDetails?.address?.city);
        this.updateProfileForm.get('lat').patchValue(this.profileDetails?.location?.coordinates[0]);
        this.updateProfileForm.get('long').patchValue(this.profileDetails?.location?.coordinates[1]);

        this.editProfilePictureURL = this.profileDetails?.profileImage?.fileUrl;
        this.editProfileCoverPictureURL = this.profileDetails?.coverImage?.fileUrl;
        
      }
      else{
        this.spinner.hide();
        this.toastrService.info("some error occured.please try after some time!");
      }
    });
    
  }

  updateProfileData() {
    // if((this.updateProfileForm.pristine && !this.profileUpdated) || (updateProfileForm.dirty && updateProfileForm.invalid)){

    // }
    if(this.updateProfileForm.dirty && this.updateProfileForm.invalid){
      this.toastrService.error("Please enter valid details");
      return;
    }
    if(this.updateProfileForm.pristine && !this.profileUpdated){
      this.modalRef.hide();
      return;
    }
    let data = {
        // "address":{
        //     "city": this.updateProfileForm.value.city,
        //     "state": this.updateProfileForm.value.state,
        //     "country": this.updateProfileForm.value.country,
        //   },

        "bandName": this.updateProfileForm.value.bandName,
        // "location":{
        //               "lat": this.updateProfileForm.value.lat,
        //                "long": this.updateProfileForm.value.long 
        //             },

        // "email":this.updateProfileForm.value.email,
        "bio": this.updateProfileForm.value.bio,
        // "genereIds": this.genersId
        // "communityIds": this.profileDetails.role!='Artist' ? this.genersId : [],
        // "genereIds": this.profileDetails.role=='Artist' ? this.genersId : [],
        // "coverImage":{
        //   "fileUrl": this.updateProfileForm.value.coverPicture,
        //   "key": this.updateProfileForm.value.coverPictureKey
        // },
        // "profileImage":{
        //   "fileUrl": this.updateProfileForm.value.profilePicture,
        //   "key": this.updateProfileForm.value.profilePictureKey
        // }
        // "youtube":{
        //   "url":this.updateProfileForm.value.youtubeURL
        // },
        // "spotify":{
        //     "url":this.updateProfileForm.value.spotifyURL
        // }

      }

      this.service.updateProfile(data).subscribe((res:any)=> {
        if(res.type == 'success'){
            this.toastrService.success(res.message);
            this.youtubeEdit = true;
            this.spotifyEdit = true;
            this.modalRef.hide();
            this.getProfile();
            this.getPlayList();
        }else{
          this.toastrService.error(res.message);
        }
      })

      if(this.updateProfileForm.value.youtubeURL != this.profileDetails?.playList?.youtube?.url || this.updateProfileForm.value.spotifyURL != this.profileDetails?.playList?.spotify?.url){
        let updatePlaylist = {
          "youtube":{
           "url":this.updateProfileForm.value.youtubeURL
          },
          "spotify":{
             "url":this.updateProfileForm.value.spotifyURL
          }
        }
  
        this.service.updatePlayList(updatePlaylist).subscribe((res:any)=> {
          if(res.type =='success'){
            this.updateProfileForm.reset();
            this.editCoverPicUpdated = false;
            this.editProfilePicUpated = false;
            this.profileUpdated = false;
          }
          else{
            // this.toastrService.info("Please try after sometime");
            this.updateProfileForm.reset();
            this.editCoverPicUpdated = false;
            this.editProfilePicUpated = false;
            this.profileUpdated = false;
          }
        },
        error => {
          // this.toastrService.info("Please try after sometime");
          this.updateProfileForm.reset();
          this.editCoverPicUpdated = false;
          this.editProfilePicUpated = false;
          this.profileUpdated = false;
        })
      } 
      else{
        this.updateProfileForm.reset();
        this.editCoverPicUpdated = false;
        this.editProfilePicUpated = false;
        this.profileUpdated = false;
      }
  }

  playYoutube(){
    document.getElementById("iframe1");
    // debugger;
  }
  youtubePlayData:any;
  spotifyPlayData:any;
  currentlyPlayingVideoId = 'fJ9rUzIMcZQ';
  openYoutubeModalP(videoIndex,data:any){
    console.log(videoIndex);
    console.log(data);
    this.youtubePlayData=data;
    this.currentlyPlayingVideoId = data.url.split('v=')[1];
    let initialState: ModalOptions = {
      initialState: {
        videoId: this.currentlyPlayingVideoId,
        videoIndex,
        playList:this.playList,
        videoPlayerOptions : {
          autoplay: true,
          width:906,
          height:596
        }
      } as Partial<Object>,
      class: 'ytmodalpopup'
    };
    this.modalRef = this.modalService.show(YoutubeModalContentComponent, initialState);
    // this.openModal(this.youtubePlay);
    // setTimeout(()=>{
    //   this.currentlyPlayingVideoId = data.url.split('v=')[1];
    //   console.log(data);
    //   console.log(this.currentlyPlayingVideoId);
    //   this.ytPlayerService.play();
    //   // console.log(this.youtubePlayer);
    //   // this.youtubePlayer.load('GKSRyLdjsPA')
    //   // this.youtubePlayer.setVolume(100)
      
    //   // this.youtubePlayer.on('playing', () => {
    //   //   console.log(this.youtubePlayer.getDuration()) // => 351.521
    //   // });
    // },5000)
    
  }

// BANDCAMP 
bandcampModalP(iframesrc:any){
  // console.log(data);
  let initialState: ModalOptions = {
    initialState: {
      source:this._sanitizer.bypassSecurityTrustUrl(iframesrc)
    } as Partial<Object>,
    class: 'bandcamppopup'
  };
  this.modalRef = this.modalService.show(BandcampModalContentComponent, initialState);
  // this.openModal(this.youtubePlay);
  // setTimeout(()=>{
  //   this.currentlyPlayingVideoId = data.url.split('v=')[1];
  //   console.log(data);
  //   console.log(this.currentlyPlayingVideoId);
  //   this.ytPlayerService.play();
  //   // console.log(this.youtubePlayer);
  //   // this.youtubePlayer.load('GKSRyLdjsPA')
  //   // this.youtubePlayer.setVolume(100)
    
  //   // this.youtubePlayer.on('playing', () => {
  //   //   console.log(this.youtubePlayer.getDuration()) // => 351.521
  //   // });
  // },5000)
  
}

openSoundCloudModal(source:any){
  // console.log(data);
  let initialState: ModalOptions = {
    initialState: {
      source
    } as Partial<Object>,
    class: 'bandcamppopup'
  };
  this.modalRef = this.modalService.show(SoundCloudModalContentComponent, initialState);
  // this.openModal(this.youtubePlay);
  // setTimeout(()=>{
  //   this.currentlyPlayingVideoId = data.url.split('v=')[1];
  //   console.log(data);
  //   console.log(this.currentlyPlayingVideoId);
  //   this.ytPlayerService.play();
  //   // console.log(this.youtubePlayer);
  //   // this.youtubePlayer.load('GKSRyLdjsPA')
  //   // this.youtubePlayer.setVolume(100)
    
  //   // this.youtubePlayer.on('playing', () => {
  //   //   console.log(this.youtubePlayer.getDuration()) // => 351.521
  //   // });
  // },5000)
  
}

  private _friendsListStatus: boolean;
  public get friendsListStatus(): boolean {
    return this._friendsListStatus;
  }
  public set friendsListStatus(value: boolean) {
    this._friendsListStatus = value;
  }
  getFriendsList(){
    this.service.getFriendsList('','','').subscribe((res:any)=> {
      if(res.type =='success'){
        this.friendsList = res.data.friends;
        if(res.data.friends.length==0){
          this.friendsListStatus = true;
        }else{
          this.friendsListStatus = false;
        }
        if(res.data.friends.length>4){
          this.topFourFriends = res.data.friends.slice(0,4);
          this.otherFriends = res.data.friends.slice(4);
        }else{
          this.topFourFriends = res.data.friends;
        }
      }else{
        this.toastrService.error(res.message);
      }
    })
  }

  getFriendsListById(id:any){
    this.service.getFriendsListByUserId(id).subscribe((res:any)=> {
      if(res.type =='success'){
        this.friendsList = res.data.friends;
        this.otherFriends = res.data.friends
        if(res.data.friends.length==0){
          this.friendsListStatus = true; 
        }else{
          this.friendsListStatus = false; 
        }
        // if(res.data.friends.length>4){
        //   this.topFourFriends = res.data.friends.slice(0,4);
        //   this.otherFriends = res.data.friends.slice(4);
        // }else{
        //   this.topFourFriends = res.data.friends;
        // }
      }else{
        this.toastrService.error(res.message);
      }
    })
  }

  

  onClickFriend(data:any){
        this.singleFriendData = data;
        if(this.showSiidbar==false){
          this.openModal(this.friendClickModal);
        }else{
          // this.route.navigate(['/user/profile'],{queryParams:{id:data?._id}})
        }
        
  }

  sendMessage(id:any){
    // alert(id);
  }

  onClickUnfriend(id:any){
    this.service.unFriend(id,{}).subscribe((res:any)=> {
      if(res.type =='success'){
        this.modalRef.hide();
        this.getFriendsList();
        this.profileDetails.friendStatus = 'NOT_FRIEND';
      }else{
        this.toastrService.error(res.message);
      }
    })
  }

  onClickSilencePost(id:any){
    this.service.silentPost(id,{"isSilent":true}).subscribe((res:any)=>{
      if(res.type == 'success'){
        this.modalRef.hide();
      }else{
        this.toastrService.error(res.message);
      }
    })
  }


  spotifySingleData:any;




  player: YT.Player;
  private id: string;
  
  savePlayer(player) {
    this.player = player;
    console.log('player instance', player);
  }
  onStateChange(event) {
    console.log('player state', event.data);
  }

  selectedIndex:any = 0;

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedIndex = tabChangeEvent.index;
    console.log(tabChangeEvent);
    if(tabChangeEvent.index == 0){
      this.allPosts = this.allPostsCopy;
    }
    if(tabChangeEvent.index == 1){
      this.allPosts = this.allShows;
    }
    // this.audioplayerprogress();

    if(tabChangeEvent.index == 3 && tabChangeEvent.tab.textLabel == 'Spotify'){
      console.log("spotify-auth tab");
      // this.checkSpotifyAuthorized();
      // this.spotifyAuth();
    }

    localStorage.setItem("selectedProfileTabIndex",JSON.stringify(tabChangeEvent.index));
  }

  gotoSpotify(){
    this.selectedIndex = 3;
  }

  gotoYoutube(){
    // this.selectedIndex = 4;
    this.selectedIndex = 6;
  }

  addFriendById(id:any){
    let data = (<HTMLInputElement>document.getElementById('userFriendsList'+id)).innerHTML;
    if(data=='Add Friend'){
      this.spinner.show();
      (<HTMLInputElement>document.getElementById('userFriendsList'+id)).innerText = 'Pending';
      (<HTMLInputElement>document.getElementById('userFriendsList'+id)).style.background='white';
      (<HTMLInputElement>document.getElementById('userFriendsList'+id)).style.border='solid 1px black';
      (<HTMLInputElement>document.getElementById('userFriendsList'+id)).style.color='black';
      this.service.AddFriend({"recipientId":id}).subscribe((res:any) => {
        if(res.type == 'success'){
          this.toastrService.success("Friend request sent"); 
        }else{
        }
      },(err) => {
        this.toastrService.error(err.error.message);
      })
      this.spinner.hide();
    }else{
      this.spinner.show();
      document.getElementById('userFriendsList'+id).innerText = 'Add Friend'
      document.getElementById('userFriendsList'+id).style.background = 'black';
      document.getElementById('userFriendsList'+id).style.color = 'white';
    
      this.service.cancelRequest(id,{"action":"CANCEL"}).subscribe((res:any)=>{
        if(res.type=='success'){
          this.toastrService.success(res.message); 
        }
      })
      this.spinner.hide();
    }
  }

  onShowsClick(){
    console.log("shows clicked");
  }
  addFriend(){
    let data = (<HTMLInputElement>document.getElementById('addFriendButton'+this.postId)).innerHTML;
    if(data=='Add Friend'){
      this.spinner.show();
      this.service.AddFriend({"recipientId":this.postId}).subscribe((res:any) => {
        if(res.type == 'success'){
          this.toastrService.success(res.message); 
          (<HTMLInputElement>document.getElementById('addFriendButton'+this.postId)).innerText = 'Pending';
          (<HTMLInputElement>document.getElementById('addFriendButton'+this.postId)).style.background='#E9E9EB';
          (<HTMLInputElement>document.getElementById('addFriendButton'+this.postId)).style.border='solid 1px black';
          (<HTMLInputElement>document.getElementById('addFriendButton'+this.postId)).style.color='black';
        }else{
        }
      },(err) => {
        this.toastrService.error(err.error.message);
      })
      this.spinner.hide();
    }else{
      this.spinner.show();
      document.getElementById('addFriendButton'+this.postId).innerText = 'Add Friend'
      document.getElementById('addFriendButton'+this.postId).style.background = 'black';
      document.getElementById('addFriendButton'+this.postId).style.color = 'white';
    
      this.service.cancelRequest(this.postId,{"action":"CANCEL"}).subscribe((res:any)=>{
        if(res.type=='success'){
          this.toastrService.success(res.message); 
        }
      })
      this.spinner.hide();
    }

  }

  addFriendImpl(){
    this.spinner.show();
    this.service.AddFriend({"recipientId":this.postId}).subscribe((res:any) => {
      if(res.type == 'success'){
        // this.toastrService.success(res.message); 
        this.toastrService.success("Friend request sent");
        this.profileDetails.friendStatus = 'REQUEST_SENT';
      }
      this.spinner.hide();
    },(err) => {
      this.toastrService.error(err.error.message);
      this.spinner.hide();
    })
    
  }
  addFriendButtonText:string = "Add Friend";

  AddFriend(){
    let id=this.postId;
    let data = document.getElementById('addFriendText'+id).innerText;
    // alert(data);
    if(data=='Pending'){
      // this.spinner.show();
      // document.getElementById('addFriendText'+id).innerText = 'Add Friend'
      // document.getElementById('addFriendText'+id).style.background = 'black';
      // document.getElementById('addFriendText'+id).style.color = 'white';
      this.cancelRequestId = this.postId;
      this.modalRef = this.modalService.show(this.cancelRequests,{class:'cancelrequestmodall'});
      // this.service.cancelRequest({"action":"CANCEL"},id).subscribe((res:any)=>{
      //   if(res.type=='success'){
      //     this.toastrService.success(res.message); 
      //   }
      // })
      // this.spinner.hide();
    }else if(data=='Add Friend'){
      this.spinner.show();
      document.getElementById('addFriendText'+id).innerText = 'Pending'
      document.getElementById('addFriendText'+id).style.background='white';
      document.getElementById('addFriendText'+id).style.color='black';
      this.service.AddFriend({"recipientId":id}).subscribe((res:any) => {      
        if(res.type == 'success'){
          // this.toastrService.success(res.message); 
          this.toastrService.success("Friend request sent"); 
        }else{
        }
      },(err) => {
        this.toastrService.error(err.error.message);
      })
      this.spinner.hide();
    }else if(data=='Accept'){ 
      this.spinner.show();
      // document.getElementById('addFriendText'+id).innerText = 'Friend'; 
      // document.getElementById('addFriendText'+id).classList.add("friend");
      // document.getElementById('addFriendText'+id).classList.remove("requestSent");

      // document.getElementById('addFriendText'+id).classList.remove('btn:enabled');
      // document.getElementById('bandSuggestionList'+id).style.color='black';
      // this.profileDetails?.friendStatus == 'FRIEND';
      // this.changeDetectorRef.detectChanges();
      this.service.AcceptFriendRequest({"recipientId": id,"action": "ACCEPT"}).subscribe((res:any) => {
        if(res.type == 'success'){
          // this.toastr.success(res.message);   
          this.toastrService.success("Friend request accepted");
          window.document.location.reload();
          // this.profileDetails?.friendStatus == 'FRIEND';
          // this.changeDetectorRef.detectChanges();
          // debugger;
          // let index = this.moreData.findIndex((obj)=>{
          //   return obj._id == id
          // });
          // if(index != -1){
          //   this.moreData[index].friendStatus = "FRIEND";
          // }
        }else{
          this.toastrService.error(res.message);   
        }
      },(err) => {
        this.toastrService.error(err.error.message);
      })
      this.spinner.hide();
    }
  
}
  cancelRequest(id, index) {
    this.service.cancelRequest(id, { "action": "CANCEL" }).subscribe((res: any) => {
      if (res.type == 'success') {
        this.toastrService.success("Cancelled friend request");
        this.friendsList[index].friendStatus = 'NOT_FRIEND';
      }
    })
  }
  cancelRequestId;
cancelRequestModalRef;

showCancelRequestLoader: Boolean = false;
cancelFriendRequest(){
  this.showCancelRequestLoader = true;
  let id = this.cancelRequestId;
  
  
  this.service.cancelRequest(id,{"action":"CANCEL"}).subscribe((res:any)=>{
    this.showCancelRequestLoader = false;
    if(res.type=='success'){
      this.toastrService.success("Cancelled friend request");   
      document.getElementById('addFriendText'+id).innerText = 'Add Friend'
      document.getElementById('addFriendText'+id).style.background = 'black';
      document.getElementById('addFriendText'+id).style.color = 'white';
    }
    else{
      this.toastrService.info("please try after some time!"); 
    }
    this.modalRef?.hide();
  },err => {
    this.showCancelRequestLoader = false;
    this.toastrService.info("please try after some time!"); 
    this.modalRef?.hide();
  })
}

  // cancelFriendRequest(){
  //   this.spinner.show();
  
  //   this.service.cancelRequest(this.postId,{"action":"CANCEL"}).subscribe((res:any)=>{
  //     if(res.type=='success'){
  //       // this.toastrService.success(res.message); 
  //       this.toastrService.success("Cancelled friend request");
  //       this.profileDetails.friendStatus = 'NOT_FRIEND';
  //     }
  //     this.spinner.hide();
  //   })
    
  // }

  onClickPhotoAlbum(id:any){
    this.openModal(this.photoAlbumSlider);
    // alert(id);
  }

  deletePhotoAlbumIndex:any;

  onClickDeletePhotoAlbum(index:any){
    this.modalRef.hide();
    this.openModal(this.deleteAlbumImage);
    console.log(this.activeSlides);
  }


  // get active slide owl carousel
  activeSlides:any=SlidesOutputData;
  getPassedData(data: SlidesOutputData) {
    this.activeSlides = data;
    console.log(this.activeSlides);
  }

  deletePhotosIds:any=[];
  onClickCheckbox(e:any, index:any){
    let ids = e.target.checked;
    if(ids == true){
        this.deletePhotosIds.push(index);
    }else{
      let j=0;
      for(var i of this.deletePhotosIds){
        if(i==index){
          this.deletePhotosIds.splice(j,1);
        }
        j++;
      }
    }
  }

  deleteFunction(){
    // alert(this.deletePhotosIds);
  }

  photoDelete(){
    let index = (<HTMLInputElement>document.getElementById('deletePhotoAlbumId')).value;
    this.modalRef.hide();
  }

  // slidesStore: any[];


  slidesStore :any= [
    {image: '../../../../assets/images/banner-demo.jpg', text: 'First',id:1},
    {image: '../../../../assets/images/bungie.png',text: 'Second',id:2},
    {image: '../../../assets/images/bungie-heading.png',text: 'Third',id:3},
    {image: '../../../../assets/images/boungie-foundation.png',text: 'Forth',id:4},
    {image: '../../../../assets/images/career-views.jpg', text: 'First',id:5},
    {image: '../../../../assets/images/bungie.png',text: 'Second',id:6},
    {image: '../../../../assets/images/banner-demo.jpg',text: 'Third',id:7},
    {image: '../../../../assets/images/bungie.png',text: 'Forth',id:8}
 ];
 noWrapSlides = false;
 showIndicator = true;
 centeralign:boolean = true;
 deleteImagePath:any;

 onClickSelectImage(){
    this.photoAlbumCheckbox=true;
 }

 onClickCancleImage(){
  this.deletePhotosIds=[];
  this.photoAlbumCheckbox=false;
 }

 progress:any=0;
 uploadPhotoAlbum(e:any){
  let file = e.target.files[0];
  let fd = new FormData();
  fd.append('file', file);
  this.service.uploadImageProgressbar(fd).subscribe((res:any)=> {
    if(res.type == 'success'){
      this.uploadPhotoAlbumUrl = res.data.fileUrl;
    }
  })
 }

 scrollCheck:boolean;
 onScrollDown(e: any) { 
   if(this.stopScrollingPost == true){
     this.page += 1;
     this.getPosts(this.page, this.itemperPage);
   }
 }

 onScrollDownPosts() { 

  console.log("from scrolling posts");

    if(this.stopScrollingPost){
      this.postsPageNumber += 1;
      this.getPostsId(this.profileDetails._id,this.postsPageNumber, this.postsPageSize);
    }
  }

  onScrollDownShows(e: any) { 
    
    if(this.stopScrollingShow){
      // this.page += 1;
      // this.getPosts(this.page, this.itemperPage);
      this.showsPageNumber += 1;
      this.getShows(this.profileDetails._id,this.showsPageNumber, this.showsPageSize);
    }
  }

 CheckEnterPress(e:any){
  if (e.keyCode == 13) {               
    e.preventDefault();
    return false;
  } 
}
 appendItems() {
   this.addItems("push");
 }

 listArray: any = [];
 addItems(_method: string) {
   for (let i = 0; i < this.itemperPage; ++i) {
     if (_method === 'push') {
       this.listArray.push([i].join(""));
     } else if (_method === 'unshift') {
       this.listArray.unshift([i].join(""));
     }
   }
 }

//  likePost(id:any){
//   this.spinner.show();
//   this.service.likePost(id,{"action":"LIKE"}).subscribe((res:any)=>{
//     if(res.type == 'success'){
//       this.postList=[];
//       this.allShows=[]
//       this.allPosts=[];
//       this.page=1;
//       this.getPosts(this.page, this.itemperPage);
//     }
//   })
//   this.spinner.hide();
//  }

likePost(id:any){
  this.spinners.show();
  let sorce = (<HTMLInputElement>document.getElementById('likeImage'+id)).src;
  console.log(sorce);
  if(sorce.includes('Heart.svg')==true){
      (<HTMLInputElement>document.getElementById('likeImage'+id)).src = '../../../assets/Indietown_icons/like-dark.svg';
      let count = (<HTMLInputElement>document.getElementById('totalLikeCount'+id)).innerHTML;
      let like = count.split(" ")[0];
      let send;
      if(parseInt(like)==0){
        send = `${parseInt(like)+1} like`;
      }else{
        send = `${parseInt(like)+1} likes`;
      }
      (<HTMLInputElement>document.getElementById('totalLikeCount'+id)).innerHTML=send;

      this.service.likePost(id,{"action":"LIKE"}).subscribe((res:any)=>{
        if(res.type == 'success'){
        }
      })
  }else{
    (<HTMLInputElement>document.getElementById('likeImage'+id)).src = '../../../assets/Indietown_icons/Heart.svg';
    let count = (<HTMLInputElement>document.getElementById('totalLikeCount'+id)).innerHTML;
    let like = count.split(" ")[0];
    let send;
    if((parseInt(like)==2)){
      send = `${parseInt(like)-1} like`;
    }else{
      send = `${parseInt(like)-1} likes`;
    }
    (<HTMLInputElement>document.getElementById('totalLikeCount'+id)).innerHTML=send;

    this.service.likePost(id,{"action":"DISLIKE"}).subscribe((res:any)=>{
      if(res.type == 'success'){
      }
    })
  }
  this.spinner.hide();
}

//  dislikePost(id:any){
//   this.spinner.show();
//   this.service.likePost(id,{"action":"DISLIKE"}).subscribe((res:any)=>{
//     if(res.type == 'success'){
//       this.postList=[];
//       this.allShows=[]
//       this.allPosts=[];
//       this.page=1;
//       this.getPosts(this.page, this.itemperPage);
//      }
//   })
//   this.spinner.hide();
//  }

dislikePost(id:any){
  this.spinners.show();
  let sorce = (<HTMLInputElement>document.getElementById('dislikeImage'+id)).src;
  if(sorce.includes('Heart.svg')==true){
    (<HTMLInputElement>document.getElementById('dislikeImage'+id)).src = '../../../assets/Indietown_icons/like-dark.svg';
      let count = (<HTMLInputElement>document.getElementById('totalLikeCount'+id)).innerHTML;
      let like = count.split(" ")[0];
      let send;
      if(parseInt(like)==0){
        send = `${parseInt(like)+1} like`;
      }else{
        send = `${parseInt(like)+1} likes`;
      }
      (<HTMLInputElement>document.getElementById('totalLikeCount'+id)).innerHTML=send;

      this.service.likePost(id,{"action":"LIKE"}).subscribe((res:any)=>{
        if(res.type == 'success'){
        }
      })
  }else{
    (<HTMLInputElement>document.getElementById('dislikeImage'+id)).src = '../../../assets/Indietown_icons/Heart.svg';
    let count = (<HTMLInputElement>document.getElementById('totalLikeCount'+id)).innerHTML;
    let like = count.split(" ")[0];
    let send;
    if(parseInt(like)==2){
      send = `${parseInt(like)-1} like`;
    }else{
      send = `${parseInt(like)-1} likes`;
    }
    (<HTMLInputElement>document.getElementById('totalLikeCount'+id)).innerHTML=send;

    this.service.likePost(id,{"action":"DISLIKE"}).subscribe((res:any)=>{
      if(res.type == 'success'){
      }
    })
  }

  this.spinners.hide();
 }

 deletePostId:any;
 deletePostShowMsg;
 onClickDeletePost(id:any,post){
  // this.openModal(this.deletePost);
  this.modalRef = this.modalService.show(this.deletePost, {class:"deletepostdynamic"});

  this.deletePostShowMsg = post.type=='SHOW' ? 'show' : 'post';
    this.deletePostId = id;
 }

 editshoww:Boolean;
 disabledCreateShow:Boolean = false;
 updatePostId;
 updateShowButton:Boolean;
 updatePostButton:Boolean;
 createPostForm = new FormGroup({
  text: new FormControl('', [Validators.required])
 })
 createShowForm = new FormGroup({
  imageUrl: new FormControl(''),
  imageUrlKey: new FormControl(''),
  title: new FormControl('', [Validators.required]),
  startDate: new FormControl('', [<AsyncValidatorFn>this.asyncDateValidator("startDate")]),
  endDate: new FormControl('', [<AsyncValidatorFn>this.asyncDateValidator("endDate")]),
  startTime: new FormControl('', [<AsyncValidatorFn>this.asyncDateValidator("startTime")]),
  endTime: new FormControl('', [<AsyncValidatorFn>this.asyncDateValidator("endTime")]),
  venue: new FormControl('', []),
  city: new FormControl(''),
  state: new FormControl(''),
  country: new FormControl(''),
  ticketFrom: new FormControl('',Validators.min(0)),
  ticketTo: new FormControl('',Validators.min(0)),
  venueDetails: new FormControl(''),
})

myTime:any;
imagesVideosArray:any=[];
countVideoUploaded:any=[];
countImagesUploaded:any=[];
minEndDate:any;

asyncDateValidator(prop:string) {

    
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    // debugger;
    if (!value) {
      return null;
    }

    let startDate,endDate,startTime,endTime;
    if(this.createShowForm.controls.startDate?.value){
      startDate = formatDate(new Date(this.createShowForm.controls.startDate?.value),'yyyy-MM-dd','en_US');
    }
    if(this.createShowForm.controls.endDate?.value){
      endDate = formatDate(new Date(this.createShowForm.controls.endDate?.value),'yyyy-MM-dd','en_US');
    }
    if(this.createShowForm.controls.startTime?.value){
      startTime = this.createShowForm.controls.startTime?.value;
    }
    if(this.createShowForm.controls.endTime?.value){
      endTime = this.createShowForm.controls.endTime?.value;
    }
    if((prop == 'endDate' || prop == 'startDate') && (startDate && endDate) && (endDate<startDate)){
      // if(endDate<startDate){
        console.log("error start is greater");
        this.toastrService.error("Start Date should be less than or equal to End Date",'',{timeOut:2000});
        return { outOfRange: true }
      // }
      // else{
      //   return null;
      // }
    }
    console.log(this.createShowForm.controls.endTime.value<this.createShowForm.controls.startTime.value);
    console.log(this.createShowForm.controls.endTime.value>this.createShowForm.controls.startTime.value);
    console.log(this.createShowForm.controls.endTime.value<=this.createShowForm.controls.startTime.value);
    console.log(prop == 'endTime' || prop == 'startTime');
    console.log(startTime && endTime);
    console.log(startDate == endDate);
    console.log(startTime);
    console.log(endTime);

    if((prop == 'endDate' || prop == 'startDate' || prop == 'endTime' || prop == 'startTime') && (startTime) && (endTime) && (((startDate == endDate) && ((endTime <= startTime) || (endTime <= new Date().getTime()))) || ((startDate < endDate) && (new Date(endDate).toDateString() == new Date().toDateString()) && (endTime <= new Date().getTime())))){
      // if(startDate == endDate){
        
          console.log("end time should be greater than current time");
          this.toastrService.error("End Time should be greater than start time and current time",'',{timeOut:2000});
          return { outOfRange: true }
        // if(this.createShowForm.controls.startTime?.value.getHours >= this.createShowForm.controls.endTime?.value.getHours){
        //   console.log("error start time hours is greater");
        // }
        // else{
        //   if(this.createShowForm.controls.startTime?.value.getMinutes >= this.createShowForm.controls.endTime?.value.getMinutes){
        //     console.log("error start time minutes is greater");
        //   }
        // }
      // }
    }

 //   const hours = value.getHours();
    
//     console.log("from hererer");
//     console.log(this.createShowForm.controls.startDate.value);
//     console.log(typeof this.createShowForm.controls.endDate.value);
//     console.log(this.createShowForm.controls.startDate.value == this.createShowForm.controls.endDate.value);
//     console.log(new Date(this.createShowForm.controls.startDate.value));
//     console.log(new Date(this.createShowForm.controls.startDate.value) == new Date(this.createShowForm.controls.endDate.value))
//     let date1 = formatDate(new Date(this.createShowForm.controls.startDate.value),'yyyy-MM-dd','en_US');
//  let date2 = formatDate(new Date(this.createShowForm.controls.endDate.value),'yyyy-MM-dd','en_US');
//  console.log(date1>date2);
//  console.log(date1<date2);
//  console.log(date1==date2);
    // if(this.createShowForm.controls.startDate.value == this.createShowForm.controls.endDate.value){
    //   console.log("need to check");
    // }

    return null;
  };
}

currentPlayingVideo: HTMLVideoElement;
onPlayingVideo(event) {
  event.preventDefault();
  // play the first video that is chosen by the user
  if (this.currentPlayingVideo === undefined) {
      this.currentPlayingVideo = event.target;
      this.currentPlayingVideo.play();
  } else {
  // if the user plays a new video, pause the last 
  // one and play the new one
      if (event.target !== this.currentPlayingVideo) {
          this.currentPlayingVideo.pause();
          this.currentPlayingVideo = event.target;
          this.currentPlayingVideo.play();
      }
  }
}
 onClickEditPost(post:any){
  // this.service.editClickOpenModal(post);
  // this.route.navigateByUrl('/home');
  // localStorage.setItem('updatePost',JSON.stringify(post));
  // this.route.navigate(['/home'], { queryParams: { update: 1 } });
  this.editshoww=true;
  document.getElementById('text').classList.add("removebodyscroll");
  this.disabledCreateShow = true;
  this.updatePostId= post._id;
    if(post.type=='POST'){
      this.updatePostButton = true;
      this.updateShowButton = false;
      this.createPostForm.get('text').patchValue(post.text);
      // this.imagesVideosArray = post.media;
      for(var i of post.media) {
        this.imagesVideosArray.push(i);
        if(i.type=='image'){
          this.countImagesUploaded.push(i);
        }else{
          this.countVideoUploaded.push(i);
        }
      }



        $('#createPostModalBootstrap').show();
    }else{
      this.minEndDate = new Date(this.createShowForm.value.startDate);
      this.updateShowButton = true;
      this.updatePostButton = false;
      this.createShowImageUrl = post.media[0].fileUrl;
      this.createShowImageUrl ? (<HTMLInputElement>document.getElementById('imageView')).src = '../../../../assets/Indietown_icons/edit-icon2.svg' : (<HTMLInputElement>document.getElementById('imageView')).src = '../../../../assets/Indietown_icons/camera.png';
      console.log(post);
      this.createShowForm.controls["imageUrl"].setValue(post.media[0].fileUrl);
      this.createShowForm.controls["imageUrlKey"].setValue(post.media[0].key);
      this.createShowForm.get('title').patchValue(post.text);
      this.createShowForm.get('startDate').patchValue(post.from);
      this.createShowForm.get('endDate').patchValue(post.to);
      this.createShowForm.get('startTime').patchValue(post.from);
      this.createShowForm.get('endTime').patchValue(post.to);
      this.createShowForm.get('venue').patchValue(post.address.venue);
      this.createShowForm.get('city').patchValue(post.address.city);
      this.createShowForm.get('state').patchValue(post.address.state);
      this.createShowForm.get('country').patchValue(post.address.country);
      this.createShowForm.get('ticketFrom').patchValue(post.money.from);
      this.createShowForm.get('ticketTo').patchValue(post.money.to);
      this.createShowForm.get('venueDetails').patchValue(post.venueDetails);
      $("#exampleModalToggle").show();
      this.initAutocomplete();
    }
 }

 updatePost(){

  let text = this.createPostForm?.value?.text?.replace(/\<br>/g, '');
  let data = {
    "text": text,
    // "media":this.imagesVideosArray
  }

  this.service.updatePost(this.updatePostId,data).subscribe((res:any)=>{
    if(res.type === "success"){
        this.toastrService.success("Post Updated");
        this.imagesVideosArray=[];
        localStorage.removeItem("updatePost");
        // this.updatePostId = '';
        this.createPostForm.reset();
        this.imagessArray=[];
        this.videoArray = [];
        this.countImagesUploaded=[];
        this.countVideoUploaded=[];
        this.postList=[];
        // this.allPosts=[];
        // this.page=1;
        // this.getPosts(this.page, this.itemperPage);
        this.progress = 0;
        this.disabledCreateShow = false;
        $("#createPostModalBootstrap").hide();
        let index = this.allPosts.findIndex((elm)=>{
          return elm._id == this.updatePostId;
        })

        this.allPosts[index] = res.data;
    document.getElementById('text').classList.remove("removebodyscroll");
    }else{
      this.toastrService.error(res.message);
    }
  })
  // this.route.navigateByUrl('/home');
 }
 clickDeletePost(){
  this.service.deletePost(this.deletePostId).subscribe((res:any)=> {
    if(res.type === 'success'){
      // this.allPostData = [];
      this.modalRef.hide();
      this.postList=[];
      // this.allPosts=[];
      // this.allShows=[];
      // this.toastrService.success(res.message);
      this.toastrService.success(this.deletePostShowMsg.charAt(0).toUpperCase() + this.deletePostShowMsg.slice(1) + " Deleted");
      this.stopScrollingPost = true;
      // this.createShowForm.reset();
      // this.page=1;
      // this.getPosts(this.page, this.itemperPage);
      console.log("here");
      let deleteElemindex = this.allPosts.findIndex((elm)=>{
        return elm._id == this.deletePostId
      })
      this.allPosts.splice(deleteElemindex,1);
    }else{
      this.toastrService.error(res.message);
    }
  },
  ((error)=> {
    this.modalRef.hide();
    this.toastrService.error(error.error.message);
  })
  )
}

newImagesVideosArray = [];
showprogressBar = false;
userCreatePost(){
  this.showprogressBar = true;
  // this.spinners.show();
  // let data = {
  //   "text": this.createPostForm.value.text,
  //   "media":this.imagesVideosArray
  // }
  // this.service.createPost(data).subscribe((res:any) => {
  //   if(res.type == 'success'){
  //     $("#createPostModalBootstrap").hide();
  //     this.createPostForm.reset();
  //     this.toastrService.success(res.message);
  //     this.imagessArray=[];
  //     this.videoArray = [];
  //     this.countImagesUploaded=[];
  //     this.countVideoUploaded=[];
  //     this.getPosts(this.page, this.itemperPage);
  //   }else{
  //     this.toastrService.error(res.message);
  //   }
  // })
  const elm = document.querySelector<HTMLElement>('.showbox');
  elm.style.boxShadow ='';
  elm.style.border = '';
  document.getElementById('text').classList.remove("removebodyscroll");
  $("#createPostModalBootstrap").hide();
  let text = this.createPostForm?.value?.text?.replace(/\<br>/g, '');

  let compltedFlag = 0;
    for (let i = 0;i<this.imagesVideosArray.length;i++) {
      let file = this.imagesVideosArray[i];
      // if (this.imagesVideosArray.length < 10) {
        if (file.type == 'image') {
          let fd = new FormData();
          // fd.append("file", file.file);
          let imageFile = new File([this.dataURItoBlob(file.fileUrl)], "Indietown.png", { type: 'image/png' });

          fd.append("file", imageFile); 
          
          // reader.onload = (rr) => {
            // this.countImagesUploaded.push({ fileUrl: rr.target.result, key: '' });
            // this.imagesVideosArray.push({ fileUrl: rr.target.result, key: '', "type": "image" });
            
              this.service.uploadImage(fd).subscribe((res: any) => {
                console.log("image uploaded");
                this.newImagesVideosArray.push({ fileUrl: res.data.fileUrl, key: res.data.key, "type": "image", mediaWidth: file.mediaWidth, mediaHeight: file.mediaHeight,aspectRatio: this.aspectRatioAll == 1/1 ? 0 : (this.aspectRatioAll == 4/5 ? 1 : (this.aspectRatioAll == 16/9 ? 2 : 0)) });
                this.progress = Math.round(100 * (i+1)/this.imagesVideosArray.length);
                console.log(this.progress);
                compltedFlag++;
                if(compltedFlag == this.imagesVideosArray.length){
                  this.createPostNew();
                }
                // this.countImagesUploaded.push({ fileUrl: res.data.fileUrl, key: res.data.key });
                // this.imagesVideosArray.push({ fileUrl: res.data.fileUrl, key: res.data.key, "type": "image" });
                // if (e.target.files.length == (this.imagesVideosArray.length - initialFilesLength)) {
                //   e.target.value = '';
                // }
              })
            // }
          // }
        }
        else if (file.type == 'video') {
          console.log(file);
          let fd2 = new FormData();
          fd2.append('file', file.file);
          // const t = await this.getVideoDuration(file);
          // console.log(t.duration);
          // if (parseFloat(t?.duration) <= 480) {
            // const reader = new FileReader();
            // reader.readAsDataURL(file);
            // reader.onload = (rr) => {
            //   this.countVideoUploaded.push({ fileUrl: rr.target.result, key: '' });
            //   this.imagesVideosArray.push({ fileUrl: rr.target.result, key: '', "type": "video" });
            // };
            this.service.uploadImage(fd2).subscribe(async (res: any) => {
              console.log('video upload', res);
              if (res.type == 'success') {



                const fileUrlForImage = URL.createObjectURL(file.file);
                const thumbUrl = await this.getThumbnailForVideo(fileUrlForImage);

                let block = thumbUrl.split(";");
                let contentType = block[0].split(":")[1];
                let realData = block[1].split(",")[1];

                // Convert it to a blob to upload
                let blob2 = this.base64toBlob(realData, contentType);
                let fd3 = new FormData();

                let file3 = new File([blob2], 'indie-town.png');
                fd3.append('file', file3);


                
                this.service.uploadImage(fd3).subscribe((res3: any) => {
                  console.log(res3);
                  // debugger;
                  // this.countVideoUploaded.push({ fileUrl: res.data.fileUrl, key: res.data.key });
                  this.newImagesVideosArray.push({ fileUrl: res.data.fileUrl, key: res.data.key, "type": "video", thumbnail: res3.data.fileUrl,mediaWidth: this.aspectRatioAll == 1/1 ? 200 : (this.aspectRatioAll == 4/5 ?  800: (this.aspectRatioAll == 16/9 ? 3200 : 200)),mediaHeight: this.aspectRatioAll == 1/1 ? 200 : (this.aspectRatioAll == 4/5 ? 600 : (this.aspectRatioAll == 16/9 ? 1800 : 200)),aspectRatio: this.aspectRatioAll == 1/1 ? 0 : (this.aspectRatioAll == 4/5 ? 1 : (this.aspectRatioAll == 16/9 ? 2 : 0))});
                  this.progress = Math.round(100 * (i+1)/this.imagesVideosArray.length);
                  compltedFlag++;
                  if(compltedFlag == this.imagesVideosArray.length){
                    this.createPostNew();
                  }
                  // debugger;
                  // if (e.target.files.length == (this.imagesVideosArray.length - initialFilesLength)) {
                  //   e.target.value = '';
                  // }
                });
                
                

                
                //Thumbnail generate and upload  thumbnail

              }
            })
          // } else {
          //   this.toastrService.error('you can upload maximum 8 minutes video file');
          // }
        }
      // }
      // else {
      //   this.toastrService.error('you can upload maximum 10 media files');
      //   e.target.value = '';
      //   break;
      // }
    }

    if(this.imagesVideosArray.length == 0){
      this.createPostNew();
    }

// console.log(text);
  // return
  // if (this.progress == 0) {
  //   this.spinners.show();
  //   let data = {
  //             "text": text,
  //             "media":this.imagesVideosArray
  //           }
  //           // console.log(data);
  //   return new Promise((resolve, reject) => {
  //     this.service.createPost(data).subscribe((event: any) => {
  //       if (event.type == 'success') {
  //         // debugger;
  //         this.createPostForm.reset();
  //         // this.toastrService.success(event.message);
  //         // this.imagessArray=[];
  //         // this.videoArray = [];
  //         // this.countImagesUploaded=[];
  //         // this.countVideoUploaded=[];
  //         this.postList=[];
  //         // this.page=1;
  //         // this.getPosts(this.page, this.itemperPage);
  //         this.progress = 0;
  //         console.log(event.data);
  //         console.log(this.allPosts);
  //         if(this.selectedIndex == 0){
  //           this.allPosts.unshift(event.data);
  //         }
  //         else{
  //           this.allPostsCopy.unshift(event.data);
  //         }
  //         console.log(this.allPosts);
  //         this.toastrService.success("Created post");
  //         this.imagessArray=[];
  //         this.videoArray = [];
  //         this.countImagesUploaded=[];
  //         this.countVideoUploaded=[];
  //         this.imagesVideosArray =[]
  //         this.postList=[];
  //       } else {
  //         this.progress = 0;
  //         this.createPostForm.reset();
  //         // this.toastrService.success(event.message);
  //         this.imagessArray=[];
  //         this.videoArray = [];
  //         this.countImagesUploaded=[];
  //         this.countVideoUploaded=[];
  //         this.imagesVideosArray =[]
  //         this.postList=[];
  //         // this.allPosts = [];
  //         // this.page=1;
  //         // this.getPosts(this.page, this.itemperPage);
  //         this.progress = 0;
  //       }

  //       // switch (event.type) {            
  //       //   case HttpEventType.UploadProgress:
  //       //     this.progress = Math.round(100 * event.loaded / event.total);
  //       //     break;
  //       //   case HttpEventType.Response:
  //       //     if (event.type == 'success') {
  //       //       this.createPostForm.reset();
  //       //       this.toastrService.success(event.message);
  //       //       // this.imagessArray=[];
  //       //       // this.videoArray = [];
  //       //       // this.countImagesUploaded=[];
  //       //       // this.countVideoUploaded=[];
  //       //       this.postList=[];
  //       //       // this.page=1;
  //       //       // this.getPosts(this.page, this.itemperPage);
  //       //       this.progress = 0;
  //       //       console.log(event.data);
  //       //       console.log(this.allPosts);
  //       //       this.allPosts.push(event.data);
  //       //       console.log(this.allPosts);
  //       //       resolve(this.progress);
  //       //     } else {
  //       //       resolve(this.progress);
  //       //       this.progress = 0;
  //       //       reject(this.progress);
  //       //       this.createPostForm.reset();
  //       //       // this.toastrService.success(event.message);
  //       //       this.imagessArray=[];
  //       //       this.videoArray = [];
  //       //       this.countImagesUploaded=[];
  //       //       this.countVideoUploaded=[];
  //       //       this.imagesVideosArray =[]
  //       //       this.postList=[];
  //       //       // this.allPosts = [];
  //       //       // this.page=1;
  //       //       // this.getPosts(this.page, this.itemperPage);
  //       //       this.progress = 0;
  //       //     }
  //       // }
  //       // this.route.navigateByUrl('/home');
        
  //       this.spinners.hide();
  //     })
  //   })
  // }
}

createPostNew(){

  let text = this.createPostForm?.value?.text?.replace(/\<br>/g, '');

  // if (this.progress == 0) {
    this.spinners.show();
    let data = {
      "text": text,
      "media": this.newImagesVideosArray
    }
    return new Promise((resolve, reject) => {
      this.service.createPost(data).subscribe((event: any) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            // this.progress = Math.round(100 * event.loaded / event.total);
            break;
          case HttpEventType.Response:
            if (event.type == 'success') {
              this.createPostForm.reset();
              this.createPostForm.controls.text.setValue('');
              // this.toastrService.info("1234");
              this.toastrService.success(event.message);
              // this.imagessArray=[];
              // this.videoArray = [];
              // this.countImagesUploaded=[];
              // this.countVideoUploaded=[];
              this.postList = [];
              // this.page=1;
              // this.getPosts(this.page, this.itemperPage);
              
              resolve(this.progress);
              this.showprogressBar = false;
              this.progress = 0;
              this.toastrService.success("Created post");
              this.countImagesUploaded = [];
              this.countVideoUploaded = [];
              this.imagesVideosArray = [];
              this.newImagesVideosArray = [];
              this.allPosts.push(event.data);
            } else {
              resolve(this.progress);
              this.progress = 0;
              reject(this.progress);
              this.createPostForm.reset();
              this.createPostForm.controls.text.setValue('');
              // this.toastrService.success(event.message);
              this.imagessArray = [];
              this.videoArray = [];
              this.countImagesUploaded = [];
              this.countVideoUploaded = [];
              this.imagesVideosArray = [];
              this.newImagesVideosArray = [];
              this.postList = [];
              // this.postList = [];
              this.page = 1;
              this.getPosts(this.page, this.itemperPage);
              this.showprogressBar = false;
              this.progress = 0;
            }
        }
        this.showprogressBar = false;
        this.route.navigateByUrl('/home');
        this.toastrService.success("Created post");
        this.countImagesUploaded = [];
        this.countVideoUploaded = [];
        this.imagesVideosArray = [];
        this.newImagesVideosArray = [];
        // this.progress = 0;
        // this.toastrService.info("12345");
        // this.toastrService.success("Created post");
        this.spinners.hide();
      })
    })
  // }
}

base64toBlob(base64Data, contentType) {
  contentType = contentType || '';
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

async getThumbnailForVideo(videoUrl) {
  const video = document.createElement("video");
  const canvas = document.createElement("canvas");
  video.style.display = "none";
  canvas.style.display = "none";

  // Trigger video load
  await new Promise((resolve, reject) => {
    video.addEventListener("loadedmetadata", () => {
      video.width = video.videoWidth;
      video.height = video.videoHeight;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // Seek the video to 25%
      video.currentTime = video.duration * 0.25;
    });
    video.onerror =  () => {
      reject("Invalid video codec. Please select a video file.")
    }
    video.addEventListener("seeked", () => resolve(''));
    video.src = videoUrl;
  });

  // Draw the thumbnailz
  canvas
    .getContext("2d")
    .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  const imageUrl = canvas.toDataURL("image/png");
  console.log(imageUrl);
  // debugger;
  return imageUrl;
}

deleteComment(id:any){
  this.service.deleteComment(id).subscribe((res:any)=>{
    if(res.type=="success"){
      this.toastrService.success(res.message);
    }else{
      this.toastrService.error(res.message);
    }
  })
}

checkEnterPress(e:any){
  e.preventDefault();
  if(e.charCode==13){
    if(this.imagesVideosArray.length>=1 || this.createPostForm.value.text!=''){
      this.userCreatePost();
    }
  }
}

validatePriceRange(e){
  if (e.target.value.length>9) {
    this.createShowForm.controls.ticketTo.setValue(e.target.value.slice(0,9));
    // this.toastrService.error("Price range incorrect", '', { timeOut: 2000 });
  }
  if(!this.createShowForm.controls.ticketFrom.value){
    this.toastrService.error("Price range incorrect",'',{timeOut: 2000});
    e.target.value = '';
    return;
  }
  if(+(e.target.value)<=this.createShowForm.controls.ticketFrom.value){
    this.toastrService.error("Price range incorrect",'',{timeOut: 2000});
  }
}

validatePriceRange1(e) {
  if (e.target.value.length>9) {
    // e.target.value = e.target.value.slice(0,9);
    this.createShowForm.controls.ticketFrom.setValue(e.target.value.slice(0,9));
    // this.toastrService.error("Price range incorrect", '', { timeOut: 2000 });
  }
  if (!this.createShowForm.controls.ticketFrom.value) {
    this.toastrService.error("Price range incorrect", '', { timeOut: 2000 });
    e.target.value = '';
    return;
  }
  if (+(e.target.value) <= this.createShowForm.controls.ticketFrom.value) {
    this.toastrService.error("Price range incorrect", '', { timeOut: 2000 });
  }
  // if (e.target.value.length>9) {
  //   e.target.value = e.target.value.slice(0,9);
  //   // this.toastrService.error("Price range incorrect", '', { timeOut: 2000 });
  // }
}

lat:any;
long:any;


showprogressBarForShow: boolean = false;
showProgress = 0;
async userCreateShow(){
  // let sd = this.createShowForm.value.startDate;
  // let cd = new Date();
  // if(sd<cd){
  //   this.toastrService.error('start time can not be greater than current time');
  //   return
  // }

  if(!this.croppedShowPictureName || !this.croppedShowPicture || this.createShowForm.invalid || this.createShowForm.value.ticketFrom >this.createShowForm.value.ticketTo){
    return
  }
  document.getElementById('text').classList.remove("removebodyscroll");
  const elm = document.querySelector<HTMLElement>('.showbox');
  elm.style.boxShadow ='';
  elm.style.border = '';
  // let startDate = this.createShowForm.value.startDate;
  // let startTime = this.createShowForm.value.startTime;
  // startDate.setHours(startTime.getHours());
  // startDate.setMinutes(startTime.getMinutes());

  // let endDate = this.createShowForm.value.endDate;
  // let endTime = this.createShowForm.value.endTime;
  // endDate.setHours(endTime.getHours());
  // endDate.setMinutes(endTime.getMinutes());


  let from123;
  let to123;
  if(this.createShowForm.controls.startDate?.value){
    console.log(this.createShowForm.controls.startDate?.value);
    from123 = new Date(this.createShowForm.controls.startDate?.value?.toLocaleDateString('en-us') + ' ' + this.createShowForm.controls.startTime?.value?.toLocaleTimeString('en-us'));
  }
  if(this.createShowForm.controls.endDate?.value){
    to123 = new Date(this.createShowForm.controls.endDate?.value?.toLocaleDateString('en-us') + ' ' + this.createShowForm.controls.endTime?.value?.toLocaleTimeString('en-us'));
  }

  $("#exampleModalToggle")?.hide();

  this.showprogressBarForShow = true;
  this.showProgress = 0;

  this.spinner.show();
  await this.uploadShowImageToS3();
  this.spinner.hide();

  let data = {
                  "text":this.createShowForm.value.title,
                  "media":[{
                      "fileUrl": this.createShowForm.value.imageUrl,
                      "key": this.createShowForm.value.imageUrlKey,
                      "type":"image",
                      mediaWidth: this.showImageWidth,
                      mediaHeight: this.showImageHeight,
                      aspectRatio: this.aspectRatio == 1/1 ? 0 : (this.aspectRatio == 4/5 ? 1 : (this.aspectRatio == 16/9 ? 2 : 0))
                  }],
                  "showDate": this.createShowForm.value.startDate,
                  "from": from123,
                  "to": to123,
                  "type":"SHOW",
                  "address":{
                      "venue": (<HTMLInputElement>document.getElementById('pac-input')).value?.split(",")[0],
                      "city": (<HTMLInputElement>document.getElementById('cityNameFetch')).value,
                      "state": (<HTMLInputElement>document.getElementById('stateModal')).value,
                      "country": (<HTMLInputElement>document.getElementById('countryModal')).value
                  },
                  "location":{
                      "lat": this.lat,
                      "long":this.long    
                  },
                  "money":{
                    "currencyCode":"INR",
                    "from":this.createShowForm.value.ticketFrom,
                    "to":this.createShowForm.value.ticketTo
                },
                "venueDetails": this.createShowForm.value.venueDetails
              }

      // this.service.createPost(data).subscribe((res:any) => {
      //   if(res.type == 'success'){
      //     $("#exampleModalToggle").hide();
      //     this.createShowForm.reset();
      //     this.getPosts(this.page, this.itemperPage);
      //     // this.modalRef.hide();
      //     this.toastrService.success(res.message);
      //     this.createShowImageUrl = '../../../../assets/Indietown_icons/upload-cover-pic.png';
      //     this.imageChangedEvent = '';
      //   }else{
      //     this.toastrService.error(res.message);
      //   }
      // })
      
      if (this.progress == 0) {
        this.spinners.show();
        return new Promise((resolve, reject) => {
          this.service.createPost(data).subscribe((event: any) => {
            $("#exampleModalToggle").hide();
            this.createShowForm.reset();
            if (event.type == 'success') {
              this.postList=[];
              // this.toastrService.success(event.message);
              // this.toastrService.success(event.message);
              this.progress = 0;
              this.allShows.unshift(event.data);
              this.toastrService.success("Created Show");
              this.createShowImageUrl = '../../../../assets/Indietown_icons/upload-cover-pic.png';
              this.imageChangedEvent = '';
            } else {
              this.progress = 0;
              this.postList=[];
              // this.allPosts=[];
              this.createShowForm.reset();
              // this.page=1;
              // this.getPosts(this.page, this.itemperPage);
              // this.modalRef.hide();
              // this.toastrService.success(event.message);
              this.createShowImageUrl = '../../../../assets/Indietown_icons/upload-cover-pic.png';
              this.imageChangedEvent = '';
              // resolve(this.progress);
              // reject(this.progress);
            }

            this.showProgress = 100;
            this.toastrService.success("Created Show");
            this.spinners.hide();
            this.route.navigateByUrl('/home');
            this.showprogressBarForShow = false;
            this.showProgress = 0;

            this.createPostForm.reset();
            this.resetImageCropper();
            this.imagessArray=[];
            this.videoArray = [];
            this.countImagesUploaded=[];
            this.countVideoUploaded=[];
            this.imagesVideosArray =[];
            (<HTMLInputElement>document.getElementById('imageView')).src = '../../../../assets/Indietown_icons/camera.png';
            // this.postList=[];
            // switch (event.type) {            
            //   case HttpEventType.UploadProgress:
            //     this.progress = Math.round(100 * event.loaded / event.total);
            //     break;
            //   case HttpEventType.Response:
            //     if (event.type == 'success') {
            //       this.postList=[];
            //       this.toastrService.success(event.message);
            //       this.toastrService.success(event.message);
            //       this.progress = 0;
            //       this.allPosts.push(event.data);
            //       resolve(this.progress);
            //     } else {
            //       this.progress = 0;
            //       this.postList=[];
            //       this.allPosts=[];
            //       this.createShowForm.reset();
            //       this.page=1;
            //       this.getPosts(this.page, this.itemperPage);
            //       // this.modalRef.hide();
            //       // this.toastrService.success(event.message);
            //       this.createShowImageUrl = '../../../../assets/Indietown_icons/upload-cover-pic.png';
            //       this.imageChangedEvent = '';
            //       resolve(this.progress);
            //       reject(this.progress);
            //     }
            // }
            this.spinners.hide();
          })
        })
      }
}

showImageWidth: any;
  showImageHeight: any;
  uploadShowImageToS3(){

    return new Promise((resolve,reject) => {

      try{
        let fd = new FormData();
        const imageFile = new File([this.dataURItoBlob(this.croppedShowPicture.base64)], this.croppedShowPictureName, { type: 'image/png' });
        fd.append("file", imageFile);
        this.service.uploadImage(fd).subscribe((res: any) => {

          this.createShowForm.controls["imageUrl"].setValue(res.data.fileUrl);
          this.createShowForm.controls["imageUrlKey"].setValue(res.data.key);
          this.showProgress = 50;
          this.showImageWidth = this.croppedShowPicture.width;
          this.showImageHeight = this.croppedShowPicture.height;
          this.croppedShowPicture = null;
          this.croppedShowPictureName = null;
          resolve({fileUrl : res.data.fileUrl, key : res.data.key})


          // this.imageChangedEvent = e;
          // this.createShowImageUrl = res.data.fileUrl;
          // this.CreateShowCropModalRef.hide();
          // document.getElementById('craeteshowplaceholder').style.borderRadius = "15px";
          // this.createShowForm.controls["imageUrl"].setValue(res.data.fileUrl);
          // this.createShowForm.controls["imageUrlKey"].setValue(res.data.key);
          // (<HTMLInputElement>document.getElementById('imageView')).src = '../../../../assets/Indietown_icons/edit-icon2.svg';
          // this.resetImageCropper();
        });
      }
      catch(e){
        reject("some error occured");
      }
      
    });
   
  }


changeToNumber(data:any){
  return parseFloat(data);
}

updateShow(){
  if(this.createShowForm.invalid){
    this.toastrService.error('All Fields required');
    return
  }
  this.updateShowButton = false;

      let data = {
                      "text":this.createShowForm.value.title,
                      "media":[{
                          "fileUrl": this.createShowForm.value.imageUrl,
                          "key": this.createShowForm.value.imageUrlKey,
                          "type":"image"
                      }],
                      "showDate": this.createShowForm.value.startDate,
                      // "from": this.createShowForm.value.startTime,
                      // "to": this.createShowForm.value.endTime,
                      "from": new Date(new Date(this.createShowForm.controls.startDate.value).toLocaleDateString('en-us') + ' ' + new Date(this.createShowForm.controls.startTime.value).toLocaleTimeString('en-us')),
                      "to": new Date(new Date(this.createShowForm.controls.endDate.value).toLocaleDateString('en-us') + ' ' + new Date(this.createShowForm.controls.endTime.value).toLocaleTimeString('en-us')),
                      "type":"SHOW",
                      "address":{
                          "venue": (<HTMLInputElement>document.getElementById('pac-input')).value.split(",")[0],
                          "city": (<HTMLInputElement>document.getElementById('cityNameFetch')).value,
                          "state": (<HTMLInputElement>document.getElementById('stateModal')).value,
                          "country": (<HTMLInputElement>document.getElementById('countryModal')).value
                      },
                      "location":{
                          "lat": this.lat,
                          "long":this.long    
                      },
                      "money":{
                        "currencyCode":"INR",
                        "from":this.createShowForm.value.ticketFrom,
                        "to":this.createShowForm.value.ticketTo
                    },
                    "venueDetails": this.createShowForm.value.venueDetails
                  }
         
document.getElementById('text').classList.remove("removebodyscroll");
          // if (this.progress == 0) {
            // this.spinners.show();
            this.minEndDate = new Date();
            localStorage.removeItem('updatePost');
            // return new Promise((resolve, reject) => {
              this.service.updatePost(this.updatePostId,data).subscribe((event: any) => {
                $("#exampleModalToggle").hide();
                if(event.type == 'success'){
                  // this.postList=[];
                  this.createShowImageUrl = '../../../../assets/Indietown_icons/upload-cover-pic.png';
                  this.disabledCreateShow=false;
                  this.toastrService.success("Updated Show");
                  // this.allPosts=[];
                  // this.page=1;
                  // this.getPosts(this.page, this.itemperPage);
                  this.createShowForm.reset();
                  // this.allPosts.push(event.data);
                  let index = this.allShows.findIndex((elm)=>{
                    return elm._id == this.updatePostId;
                  })

                  this.allShows[index] = event.data;
                }
                this.resetImageCropper();
                (<HTMLInputElement>document.getElementById('imageView')).src = '../../../../assets/Indietown_icons/camera.png';
              })
          // }
          // this.toastrService.success("Successfully Updated Show");
          // this.spinners.hide();
          // this.route.navigateByUrl('/home');
 }


 latitude:any;
longitude:any;

 initAutocomplete() {
  const map = new google.maps.Map(
    document.getElementById("mapmk1234") as HTMLElement,
    {
      center: { lat: this.latitude ? parseFloat(this.latitude): 28.7041, lng: this.longitude ? parseFloat(this.longitude): 77.1025 },
      zoom: 13,
      mapTypeId: "roadmap",
    }
  );


  // Create the search box and link it to the UI element.
  const input = document.getElementById("pac-input") as HTMLInputElement;
  // const searchBox = new google.maps.places.SearchBox(input);

  const searchBox = new google.maps.places.Autocomplete(input,{
    types:['establishment']
  });

  map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

  // Bias the SearchBox results towards current map's viewport.
  map.addListener("bounds_changed", () => {
    searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
  });

  
  // Listen for the event fired when the user selects a prediction and retrieve
  // more details for that place.
  searchBox.addListener("place_changed", () => {
    const place = searchBox.getPlace();

    // if (places.length == 0) {
    //   return;
    // }

    if(!Object.keys(place)?.length){
      return;
    }

    // For each place, get the icon, name and location.
    const bounds = new google.maps.LatLngBounds();

    // places.forEach((place) => {
      if (!place.geometry || !place.geometry.location) {
        console.log("Returned place contains no geometry");
        return;
      }

      const icon = {
        url: place.icon as string,
        size: new google.maps.Size(71, 71),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(25, 25)
      };

      this.lat = place.geometry.location.lat();
      this.long = place.geometry.location.lng();

      this.codeLatLng(place.geometry.location.lat(), place.geometry.location.lng());
      let data = (<HTMLInputElement>document.getElementById('pac-input')).value;
      (<HTMLInputElement>document.getElementById('pac-input')).value = data.split(",")[0];
      // this.updateProfile.get('lat').patchValue(place.geometry.location.lat());
      // this.updateProfile.get('long').patchValue(place.geometry.location.lng());
      // this.codeLatLng(place.geometry.location.lat(),place.geometry.location.lng());

        
      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    // });
    map.fitBounds(bounds);      
  });

}


codeLatLng(lat, lng) {
  let geocoder = new google.maps.Geocoder();
  var latlng = new google.maps.LatLng(lat, lng);
  geocoder.geocode({location:latlng}, function(results, status) {
    if (status == google.maps.GeocoderStatus.OK) {
      if (results[1]) {
      let a = [];      
      a = results[0].formatted_address.split(",");
      for (i = 0; i < results[0].address_components.length; i++) {
      let len = results.length;        
      (<HTMLInputElement>document.getElementById('countryModal')).value=results[len-1].formatted_address;
        if (results[0].address_components[i].types[0] == 'postal_code')
            // alert("Zip Code: " + results[0].address_components[i].short_name);
          (<HTMLInputElement>document.getElementById('abcd')).value=results[0].address_components[i].short_name;
          
          console.log("*****************************",results[0].address_components[3].long_name);
          (<HTMLInputElement>document.getElementById('cityNameFetch')).value=results[0].address_components[3].long_name;
        }

           for (var i=0; i<results[0].address_components.length; i++) {
          for (var b=0;b<results[0].address_components[i].types.length;b++) {

          //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
              if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
                  // this is the object you are looking for
                  let a = results[0].address_components[i];
                  (<HTMLInputElement>document.getElementById('stateModal')).value=a.long_name;
                  let city_name = (<HTMLInputElement>document.getElementById('pac-input')).value;
                  let cityArray = city_name.split(',');
                  
                  // (<HTMLInputElement>document.getElementById('cityNameFetch')).value=cityArray[cityArray.length-3];
                  break;
              }
          }
      }
      } else {
        alert("No results found");
      }
    } else {
    }
  });
}


removeVideo(index:any,key:any){
  this.countVideoUploaded.splice(index, 1);
  // this.imagesVideosArray = this.imagesVideosArray.filter(function(data:any){
  //   return data.key!=key;
  // })

  let ind = this.imagesVideosArray.findIndex( (data: any) => {
    return data.key == key;
  })

  if(ind != -1){
    this.imagesVideosArray.splice(ind,1);
  }
}

removeImage(index:any,key:any){
  this.countImagesUploaded.splice(index, 1);
  // this.imagesVideosArray = this.imagesVideosArray.filter(function(data:any){
  //   return data.key!=key;
  // })   
  
  let ind = this.imagesVideosArray.findIndex( (data: any) => {
    return data.key == key;
  })

  if(ind != -1){
    this.imagesVideosArray.splice(ind,1);
  }
}

opneCreateShowModal(){
  console.log(1);
  if(this.disabledCreateShow == false){
    console.log(2);
    $("#createPostModalBootstrap").hide();
    $("#exampleModalToggle").show();
    // $("#createPostModal").remove();
  document.getElementById('text').classList.add("removebodyscroll");
  this.editshoww=false;
  console.log(4);
  this.initAutocomplete();
  }
    // this.modalRef.hide();
    // this.openModal(this.createShow);
}
imageChangedEvent: any = '';
  croppedImage: any = '';
uploadShowImage(e:any){
  let files = e.target.files;
  let file = e.target.files[0];
  let fd = new FormData();
  fd.append("file", file);
  if (e.target.files && e.target.files.length) {
    for (const file of e.target.files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
            this.service.uploadImage(fd).subscribe((res:any)=>{
              this.imageChangedEvent = e;
              this.createShowImageUrl = res.data.fileUrl;

              document.getElementById('craeteshowplaceholder').style.borderRadius="15px";
              this.createShowForm.controls["imageUrl"].setValue(res.data.fileUrl);
              this.createShowForm.controls["imageUrlKey"].setValue(res.data.key);
              (<HTMLInputElement>document.getElementById('imageView')).src = '../../../../assets/Indietown_icons/edit-icon2.svg';
            })
        }
      }
    }
  }
}


removespacess(e:any,type){
  if(type==1){
    let data = e.srcElement.innerText.trim();
    if(data==''){
        this.btndisablespace=true; 
    }else{
      this.btndisablespace=false;
    }
  }else{
    console.log("show",e.target.value)
    if(e.target.value.trim()==""){  
      this.btndisablespace=true;
    }
    else{
      this.btndisablespace=false;
    }
  }
}  

backButtonClick(){
  // this.modalRef.hide();
  
  this.updateShowButton = false;
  this.createShowForm.reset();
  this.disabledCreateShow = false;
  this.createShowImageUrl = '../../../../assets/Indietown_icons/upload-cover-pic.png';
  (<HTMLInputElement>document.getElementById('uploadshowImg')).value='';
  (<HTMLInputElement>document.getElementById('imageView')).src = '../../../../assets/Indietown_icons/camera.png';
  $("#exampleModalToggle").hide();
  $("#createPostModalBootstrap").show();
  // this.openModal(this.createPost);
}

hideshowpopup(){
  this.minEndDate = new Date();
  localStorage.removeItem('updatePost');
  $("#exampleModalToggle").hide();
  document.getElementById('text').classList.remove("removebodyscroll");
  this.updateShowButton = false;
  this.createShowForm.reset();
  this.disabledCreateShow = false;
  this.createShowImageUrl = '../../../../assets/Indietown_icons/upload-cover-pic.png';
  (<HTMLInputElement>document.getElementById('uploadshowImg')).value='';
  (<HTMLInputElement>document.getElementById('imageView')).src = '../../../../assets/Indietown_icons/camera.png';
  // this.route.navigateByUrl('/home');
}

getTimeWeekWise(previousDate){
  // let previousDate = '2023-03-31T10:20:53.182Z';
  let currentDate = new Date().getTime();
  let previous = new Date(previousDate).getTime();
  let miliseconds = currentDate-previous;

  var days:any    = Math.floor(miliseconds / (1000 * 60 * 60 * 24));
  var hours:any   = Math.floor((miliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes:any = Math.floor((miliseconds % (1000 * 60 * 60)) / (1000 * 60));
  var seconds:any = Math.floor((miliseconds % (1000 * 60)) / 1000);

  // console.log(`mili seconds: ${miliseconds}`);
  // console.log(`Days: ${days}`);
  if(days){
    let msg ;
    if(days==1){
      return `${days} day ago`;
    }else if(days>1 && days<=6){ 
      return `${days} days ago`;
    }else if(days >=7){
      let week:any = days/7;
      let we = parseInt(week);
      if(we==1){
        return `${we} week ago`;
      }else{
        return `${we} weeks ago`;
      }
    }      
  }else if(hours){
    if(hours>1){
      return `${hours} hours`;
    }
    else{
      return `${hours} hour`;
    }
    
  }else if(minutes){
    if(minutes>1){
      return `${minutes} minutes`;
    }
    else{
      return `${minutes} minute`;
    }
  }else{
    return `now`;
  }
}

deletePostComment(){
  // this.service.deleteComment(id).subscribe((res:any)=>{
  //   if(res.type=="success"){
  //     this.toastrService.success(res.message);
  //     // this.allPostData = [];
  //     this.postList=[];
  //     this.page=1;
  //     this.getPosts(this.page,this.itemperPage);
  //   }else{
  //     this.toastrService.error(res.message);
  //   }
  // })   

  this.deleteCommentModalRef?.hide();
  let postIndex = this.deleteCommentPostIndex;
  let commentIndex = this.deleteCommentIndex;

  this.service.deleteComment(this.allPosts[postIndex]["comments"][commentIndex]._id).subscribe((res:any)=>{
    if(res.type=="success"){
      // this.toastrService.success(res.message);
      this.toastrService.success("Comment Deleted");
      // debugger;

      var totalRepliedCommentCount = this.allPosts[postIndex]["comments"][commentIndex].replies.length + 1
      if(this.allPosts[postIndex]["comments"][commentIndex].replies.length==0){
        this.allPosts[postIndex].commentCount--;
      }else{
        console.log(this.allPosts[postIndex].commentCount, totalRepliedCommentCount)
        this.allPosts[postIndex].commentCount = this.allPosts[postIndex].commentCount - totalRepliedCommentCount

      }

      console.log(this.allPosts[postIndex]["comments"][commentIndex]);
      console.log(this.allPosts[postIndex]["comments"]);
      this.allPosts[postIndex]["comments"].splice(commentIndex,1);
      this.allPosts[postIndex].commentsCount--;
      console.log(this.allPosts[postIndex]["comments"]);
      // this.allPostData = [];
      // this.postList=[];
      // this.page=1;
      // this.getPosts(this.page,this.itemperPage);
    }else{
      this.toastrService.error(res.message);
    }
  })   
}

commentbandname:any;
replyPostCommentEnable(postIndex,comment:any,reply){
  // (<HTMLInputElement>document.getElementById('postCommentReply'+id)).style.display='block';

  console.log(postIndex);
  console.log(comment);
  console.log(reply);
  if(reply){
    this.commentbandname = reply.userId.bandName;
    (<HTMLInputElement>document.getElementById('addcommnt'+this.allPosts[postIndex]._id)).focus();
    this.allPosts[postIndex]["commentFieldStatus"] = 'reply';
    this.allPosts[postIndex]["replyReplyId"] = reply.userId?._id;
    (<HTMLInputElement>document.getElementById('replyCrossMark'+this.allPosts[postIndex]._id)).style.display = "block";
    (<HTMLInputElement>document.getElementById('addcommnt'+this.allPosts[postIndex]._id)).value = `@${reply.userId.bandName} `;
    this.mentionReplyingTo = [reply.userId];
  }
  else{   
    this.commentbandname = comment.userId.bandName;
    (<HTMLInputElement>document.getElementById('addcommnt'+this.allPosts[postIndex]._id)).focus();
    this.allPosts[postIndex]["commentFieldStatus"] = 'reply';
    this.allPosts[postIndex]["replyCommentId"] = comment._id;
    (<HTMLInputElement>document.getElementById('replyCrossMark'+this.allPosts[postIndex]._id)).style.display = "block";
    (<HTMLInputElement>document.getElementById('addcommnt'+this.allPosts[postIndex]._id)).value = `@${comment.userId.bandName} `;
    this.mentionReplyingTo = [comment.userId];
  }
  
}

reportReplyId:any;
openReportCommentModel(commentId:any,postId,replyId){
  // this.reportPostModel = true;
  // this.openModal(this.reportPostModal);
  this.modalRef = this.modalService.show(this.reportPostModal, {class : 'reportcommentdynamic',backdrop: 'static'});

  // this.reportPostModel = false;
  // this.reportCommentModel = true;
  // this.reportCommentId = commentId;
  // this.reportCommentPostId = postId;   
  this.reportPostModel = false;
    this.reportCommentModel = true;
    this.reportCommentId = commentId;
    this.reportCommentPostId = postId;
    this.reportReplyId = replyId; 
}

deletePostCommentReply(){

  this.deleteCommentReplyModalRef?.hide();
  let postIndex = this.deleteCommentPostIndex;
  let commentIndex = this.deleteCommentIndex;
  let replyIndex = this.deleteCommentReplyIndex;

  this.service.deleteReply(this.allPosts[postIndex]._id,this.allPosts[postIndex]["comments"][commentIndex]['replies'][replyIndex]._id).subscribe((res:any)=>{
    if(res.type=="success"){
      // this.toastrService.success(res.message);
      this.allPosts[postIndex].commentCount--;
      this.toastrService.success("Reply Deleted");
      this.allPosts[postIndex]["comments"][commentIndex]["replies"].splice(replyIndex,1);
      this.allPosts[postIndex]["comments"][commentIndex].totalRepliesCount--;
      // this.allPostData = [];
      // this.postList=[];
      // this.page=1;
      // this.getPosts(this.page,this.itemperPage);
    }else{
      this.toastrService.error(res.message);
    }
  })
}

loadMoreReplies(postIndex,commentIndex){
  console.log(postIndex);
  console.log(commentIndex);
  let comment = this.allPosts[postIndex].comments[commentIndex];


  if(comment.replyPageNumber)
    comment.replyPageNumber++ ;
  else
    comment.replyPageNumber = 1;
  this.service.getRepliesOfComment(comment._id,comment.replyPageNumber,10).subscribe((res:any)=>{
    if(comment.replies.length <= 3)
      this.allPosts[postIndex].comments[commentIndex].replies = res.data;
    else
      this.allPosts[postIndex].comments[commentIndex].replies.push(...res.data);
  })
}
hideReplies(postIndex,commentIndex){
  let comment = this.allPosts[postIndex].comments[commentIndex];
  (<HTMLInputElement>document.getElementById('hideunderline'+comment._id)).style.display='block';
  (<HTMLInputElement>document.getElementById('repliesBox'+comment._id)).style.display='none';
  // (<HTMLInputElement>document.getElementById('hideunderline'+comment._id)).style.display='none';
  (<HTMLInputElement>document.getElementById('showRepliesBtn'+comment._id)).style.display='block';
  // comment["replyPageNumber"] = 0;
  // comment.replies = [];
}

replyPostCommentClick(postIndex,commentId:any,postId:any){
    // let elms = (<HTMLCollectionOf<Element>>document.getElementsByClassName('replyBox'));
    // $('.reply-box').hide();
    // debugger;
    let replyText = (<HTMLInputElement>document.getElementById('addcommnt'+postId)).value;

    // if(replyText!='')
      // alert(`Comment Id - ${commentId} Post Id: ${postId}`);
    if(replyText!=''){
      let data = {postId,commentId,"text":replyText};

      if(this.allPosts[postIndex]["replyReplyId"]){
        data['isReplyToReply'] = true;
        data['replyUserId'] = this.allPosts[postIndex]["replyReplyId"];
      }

      if(this.mentionsAdded){
        data['mentions'] = [];
        this.mentionsAdded.forEach(mention => {
          data['mentions'].push({
            "userId" : mention.choice._id,
            "name" : mention.choice.bandName,
            "key" : "@"+mention.choice.bandName,
            "startIndex" : mention.indices?.start,
            "endIndex" : mention.indices?.end
          })
        });
      }
      // this.mentionsAdded ? data['mentions'] = this.mentionsAdded : data ;
      this.service.replyToPostComment(data).subscribe((res:any)=>{
        this.createCommentForm.reset();
        this.mentionsAdded = [];
        let obj = this.allPosts.find(function(obj){
          return obj._id == postId;
        })
        obj.commentFieldStatus = 'comment';
        delete obj.replyCommentId;
        (<HTMLInputElement>document.getElementById('replyCrossMark'+postId)).style.display = "none";
        (<HTMLInputElement>document.getElementById('addcommnt'+postId)).value = '';
        this.mentionReplyingTo = [];
        if(res.type == 'success'){
          obj.commentCount++;
          this.toastrService.success("you replied to comment");
          let commentObj = obj.comments.find((comment)=>{
            return comment._id == commentId
          });
          // commentObj.replies.unshift(
          //   {
          //     "text":replyText,
          //     "mentions":[],
          //     "_id":"641d70cbd49fa109d8e72449",
          //     "createdOn":"2023-03-24T09:43:39.876Z",
          //     "reports":[]
          //   }
          // )
          commentObj.replies.unshift(res.data);
          commentObj.totalRepliesCount ? commentObj.totalRepliesCount++ : commentObj.totalRepliesCount = 1;
        }
      })
    } 
}

showReplies(comment){
  (<HTMLInputElement>document.getElementById('repliesBox'+comment._id)).style.display='block';
  (<HTMLInputElement>document.getElementById('showRepliesBtn'+comment._id)).style.display='none';
  (<HTMLInputElement>document.getElementById('hideunderline'+comment._id)).style.display='none';
  
  console.log(comment);
}

commentsListData:any=[];
openModalPostId:any;

loadMoreComments(id:any){
  this.openModalPostId = id;
  // this.commentsListData = [];
  this.commentPostId = id;
  // this.openModal(this.commentsListModel);
  this.getCommentsList(id);
  return
}

showLessComments(postIndex){
  this.allPosts[postIndex]['comments'] = this.allPosts[postIndex]['comments'].slice(0,3);
  this.allPosts[postIndex].pageNumber = 0;
  console.log(this.allPosts[postIndex]['comments'].length);
}

changeTime(time:any){
  var dt = new Date(time);
  var hours:any = dt.getHours() ; // gives the value in 24 hours format
  var AmOrPm = hours >= 12 ? 'PM' : 'AM';
  hours = (hours % 12) || 12;
  if(hours<10){
    hours= `0${hours}`;
  }
  var minutes:any = dt.getMinutes() ;
  if(minutes<10){
    minutes= `0${minutes}`;
  }
  var finalTime = hours + ":" + minutes + " " + AmOrPm; 
  finalTime // final time Time - 22:10
  return finalTime;
}

showMoreDesc(id:any){
  (<HTMLInputElement>document.getElementById('lessDesc'+id)).style.display = "none";
  (<HTMLInputElement>document.getElementById('moreBtn'+id)).style.display = "none";
  (<HTMLInputElement>document.getElementById('moreBtn'+id)).style.display = "none";
  (<HTMLInputElement>document.getElementById('moreDataDesc'+id)).style.display = "inline";
  (<HTMLInputElement>document.getElementById('lessBtn'+id)).style.display = "inline";
}

showLessDesc(id:any){
  (<HTMLInputElement>document.getElementById('lessDesc'+id)).style.display = "inline";
  (<HTMLInputElement>document.getElementById('moreBtn'+id)).style.display = "inline";
  (<HTMLInputElement>document.getElementById('moreBtn'+id)).style.display = "inline";
  (<HTMLInputElement>document.getElementById('moreDataDesc'+id)).style.display = "none";
  (<HTMLInputElement>document.getElementById('lessBtn'+id)).style.display = "none";
}

getCommentsList(id:any){
  // this.service.getCommentsList(id,1,1000).subscribe((res:any)=>{
  //   this.commentsListData =res.data;
  // })
  let obj = this.allPosts.find(function(obj){
    return obj._id == id;
  })
  if(obj.pageNumber)
    obj.pageNumber++ ;
  else
    obj.pageNumber = 1;
  this.service.getCommentsList(id,obj.pageNumber,10).subscribe((res:any)=>{
    this.commentsListData = res.data;
    // debugger;
    if(obj.comments.length <= 3)
      obj.comments = res.data;
    else
      obj.comments.push(...res.data);
  })
}

openCreatePost(){
  if(this.postId){
    $("#createCommentOnFriendsTimeline").show();
    document.getElementById('text').classList.add("removebodyscroll");
  }
  else{
    this.updatePostButton = false;
    document.getElementById('text').classList.add("removebodyscroll");
    const elm = document.querySelector<HTMLElement>('.showbox');
    elm.style.border = '1px solid #0F0D0F';
    elm.style.boxShadow ='0 0 0px 1000px white inset';
    // document.getElementsByClassName('showbox').style.border="";
    $("#createPostModalBootstrap").show();
  }
  
    // this.openModal(this.createPost);
}

hideTimelineComment(){
  $("#createCommentOnFriendsTimeline").hide();
  document.getElementById('text').classList.remove("removebodyscroll");
  
  this.commentOnFriendsTimelineForm.reset();
}

commentOnFriendsTimelineForm = new FormGroup({
  comment: new FormControl('', [Validators.required])
})


createCommentOnFriendTimeline(){
  let text = this.commentOnFriendsTimelineForm.value.comment.trim();
  if(text!=''){
    // this.reportPostSubmit = false;
    let type;
    console.log(this.selectedIndex);
    if(this.selectedIndex == 1){
      type = "SHOW";
    }
    let data = {
      "text": text,
      "createdFor":this.postId,
      type
    }
    this.service.commentOnFriendsTimeline(data).subscribe((res:any) => {
      if(res.type == 'success'){
        this.hideTimelineComment();
        // this.getPostsId(this.postId,this.postsPageNumber,this.postsPageSize);
        if(this.selectedIndex == 0){
          this.allPostsCopy.unshift(res?.data);
          // this.allPosts.unshift(res?.data);
        }
        else if(this.selectedIndex == 1){
          this.allShows.unshift(res?.data);
        }
        this.commentOnFriendsTimelineForm.reset();
      }else{
        this.toastrService.error(res.message);
      }
    })
  }
}

videoArray = [];
cancelPostModal(){
  localStorage.removeItem("updatePost");
  this.disabledCreateShow = false;
  const elm = document.querySelector<HTMLElement>('.showbox');
  elm.style.boxShadow ='';
  elm.style.border = '';
  document.getElementById('text').classList.remove("removebodyscroll");
  $("#createPostModalBootstrap").hide();
  this.imagessArray=[];
  this.countImagesUploaded=[];
  this.countVideoUploaded=[];
  this.imagesVideosArray=[];
  this.videoArray=[];
  this.createPostForm.reset();
  // this.route.navigateByUrl('/home');
  
  
  
}

cancelShowModal(){
  // localStorage.removeItem("updateShow");
  // this.disabledCreateShow = false;
  // const elm = document.querySelector<HTMLElement>('.showbox');
  // elm.style.boxShadow ='';
  // elm.style.border = '';
  // document.getElementById('text').classList.remove("removebodyscroll");
  $("#exampleModalToggle").hide();
  this.createShowForm.reset();
  // this.route.navigateByUrl('/home');
  
  
  
}

btndisablespace:Boolean;
editorConfig: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: '122px',
  minHeight: '0',
  maxHeight: 'auto',
  width: 'auto',
  minWidth: '0',
  translate: 'yes',
  enableToolbar: false,
  showToolbar: false,
  // placeholder: 'Enter text here...',
  defaultParagraphSeparator: '',
  defaultFontName: '',
  defaultFontSize: '',
  fonts: [
    { class: 'arial', name: 'Arial' },
    { class: 'times-new-roman', name: 'Times New Roman' },
    { class: 'calibri', name: 'Calibri' },
    { class: 'comic-sans-ms', name: 'Comic Sans MS' }
  ],
  customClasses: [
    {
      name: 'quote',
      class: 'quote',
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: 'titleText',
      class: 'titleText',
      tag: 'span',
    },
  ],
  uploadUrl: 'v1/image',
  uploadWithCredentials: false,
  sanitize: true,
  toolbarPosition: 'top',
  toolbarHiddenButtons: [
    ['bold', 'italic'],
    ['fontSize']
  ]
}

invalidVideoCodecPopupRef;
unSupportedVideoDetailsPopupOpened: boolean = false;
async onChangeimg1(e: any) {
  // console.log(e.target.files.length);
  // console.log(this.imagesVideosArray.length);
  if(this.imagesVideosArray.length<10 && (e?.target?.files?.length+this.imagesVideosArray.length)<=10 ){
    if (e.target.files && e.target.files.length) {
      let initialFilesLength = this.imagesVideosArray.length;
      for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i];
        if(this.imagesVideosArray.length<10){
          let index = this.imagesVideosArray.findIndex((media: any) => {
            return media.key == file.name;
          })
          if(index == -1){
            if(file.type.includes('image/')){
              let fd = new FormData();
              fd.append("file", file);
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = async(rr) => {
  
                // ================OLD====================
                // this.countImagesUploaded.push({ fileUrl: rr.target.result, key: file.name });
                //   this.imagesVideosArray.push({ fileUrl: rr.target.result, key: file.name, "type": "image", file });
                //     if ((i+1) == e.target.files.length) {
                //       e.target.value = '';
                //     }
                // =========================OLD END============================
  
                // ===================NEW=====================
                let obj: any = await this.crop(reader.result as string,1);
                this.countImagesUploaded.push({ fileUrl: obj.resolve, key: file.name, file });
                this.imagesVideosArray.push({ fileUrl: obj.resolve, key: file.name, "type": "image", file, mediaWidth: obj.mediaWidth, mediaHeight: obj.mediaHeight });
                if ((i+1) == e.target.files.length) {
                  e.target.value = '';
                  // this.createPostEditImagesCropModalRef = this.modalService.show(this.CreatePostImagesCropModal,{class : "Create-Post-Image-Crop-Modal"});
                }
                // ==================NEW END=======================
  
  
  
                // const img = new Image();
                // img.src = reader.result as string;
                // img.onload = () => {
                //   this.service.uploadImage(fd).subscribe((res:any)=>{
                //       this.countImagesUploaded.push({fileUrl:res.data.fileUrl,key:res.data.key});
                //       this.imagesVideosArray.push({fileUrl:res.data.fileUrl,key:res.data.key,"type":"image"});
                //       if(e.target.files.length == (this.imagesVideosArray.length-initialFilesLength)){
                //         e.target.value = '';
                //       }
                //   })
                // }
              }
            }
            else if(file.type.includes('video/')){
              console.log(file);
              let fd2 = new FormData();
              fd2.append('file', file);
              // const t = await this.getVideoDuration(file);
              let t = null;
              let thumbUrl = null;
              try{
                t = await this.getVideoDuration(URL.createObjectURL(file));
                thumbUrl = await this.getThumbnailForVideo(URL.createObjectURL(file));
                if(!thumbUrl || thumbUrl == 'data:,'){
                  // this.toastrService.error(`Invalid video."${file?.name}"`);
                  throw new Error(`Invalid video."${file?.name}"`);
                }
              }
              catch(error){
                console.log(error);
                // this.toastrService.error(`Invalid video."${file?.name}"`,'',{timeOut:3000});
                this.unSupportedVideoDetails.push(file);
                if(!this.unSupportedVideoDetailsPopupOpened){
                  this.invalidVideoCodecPopupRef = this.modalService.show(this.invalidVideoCodecPopup);
                  this.unSupportedVideoDetailsPopupOpened = true;
                }
                if ((i+1) == e.target?.files?.length) {
                  e.target.value = '';
                }
                continue;
              }
              console.log(t.duration);
              if(parseFloat(t?.duration)<=480){
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (rr) => {
                  this.countVideoUploaded.push({ fileUrl: rr.target.result, key: file.name,fileNew : this._sanitizer.bypassSecurityTrustResourceUrl((window.URL || window.webkitURL).createObjectURL(file)) });
                  this.imagesVideosArray.push({ fileUrl: rr.target.result, key: file.name, "type": "video", file });
                  if ((i+1) == e.target.files.length) {
                    e.target.value = '';
                  }
                };
                // this.service.uploadImage(fd2).subscribe((res: any) => {
                //   console.log('video upload', res);
                //   if (res.type == 'success') {
                //     this.countVideoUploaded.push({fileUrl:res.data.fileUrl,key:res.data.key});
                //     this.imagesVideosArray.push({fileUrl:res.data.fileUrl,key:res.data.key,"type":"video"});
                //     if(e.target.files.length == (this.imagesVideosArray.length-initialFilesLength)){
                //       e.target.value = '';
                //     }
                //   }
                // })
              }else{
                this.toastrService.error('you can upload maximum 8 minutes video file');
              }
            }
          } else {
            // you cannot upload same media file again.please rename the duplicate file
            this.toastrService.error('Duplicate file detected. Please rename the file and try uploading again.');
            if ((i+1) == e.target.files.length) {
              e.target.value = '';
              // this.createPostEditImagesCropModalRef = this.modalService.show(this.CreatePostImagesCropModal,{class : "Create-Post-Image-Crop-Modal"});
            }
            continue;
          }
          
        }
        else{
          this.toastrService.error('you can upload maximum 10 media files');
          e.target.value = '';
          break;
        }
      }
    }
  }
  else{
    this.toastrService.error('you can upload maximum 10 media files');
    e.target.value = '';
  }
}

inputVideoWith = 200;
inputVideoHeight = 200;
crop(url, aspectRatio) {
  // we return a Promise that gets resolved with our canvas element
  return new Promise((resolve) => {
      // this image will hold our source image data
      const inputImage = new Image();

      // we want to wait for our image to load
      inputImage.onload = () => {
          // let's store the width and height of our image
          const inputWidth = inputImage.naturalWidth;
          const inputHeight = inputImage.naturalHeight;

          // get the aspect ratio of the input image
          const inputImageAspectRatio = inputWidth / inputHeight;

          // if it's bigger than our target aspect ratio
          let outputWidth = inputWidth;
          let outputHeight = inputHeight;
          if (inputImageAspectRatio > aspectRatio) {
              outputWidth = inputHeight * aspectRatio;
          } else if (inputImageAspectRatio < aspectRatio) {
              outputHeight = inputWidth / aspectRatio;
          }

          // calculate the position to draw the image at
          const outputX = (outputWidth - inputWidth) * 0.5;
          const outputY = (outputHeight - inputHeight) * 0.5;

          // create a canvas that will present the output image
          const outputImage = document.createElement('canvas');

          // set it to the same size as the image
          outputImage.width = outputWidth;
          outputImage.height = outputHeight;
          this.inputVideoWith = outputWidth;
          this.inputVideoHeight = outputHeight;

          console.log(outputImage.width);
          console.log(outputImage.height);

          // draw our image at position 0, 0 on the canvas
          const ctx = outputImage.getContext('2d');
          ctx.drawImage(inputImage, outputX, outputY);
          var data = outputImage.toDataURL('image/jpeg', 1);
          let obj = {resolve: data, mediaWidth: outputWidth, mediaHeight: outputHeight}
          resolve(obj);
      };

      // start loading our image
      inputImage.src = url;
  });
}


aspectRatios = [1/1, 4/5, 16/9];

aspectRatioAll: any = 1/1;
changeImageAspectRatioAll(ratioIndex: any){
  this.aspectRatioAll = this.aspectRatios[ratioIndex];
  console.log(this.aspectRatios[ratioIndex]);
  // here5555
  // this.cd.detectChanges();

  for (let i = 0; i < this.countImagesUploaded.length; i++) {
    

    let file = this.countImagesUploaded[i].file;
      
    let fd = new FormData();
    fd.append("file", file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (rr) => {

      let obj: any = await this.crop(reader.result as string,this.aspectRatioAll);
      this.countImagesUploaded[i].fileUrl = obj.resolve;
      let imagesVideosArrayIndex = this.imagesVideosArray.findIndex((obj)=>{
        return obj.key == this.countImagesUploaded[i].key;
      });
    
      // debugger;
      if(imagesVideosArrayIndex != -1){
        this.imagesVideosArray[imagesVideosArrayIndex]['fileUrl'] = obj.resolve;
        this.imagesVideosArray[imagesVideosArrayIndex]['mediaWidth'] = obj.mediaWidth;
        this.imagesVideosArray[imagesVideosArrayIndex]['mediaHeight'] = obj.mediaHeight;
      }
    }
  }
}

getVideoDuration(file):Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      let video = document.createElement('video')
      video.preload = 'metadata'

      video.onloadedmetadata = function () {
          resolve(this)
      }

      video.onerror = function () {
          reject("Invalid video. Please select a video file.")
      }

      video.src = file;
      // video.src = window.URL.createObjectURL(file)
    } catch (e) {
        reject(e)
    }
  });
}
removespacessPost(e:any){
  // let data = e.srcElement.innerText.trim();
  let data = e.target.value;
    if(data==''){
        this.btndisablespace=true; 
    }else{
      this.btndisablespace=false;
    }
}

replyCommentEnable(id:any){
  (<HTMLInputElement>document.getElementById('commentReply'+id)).style.display='block';
}

replyCommentClick(commentId:any,postId:any){
  let data = (<HTMLInputElement>document.getElementById('replyText'+commentId)).value;
  console.log(data);

  if(data!='')
    alert(`Comment Id - ${commentId} Post Id: ${postId}`);
}


getTimeRemaining(miliseconds:any){
  miliseconds = miliseconds - 1000;
  const days    = Math.floor(miliseconds / (1000 * 60 * 60 * 24));
  const hours   = Math.floor((miliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((miliseconds % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((miliseconds % (1000 * 60)) / 1000);
  let data = minutes + " : " + seconds;
  return data
}

getImage(id:any){
  let src = "https://img.youtube.com/vi/" +id +"/0.jpg";
  return src
}

onClickYoutubeEditUrl(){
  this.youtubeEdit = false;
}

onClickSpotifyEditUrl(){
  this.spotifyEdit = false;
}

getYoutubeId(data){
  let id = data.toString();
  let youId = id.split("=");
  return youId[1];

}

searchFriend(e:any){
  this.searchFriendKey = e;
}

updateCity(){
  // this.modalRef.hide();
  // this.route.navigateByUrl('/profile/update/city');
  this.showUpdateModal('updateCity');
}

updateMobNum(){
  this.showUpdateModal('updateMobNum');   
}

reportProfileUser: Boolean = false;
onClickreportProfile(){
  this.reportProfileUser = true;
  this.openModal(this.reportPostModal);
}

reportUserById(){
  this.service.reportUser(this.postId, {"text":this.reportPostForm.value.comment}).subscribe((res:any) => {
    if(res.type == 'success'){
      this.modalRef.hide();
      this.reportPostForm.reset();
      this.toastrService.success('user reported');
    }else{
      this.toastrService.error(res.message);
    }
    this.reportProfileUser = false;
  })
}

unFriendConfirm(){
    this.onClickUnfriend(this.postId);
}

onClickUnfriendUser(){
  // this.openModal(this.userUnfriend);
  this.modalRef = this.modalService.show(this.userUnfriend, {class:'userunfirendpopup'});

}

audioplayerprogress(){
  return
      var title = document.querySelectorAll('ang-music-player div');
      let time =title[6].innerHTML.split(":");
      let time2 =title[6].innerHTML.split(":");
      let seconds = parseInt(time[1]);
      let minutes = parseInt(time[0]);
      let totalSeconds = (minutes * 60)+ seconds;
      let seconds2 = parseInt(time[1]);
      let minutes2 = parseInt(time[0]);
      let totalSeconds2 = (minutes * 60)+ seconds;
      let width = totalSeconds /465;
      // document.documentElement.style.setProperty("--anchor-hover-color",width+'px');
}
formatTimeUnit(input, unit){
  var index = input.indexOf(unit);
  var output = "00"
  if(index < 0){
    return output; // unit isn't in the input
  }

  if(isNaN(input.charAt(index-2))){
    return '0' + input.charAt(index-1);
  }else{
    return input.charAt(index-2) + input.charAt(index-1);
  }
}

ISO8601toDuration(input){
  var H = this.formatTimeUnit(input, 'H');
  var M = this.formatTimeUnit(input, 'M');
  var S = this.formatTimeUnit(input, 'S');

 if(H == "00"){
   H = "";
 }else{
   H += ":"
 }

 return H  + M + ':' + S ;
}

showspinner(){
  this.spinner.show();
}
hidespinner(){
  this.spinner.hide();
}

bandcampp() {
  console.log(' Bandcampplayer...');
  // var names = document.querySelectorAll('#bandcamppp').contents().find('body').addClass('foo');;
  // names.contents().find('body').addClass('foo');
  // $("iframe").contents().find('#player').addClass('foo');
  // var iframe = (document?.querySelector('#bandcamppp') as HTMLIFrameElement) 
  // var iframe_doc = iframe.contentWindow ? iframe.contentWindow.document : iframe.contentDocument;
  $('#bandcamppp').find('#player').addClass('bandyyyy');  
  $('#bandcamppp').find('body').addClass('bannnnn');  
  console.log('banddiv' + $('#bandcamppp').find('#player'))
  //  console.log(iframe_doc + "BODY");
}

  moreVideosComing = false;

  onYoutubeScrollDown(e: any) { 
    console.log(e);
    this.service.getYoutubePlayList({nextPageToken:this.NextPageTokenForYoutube}).subscribe((res:any)=>{
      // this.playList = res?.data?.youtubeResult;
      console.log(res.data.videos);
      this.playList.push(...res.data.videos)
      if(res?.data?.pageInfo?.nextPageToken){
        this.moreVideosComing = true;
      }
      this.NextPageTokenForYoutube = res.data?.pageInfo?.nextPageToken;
    })
  }
  sidebarFixed:any;

  @HostListener('window:scroll', ['$event']) onScroll(event) {

//  console.log(window.scrollY);
    if (window.scrollY > 350) {
      this.sidebarFixed = true;
    }
    else if (window.scrollY < 350) {
      this.sidebarFixed = false;
    }

  }
  makeFavouriteFriend(){
    this.service.makeFavouriteFriend(this.postId).subscribe(res=>{
      console.log(res);
      this.profileDetails.isTopFriend = true;
      // change star
    })
  }
  makeUnFavouriteFriend(){
    this.service.makeUnFavouriteFriend(this.postId).subscribe(res=>{
      console.log(res);
      this.profileDetails.isTopFriend = false;
      // change star
    })
  }

  AddFriendLikes(id:any){
    let data = document.getElementById('addFriendTextLikes'+id).innerText;
    if(data=='Pending'){
      this.spinners.show();
      document.getElementById('addFriendTextLikes'+id).innerText = 'Add Friend'
      document.getElementById('addFriendTextLikes'+id).classList.remove("requestSent");
      document.getElementById('addFriendTextLikes'+id).classList.add("requestNotSent");     
      this.service.cancelRequest(id,{"action":"CANCEL"}).subscribe((res:any)=>{
        if(res.type=='success'){
          this.toastrService.success(res.message); 
        }
      })
      this.spinners.hide();
    }else if(data=='Add Friend'){
      this.spinners.show();
      document.getElementById('addFriendTextLikes'+id).innerText = 'Pending'
      document.getElementById('addFriendTextLikes'+id).classList.add("requestSent");
      document.getElementById('addFriendTextLikes'+id).classList.remove("requestNotSent");
      this.service.AddFriend({"recipientId":id}).subscribe((res:any) => {      
        if(res.type == 'success'){
          this.toastrService.success(res.message); 
        }else{
        }
      },(err) => {
        this.toastrService.error(err.error.message);
      })
      this.spinners.hide();
    }else if(data=='Accept'){ 
      document.getElementById('addFriendTextLikes'+id).innerText = 'Friend'; 
      document.getElementById('addFriendTextLikes'+id).classList.add("friend");
      document.getElementById('addFriendTextLikes'+id).classList.remove("requestSent");

      document.getElementById('addFriendTextLikes'+id).classList.remove('btn:enabled');
      // document.getElementById('bandSuggestionList'+id).style.color='black';
      this.service.AcceptFriendRequest({"recipientId": id,"action": "ACCEPT"}).subscribe((res:any) => {
        if(res.type == 'success'){
          // this.toastr.success(res.message);   
          this.toastrService.success("Friend request accepted");
          // debugger;
          // let index = this.moreData.findIndex((obj)=>{
          //   return obj._id == id
          // });
          // if(index != -1){
          //   this.moreData[index].friendStatus = "FRIEND";
          // }
        }else{
          this.toastrService.error(res.message);   
        }
      },(err) => {
        this.toastrService.error(err.error.message);
      })
      this.spinner.hide();
    }
  }

  generUpdatemodalRef;
  updateScreen;
  updateData = {};
  showGenerUpdate(){
    this.generUpdatemodalRef = this.modalService.show(this.showupdateGenerModal,{class:"update-gener-modal"});
  }

  showUpdateModal(updateScreen){
    this.updateScreen = updateScreen;
    this.generUpdatemodalRef = this.modalService.show(this.showupdateGenerModal,{class:"update-gener-modal"});
  }

  collectUpdatedIds(e){
    console.log(e);
    // this.updateData['ids'] = e;
    this.updateProfileForm.get('genre').patchValue(e.generNames);
    this.genersId = e.ids;
    this.generUpdatemodalRef.hide();
    this.profileUpdated = true;
  }

  collectUpdatedupdateMobNum(e){
    console.log(e, "mob");
    this.generUpdatemodalRef.hide();
    this.profileUpdated = true;
    let phone = e.countryCode + ' ' + e.mobileNumber.toString().slice(0,3) + ' ' + e.mobileNumber.toString().slice(3,6) + ' ' + e.mobileNumber.toString().slice(6);
    this.updateProfileForm.get('phone').patchValue(phone);
    this.updateProfileForm.get('MobNum').patchValue(e.mobileNumber);
    
  }

  collectUpdatedAddress(e){
    console.log(e);
    // this.updateData['addressDetails'] = JSON.parse(e);
    this.updateProfileForm.controls.city.patchValue(JSON.parse(e).address.city);
    this.updateProfileForm.controls.state.patchValue(JSON.parse(e).address.state);
    this.updateProfileForm.controls.country.patchValue(JSON.parse(e).address.country);
    this.updateProfileForm.controls.lat.patchValue(JSON.parse(e).location.lat);
    this.updateProfileForm.controls.long.patchValue(JSON.parse(e).location.long);
    this.updateProfileForm.get('location').patchValue(JSON.parse(e).address.city+', '+JSON.parse(e).address.state+', '+JSON.parse(e).address.country);

    this.generUpdatemodalRef.hide();
    this.profileUpdated = true;
  }

  collectUpdatedCoverPicture(e){
    console.log(e);
    // this.updateData['coverPicDetails'] = JSON.parse(e);
    this.editProfileCoverPictureURL = JSON.parse(e).coverImage.fileUrl;
    this.updateProfileForm.controls.coverPicture.patchValue(JSON.parse(e).coverImage.fileUrl);
    this.updateProfileForm.controls.coverPictureKey.patchValue(JSON.parse(e).coverImage.key);
    this.generUpdatemodalRef.hide();
    this.editCoverPicUpdated = true;
    this.profileUpdated = true;
  }

  collectUpdatedProfileDetails(e){
    console.log(e);
    // this.updateData['profilePicDetails'] = JSON.parse(e);
    this.editProfilePictureURL = JSON.parse(e).profileImage.fileUrl;
    this.updateProfileForm.controls.profilePicture.patchValue(JSON.parse(e).profileImage.fileUrl);
    this.updateProfileForm.controls.profilePictureKey.patchValue(JSON.parse(e).profileImage.key);
    this.generUpdatemodalRef.hide();
    this.editProfilePicUpated = true;
    this.profileUpdated = true;
  }

  enableBandNameUpdate(){
    document.getElementById('bandNameUpdate').style.display = 'none';
    this.updateProfileForm.controls.bandName.enable();
    setTimeout(()=>{
      document.getElementById('bandNameUpdateText').focus();
    },500);
  }
  enableBioUpdate(){
    document.getElementById('bioUpdate').style.display = 'none';
    this.updateProfileForm.controls.bio.enable();
    setTimeout(()=>{
      document.getElementById('bioUpdateText').focus();
    },500);
  }
  enableSpotifyUpdate(){
    document.getElementById('spotifyUpdate').style.display = 'none';
    this.updateProfileForm.controls.spotifyURL.enable();
    setTimeout(()=>{
      document.getElementById('spotifyUpdateText').focus();
    },500);
  }
  enableYoutubeUpdate(){
    document.getElementById('youtubeUpdate').style.display = 'none';
    this.updateProfileForm.controls.youtubeURL.enable();
    setTimeout(()=>{
      document.getElementById('youtubeUpdateText').focus();
    },500);
  }

  async checkSpotifyAuthorized(){
    // let spotifyTokenInfo = localStorage.getItem("spotifyTokenInfo");
    // if(spotifyTokenInfo){
    //   console.log(spotifyTokenInfo);
    //   let spotifyTokenInfoParsed = JSON.parse(spotifyTokenInfo);
    //   console.log(spotifyTokenInfoParsed);
    //   if(!spotifyTokenInfoParsed.accessToken){
    //     this.spotifyAuth();
    //   }
    //   else{
    //     // Check token validity
    //     console.log("check token validity");
    //   }
    // }
    // else{
    //   this.spotifyAuth();
    // }
    let spotifyTokenInfoParsed:any = await this.getSpotifyToken();
    // spotifyTokenInfoParsed
    console.log(spotifyTokenInfoParsed);
    if(spotifyTokenInfoParsed){
      console.log(spotifyTokenInfoParsed);
      // let spotifyTokenInfoParsed = JSON.parse(spotifyTokenInfo);
      console.log(spotifyTokenInfoParsed);

      // Check token expired
      if(new Date().getTime() - spotifyTokenInfoParsed.expiresAtDate >= 216000){
        let refreshTokenResponse =  await this.refreshSpotifyToken(spotifyTokenInfoParsed.refresh_token);
        refreshTokenResponse['expiresAtDate'] = refreshTokenResponse.expires_in + new Date().getTime();
        this.updateSpotifyToken(refreshTokenResponse);
        this.spotifyTokenInfo = refreshTokenResponse;
        this.spotifyInitialization();
      }
      else{
        this.spotifyTokenInfo = spotifyTokenInfoParsed;
        this.spotifyInitialization();
      }
      
    }
    else{
      this.spotifyAuth();
    }
  }

  spotifyTokenInfo:any;

  updateSpotifyToken(data){
    this.service.saveOrUpdateSpotifyToken(data).subscribe((res:any)=>{
      console.log(data);
    })
  }

  // b9568077e42a48ba89e30e3c7aa48b33

  async refreshSpotifyToken(refresh_token){

    let body = new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: refresh_token,
      client_id: '9dd84c590fe34a9f849c5b45df2046c0',
    });
    const response = await fetch('https://accounts.spotify.com/api/token', {
      method: 'POST',
      body:body,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    });

    const data = await response.json();

    console.log(data);

    if(data.error){
      this.spotifyAuth();
    }
    else{
      return data;
    }

    // this.service.saveOrUpdateSpotifyToken(data).subscribe((res:any)=>{
    //   console.log(data);
    // })
  }

  async getSpotifyToken(){
    return new Promise((resolve,reject)=>{
      this.service.getSpotifyAuthToken().subscribe((res:any)=>{
        console.log(res);
        if(res.data && res.data.access_token){
          resolve(res.data);
        }
        else{
          this.spotifyAuth();
        }
      },
      err => {
        console.log(err);
        this.toastrService.info("There is some issue with spotify.Please try after sometime");
        resolve('');
      })
    })
    // return 
    // let tokenInfo = localStorage.getItem('spotifyTokenInfo');
    // return tokenInfo;
  }

  spotifyAuth(){
    console.log("from spotify-auth");
    // const clientId = 'b9568077e42a48ba89e30e3c7aa48b33';
    const clientId = '9dd84c590fe34a9f849c5b45df2046c0';


    // const redirectUri = 'http://localhost:4200/spotify-auth-redirect';

    // const redirectUri = 'https://indietown-web.approd.ca/spotify-auth-redirect';

    const redirectUri = 'https://stg-indietown-web.approd.ca/spotify-auth-redirect';

    let codeVerifier = this.generateRandomString(128);

    console.log("codeVerifier",codeVerifier);

    this.generateCodeChallenge(codeVerifier).then(codeChallenge => {
      let state = this.generateRandomString(16);
      console.log("state",state);

      let scope = 'streaming user-read-email user-modify-playback-state user-read-private';

      localStorage.setItem('code_verifier', codeVerifier);

      let args = new URLSearchParams({
        response_type: 'code',
        client_id: clientId,
        scope: scope,
        redirect_uri: redirectUri,
        state: state,
        code_challenge_method: 'S256',
        code_challenge: codeChallenge
      });

      // Location.g
      // Location.go('https://accounts.spotify.com/authorize?' + args);
      // Location.
      this.document.location.href = 'https://accounts.spotify.com/authorize?' + args;
    });
  }

  generateRandomString(length) {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  async generateCodeChallenge(codeVerifier) {
    function base64encode(string) {
      return btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
    }
  
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
  
    return base64encode(digest);
  }

  addSpotifyUrl(){
    console.log((<HTMLInputElement>document.getElementById("spotifyUrlNew")).value);
    let spotifyUrl = (<HTMLInputElement>document.getElementById("spotifyUrlNew")).value;
    this.service.addSpotifyUrl({url:spotifyUrl}).subscribe((res:any)=>{
      console.log(res);
      this.spotifyList.unshift(res.data);
      (<HTMLInputElement>document.getElementById("spotifyUrlNew")).value = '';
    },(err)=>{
      console.log(err);
      this.toastrService.info(err?.error?.message);
      (<HTMLInputElement>document.getElementById("spotifyUrlNew")).value = '';
    })
  }

  spotifyList = [];
  getSpotifyList(){
    this.service.getSpotifyList("",this.spotifyPageNumber,this.spotifyPageLimit).subscribe((res:any)=>{
      console.log(res);
      this.spotifyList = res.data;
      this.originalSpotifyList = res.data;
      // if(this.spotifyList[0].trackType == 'playlist'){
      //   this.currentlyPlayingSpotifySong = this.spotifyList[0];
      //   this.spotifySongSelectedIndex = 0;
      // }
      // else{

      // }

      this.spotifySongSelected = true;
      if(this.spotifyList[0].trackType == 'playlist'){
        this.spotifySongTrackType = 'playlist';
        this.spotifySongPlaylistIndex = 0;
        this.spotifySongSelectedIndex = 0;
        this.currentlyPlayingSpotifySong = this.spotifyList[0].items[0];
      }
      else{
        this.spotifySongTrackType = 'singleTrack';
        this.spotifySongSelectedIndex = 0;
        this.currentlyPlayingSpotifySong = this.spotifyList[0].items[0];
      }
      
    })
  }

  removeSingleTrack(index){
    let id = this.spotifyList[index]._id;
    this.service.removeSingleTrack(id).subscribe((res:any)=>{
      console.log(res);
      if(res.type == 'success'){
        this.spotifyList.splice(index,1);
      }
    })
  }

  removePlaylist(index){
    let id = this.spotifyList[index]._id;
    this.service.removePlaylist(id).subscribe((res:any)=>{
      console.log(res);
      if(res.type == 'success'){
        this.spotifyList.splice(index,1);
      }
    })
  }

  removePlaylistItem(index, subtrackIndex){
    let id = this.spotifyList[index].items[subtrackIndex]._id;
    this.service.removePlaylistItem(id).subscribe((res:any)=>{
      console.log(res);
      if(res.type == 'success'){
        this.spotifyList[index].items.splice(subtrackIndex,1);
        if(!this.spotifyList[index].items.length){
          this.spotifyList.splice(index,1);
        }
      }
    })
  }

  playlistOpened(index){
    console.log("playlist opened",index);
    // 22931509_SID@ftts.BSNL.IR
    let playlistId = this.spotifyList[index]._id;
    this.service.getPlaylistItems(playlistId).subscribe((res:any)=>{
      console.log(res);
      if(res.type == 'success'){
        this.spotifyList[index].items = res.data;
      }
    })
  }

  getMoreSpotifyTracks:Boolean = true;
  spotifyPageNumber = 1;
  spotifyPageLimit = 20;
  loadMoreSpotifyTracks(e){
    console.log("load more spotify tracks");
    if(this.getMoreSpotifyTracks){
      this.spotifyPageNumber++;
      this.service.getSpotifyList("",this.spotifyPageNumber,this.spotifyPageLimit).subscribe((res:any)=>{
        console.log(res);
        if(res.data.length){
          this.spotifyList.push(...res.data);
        }
        else{
          this.getMoreSpotifyTracks = false;
        }
      })
    }
  }

  addPlaylistModalRef;
  openAddPlaylistModal(){
    this.addPlaylistModalRef = this.modalService.show(this.addPlaylistModal, {class: 'addPlaylistModal'});
  }

  closeRegisterPlaylist(){
    this.addPlaylistModalRef.hide();
  }

  navigateToMessages(){
    localStorage.setItem("cameFromProfile",JSON.stringify(this.profileDetails));
    this.route.navigateByUrl('/messages');
  }

  showSpotifyTab: Boolean = false;
  showYoutubeTab: Boolean = false;
  showSoundcloudTab: Boolean = false;
  showBandcampTab: Boolean = false;
  checkSpotifyExist(userId){
    this.service.getSpotifyList(userId,1,20).subscribe((res:any)=>{
      if(res?.type == 'success' && res?.data?.length){
        this.showSpotifyTab = true;
        this.checkRedirectedFromSpotifyAuthorization();
      }
    });
  }
  checkRedirectedFromSpotifyAuthorization(){
    if(localStorage.getItem("SpotifyAutorizedAndRedirected")){
      localStorage.removeItem("SpotifyAutorizedAndRedirected");
      this.selectedIndex = 3;
    }
  }
  checkYoutubeExist(userId){
    this.service.getYoutubeList(userId,1,20).subscribe((res:any)=>{
      if(res?.type == 'success' && res?.data?.length){
        this.showYoutubeTab = true;
      }
    });
  }
  checkSoundcloudExist(userId){
    this.service.getSoundcloudList(userId,1,20).subscribe((res:any)=>{
      if(res?.type == 'success' && res?.data?.length){
        this.showSoundcloudTab = true;
      }
    });
  }
  checkBandcampExist(userId){
    this.service.getBandcampList(userId,1,20).subscribe((res:any)=>{
      if(res?.type == 'success' && res?.data?.length){
        this.showBandcampTab = true;
      }
      if(!this.postId){
        setTimeout(()=>{
          if(localStorage.getItem("selectedProfileTabIndex")){
            this.selectedIndex = localStorage.getItem("selectedProfileTabIndex");
          }
        },2000);
      }
    });
  }

  newBandcampAddedData;
  newSoundcloudAddedData;
  newSpotifyAddedData;
  newYoutubeAddedData;

  newPlaylistUrlAdded(e){
    console.log(e);
    this[e.name] = true;
    if(e.name == "showSpotifyTab"){
      this.newSpotifyAddedData = e.data;
    }
    else if(e.name == "showSoundcloudTab"){
      this.newSoundcloudAddedData = e.data;
    }
    else if(e.name == "showBandcampTab"){
      this.newBandcampAddedData = e.data;
    }
    else if(e.name == "showYoutubeTab"){
      this.newYoutubeAddedData = e.data;
    }
  }
  navigateToUserProfile(id,bandName){
    if(this.postId){
      if(this.myProfileDetails?._id == id){
        this.route.navigateByUrl(`profile`);
      }
      else{
        this.route.navigateByUrl(`user/profile?id=${id}&user=${bandName}`).then((res)=>{
          window.location.reload();
        });
        
      }
    }
    else{
      if(this.profileDetails?._id == id){
        // this.route.navigateByUrl(`profile`);
        console.log("you are in the same screen");
      }
      else{
        this.route.navigateByUrl(`user/profile?id=${id}&user=${bandName}`);
      }
    }
    
  }

  navigateToPhotoAlbum(profileorCoverImageDetails){
    console.log(this.tabGroup);
    if(profileorCoverImageDetails?.key){
      profileorCoverImageDetails["_id"] = "custom_id";
      localStorage.setItem("profileorCoverImageDetails",JSON.stringify(profileorCoverImageDetails));
      if(this.tabGroup._allTabs.length-2){
        this.selectedIndex = this.tabGroup._allTabs.length-2;
      } 
    }
    
    // if(imageURL){
    //   this.enlargeProfileOrCoverPicture(imageURL);
    // }
  }

  enlargeProfileOrCoverPictureModalRef;
  enlargeProfileOrCoverPictureURL='';

  enlargeProfileOrCoverPicture(imageURL){
    this.enlargeProfileOrCoverPictureURL = imageURL;
    this.enlargeProfileOrCoverPictureModalRef = this.modalService.show(this.ProfileOrCoverPictureview,{class:'enlargeProfileOrCoverPicture'});
  }

  stopGoToNextLine(e){
    e.preventDefault();
  }

  // imageChangedEvent: any = '';
  // croppedImage: any = '';

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.createShowImageUrl = event.base64;
    this.singalurl = event.base64;
  }

  showImageChangedEvent:any;
  CreateShowCropModalRef:any;
  // imageTransform = {};
  imageTransform: ImageTransform = {
    translateUnit: 'px'
  };
  croppedShowPicture:any;
  croppedShowPictureName:any;

  aspectRatio = 1/1;
  uploadShowImage1(e: any) {
    this.showImageChangedEvent = e;
    this.CreateShowCropModalRef = this.modalService.show(this.CreateShowCropModal,{class : "Create-Show-Crop-Modal"});
    this.croppedShowPictureName = e?.target?.files[0]?.name;
  }

  // aspectRatios = [1/1, 4/3, 16/9];
  changeImageAspectRatio(ratioIndex: any){
    this.aspectRatio = this.aspectRatios[ratioIndex];
    console.log(this.aspectRatios[ratioIndex]);
    // this.cd.detectChanges();
  }

  getInputVideoHeight(){
    return 200/(this.aspectRatioAll);
  }

  getDisplayVideoHeight(media){
    // console.log(mediaArray);
    // let found = false;
    // let mediaWidth;
    // let mediaHeight;
    // for(let i=0;i<mediaArray.length;i++){
    //   let media = mediaArray[i];
    //   if(media?.type?.includes("image")){
    //     found = true;
    //     mediaWidth = media.mediaWidth;
    //     mediaHeight = media.mediaHeight;
    //     break;
    //   }
    // }

    // let widthOfVideo = document.getElementById("homePostVideo").clientWidth;

    // if(found && mediaWidth && mediaHeight){
    //   return widthOfVideo/(mediaWidth/mediaHeight);
    // } else {
    //   return widthOfVideo;
    // }
    let widthOfVideo = document.getElementById("homePostVideo")?.clientWidth;
    
    if(media?.aspectRatio){
      // return 772/(mediaWidth/mediaHeight);
      return widthOfVideo/(this.aspectRatios[media?.aspectRatio]);
    } else {
      return widthOfVideo;
    }
  }

  croppedProfilePicture(event) {
    console.log("Profile Picture Image Cropped");
    console.log(event);
    this.croppedShowPicture = event;
  }

  uploadShowImage2() {
    this.createShowImageUrl = this.croppedShowPicture.base64;
    this.CreateShowCropModalRef.hide();
    document.getElementById('craeteshowplaceholder').style.borderRadius = "15px";
    // this.createShowForm.controls["imageUrl"].setValue(res.data.fileUrl);
    // this.createShowForm.controls["imageUrlKey"].setValue(res.data.key);
    (<HTMLInputElement>document.getElementById('imageView')).src = '../../../../assets/Indietown_icons/edit-icon2.svg';
    this.resetImageCropper();



    // this.spinner.show();
    // let fd = new FormData();
    // const imageFile = new File([this.dataURItoBlob(this.croppedShowPicture.base64)], this.croppedShowPictureName, { type: 'image/png' });
    // fd.append("file", imageFile);
    // this.service.uploadImage(fd).subscribe((res: any) => {
    //   // this.imageChangedEvent = e;
    //   this.createShowImageUrl = res.data.fileUrl;
    //   this.CreateShowCropModalRef.hide();
    //   this.spinner.hide();
    //   document.getElementById('craeteshowplaceholder').style.borderRadius = "15px";
    //   this.createShowForm.controls["imageUrl"].setValue(res.data.fileUrl);
    //   this.createShowForm.controls["imageUrlKey"].setValue(res.data.key);
    //   (<HTMLInputElement>document.getElementById('imageView')).src = '../../../../assets/Indietown_icons/edit-icon2.svg';
    //   this.resetImageCropper();
    // });
  }

  green = 'green';

  dataURItoBlob(dataURI) {
    const parts = dataURI.split(';base64,');
    const byteString = atob(parts[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  scale:any = 1;

  zoomOut() {
    if (this.scale == 1) {
      return;
    }

    if (this.scale > 1) {

      this.scale -= .1;
      this.imageTransform = {
        ...this.imageTransform,
        scale: this.scale
      };
    }

  }

  translateV : any = 1;
  moveRight(){
    if(this.scale>1){
      console.log("moving to left");
      this.translateV = this.translateV + 50;
      console.log(this.translateV);
      this.imageTransform = {
        ...this.imageTransform,
        translateH: this.translateV
      };
    }
  }

  translateH : any = 1;
  moveLeft(){
    if(this.scale>1){
      console.log("moving to left");
      this.translateH = this.translateH + 50;
      console.log(this.translateH);
      this.imageTransform = {
        ...this.imageTransform,
        translateH: this.translateH
      };
    }
  }

  zoomIn() {
      console.log( this.scale)
      if (this.scale <= 10) {
        this.scale += .1;
        console.log( "hello")
        this.imageTransform = {
          ...this.imageTransform,
          scale: this.scale
        };
      } else {
        console.log( "hi no more zoom in");
        return;
        // this.scale = 1;
        // this.scale += .1;
        // this.imageTransform = {
        //   ...this.imageTransform,
        //   scale: this.scale
        // };
      }
    // }
  }

  ZoomImage(e) {
    var slider: any = document.getElementById("myRange");;
    console.log(slider.value)
    this.scale = slider.value;
    this.imageTransform = {
      ...this.imageTransform,
      scale: slider.value
    };

  }

  createPostEditImageCropModalRef;
  createPostEditImageUrl;
  createPostEditImageIndex;
  createPostEditImageTransform:ImageTransform = {};
  croppedcreatePostEditImageName;
  croppedcreatePostEditImage;


  createPostEditImageFile: any;
  cropCreatePostImage(imageDetails,index){
    console.log("let's crop",imageDetails,index);
    // this.createPostEditImageUrl = imageDetails.fileUrl;
    this.createPostEditImageFile = imageDetails.file;
    this.createPostEditImageIndex = index;
    this.createPostEditImageCropModalRef = this.modalService.show(this.CreatePostImageCropModal,{class : "Create-Post-Image-Crop-Modal"});
    // this.croppedcreatePostEditImageName = imageDetails.key?.split(".")[0];
    // this.croppedcreatePostEditImageName = imageDetails.key;
    this.croppedcreatePostEditImageName = imageDetails?.key ? imageDetails.key :"Indietown.png";
  }

  croppedCreatePostImage(e){
    console.log("create post Image Cropped");
    console.log(e);
    this.croppedcreatePostEditImage = e;
  }

  cancelCreatePostEditImage(){
    this.createPostEditImageCropModalRef.hide();
    this.resetImageCropper();
  }

  cancelUploadShowImage(){
    this.CreateShowCropModalRef.hide();
    this.showImageChangedEvent = null;
    this.croppedShowPictureName = '';
    this.resetImageCropper();
  }

  // uploadShowImage1(e: any) {
  //   this.showImageChangedEvent = e;
  //   this.CreateShowCropModalRef = this.modalService.show(this.CreateShowCropModal,{class : "Create-Show-Crop-Modal"});
  //   this.croppedShowPictureName = e?.target?.files[0]?.name;
  // }

  // croppedProfilePicture(event) {
  //   console.log("Profile Picture Image Cropped");
  //   console.log(event);
  //   this.croppedShowPicture = event;
  // }

  updateCreatePostEditImage() {


    // ======================  OLD  =================================
    // let fd = new FormData();
    // const imageFile = new File([this.dataURItoBlob(this.croppedcreatePostEditImage.base64)], this.croppedcreatePostEditImageName, { type: 'image/png' });
    
    // console.log(imageFile);
    // fd.append("file", imageFile);
    // // this.service.uploadImage(fd).subscribe((res: any) => {
    //   this.createPostEditImageCropModalRef.hide();

    //   let key = this.countImagesUploaded[this.createPostEditImageIndex]['key'];
    //   this.countImagesUploaded[this.createPostEditImageIndex]['fileUrl'] = this.croppedcreatePostEditImage.base64;
    //   this.countImagesUploaded[this.createPostEditImageIndex]['key'] = this.croppedcreatePostEditImageName;
    //   this.resetImageCropper();

    //   let imagesVideosArrayIndex = this.imagesVideosArray.findIndex((obj)=>{
    //     return obj.key == key;
    //   });

    //   if(imagesVideosArrayIndex != -1){
    //     this.imagesVideosArray[imagesVideosArrayIndex]['fileUrl'] = this.croppedcreatePostEditImage.base64;
    //     this.imagesVideosArray[imagesVideosArrayIndex]['key'] = this.croppedcreatePostEditImageName;
    //     this.imagesVideosArray[imagesVideosArrayIndex]['file'] = imageFile;
    //   }

    // ======================  OLD END =================================
      
    //============================= NEW ===============================================
    
    this.createPostEditImageCropModalRef.hide();

    let key = this.countImagesUploaded[this.createPostEditImageIndex]['key'];
    this.countImagesUploaded[this.createPostEditImageIndex]['fileUrl'] = this.croppedcreatePostEditImage.base64;
    
    this.resetImageCropper();

    let imagesVideosArrayIndex = this.imagesVideosArray.findIndex((obj)=>{
      return obj.key == key;
    });

    if(imagesVideosArrayIndex != -1){
      this.imagesVideosArray[imagesVideosArrayIndex]['fileUrl'] = this.croppedcreatePostEditImage.base64;
    }

  // ================================= NEW END =============================================

      // this.imageChangedEvent = e;
      // this.createShowImageUrl = res.data.fileUrl;
      // this.createPostEditImageCropModalRef.hide();
      // document.getElementById('craeteshowplaceholder').style.borderRadius = "15px";
      // this.createShowForm.controls["imageUrl"].setValue(res.data.fileUrl);
      // this.createShowForm.controls["imageUrlKey"].setValue(res.data.key);
      // (<HTMLInputElement>document.getElementById('imageView')).src = '../../../../assets/Indietown_icons/edit-icon2.svg';
    // });
  }

  createPostImageEditScale:any = 1;

  createPostImageEditZoomOut() {
    if (this.createPostImageEditScale == 1) {
      return;
    }

    if (this.createPostImageEditScale > 1) {

      this.createPostImageEditScale -= .1;
      this.createPostEditImageTransform = {
        ...this.createPostImageEditScale,
        scale: this.createPostImageEditScale
      };
    }

  }

  createPostImageEditZoomIn() {
      console.log( this.createPostImageEditScale)
      if (this.createPostImageEditScale <= 10) {
        this.createPostImageEditScale += .1;
        console.log( "hello")
        this.createPostEditImageTransform = {
          ...this.createPostEditImageTransform,
          scale: this.createPostImageEditScale
        };
      } else {
        console.log( "hi no more zoom in");
        return;
        // this.scale = 1;
        // this.scale += .1;
        // this.imageTransform = {
        //   ...this.imageTransform,
        //   scale: this.scale
        // };
      }
    // }
  }

  createPostImageEditZoomImage(e) {
    var slider: any = document.getElementById("myRange2");;
    console.log(slider.value)
    this.createPostImageEditScale = slider.value;
    this.createPostEditImageTransform = {
      ...this.createPostEditImageTransform,
      scale: slider.value
    };

  }

  resetImageCropper(){
    this.scale = 1;
    this.imageTransform = {};
    this.createPostImageEditScale = 1;
    this.createPostEditImageTransform = {};
    if(document.getElementById("uploadshowImg") as HTMLInputElement){
      (document.getElementById("uploadshowImg") as HTMLInputElement).value = "";
    }
  }

  deleteCommentModalRef;
  deleteCommentReplyModalRef;
  deleteCommentPostIndex;
  deleteCommentIndex;
  deleteCommentReplyIndex
  openDeleteCommentModal(deleteCommentPostIndex,deleteCommentIndex){
    this.deleteCommentPostIndex = deleteCommentPostIndex;
    this.deleteCommentIndex = deleteCommentIndex;
    this.deleteCommentModalRef = this.modalService.show(this.deleteCommentPopup);
  }

  openDeleteCommentReplyModal(deleteCommentPostIndex,deleteCommentIndex,deleteCommentReplyIndex){
    this.deleteCommentPostIndex = deleteCommentPostIndex;
    this.deleteCommentIndex = deleteCommentIndex;
    this.deleteCommentReplyIndex = deleteCommentReplyIndex;
    this.deleteCommentReplyModalRef = this.modalService.show(this.deleteCommentReplyPopup);
  } 

  navigateToProfile(userId, userName) {
    if(this.postId){
      if(this.myProfileDetails._id == userId){
        this.route.navigateByUrl('profile');
      }
      else if(this.profileDetails._id == userId){
        return;
      }
      else{
        this.route.navigateByUrl('user/profile?id=' + userId + '&user=' + userName).then(() => {
          window.location.reload();
        });
      }
    }
    else{
      if(this.profileDetails._id == userId){
        return;
      }
      this.route.navigateByUrl('user/profile?id=' + userId + '&user=' + userName).then(() => {
        window.location.reload();
      });
    }
    
  }

  reportNonFriend(){
    console.log("open report non friend dialog");
    this.onClickreportProfile();
  }

  timeSince(date1) {

    // debugger;
    // console.log(date1);
    let date2 = new Date(date1);
    let date:any = Math.floor(new Date(date2).getTime() / 1000);

    var seconds = Math.floor(((new Date().getTime()/1000) - date)),
    interval = Math.floor(seconds / 31536000);

    if (interval > 1) return interval + "y";

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return interval + "m";

    let date3:any = Math.floor(new Date(date2.toDateString()).getTime() / 1000);

    var seconds2 = Math.floor(((new Date(new Date().toDateString()).getTime()/1000) - date3)),
    // interval2 = Math.floor(seconds2 / 31536000);

    interval = Math.floor(seconds2 / 86400);
    if (interval >= 1 && interval <=6) return interval + "d";
    else if(interval >= 7){
      return Math.floor(interval/7) + 'w';
    }

    interval = Math.floor(seconds / 3600);
    if (interval >= 1) return interval + "h";

    interval = Math.floor(seconds / 60);
    if (interval >= 1) return interval + "m ";

    return Math.floor(seconds) + "s";
  }

  previousScrollTop = 0;
  // @HostListener("window:scroll", ['$event'])
  // onWindowScroll($event) {
  //   console.log("Scrolling!");
  //   // console.log($event);
  //   // console.log($event.target?.scrollingElement?.scrollTop);
  //   if(!this.previousScrollTop){
  //     console.log("previousScrollTop initiated ",$event.target?.scrollingElement?.scrollTop);
  //     this.previousScrollTop = $event.target?.scrollingElement?.scrollTop;
  //   }
  //   else{
  //     if(Math.abs($event.target?.scrollingElement?.scrollTop - this.previousScrollTop) >= 300){
  //       console.log("scrolled more than 100",$event.target.scrollingElement.scrollTop);
  //       this.previousScrollTop = $event.target.scrollingElement.scrollTop;
  //       this.currentPlayingVideo?.pause();
  //     }
  //   }
  //   // debugger;
    
  //   // this.currentPlayingVideo?.pause();
  // }

  navigateToSpotify(){
    if(this.postId){
      localStorage.setItem("spotifyUserId",this.postId);
    }
    else{
      localStorage.removeItem("spotifyUserId");
    }
    this.route.navigateByUrl('/spotify-view');
  }
  unSupportedVideoDetails = [];
  closeUnsupportedVideoPopup(){
    this.unSupportedVideoDetails = [];
    this.invalidVideoCodecPopupRef.hide();
    this.unSupportedVideoDetailsPopupOpened = false;
  }

  logoutmodalRef;
  openLogoutModal(template: TemplateRef<any>){
    this.logoutmodalRef = this.modalService.show(template, {class:'logoutpopupmodal'});
  }

  confirm() {
    this.logoutmodalRef.hide();
    this._header.logout("logout");
  }
}


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'youtube-modal-content',
  template: `
  <div>
    <div class="modal-body text-center delete-modal align-items-center"> 
      <yt-player [videoId]="videoId" [options]="videoPlayerOptions" (stateChange)="onStateChange($event)"></yt-player>
      <button style="position:absolute;top:26px;right:36px;color:white;" type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  `
})
 
export class YoutubeModalContentComponent implements OnInit {
  videoId;
  videoPlayerOptions;
  videoIndex;
  playList;
  isMobile:boolean=false;

  constructor(public modalRef: BsModalRef,private ytPlayerService: YtPlayerService) {
    if(screen.width < 640) {
      // true for mobile device
      this.isMobile = true;
    } else {
      // false for not mobile device
      this.isMobile = false;
    }
  }
 
  ngOnInit() {
    console.log(this.ytPlayerService);  
  }

  playNextVideo(){
    this.videoIndex = this.videoIndex+1;
    this.videoId = this.playList[this.videoIndex].url.split("v=")[1];
    this.ytPlayerService.load(this.videoId);
    // this.ytPlayerService.
  }

  onStateChange(e){
    console.log(e);
    if(e.type == 6){
      setTimeout(()=>{
        // let elm: any = document.getElementsByClassName("ytp-chrome-top-buttons")[0];
        // elm.style.display = 'none';
        // debugger;
        const x = document.querySelector("iframe").contentWindow;
        //x = window.frames[0];

        x.document.querySelector("body").style.backgroundColor = "blue";
        // this would turn the 1st iframe in document blue.

        console.log(x);
        console.log(document.getElementsByClassName("ytp-chrome-top-buttons")[0]);
      },10000);
    }  
    if(e.type == 3){
      this.playNextVideo();
    }
  }

  
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bandcamp-modal-content',
  template: `
  <div>
  <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true" style="text-shadow: none"
        ><img src="../../../assets/Indietown_icons/close.svg" alt=""
      /></span>
    </button>
    <div class="modal-body text-center delete-modal align-items-center"> 
      <iframe style="border: 0; width: 350px; height: 470px;" src="https://bandcamp.com/EmbeddedPlayer/album=1028102295/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/track=2957674094/transparent=true/" seamless></iframe>
    </div>
  </div>
  `
})
 
export class BandcampModalContentComponent implements OnInit {
  // videoId: string;
  source;

  constructor(public modalRef: BsModalRef,    protected _sanitizer: DomSanitizer    ) {}
 
  ngOnInit() {
    console.log(this.source);
    this.source = this._sanitizer.bypassSecurityTrustUrl(this.source);
  }
  ngAfterViewInit()	{
    // console.log(document.getElementsByClassName("ytp-pause-overlay-container"));
    // this.ytPlayerService.play();
  }

}


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'soundcloud-modal-content',
  template: `
  <div>
  <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true" style="text-shadow: none"
        ><img src="../../../assets/Indietown_icons/close.svg" alt=""
      /></span>
    </button>
    <div class="modal-body text-center delete-modal align-items-center"> 
      <iframe
      width="100%"
      height="300"
      scrolling="no"
      frameborder="no"
      allow="autoplay"
      src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1028228941%3Fsecret_token%3Ds-S5kXfxGSsuU&color=%23484848&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
    ></iframe>
    </div>
  </div>
  `
})
 
export class SoundCloudModalContentComponent implements OnInit {
  // videoId: string;
  source;

  constructor(public modalRef: BsModalRef, protected _sanitizer: DomSanitizer) {}
 
  ngOnInit() {
    console.log(this.source);
    // this.source = this._sanitizer.bypassSecurityTrustUrl(this.source);
  }
  ngAfterViewInit()	{
    // console.log(document.getElementsByClassName("ytp-pause-overlay-container"));
    // this.ytPlayerService.play();
  }
 
}
