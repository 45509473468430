
<div class="commmunityflow" id="communityflow">
    <!-- <div id="forgot-password">
     <div class="container-fluid">
       <div class="d-flex logoimg">
         <div class="logo">
           <img
             src="../../../../assets/Indietown_icons/login-logo.svg"
             alt="logo"
           />
         </div>
       </div>
     </div>
   </div> -->
   <!-- style="margin-left: 0px;"
        type="button"
        class="close pull-right"
        aria-label="Close"
        
        style="background: #FFFFFF;
        border: 1px solid rgba(15, 13, 15, 0.2);
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.05);
        opacity: 1;
        margin-top: -8px;
        margin-left: 0px;"
      > -->
      <!-- style="margin-top: -3px;
          width: 18.5px;
          height: 19.5px;" -->
        
   <div class="container-1 playlistsec" id="container1" style="height: 716px;width: 100%;padding-top: 20px;margin-top: 0;">
    <div class="playlistcrossbtn" style="position: absolute;
    right: 14px;
    top: 10px;
    /* font-size: 88px; */
    border: 1px solid grey;
    border-radius: 50%;
    width: 24px;cursor: pointer;" (click)="closeAddPlaylistModal()">
        <img  src="../../../assets/Indietown_icons/cross.svg" alt="" style="width: 14px;
        height: 14px;margin-left: 4px;">
    </div>
    <div class="progress-bar" id="progressbar">
        <!-- <div class="d-flex justify-content-center">
            <mat-progress-bar
                mode="determinate"
                value="90"
                class="top-progressbar"
            ></mat-progress-bar>
        </div> -->
        <div class="form-outer">
            <!-- <form action="#"> -->
            <div class="sample-form">
                <div
                class="page"
                style="
                display: flex;
                justify-content: center;
                align-items: center;
                "
                id="sixthpage"
            >
                <!-- 6th page -->
                <div class="socialAcc">
                <div
                class="text-center main-heading"
                style=""
                >
                <h1 style=""><span class="text-grey">Create </span> Playlists</h1>
                </div>
                <div class="mt-4 mb-30">
                    <!-- <div class="social-app">
                                <span><img src="../../../../assets/Indietown_icons/spotify-green.svg" alt="" height="75" width="90"></span>
                                <span><img src="../../../../assets/Indietown_icons/sound-cloud.svg" alt="" height="75" width="90"></span>
                                <span><img src="../../../../assets/Indietown_icons/bandcamp.svg" alt="" height="75" width="90"></span>
                            </div> -->
    
                    <div class="acc-block" style="margin-top: 5%; margin-bottom: 3%;">
                    <div>
                        <div class="selectacc"
                        style=" margin-bottom: 4%"
                        >
                        <label for="" style="font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        color: #0F0D0F"
                            >Select an account.</label
                        >
                        </div>
                        <!-- <img
                        [src]=spotifyimgsrc
                        height="75"
                        width="90"
                        alt=""
                        (click)="onSpotifySelected()"
                        [ngClass]="
                            spotifySelected == true
                            ? 'spotify-selected'
                            : 'spotify-notselected'
                        "
                        /> -->
                        <img
                        [src]=soundcloudimgsrc
                        height="75"
                        width="90"
                        alt=""
                        (click)="onSoundcloudSelected()"
                        [ngClass]="
                            soundcloudSelected == true
                            ? 'soundcloud-selected'
                            : 'soundcloud-notselected'
                        "
                        />
                        <img
                        [src]=bandcampimgsrc
                        height="75"
                        width="90"
                        alt=""
                        (click)="onbandcampSelected()"
                        [ngClass]="
                            bandcampSelected == true
                            ? 'bandcamp-selected'
                            : 'bandcamp-notselected'
                        "
                        />
                    </div>
                    </div>
    
                    <div class="form-group" *ngIf="bandcampSelected">
                    <span class="input-icon" style="position: absolute"
                        ><img
                        class="youtubeIcon"
                        style="
                            margin-top: 12px !important;
                        "
                        src="../../../../assets/Indietown_icons/bandcamp-input-icon.svg"
                        alt=""
                    /></span>
                    <input
                        [ngClass]="{'redbordercolor':bandcampError==true}"
                        class="form-control"
                        [(ngModel)]="bandcampURL"
                        placeholder="Enter Bandcamp embed (iframe) code"
                        id="spotifyURL"
                        (keypress)="CheckEnterPress($event)"
                    />
                        <div class="alert alert-danger" *ngIf="bandcampError">
                        <span>
                            {{bandcampErrorMsg}}
                        </span>
                        </div>
                    </div>
    
                    <div class="form-group" *ngIf="soundcloudSelected">
                    <span class="input-icon" style="position: absolute"
                        ><img
                        class="youtubeIcon"
                        style="
                            margin-top: 12px !important;
                        "
                        src="../../../../assets/Indietown_icons/soundcloud-input-icon.svg"
                        alt=""
                    /></span>
                    <input
                        [ngClass]="{'redbordercolor':soundcloudError==true}"
                        class="form-control"
                        [(ngModel)]="soundcloudURL"
                        placeholder="Enter SoundCloud embed (iframe) code"
                        id="spotifyURL"
                        (keypress)="CheckEnterPress($event)"
                    />
                    <div class="alert alert-danger" *ngIf="soundcloudError">
                        <span>
                            {{soundcloudErrorMsg}}
                        </span>
                    </div>
                    </div>
    
                    <div class="form-group" *ngIf="spotifySelected">
                    <span class="input-icon" style="position: absolute"
                        ><img
                        class="youtubeIcon"
                        style="
                            margin-top: 12px !important;
                        "
                        src="../../../../assets/Indietown_icons/spotify-green-black.svg"
                        alt=""
                    /></span>
                    <!-- Enter Spotify URL -->
                    <input
                        [ngClass]="{'redbordercolor':spotifyError==true}"
                        class="form-control"
                        [(ngModel)]="spotifyURL"
                        placeholder="Spotify URL: Album, Single, Playlist"
                        id="spotifyURL"
                        (keypress)="CheckEnterPress($event)"
                    />
                    <div class="alert alert-danger" *ngIf="spotifyError">
                        <span>
                            {{spotifyErrorMsg}}
                        </span>
                    </div>
                    </div>
                </div>
                <div class="socialForm">
                    <div class="form-group">
                    <div class="yt-icon">
                        <img
                        class="youtube-red"
                        src="../../../../assets/Indietown_icons/youtube-red.svg"
                        alt=""
                        height="75"
                        width="90"
                        />
                    </div>
                    <span class="input-icon" style="position: absolute"
                        ><img
                        class="youtubeIcon"
                        style="
                            margin-top: 12px !important;
                        "
                        src="../../../../assets/Indietown_icons/youtube-input-icon.svg"
                        alt=""
                    /></span>
                    <input
                        [ngClass]="{'redbordercolor':youtubeError==true}"
                        class="form-control"
                        [(ngModel)]="youtubeURL"
                        placeholder="YouTube URL: Video or Playlist"
                        id="youtubeURL"
                        (keypress)="CheckEnterPress($event)"
                    />
                    <div class="alert alert-danger" *ngIf="youtubeError">
                        <span>
                            {{youtubeErrorMsg}}
                        </span>
                    </div>
                    </div>
                </div>
                <div class="field btns" style="margin: auto;
                margin-top: 25px;display: flex;
    height: 70px;
    position: relative;">
                    <button
                    style="width: 540px;height: 70px;
                    font-size: 18px;
                    font-weight: 600;
                    cursor: pointer;
                    margin: 10px auto;
                    border-radius: 15px;"
                    class="next-5 next btn btn-primary"
                    (click)="save()"
                    [disabled]="(!spotifyURL && !soundcloudURL && !bandcampURL && !youtubeURL) || showLoader"
                    >
                    Save
                    </button>
                </div>
                <!-- *ngIf="showLoader" -->
                <div  class="mt-4" *ngIf="showLoader" style="text-align: center;width: 540px;">
                    <img width="50"  src="../../../assets/Indietown_icons/Loading_icon.gif">
                </div>
                <!-- <div class="field btns">
                    <button
                    class="next-5 next btn btn-primary"
                    (click)="connectSpcialAccount()"
                    [disabled]="updateProfile.value.spotifyURL =='' && updateProfile.value.soundcloudURL =='' && updateProfile.value.bandcampURL ==''  && updateProfile.value.youtubeURL ==''"
                    >
                    Next
                    </button>
                </div> -->
    
                <!-- <div class="text-center mat-30" style="transform: translate(0px, 10px);">
                    <span
                    class="skip-connections"
                    style="cursor: pointer"
                    (click)="skipconnectSpcialAccount()"
                    >Skip</span
                    >
                </div> -->
    
                <!-- <div class="d-flex justify-content-center">
                    <span class="backtoBio backButtonCommon">
                    <img src="../../../../assets/Indietown_icons/back-arrow-icon.svg" alt="" height="50" width="20">
                    </span>
                </div> -->
                <div class="m-20 d-flex justify-content-center" style="    margin-top: 20px;     margin-bottom: 20px;">
                    <a style="padding-top: 0px; cursor: pointer;" class="backbtnn backtoBio backButtonCommon">
                    <!-- <i class="fa fa-arrow-left" aria-hidden="true"></i> Back -->
                    </a>
                </div>
                </div>
                </div>
            </div>
            <!-- </form> -->
        </div>
    </div>
</div>
</div>

<ng-template #showErrorModal>
    <!-- <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="showErrorModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div> -->
    <div class="modal-body text-center">
        <div class="mt-5" style="margin-left: 30px;">
            <div class="success-msg mt-3 d-flex" *ngIf="spotifySuccessMsg"><img src="assets/Indietown_icons/Tick Square.svg" style="margin-right: 20px;" class="icon-blue" />{{spotifySuccessMsg}}</div>
            <div class="error-msg mt-3 d-flex" *ngIf="spotifyErrorMsg"><img src="assets/Indietown_icons/Close-Square.svg" style="margin-right: 20px;" class="icon-red" />{{spotifyErrorMsg}}</div>
            <div class="success-msg mt-3 d-flex" *ngIf="soundcloudSuccessMsg"><img src="assets/Indietown_icons/Tick Square.svg" style="margin-right: 20px;" class="icon-blue" />{{soundcloudSuccessMsg}}</div>
            <div class="error-msg mt-3 d-flex" *ngIf="soundcloudErrorMsg"><img src="assets/Indietown_icons/Close-Square.svg" style="margin-right: 20px;" class="icon-red" />{{soundcloudErrorMsg}}</div>
            <div class="success-msg mt-3 d-flex" *ngIf="bandcampSuccessMsg"><img src="assets/Indietown_icons/Tick Square.svg" style="margin-right: 20px;" class="icon-blue" />{{bandcampSuccessMsg}}</div>
            <div class="error-msg mt-3 d-flex" *ngIf="bandcampErrorMsg"><img src="assets/Indietown_icons/Close-Square.svg" style="margin-right: 20px;" class="icon-red" />{{bandcampErrorMsg}}</div>
            <div class="success-msg mt-3 d-flex" *ngIf="youtubeSuccessMsg"><img src="assets/Indietown_icons/Tick Square.svg" style="margin-right: 20px;" class="icon-blue" />{{youtubeSuccessMsg}}</div>
            <div class="error-msg mt-3 d-flex" *ngIf="youtubeErrorMsg"><img src="assets/Indietown_icons/Close-Square.svg" style="margin-right: 20px;" class="icon-red" />{{youtubeErrorMsg}}</div>
        </div>
      <!-- <p style="line-height: 26px;font-size: 16px;font-weight: bold;color: black;">...</p> -->
      <div class="modal-btn d-flex" style="margin-top: 40px; margin-bottom: 40px;">
        <button class="btn btn-red yesbtn" (click)="addMore()" style="margin-right: 10px;font-weight:bold;">Add More</button>
        <button class="btn btn-blue logoutbtn" (click)="continue()" style="background-color: black;font-weight:bold;">Continue</button>
      </div>
      <!-- <div *ngIf="showRemoveUrlLoader" style="text-align: center;">
        <img width="50"  src="../../../assets/Indietown_icons/Loading_icon.gif">
      </div> -->
    </div>
</ng-template>