import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-spotify-view',
  templateUrl: './spotify-view.component.html',
  styleUrls: ['./spotify-view.component.scss']
})
export class SpotifyViewComponent implements OnInit {

  constructor(
    private service: SharedService,
    private toastrService: ToastrService,
    private spinner: SpinnerVisibilityService,
    @Inject(DOCUMENT) private document: Document,
    private modalService: BsModalService,
    private route: Router
  ) { }

  userId;
  ngOnInit(): void {
    if(localStorage.getItem("spotifyUserId")){
      this.userId = localStorage.getItem("spotifyUserId");
    }
    this.getSpotifyList();
    // Read userID from route pending

  }

  spotifyList = [];
  spotifyPageNumber = 1;
  spotifyPageLimit = 20;
  getSpotifyList(){
    this.spinner.show();
    this.service.getSpotifyList(this.userId,this.spotifyPageNumber,this.spotifyPageLimit).subscribe((res:any)=>{
      console.log(res);
      this.spinner.hide();
      if(res.data.length){
        for(let i=0;i<res.data.length;i++){
          if(res.data[i].contentType == 'playlist' || res.data[i].contentType == 'album'){
            res.data[i].expanded = false;
          }
        }
      }
      this.spotifyList = res.data;
    },(err) => {
      this.spinner.hide();
      console.log("some error occured");
      this.toastrService.info("some error occured, please try again");
    }
    )
  }

  navigateToSelectedSong(type,spotifyIndex){
    // localStorage.setItem("spotifySongType",type);
    if(this.showDeleteIcon){
      return;
    }
    localStorage.setItem("spotifySongDetails",JSON.stringify(this.spotifyList[spotifyIndex]));
    if(type == 'track'){
      localStorage.setItem("spotifySongDetails",JSON.stringify(this.spotifyList[spotifyIndex]?.items[0]));
      this.route.navigateByUrl("/spotify-play");
    }
    else{
      localStorage.setItem("spotifyPlaylistDetails",JSON.stringify(this.spotifyList[spotifyIndex]));
      this.route.navigateByUrl("/spotify-playlist-play");
    }
  }

  @ViewChild("addMusicURL") addMusicURL:TemplateRef<any>;
  @ViewChild("addNewMusicURL") addNewMusicURL:TemplateRef<any>;
  @ViewChild("removeMusicPopup") removeMusicPopup:TemplateRef<any>;

  addMusicURLModalref;
  addNewMusicModalref;
  myInputValuedyn = '';
  showAddOrRemoveUrlLoader:boolean = false;
  
  openAddMusicPopup(){
    this.addMusicURLModalref = this.modalService.show(this.addMusicURL);
  }

  spotifyAddedSuccess: boolean = false;
  spotifyAddedInvalid: boolean = false;
  addSpotify(){
    this.showAddOrRemoveUrlLoader = true;
    console.log((<HTMLInputElement>document.getElementById("spotifyUrlNew")).value);
    let spotifyUrl = (<HTMLInputElement>document.getElementById("spotifyUrlNew")).value;
    this.service.addSpotifyUrl({url:spotifyUrl}).subscribe((res:any)=>{
      this.showAddOrRemoveUrlLoader = false;
      this.addMusicURLModalref.hide();
      console.log(res);
      this.spotifyList.unshift(res.data);
      (<HTMLInputElement>document.getElementById("spotifyUrlNew")).value = '';
      this.myInputValuedyn = '';
      this.spotifyAddedSuccess = true;
      this.addNewMusicModalref = this.modalService.show(this.addNewMusicURL);
    },(err)=>{
      this.showAddOrRemoveUrlLoader = false;
      console.log(err);
      this.addMusicURLModalref.hide();
      // this.toastrService.info(err?.error?.message);
      if(err?.error?.message == 'Spotify URL  already added'){
        this.addNewMusicModalref = this.modalService.show(this.addNewMusicURL);
      }
      else if(err?.error?.message == 'Spotify Album, Single or Playlist URL only'){
        this.spotifyAddedInvalid = true;
        this.addNewMusicModalref = this.modalService.show(this.addNewMusicURL);
      }
      (<HTMLInputElement>document.getElementById("spotifyUrlNew")).value = '';
      this.myInputValuedyn = '';
    })
  }

  addNewSpotify(){
    this.addNewMusicModalref?.hide();
    this.spotifyAddedSuccess = false;
    this.spotifyAddedInvalid = false;
    this.addMusicURLModalref = this.modalService.show(this.addMusicURL);
  }

  showDeleteIcon: boolean = false;
  onRightClick() {
    if(this.userId){
      return;
    }
    this.showDeleteIcon = true;
    return false;
  }

  close(){
    this.spotifyAddedSuccess = false;
    this.spotifyAddedInvalid = false;
    this.addNewMusicModalref.hide()
  }

  removeMusicModalref;
  removeMusicTrackDetails;
  removeMusicTrackIndex;
  remove(trackDetails,index){
    this.removeMusicModalref = this.modalService.show(this.removeMusicPopup);
    this.removeMusicTrackDetails = trackDetails;
    this.removeMusicTrackIndex = index;
  }

  removeMusic(){
    this.showAddOrRemoveUrlLoader = true;
    this.service.removeSpotifyUrl(this.removeMusicTrackDetails._id).subscribe((res:any)=>{
      console.log(res);
      if(res.type == 'success'){
        this.spotifyList.splice(this.removeMusicTrackIndex,1);
        if(!this.spotifyList.length){
          this.showDeleteIcon = false;
        }
      }
      this.showAddOrRemoveUrlLoader = false;
      this.removeMusicModalref.hide();
      // this.showDeleteIcon = false;
    },err => {
      this.showAddOrRemoveUrlLoader = false;
      this.removeMusicModalref.hide();
      this.toastrService.info("please try after sometime");
      // this.showDeleteIcon = false;
    })
  }
  hideRemoveMusic(){
    this.removeMusicModalref.hide();
    // this.showDeleteIcon = false;
  }
  navigateToSpotifyApp(){
    window.open("https://spotify.com", "_blank");
  }
  cancelRemove(){
    this.showDeleteIcon = false;
  }
  goBack(){
    this.route.navigateByUrl("/profile");
  }
}
