import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard/dashboard.component';
import { ProfileDynamicComponent } from './dynamic-profile/dynamic-profile.component';
import { TermComponent } from './term/term.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PrivacySettingComponent } from './settingsprivacy/privacysetting.component';
import { SpotifyAuthRedirectComponent } from './spotify-auth-redirect/spotify-auth-redirect.component';
import { SpotifyViewComponent } from './spotify-view/spotify-view.component';
import { SpotifyPlayComponent } from './spotify-play/spotify-play.component';
import { SpotifyPlaylistPlayComponent } from './spotify-playlist-play/spotify-playlist-play.component';
const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent }, 
  {path: 'profile', component: ProfileDynamicComponent},
  {path: 'friends-list', component: ProfileDynamicComponent},
  {path: 'terms-conditions', component: TermComponent},
  {path: 'privacy-policy', component: PrivacyComponent},
  {path: 'setting-privacy-policy', component: PrivacySettingComponent},
  {path: 'spotify-auth-redirect', component: SpotifyAuthRedirectComponent},
  {path: 'spotify-view', component: SpotifyViewComponent},
  {path: 'spotify-play', component: SpotifyPlayComponent},
  {path: 'spotify-playlist-play', component: SpotifyPlaylistPlayComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SharedRoutingModule {}
