import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-spotify-auth-redirect',
  templateUrl: './spotify-auth-redirect.component.html',
  styleUrls: ['./spotify-auth-redirect.component.scss']
})
export class SpotifyAuthRedirectComponent implements OnInit {

  constructor(
    private route: Router,
    private service: SharedService,
  ) { }

  ngOnInit(): void {

    const urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get('code');
    this.requestAccessToken(code);
  }

  requestAccessToken(code){
    let codeVerifier = localStorage.getItem('code_verifier');

    // b9568077e42a48ba89e30e3c7aa48b33 
    let body = new URLSearchParams({
      grant_type: 'authorization_code',
      code: code,
      // redirect_uri: 'http://localhost:4200/spotify-auth-redirect',
      // redirect_uri: 'https://indietown-web.approd.ca/spotify-auth-redirect',
      redirect_uri: 'https://stg-indietown-web.approd.ca/spotify-auth-redirect',
      client_id: '9dd84c590fe34a9f849c5b45df2046c0',
      code_verifier: codeVerifier
    });

    const response = fetch('https://accounts.spotify.com/api/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
      body: body
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('HTTP status ' + response.status);
      }
      return response.json();
    })
    .then(data => {

      let spotifyTokenInfo = {
        accessToken: data.access_token,
        refreshToken: data.refresh_token
      }
      // Need to add expiry time also
      let obj = {
        access_token: data.access_token,
        token_type: data.token_type,
        scope: data.scope,
        expires_in: data.expires_in,
        expiresAtDate: new Date().getTime() + (data.expires_in * 1000),
        refresh_token: data.refresh_token
      }
      localStorage.setItem('spotifyTokenInfo', JSON.stringify(obj));

      console.log(data);

      

      


      this.service.saveOrUpdateSpotifyToken(obj).subscribe((res:any)=>{
        console.log(res);
        localStorage.setItem('SpotifyAutorizedAndRedirected', "true");
        // COMMENTING FOR NEW SPOTIFY UI
        // this.route.navigate(['/profile']);
        this.route.navigate(['/spotify-play']);
      })  
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

}
